import React from 'react';

import ExerciseDetails from 'ui/components/molecules/exercise-details';

import { findCurrentActivity, MappedActivity } from 'ui/components/molecules/current-exercise/utils';

type Props = {
  currentTime: number,
  timeline: MappedActivity[],
};

export const CurrentExercise = ({ currentTime, timeline }: Props) => {
  const current = findCurrentActivity(timeline, currentTime);

  if (!current) {
    return null;
  }

  return (
    <ExerciseDetails
      currentTime={currentTime - current.startTime}
      duration={current.duration}
      name={current.name}
      disabled={false}
    />
  );
};

export default CurrentExercise;
