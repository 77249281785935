export type Trainers = Array<{
  firstname: string,
  lastname?: string,
}>;

export const trainerNames = (trainers: Trainers, prefix = '') => {
  const displayPrefix = prefix ? `${prefix} ` : '';
  if (trainers.length === 1) {
    const { firstname, lastname } = trainers[0];
    const lastnameDisplay = lastname ? ` ${lastname}` : '';
    return `${displayPrefix}${firstname}${lastnameDisplay}`;
  }

  const trainersNames = trainers.map(({ firstname }) => firstname);
  return `${displayPrefix}${trainersNames.slice(0, -1).join(', ')} & ${trainersNames.slice(-1)}`;
};
