import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rem, rgba } from 'ui/helpers';
import { useAppState } from 'state';

type Props = {
  className?: string,
  upNext?: ReactNode,
  exercise?: ReactNode,
  liveExercise?: ReactNode,
  heartRate?: ReactNode,
  heartRateZone?: ReactNode,
  fiitPoints?: ReactNode,
  leaderboard?: ReactNode,
  progressBar?: ReactNode,
  animation?: ReactNode,
  pauseVisible?: boolean,
  video?: ReactNode,
  countdown?: ReactNode,
  activityReps?: ReactNode,
  totalReps?: ReactNode,
  kcals?: ReactNode,
  bikeKcals?: ReactNode,
  bikeRPM?: ReactNode,
  bikeTotalScore?: ReactNode,
  treadKcals?: ReactNode,
  treadSpeed?: ReactNode,
  treadTotalScore?: ReactNode,
  showStatsOnly?: boolean,
  progressTop?: boolean,
  poweredBy?: ReactNode,
  hasVideo?: boolean,
};

interface StatProps extends Props {
  recordingModeEnabled: boolean,
  showStatsOnly: boolean,
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 1rem 0 1rem;
  overflow: hidden;
`;

const VideoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const ClassInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const MainRow = styled(Row)`
  height: 95%;
  align-items: flex-end;
`;

const TimelineRow = styled(Row)<{ pauseVisible: boolean }>`
  height: 5%;
  padding-left: ${({ theme }) => theme.misc.safeScreenPaddingHorizontal}rem;
  padding-right: ${({ theme }) => theme.misc.safeScreenPaddingHorizontal}rem;
  position: relative;
  ${({ pauseVisible }) => (pauseVisible ? 'z-index: 3' : '')};
`;

const ProgressBarContainer = styled.div<{ pauseVisible: boolean }>`
  background-color: transparent;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  ${({ pauseVisible }) => (pauseVisible ? 'opacity: 0.5' : '')};
`;

const statsOnlyBottomRow = css`
  position: absolute;
  bottom: ${rem(80)};
  right: 0;
  width: ${rem(430)};
  justify-content: space-between;
  border-radius: ${rem(4)} 0 0 ${rem(4)};
`;

const BottomRow = styled.div<{ showStatsOnly: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.black, 0.8)};
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme.misc.safeScreenPaddingHorizontal}rem;
  padding-right: ${({ theme }) => theme.misc.safeScreenPaddingHorizontal}rem;
  ${({ showStatsOnly }) => (showStatsOnly ? statsOnlyBottomRow : '')};
`;

const LeaderboardColumn = styled.div<{hasVideo?: boolean}>`
  position: absolute;
  height: ${rem(750)};
  width: 20%;
  right: 0;
  top: ${({ hasVideo }) => (hasVideo ? '0' : '10%')};
  margin: ${({ hasVideo }) => (hasVideo ? `1rem 1rem 2.5rem ${rem(60)}` : `1rem 3rem 2.5rem ${rem(60)}`)};
  overflow: hidden;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 80%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
`;

const LeaderboardSlot = styled.div`
  height: 100%;
  width: 100%;
`;

const ExerciseSlot = styled.div<{ pauseVisible: boolean }>`
  width: 41.5%;
  flex-grow: 1;
  position: relative;
  ${({ pauseVisible }) => (pauseVisible ? `
    opacity: 0.5;
    z-index: 3;
  ` : '')}
`;

const FiitPointsSlot = styled.div`
  width: 15%;
  margin-right: 0.5%;
`;

const HeartRateSlot = styled.div<{ restrictWidth: boolean }>`
  ${({ restrictWidth }) => (restrictWidth ? 'width: 25%' : '')};
`;

const UpNextSlot = styled.div`
  position: relative;
  margin-bottom: 1%;
`;

const Divider = styled.div<{ hide?: boolean }>`
  ${({ hide }) => (hide ? 'display: none' : '')};
  border-left: 1px solid ${({ theme }) => theme.colors.janosGrey};
  height: 1rem;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 65%;
  height: 65%;
`;

const TotalRepsContainer = styled.div`
  position: absolute;
  top: ${rem(50)};
  right: 0;
`;

const LiveExerciseContainer = styled.div`
  width: 76.5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PoweredByWrapper = styled.div`
  position: absolute;
  right: ${rem(80)};
  bottom: ${rem(60)};
  z-index: 1;
`;

const FiitPoints = ({ recordingModeEnabled, fiitPoints }: StatProps) => {
  if (recordingModeEnabled || !fiitPoints) {
    return null;
  }

  return (
    <>
      <Divider />
      <FiitPointsSlot>
        { fiitPoints }
      </FiitPointsSlot>
    </>
  );
};

const HeartRate = ({ recordingModeEnabled, heartRate, showStatsOnly }: StatProps) => {
  if (recordingModeEnabled || !heartRate) {
    return null;
  }

  return (
    <>
      <Divider hide={showStatsOnly} />
      <HeartRateSlot restrictWidth={!showStatsOnly}>
        { heartRate }
      </HeartRateSlot>
    </>
  );
};

const HeartRateZone = ({ recordingModeEnabled, heartRateZone }: StatProps) => {
  if (recordingModeEnabled || !heartRateZone) {
    return null;
  }

  return (
    <>
      <Divider />
      <HeartRateSlot restrictWidth>
        { heartRateZone }
      </HeartRateSlot>
    </>
  );
};

const ActivityReps = ({ recordingModeEnabled, activityReps }: StatProps) => {
  if (recordingModeEnabled || !activityReps) {
    return null;
  }

  return (
    <>
      <Divider />
      {activityReps}
    </>
  );
};

const Kcals = ({ recordingModeEnabled, kcals }: StatProps) => {
  if (recordingModeEnabled || !kcals) {
    return null;
  }

  return (
    <>
      <Divider />
      {kcals}
    </>
  );
};

const BikeKcals = ({ recordingModeEnabled, bikeKcals }: StatProps) => {
  if (recordingModeEnabled || !bikeKcals) {
    return null;
  }

  return (
    <>
      <Divider />
      {bikeKcals}
    </>
  );
};

const BikeRPM = ({ recordingModeEnabled, bikeRPM }: StatProps) => {
  if (recordingModeEnabled || !bikeRPM) {
    return null;
  }

  return (
    <>
      <Divider />
      {bikeRPM}
    </>
  );
};

const BikeTotalScore = ({ recordingModeEnabled, bikeTotalScore }: StatProps) => {
  if (recordingModeEnabled || !bikeTotalScore) {
    return null;
  }

  return (
    <>
      <Divider />
      {bikeTotalScore}
    </>
  );
};

const TreadKcals = ({ recordingModeEnabled, treadKcals }: StatProps) => {
  if (recordingModeEnabled || !treadKcals) {
    return null;
  }

  return (
    <>
      <Divider />
      {treadKcals}
    </>
  );
};

const TreadSpeed = ({ recordingModeEnabled, treadSpeed }: StatProps) => {
  if (recordingModeEnabled || !treadSpeed) {
    return null;
  }

  return (
    <>
      <Divider />
      {treadSpeed}
    </>
  );
};

const TreadTotalScore = ({ recordingModeEnabled, treadTotalScore }: StatProps) => {
  if (recordingModeEnabled || !treadTotalScore) {
    return null;
  }

  return (
    <>
      <Divider />
      {treadTotalScore}
    </>
  );
};

const TotalReps = ({ recordingModeEnabled, totalReps }: StatProps) => {
  if (recordingModeEnabled || !totalReps) {
    return null;
  }

  return (
    <TotalRepsContainer>{totalReps}</TotalRepsContainer>
  );
};

const InClass = ({
  className = '',
  video,
  liveExercise,
  exercise,
  upNext,
  fiitPoints,
  heartRate,
  heartRateZone,
  leaderboard,
  progressBar,
  countdown,
  pauseVisible = false,
  animation,
  activityReps,
  totalReps,
  kcals,
  bikeKcals,
  bikeRPM,
  bikeTotalScore,
  treadKcals,
  treadSpeed,
  treadTotalScore,
  showStatsOnly = false,
  progressTop = false,
  poweredBy,
  hasVideo = false,
}: Props) => {
  const { recordingModeEnabled } = useAppState((state) => state.flag);

  const statProps = {
    fiitPoints,
    heartRate,
    heartRateZone,
    activityReps,
    kcals,
    totalReps,
    bikeKcals,
    bikeRPM,
    bikeTotalScore,
    treadKcals,
    treadSpeed,
    treadTotalScore,
    recordingModeEnabled,
    showStatsOnly,
  };

  return (
    <Wrapper className={className}>
      <AnimationContainer>
        {animation}
      </AnimationContainer>
      <VideoContainer>
        { video }
      </VideoContainer>
      { countdown }
      <TotalReps {...statProps} />
      {progressTop && (
        <TimelineRow pauseVisible={pauseVisible}>
          <ProgressBarContainer pauseVisible={pauseVisible}>
            { progressBar }
          </ProgressBarContainer>
        </TimelineRow>
      )}
      <MainRow>
        {liveExercise ? (
          <LiveExerciseContainer>
            {liveExercise}
          </LiveExerciseContainer>
        ) : (
          <ClassInfoColumn>
            <UpNextSlot>
              { upNext }
            </UpNextSlot>
            <BottomRow showStatsOnly={showStatsOnly}>
              {!showStatsOnly && (
              <ExerciseSlot pauseVisible={pauseVisible}>
                { exercise }
              </ExerciseSlot>
              )}
              <FiitPoints {...statProps} />
              <HeartRate {...statProps} />
              <HeartRateZone {...statProps} />
              <ActivityReps {...statProps} />
              <Kcals {...statProps} />
              <BikeRPM {...statProps} />
              <BikeKcals {...statProps} />
              <BikeTotalScore {...statProps} />
              <TreadSpeed {...statProps} />
              <TreadKcals {...statProps} />
              <TreadTotalScore {...statProps} />
            </BottomRow>
          </ClassInfoColumn>
        )}
        <LeaderboardColumn hasVideo={hasVideo}>
          {leaderboard && <LeaderboardSlot>{leaderboard}</LeaderboardSlot>}
        </LeaderboardColumn>
      </MainRow>
      {poweredBy && (
        <PoweredByWrapper>
          {poweredBy}
        </PoweredByWrapper>
      )}
      {!progressTop && (
        <TimelineRow pauseVisible={pauseVisible}>
          <ProgressBarContainer pauseVisible={pauseVisible}>
            { progressBar }
          </ProgressBarContainer>
        </TimelineRow>
      )}
    </Wrapper>
  );
};

export default InClass;
