import React, { useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import RailCard, { CardSize } from 'ui/components/atoms/rail-card';
import { Link } from 'react-router-dom';
import { rem } from 'ui/helpers';
import { useSpatialTarget } from 'utils/spatial-nav';

export type Props = {
  name: string,
  backgroundImage?: string,
  backgroundColor?: string | null,
  to: string,
  autofocus?: boolean,
  size?: CardSize,
  dataTest?: string,
};

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: baseline;
  border-radius: 0.067rem;
  background-position: 80% 45%;
  background-repeat: no-repeat;
  background-size: ${rem(150)} ${rem(150)};
  padding: 0 ${({ theme }) => theme.spacing.m};
`;

const CardLink = styled(Link)`
  outline: none;
  display: block;
`;

const StudioCard = ({
  name,
  backgroundImage,
  backgroundColor,
  to,
  autofocus,
  size = 'studio',
  dataTest,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
  });

  const card = (
    <RailCard size={size} focus={focused} boxSize={size}>
      <CardContent style={{
        backgroundColor: backgroundColor || 'initial',
        backgroundImage: `url(${backgroundImage}?size=200x200)`,
      }}
      >
        <Typography
          color="black"
          variant="body-copy"
          data-test={dataTest}
          weight="bold"
        >
          {name}
        </Typography>
      </CardContent>
    </RailCard>
  );

  return (
    <CardLink ref={elRef} to={to} data-test="card-link">
      { card }
    </CardLink>
  );
};

export default StudioCard;
