import React, { createContext, useContext, ReactNode } from 'react';
import { platformBackKeyCodes } from 'utils/use-key-event';
import { LoggingLevel } from 'utils/logging';
// @ts-ignore - we're using js so we can pull it more easily into webpack
import importedConfig from 'config/config';

type SentryEnvironment = 'dev' | 'staging' | 'uat' | 'prod';
type CacheQuality = 'HIGHEST' | 'MEDIUM' | 'LOWEST';

export type Config = {
  ALLOW_IN_SESSION_DEBUG: boolean;
  AUTH_URL: string;
  AUTH_CLIENT_ID: string;
  AUTH_CLIENT_SECRET: string;
  SENTRY_DSN: string;
  CONNECT_APOLLO_DEV_TOOLS: boolean;
  APP_TYPE: string;
  VERSION: string;
  WORKOUT_API_URL: string;
  SUBSCRIPTION_API_URL: string;
  SUBSCRIPTIONS_MINIMUM_TOKEN_TTL_SECONDS: number;
  LESSON_INSTANCE_UPDATES_URL: string;
  POST_CLASS_DISPLAY_SECONDS: number;
  IDLE_OR_ERROR_DISPLAY_SECONDS: number;
  SENTRY_ENVIRONMENT: SentryEnvironment;
  PRE_CACHE_DASH_ASSETS: boolean;
  CACHE_DASH_ASSET_QUALITY: CacheQuality;
  REMOTE_LOGIN_WEBSOCKET_URL: string;
  REMOTE_LOGIN_PIN_BUFFER: number;
  INITIAL_LOAD_LOGO_ID: string;
  INITIAL_LOAD_TRANSITION_SECONDS: number;
  VIDEO_BUFFERING_TIMEOUT: number;
  FLAG_SIGN_UP_FLOW_ENABLED_DEFAULT: boolean;
  API_ENVIRONMENT: string;
  PLATFORM_RCU_BACK_BUTTON: keyof typeof platformBackKeyCodes;
  CHOOSE_MEDIA_OPTION_BUTTON: keyof typeof platformBackKeyCodes;
  MAGIC_AUTH_URL: string;

  // Video props
  VIDEO_TRAILING_BUFFER_SECONDS: number;
  VIDEO_MINIMUM_LEADING_BUFFER_SECONDS: number;
  VIDEO_MAXIMUM_LEADING_BUFFER_SECONDS: number;
  VIDEO_STALL_SKIP_SECONDS: number;
  VIDEO_DEFAULT_BANDWIDTH_ESTIMATE: number;
  VIDEO_MAX_RESOLUTION_HEIGHT: number;
  VIDEO_INCREASED_PLAYBACK_SPEED: number;

  // Video monitoring
  MONITOR_WORKOUT_VIDEO: boolean;
  MUX_DATA_ENV_KEY: string;

  // Rest endpoints
  AMAZON_RVS_HANDLER_URL: string;

  // State defaults
  FLAG_MAT_CONNECTIVITY_FLOW_ENABLED_DEFAULT: boolean;
  FLAG_CK_CONNECTIVITY_FLOW_ENABLED_DEFAULT: boolean;
  FLAG_RECORDING_MODE_ENABLED_DEFAULT: boolean;
  FLAG_IN_CLASS_ANIMATIONS_ENABLED_DEFAULT: boolean;
  FLAG_IN_CLASS_TRANSITIONS_ENABLED_DEFAULT: boolean;
  FLAG_DYNAMIC_HERO_TRANSITION_ENABLED_DEFAULT: boolean;
  FLAG_CLASS_DETAILS_EXTRA_ENABLED_DEFAULT: boolean;
  REACT_APP_FLAG_RECORDING_MODE_ENABLED_DEFAULT: boolean;
  FLAG_NOT_LOGGED_IN_MODE_ENABLED_DEFAULT: boolean;
  SCREEN_SAVER_ENABLED_DEFAULT: boolean;
  SCREEN_IDLE_TIME_SECS: number; // in seconds

  // Logging
  LOGGER_STREAM_NAME: string;
  LOGGER_URL: string;
  LOGGER_LEVEL: LoggingLevel;
};

type RawConfigValue = {
  value: any,
  format?: (value: any) => any,
};

const configContext = {
  config: Object.entries<RawConfigValue>(importedConfig)
    .reduce((formattedConfig, [key, { value, format }]) => ({
      ...formattedConfig,
      [key]: format ? format(value) : value,
    }), {}) as Config,
};

export const { config } = configContext;

// defined as an object so later we could add a debug menu using setConfig/useState
const context = createContext(configContext);

export const Index = ({ children }: { children: ReactNode }) => (
  <context.Provider value={configContext}>
    { children }
  </context.Provider>
);

export default () => useContext(context);
