import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

function ArrowRight({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
      <path
        d="M19.9 16l-1-1.1 2.4-2.4H0v-1.4h21.2l-2.4-2.2 1-.9 4.1 3.8-4 4.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
      />
    </Svg>
  );
}

export default ArrowRight;
