import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

import Button from 'ui/components/atoms/button';

export type Props = {
  icon?: ReactNode,
  selected?: boolean,
  label: string,
  expanded?: boolean,
  onClick?: (event: SyntheticEvent) => void,
  autofocus?: boolean,
};

const Wrapper = styled.li`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

const FullWidthButton = styled(Button)<{ expanded?: boolean }>`
  width: 100%;
  border-radius: 0 2rem 2rem 0;
  text-align: left;
  ${({ expanded }) => (expanded ? 'padding-left: 3rem' : '')};
`;

const MenuItem = ({ onClick, icon, selected, label, expanded, autofocus = false }: Props) => (
  <Wrapper>
    <FullWidthButton
      dataTest={`menu-item-${label.toLowerCase().replace(' ', '-')}`}
      variant={expanded ? 'menu' : 'icon'}
      onClick={onClick}
      icon={icon}
      label={expanded ? label : undefined}
      selected={selected}
      autofocus={autofocus}
      expanded={expanded}
    />
  </Wrapper>
);

export default MenuItem;
