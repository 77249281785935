import styled from 'styled-components';
import CardFocus from 'ui/components/atoms/card-focus';

export type CardSize = 'small' | 'large' | 'trainer' | 'studio' | 'upsell';

type Dimension = {
  height: string,
  width: string,
};

type DimensionRecord = Record<CardSize, Dimension>;

const dimensions: DimensionRecord = {
  large: { height: '7.8rem', width: '13.6rem' },
  small: { height: '5rem', width: '10rem' },
  trainer: { height: '9.8rem', width: '18rem' },
  studio: { height: '3.45rem', width: '15.3rem' },
  upsell: { height: '10.45rem', width: '15.6rem' },
};

const RailCard = styled(CardFocus)<{ size: CardSize }>`
  height: ${({ size }) => (dimensions[size].height)};
  width: ${({ size }) => (dimensions[size].width)};
  transform: translate3d(0, 0, 0);
`;

export default RailCard;
