import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const SvgRepeat = ({ className = '' }: IconProps) => (
  <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 1L21 5L17 9" stroke="#B2B2B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 11V9C3 7.93913 3.42143 6.92172 4.17157 6.17157C4.92172 5.42143 5.93913 5 7 5H21" stroke="#B2B2B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 23L3 19L7 15" stroke="#B2B2B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 13V15C21 16.0609 20.5786 17.0783 19.8284 17.8284C19.0783 18.5786 18.0609 19 17 19H3" stroke="#B2B2B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default SvgRepeat;
