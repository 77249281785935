import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgWatch = ({ className = '', color } : IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : theme.colors.white;
  return (
    <Svg className={className} x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
      <path
        style={{ fill }}
        d="M12,4.4c5.5,0,10,4.4,10,9.8S17.5,24,12,24S2,19.6,2,14.2S6.5,4.4,12,4.4z M12,6.5v7.6h7.8
    C19.8,10,16.3,6.5,12,6.5z M20.9,2.2c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S20.3,2.2,20.9,2.2z M15.3,0v2.2H8.7
    V0H15.3z"
      />
    </Svg>
  );
};

export default SvgWatch;
