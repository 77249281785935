import React from 'react';
import LottieAnimation from 'ui/components/atoms/animated-icons/lottie-animation';

import animationData from './loader-animation.json';

export type Props = {
  className?: string,
};

const AnimatedSVGLoader = ({ className = '' }: Props) => (
  <LottieAnimation
    className={className}
    loop
    autoplay
    animationData={animationData}
  />
);

export default AnimatedSVGLoader;
