import { FlagActionTypes } from 'actions/flag';
import createReducer from 'utils/create-reducer';
import { Config } from 'config';

export type FlagState = {
  matConnectivityFlowEnabled: boolean,
  ckConnectivityFlowEnabled: boolean,
  inClassAnimationsEnabled: boolean,
  inClassTransitionsEnabled: boolean,
  dynamicHeroTransitionEnabled: boolean,
  signUpFlowEnabled: boolean,
  classDetailsExtraEnabled: boolean,
  recordingModeEnabled: boolean,
  notLoggedInModeEnabled: boolean,
};

const setMatConnectivityFlowEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  matConnectivityFlowEnabled: payload,
});

const setCkConnectivityFlowEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  ckConnectivityFlowEnabled: payload,
});

const setInClassAnimationsEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  inClassAnimationsEnabled: payload,
});

const setInClassTransitionsEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  inClassTransitionsEnabled: payload,
});

const setDynamicHeroTransitionEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  dynamicHeroTransitionEnabled: payload,
});

const setSignUpFlowEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  signUpFlowEnabled: payload,
});

const setClassDetailsExtraEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  classDetailsExtraEnabled: payload,
});

const setRecordingModeEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  recordingModeEnabled: payload,
});

const setNotLoggedInModeEnabled = (state: FlagState, payload: boolean) => ({
  ...state,
  notLoggedInModeEnabled: payload,
});

const handlers = {
  [FlagActionTypes.SET_MAT_CONNECTIVITY_FLOW_ENABLED]: setMatConnectivityFlowEnabled,
  [FlagActionTypes.SET_CK_CONNECTIVITY_FLOW_ENABLED]: setCkConnectivityFlowEnabled,
  [FlagActionTypes.SET_IN_CLASS_ANIMATIONS_ENABLED]: setInClassAnimationsEnabled,
  [FlagActionTypes.SET_IN_CLASS_TRANSITIONS_ENABLED]: setInClassTransitionsEnabled,
  [FlagActionTypes.SET_DYNAMIC_HERO_TRANSITION_ENABLED]: setDynamicHeroTransitionEnabled,
  [FlagActionTypes.SET_SIGN_UP_FLOW_ENABLED]: setSignUpFlowEnabled,
  [FlagActionTypes.SET_CLASS_DETAILS_EXTRA_ENABLED]: setClassDetailsExtraEnabled,
  [FlagActionTypes.SET_RECORDING_MODE_ENABLED]: setRecordingModeEnabled,
  [FlagActionTypes.SET_NOT_LOGGED_IN_MODE_ENABLED]: setNotLoggedInModeEnabled,
};

// We can't access config on the first initial state setting, so have to accept null as a propType
export const initialFlagState = (config: Config | null) => (
  {
    matConnectivityFlowEnabled: config?.FLAG_MAT_CONNECTIVITY_FLOW_ENABLED_DEFAULT || false,
    ckConnectivityFlowEnabled: config?.FLAG_CK_CONNECTIVITY_FLOW_ENABLED_DEFAULT || false,
    inClassAnimationsEnabled: config?.FLAG_IN_CLASS_ANIMATIONS_ENABLED_DEFAULT || false,
    inClassTransitionsEnabled: config?.FLAG_IN_CLASS_TRANSITIONS_ENABLED_DEFAULT || false,
    dynamicHeroTransitionEnabled: config?.FLAG_DYNAMIC_HERO_TRANSITION_ENABLED_DEFAULT || false,
    signUpFlowEnabled: config?.FLAG_SIGN_UP_FLOW_ENABLED_DEFAULT || false,
    classDetailsExtraEnabled: config?.FLAG_CLASS_DETAILS_EXTRA_ENABLED_DEFAULT || false,
    recordingModeEnabled: config?.FLAG_RECORDING_MODE_ENABLED_DEFAULT || false,
    notLoggedInModeEnabled: config?.FLAG_NOT_LOGGED_IN_MODE_ENABLED_DEFAULT || false,
  }
);

export const flagReducer = createReducer(handlers);
