import React, { FC } from 'react';
import styled from 'styled-components';
import { addDays, format } from 'date-fns';

import Typography from 'ui/components/atoms/typography';
import ScheduledClass, { Props as ScheduledClassProps } from 'ui/components/molecules/scheduled-class';
import EmptyState from 'ui/components/molecules/empty-state';
import GhostIcon from 'ui/components/atoms/icons/ghost';
import { rem } from 'ui/helpers';

type LessonInstance = ScheduledClassProps['instance'];

export type Props = {
  instances: LessonInstance[],
  selectedDayNumber: string,
  updateDayNumber?: (day: string) => void,
  canScheduleLiveClasses: boolean,
};

const ScheduleContainer = styled.div`
  margin: ${({ theme }) => `0 ${theme.spacing.xl} ${theme.spacing.xl}`};
  padding: ${({ theme }) => theme.spacing.m};
  width: 90%;
`;

const DayBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${rem(24)};
`;

const DayFirstLetter = styled(Typography)<{ isBold?: boolean }>`
  margin-bottom: 8px;
  align-self: center;
`;

const DayText = styled(Typography)<{ selected?: boolean }>`
  margin-bottom: 8px;
  padding: 5px;
  width: ${rem(50)};
  height: ${rem(50)};
  border-radius: 50%;
  cursor: pointer;
  font-weight: 600;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.janosGrey)};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

const AllDaysBox = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  margin: ${({ theme }) => `${theme.spacing.xl} 0 ${theme.spacing.xl}`};
`;

const getNextDays = () => {
  const daysArray = [...Array(28).keys()].map((i) => {
    const day = addDays(new Date(), i);
    const dayNumber = format(day, 'dd');
    const dayFirstLetters = format(day, 'EEE').substring(0, 2);
    const fullDay = format(day, 'yyyy-MM-dd');

    return {
      dayNumber,
      dayFirstLetters,
      fullDay,
    };
  });

  return daysArray;
};

const nextDaysArray = getNextDays();

export const ScheduleDisplay: FC<Props> = ({
  instances,
  updateDayNumber = () => null,
  selectedDayNumber,
  canScheduleLiveClasses,
}: Props) => (
  <ScheduleContainer>
    <Typography variant="double-pica">STUDIO</Typography>
    <AllDaysBox>
      {nextDaysArray.map((nextDay) => (
        <DayBox key={nextDay.dayNumber}>
          <DayFirstLetter
            isBold={['SA', 'SU'].includes(nextDay.dayFirstLetters)}
          >
            {nextDay.dayFirstLetters}
          </DayFirstLetter>
          <DayText
            onClick={async () => {
              await updateDayNumber(nextDay.fullDay);
            }}
            selected={nextDay.dayNumber === selectedDayNumber}
          >
            {nextDay.dayNumber}
          </DayText>
        </DayBox>
      ))}
    </AllDaysBox>
    { instances.length > 0 ? (
      instances.map((instance) => (
        <ScheduledClass key={instance.id} instance={instance} canScheduleLiveClasses={canScheduleLiveClasses} />
      ))
    ) : (
      <EmptyState
        icon={<GhostIcon />}
        heading="Nothing scheduled yet"
      />
    )}

  </ScheduleContainer>
);

export default ScheduleDisplay;
