import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from 'ui/components/atoms/button';
import { rem } from 'ui/helpers';
import { SpatialNavParent } from 'utils/spatial-nav';
import { usePageScroll } from 'utils/use-page-scroll';

export { useDurationFilter, AllDurations, DurationRanges } from 'ui/components/molecules/filter-bar/hooks';

export type Props = {
  className?: string,
  enums: {[key:string]: string},
  labelFromEnum?: (val: string) => string
  selected?: string,
  onSelect: (value: string) => void,
  autofocus?: boolean,
  disableAllFilter?: boolean,
  resetScroll?: boolean,
};

const Wrapper = styled.div`
  display: flex;
`;

const Item = styled.div`
  margin-right: ${rem(30)};
`;

type FilterItemProps = {
  selected?: boolean,
  label: string,
  variant: ButtonVariant,
  onSelect: (value: string) => void,
  value: string,
  autofocus: boolean,
};

const FilterItem = ({ selected = false, label, variant, onSelect, value, autofocus }: FilterItemProps) => {
  const onSelectCallback = useCallback(() => {
    if (!selected) {
      onSelect(value);
    }
  }, [onSelect, selected, value]);

  return (
    <Item>
      <Button
        onClick={onSelectCallback}
        dataTest={`filter-item-${value}`}
        label={label}
        selected={selected}
        variant={variant}
        autofocus={autofocus}
      />
    </Item>
  );
};

const buildOptionsFromEnums = (
  enums: {[key:string]: string},
  labelFromEnum?: (val: string) => string,
  disableAllFilter?: boolean,
) => (
  [
    ...(!disableAllFilter ? [{
      label: 'All',
      value: 'ALL',
    }] : []),
    ...Object.values(enums).map((val) => ({
      label: labelFromEnum ? labelFromEnum(val) : val,
      value: val,
    })),
  ]
);

export const FilterBar = ({
  className,
  enums,
  labelFromEnum,
  selected,
  onSelect,
  autofocus = false,
  disableAllFilter = false,
  resetScroll = false,
}: Props) => {
  const options = buildOptionsFromEnums(enums, labelFromEnum, disableAllFilter);

  const pageScroll = usePageScroll();

  const onFocus = useCallback(() => resetScroll && pageScroll(0), [pageScroll, resetScroll]);

  return (
    <Wrapper className={className}>
      <SpatialNavParent layout="horizontal" onFocus={onFocus}>
        { options.map((option, index) => (
          <FilterItem
            key={option.value}
            selected={selected === option.value}
            label={option.label}
            variant="filter"
            onSelect={onSelect}
            value={option.value}
            autofocus={autofocus && index === 0}
          />
        )) }
      </SpatialNavParent>
    </Wrapper>
  );
};

export default FilterBar;
