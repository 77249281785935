import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useConfig from 'app/on-tv/config-provider';

const useDelayOnBack = () => {
  const history = useHistory();
  const [shouldDelay, setShouldDelay] = useState(false);
  const { config } = useConfig();

  useEffect(() => {
    if (!config.BACK_TRANSITION_MINIMUM_DELAY_MS) {
      return;
    }

    history.listen((_, action) => {
      setShouldDelay(action === 'POP');
    });
  }, [config.BACK_TRANSITION_MINIMUM_DELAY_MS, history]);

  useEffect(() => {
    if (!shouldDelay) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setShouldDelay(false);
    }, config.BACK_TRANSITION_MINIMUM_DELAY_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [shouldDelay, config.BACK_TRANSITION_MINIMUM_DELAY_MS]);

  return shouldDelay;
};

export default useDelayOnBack;
