import React from 'react';
import styled from 'styled-components';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';
import { Link } from 'react-router-dom';

const LogoContainer = styled.div`
  position: absolute;
  left: 1em;
  top: 1em;
  z-index: 1;
`;

const Wrapper = styled.div`
  background-color: black;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 10%;
  text-align: center;
`;

const FourOhFour = () => (
  <Wrapper>
    <LogoContainer>
      <Logo color="white" />
    </LogoContainer>
    <Typography as="div" variant="paragon">404 Error</Typography>
    <Typography as="div" variant="double-pica">
      Looks like that link is taking a rest day! Let’s get you back on track:
    </Typography>
    <Link to="/">Home Page</Link>
  </Wrapper>
);

FourOhFour.menu = false;

export default FourOhFour;
