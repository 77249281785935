import styled from 'styled-components';
import { ThemeColors } from 'ui/theme';

type Props = {
  variant?: TypographyVariant,
  size?: TypographySize,
  color?: ThemeColors,
  weight?: TypographyWeight,
  spacing?: TypographySpacing,
  uppercase?: boolean,
  lineHeight?: TypographyLineHeight,
};

const paragon: Props = {
  size: 'xl',
  lineHeight: 'xs',
  weight: 'heavy',
  spacing: 'm',
  uppercase: true,
};

const doublePica: Props = {
  size: 'l',
  lineHeight: 's',
  weight: 'bold',
  spacing: 'xs',
};

const bodyCopy: Props = {
  spacing: 's',
  lineHeight: 'l',
};

const pica: Props = {
  size: 's',
  spacing: 'xl',
  weight: 'bold',
  uppercase: true,
};

const longPrimer: Props = {
  size: 'xs',
};

const brevier: Props = {
  size: 'xxs',
};

const minion: Props = {
  size: 'xxxs',
  uppercase: true,
  spacing: 'l',
};

const variantPresets = {
  paragon,
  'double-pica': doublePica,
  'body-copy': bodyCopy,
  pica,
  'long-primer': longPrimer,
  brevier,
  minion,
};
export type TypographyVariant = keyof typeof variantPresets;

// 1 rem is "m" which is assigned to body-copy - that's your standard page text which everything is relative to
const fontSizeMap = {
  xl: 2.6, // 78px @1080p
  l: 1.4, // 42px @1080p
  m: 1, // 30px @1080p
  s: 0.8, // 24px @1080p
  xs: 0.666, // 20px @1080p
  xxs: 0.533, // 16px @1080p (almost legible?)
  xxxs: 0.4, // 12px @1080 (most probably illegible?!)
};
export type TypographySize = keyof typeof fontSizeMap;
const fontSizePicker = ({ size = 'm' }: Props) => fontSizeMap[size];

const lineHeightMap = {
  l: 1.33,
  m: 1,
  s: 0.95,
  xs: 0.91,
};
export type TypographyLineHeight = keyof typeof lineHeightMap;
const lineHeightPicker = ({ lineHeight }: Props) => (
  lineHeight ? `line-height: ${lineHeightMap[lineHeight]};` : ''
);

// Matched to font weights defined in fonts.css.ts
const fontWeightMap = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  heavy: 900,
};
export type TypographyWeight = keyof typeof fontWeightMap;
const fontWeightPicker = ({ weight = 'regular' }: Props) => fontWeightMap[weight];

const letterSpacingMap = {
  xl: 0.133,
  l: 0.067,
  m: 0.028,
  s: 0.0166,
  xs: 0.0133,
};
export type TypographySpacing = keyof typeof letterSpacingMap;
const letterSpacingPicker = ({ spacing }: Props) => (
  spacing ? `
    letter-spacing: ${letterSpacingMap[spacing]}rem;
    margin-right: -${letterSpacingMap[spacing]}rem;
  ` : ''
);

export const bodyCopyString = `
  font-weight: ${fontWeightPicker({})};
  ${lineHeightPicker({ lineHeight: bodyCopy.lineHeight })}
  ${letterSpacingPicker({ spacing: bodyCopy.spacing })}
`;

const Typography = styled.span.attrs<Props>(({ variant, ...props }) => ({
  ...(variant && variantPresets[variant]),
  ...props,
}))`
  margin: 0;
  color: ${({ theme, color }) => (color ? theme.colors[color] : 'inherit')};
  font-weight: ${fontWeightPicker};
  font-size: ${fontSizePicker}rem;
  ${lineHeightPicker}
  ${letterSpacingPicker}
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : '')};
`;

export default Typography;
