import { useMemo } from 'react';
import useConfig from 'config';
import { createLogger } from 'utils/logging';

export default function useLogger(label: string) {
  const { config } = useConfig();
  const streamName = config.LOGGER_STREAM_NAME;
  const logUrl = config.LOGGER_URL;
  const loggerLevel = config.LOGGER_LEVEL;

  return useMemo(
    () => createLogger(
      { label, streamName, logUrl, loggerLevel },
    ),
    [label, streamName, logUrl, loggerLevel],
  );
}
