import React from 'react';
import styled from 'styled-components';
import ProfileIcon from 'ui/components/atoms/icons/profile';
import { rem } from 'ui/helpers';
import Link from 'ui/components/atoms/link';

export type Props = {
  imageUrl?: string | null,
  className?: string,
  focused?: boolean,
  to?: string,
  borderless?: boolean,
};

type UserImageProps = {
  url: string
};

const UserImage = styled.div<{ focused?: boolean, borderless?: boolean }>`
  display: block;
  border-radius: 50%;
  background-size: cover;
  ${({ borderless, theme }) => (borderless ? '' : `border: ${rem(5)} solid ${theme.colors.black}`)};
`;

const ImageContainer = styled.div<{ focused?: boolean, borderless?: boolean }>`
  display: block;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.fordDarkCharcoal};
  position: relative;
  ${({ borderless, theme }) => (borderless ? '' : `border: ${rem(5)} solid ${theme.colors.black}`)};
`;

const StyledProfileIcon = styled(ProfileIcon)`
  height: 50%;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// I don't think it understands the ${} inserts
/* stylelint-disable declaration-block-semicolon-newline-after, rule-empty-line-before */
const Wrapper = styled(Link)<{ borderless?: boolean }>`
  ${UserImage},
  ${ImageContainer} {
    ${({ borderless, theme }) => (borderless ? '' : `border: ${rem(5)} solid ${theme.colors.wednesdayGrey}`)};
  }

  &:focus {
    ${UserImage},
    ${ImageContainer} {
      border: ${rem(5)} solid ${({ theme }) => theme.colors.fiitBlue};
    }
  }
`;

const ProfileImage = ({ imageUrl, className, to, borderless }: Props) => (
  <Wrapper {...(to ? { to } : { as: 'div' })} borderless={borderless}>
    {
      imageUrl && (
        <UserImage
          tabIndex={0}
          data-test="menu-avatar-image"
          className={className}
          style={{ backgroundImage: `url("${imageUrl}")` }}
          borderless={borderless}
        />
      )
    }
    {
      !imageUrl && (
        <ImageContainer className={className} borderless={borderless}>
          <StyledProfileIcon />
        </ImageContainer>
      )
    }
  </Wrapper>
);

export default ProfileImage;
