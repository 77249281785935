import React from 'react';
import styled from 'styled-components';

import ReactQRCode from 'qrcode.react';

export type Props = {
  mode?: 'svg' | 'canvas',
  url?: string,
  className?: string,
};

const StyledQRCode = styled(ReactQRCode)`
  height: 100%;
  width: 100%;
`;

const QRCode = ({ mode, url, className }: Props) => (url ? (
  <StyledQRCode className={className} value={url} renderAs={mode || 'svg'} />
) : null);

export default QRCode;
