import React from 'react';
import { format } from 'date-fns';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { StatsQuery, StatsQueryVariables, LessonInstanceStatsFragmentFragment } from 'app/in-studio/types/graphql';
import { Stats as STATS_QUERY } from 'app/in-studio/pages/stats/stats-query.gql';
import { useAppState } from 'state';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

type StatsDetailsProps = {
  userId: number,
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const StatsDetails = ({ userId }: StatsDetailsProps) => {
  useDismissEvent();

  const { data, loading, error } = useQuery<StatsQuery, StatsQueryVariables>(STATS_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const lessonInstances = data?.auth?.user?.lessonInstanceSignUps.edges || [];

  const nodeToLessonInstanceSignup = ({
    id,
    workoutStartTime,
    lesson: {
      name,
      studio: {
        id: studioId,
      },
      equipmentLevel,
    },
    userSignUpCount,
    workouts: {
      totalCount,
    },
  }: LessonInstanceStatsFragmentFragment) => {
    const nameAndStudioStr = `${name} (${studioId})`;
    const equStr = equipmentLevel?.map((eq) => `${eq.equipment.longDisplay} (${eq.displayName})`).join(',');
    const dateStr = workoutStartTime ? format(new Date(workoutStartTime), 'dd MMM yyyy, HH:mm') : '';
    const signupsStr = `${Math.max(userSignUpCount - 1, 0)} signups`;
    const attendesStr = `${totalCount} attended`;
    return <div key={id}>{nameAndStudioStr} {equStr} - {dateStr} - {signupsStr} - {attendesStr} </div>;
  };

  return (
    <>
      <Wrapper>
        {
          lessonInstances.map((instance) => nodeToLessonInstanceSignup(instance.node))
        }
      </Wrapper>
    </>
  );
};

const StatsPage = () => {
  const userId = useAppState((state) => state.auth.userId);

  if (!userId) {
    return <div>error</div>;
  }

  return <StatsDetails userId={userId} />;
};

StatsPage.menu = true;

export default StatsPage;
