import camelCase from 'lodash/fp/camelCase';
import isPlainObject from 'lodash/fp/isPlainObject';

export default function camelify(obj: object | object[]): any | any[] {
  if (Array.isArray(obj)) {
    return obj.map(camelify);
  }

  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => ({
      ...acc,
      [camelCase(key)]: camelify((obj as any)[key]),
    }), {});
  }
  return obj;
}
