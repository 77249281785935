import { rem } from 'ui/helpers';

export default `/*!
* https://github.com/arqex/react-datetime
*/

.rdt {
 position: relative;
}
.rdt input {
 width: 12rem;
 background: #000;
 border-radius: 15px;
 border: none;
 color: white;
 padding: 0.5rem 0.25rem 0.25rem 0.25rem;
 text-align: center;
 height: 2rem;
}
.rdtPicker {
 display: none;
 position: absolute;
 min-width: 250px;
 padding: 4px;
 margin-top: 1px;
 z-index: 99999 !important;
 background: #000;
 border: 2px solid #4d4d4d;
 width: 16rem;
 border-radius: 15px;
 left: -2rem;
}
.rdtDays {
 padding: 0.75rem 0.75rem 0;
}
.rdtOpen .rdtPicker {
 display: block;
}
.rdtStatic .rdtPicker {
 box-shadow: none;
 position: static;
}

.rdtPicker .rdtTimeToggle {
 text-align: center;
}

.rdtPicker table {
 width: 100%;
 margin: 0;
}
.rdtPicker td,
.rdtPicker th {
 text-align: center;
 height: 28px;
 border-radius: 10px;
 line-height: ${rem(45)};
}
.rdtPicker td {
 border: 2px solid transparent;
 cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
 background: #4d4d4d;
 cursor: pointer;
 border-radius: 15px;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
 color: #999999;
}
.rdtPicker td.rdtToday {
 position: relative;
}
.rdtPicker td.rdtToday:before {
 content: '';
 display: inline-block;
 border-left: 7px solid transparent;
 border-bottom: 7px solid #428bca;
 border-top-color: rgba(0, 0, 0, 0.2);
 position: absolute;
 bottom: 4px;
 right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
 color: #fff;
 border: 2px solid white;
 border-radius: 15px;
}
.rdtPicker td.rdtActive.rdtToday:before {
 border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
 background: none;
 color: #999999;
 cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
 color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
 background: none;
 color: #999999;
 cursor: not-allowed;
}
.rdtPicker .dow {
 width: 14.2857%;
 border-bottom: none;
 cursor: default;
}
.rdtPicker th.rdtSwitch {
 width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
 font-size: 21px;
 vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
 display: block;
 -webkit-touch-callout: none; /* iOS Safari */
 -webkit-user-select: none;   /* Chrome/Safari/Opera */
 -khtml-user-select: none;    /* Konqueror */
 -moz-user-select: none;      /* Firefox */
 -ms-user-select: none;       /* Internet Explorer/Edge */
 user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
 background: none;
 color: #999999;
 cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
 cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
 background: #4d4d4d;
}

.rdtPicker tfoot {
 border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
 border: none;
 background: none;
 cursor: pointer;
}
.rdtPicker button:hover {
 background-color: #4d4d4d;
}

.rdtPicker thead button {
 width: 100%;
 height: 100%;
}

td.rdtMonth,
td.rdtYear {
 height: 50px;
 width: 25%;
 cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
 background: #4d4d4d;
}

.rdtCounters {
 display: inline-block;
}

.rdtCounters > div {
 float: left;
}

.rdtCounter {
 height: 130px;
}

.rdtCounter {
 width: 50px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-evenly;
 margin: 0 0.15rem;
}

.rdtCounterSeparator {
 line-height: 125px;
}

.rdtCounter .rdtBtn {
 height: 40%;
 line-height: 40px;
 cursor: pointer;
 display: block;

 -webkit-touch-callout: none; /* iOS Safari */
 -webkit-user-select: none;   /* Chrome/Safari/Opera */
 -khtml-user-select: none;    /* Konqueror */
 -moz-user-select: none;      /* Firefox */
 -ms-user-select: none;       /* Internet Explorer/Edge */
 user-select: none;
}
.rdtCounter .rdtBtn:hover {
 background: #4d4d4d;
 border-radius: 15px;
}
.rdtCounter .rdtCount {
 height: 35%;
 font-size: 1.2em;
 padding-bottom: 1.75rem;
}

.rdtMilli {
 vertical-align: middle;
 padding-left: 8px;
 width: 48px;
}

.rdtMilli input {
 width: 100%;
 font-size: 1.2em;
 margin-top: 37px;
}

.rdtTime td {
 cursor: default;
}
`;
