import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 0.5rem;
  height: 0.5rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgChevronRight = ({ className = '' }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 10 16" className={className}>
      <defs>
        <path id="chevron_right_svg__a" d="M0 1l8 7-8 7 1 1c2.13-1.69 7.279-6.411 9-8-1.278-1.162-.031-.02-9-8L0 1z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="chevron_right_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#chevron_right_svg__a" />
        </mask>
        <use fill="#231F20" xlinkHref="#chevron_right_svg__a" />
        <g fill={theme.colors.white} mask="url(#chevron_right_svg__b)">
          <path d="M0 0H24V24H0z" transform="translate(-8 -4)" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgChevronRight;
