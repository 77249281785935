import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgBikeTotalScore = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#00DCEA';

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <g clipPath="url(#BikeTotalScore_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.207 8l4.96-5.333L9.053 8h1.74l-4.96 5.333L7.947 8h-1.74Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="BikeTotalScore_svg__a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default SvgBikeTotalScore;
