import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgLive = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} viewBox="0 0 40 40">
      <g clipPath="url(#Virtual_svg__a)">
        <g fill={fill} clipPath="url(#Virtual_svg__b)">
          <path d="M20.018 9h10.447c2.69 0 4.53 1.885 4.533 4.645.002 4.609.006 9.218-.002 13.827-.004 2.328-1.495 4.114-3.726 4.483-.265.044-.538.042-.807.042-6.978.003-13.955.004-20.933 0-2.213 0-3.905-1.302-4.4-3.391a4.945 4.945 0 0 1-.124-1.136c-.006-4.648-.009-9.297-.002-13.945.004-2.606 1.874-4.52 4.413-4.523C12.95 8.996 16.485 9 20.018 9Zm-.014 20.836c3.507 0 7.015.004 10.522-.002 1.427-.002 2.348-.936 2.352-2.4.008-4.622.008-9.244 0-13.865-.002-1.468-.919-2.406-2.344-2.407-7.028-.004-14.055-.005-21.083 0-1.4 0-2.334.947-2.336 2.379-.008 4.634-.014 9.27.014 13.904.003.464.154.977.384 1.377.432.756 1.169 1.016 2.008 1.015 3.494-.005 6.99-.002 10.484-.002h-.001Z" />
          <path d="M21.051 18.652V25.406c0 .466-.188.821-.598 1.024-.377.185-.754.144-1.092-.114-.329-.25-.418-.606-.416-1.013.006-2.033.003-4.067.003-6.1v-.504c-.135.12-.22.186-.297.263-.746.765-1.485 1.536-2.24 2.292-.384.384-.893.433-1.308.166a1.06 1.06 0 0 1-.454-1.2c.064-.204.189-.409.337-.562a627.944 627.944 0 0 1 4.195-4.316c.522-.533 1.112-.536 1.63-.008a817.386 817.386 0 0 1 4.198 4.314c.488.505.507 1.177.073 1.622-.444.454-1.067.422-1.574-.093-.793-.808-1.58-1.622-2.456-2.524h-.001Z" />
        </g>
      </g>
      <defs>
        <clipPath id="Virtual_svg__a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
        <clipPath id="Virtual_svg__b">
          <path fill="#fff" d="M5 9h30v23H5z" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default SvgLive;
