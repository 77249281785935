import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgMedalGold = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 42 42" className={className} fill="none">
    <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_6412_12915)" />
    <circle cx="21" cy="21" r="16" stroke="#D4A30C" strokeWidth="2" />
    <path d="M20.0799 13.2031H22.9599V30.0031H19.6479V16.7071L16.4799 17.5951L15.6639 14.7631L20.0799 13.2031Z" fill="black" fillOpacity="0.6" />
    <defs>
      <linearGradient id="paint0_linear_6412_12915" x1="2.45" y1="3.85" x2="36.4" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F6CA44" />
        <stop offset="1" stopColor="#B98D09" />
      </linearGradient>
    </defs>
  </Svg>
);

export default SvgMedalGold;
