import React, { createContext, useContext, ReactNode } from 'react';

import { Config } from 'config';
import createAuthService from 'services/auth';

type ExtendedConfig = Config & {
  AUTH_CLIENT_ID: string,
  AUTH_CLIENT_SECRET: string,
};

const createServices = (config: ExtendedConfig) => ({
  auth: createAuthService({
    clientId: config.AUTH_CLIENT_ID,
    clientSecret: config.AUTH_CLIENT_SECRET,
    url: config.AUTH_URL,
    magicLinkUrl: config.MAGIC_AUTH_URL,
  }),
});

const context = createContext({} as ReturnType<typeof createServices>);

export const ServicesProvider = ({ children, config }: { children: ReactNode, config: ExtendedConfig }) => (
  <context.Provider value={createServices(config)}>
    { children }
  </context.Provider>
);

export default () => useContext(context);
