import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgRow = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FFFFFF';
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M2.375 5.682c0-.377.308-.682.688-.682s.687.305.687.682v5.454a.685.685 0 0 1-.687.682.685.685 0 0 1-.688-.682zM18.875 11.136c0 1.13-.923 2.046-2.062 2.046a2.054 2.054 0 0 1-2.063-2.046c0-1.13.923-2.045 2.063-2.045 1.139 0 2.062.916 2.062 2.045"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.5 17.235a6.194 6.194 0 0 1-4.577-1.326H3.75V20H1v-.636a.73.73 0 0 1 .733-.728h.642v-3.363a.73.73 0 0 1 .733-.727h8.559a6.07 6.07 0 0 1-1.042-3.41c0-.744.134-1.458.379-2.118a.681.681 0 0 1 .309-1.29.7.7 0 0 1 .306.07A6.2 6.2 0 0 1 16.813 5C20.23 5 23 7.747 23 11.136c0 2.672-1.722 4.945-4.125 5.788v1.712h.642a.73.73 0 0 1 .733.728V20H17.5zm2.567-6.099c0 1.783-1.457 3.228-3.254 3.228-1.798 0-3.255-1.445-3.255-3.228 0-1.782 1.457-3.227 3.255-3.227s3.254 1.445 3.254 3.227"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        d="M6.5 8.41a.685.685 0 0 1-.687.68.685.685 0 0 1-.688-.68c0-.377.308-.683.688-.683s.687.306.687.682M8.563 9.09c.38 0 .687-.304.687-.68a.685.685 0 0 0-.687-.683.685.685 0 0 0-.688.682c0 .377.308.682.688.682"
      />
    </Svg>
  );
};
export default SvgRow;
