
import React from 'react';

import styled from 'styled-components';

import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';
import Rating from 'ui/components/atoms/icons/rating';
import ClassLength from 'ui/components/atoms/class-length';
import { LessonDurationRange } from 'types/graphql';

import { trainerNames } from 'ui/components/utils/trainer-names';

const RatingScore = styled(Typography)`
  margin-right: ${rem(5)};
  opacity: 0.8;
`;

export type Trainers = Array<{
  firstname: string,
}>;

export type Props = {
  name: string,
  trainers: Trainers,
  review?: number | null,
  duration?: LessonDurationRange,
};

const ClassDetailsBasicInformation = ({ name, trainers, review, duration }: Props) => (
  <>
    <Typography variant="paragon" as="h1">{name}</Typography>
    <Typography as="p" weight="bold">{trainerNames(trainers, 'with')}</Typography>
    {review && (
      <>
        <RatingScore variant="pica">{review.toFixed(2)}</RatingScore>
        <Rating />
      </>
    )}
    {duration && <ClassLength duration={duration} showIcon />}

  </>
);

export default ClassDetailsBasicInformation;
