import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useServices from 'services';
import { useDispatch, useAppState } from 'state';
import useConfig from 'config';
import { login } from 'actions/auth';
import { rem } from 'ui/helpers';

import LoginPage from 'ui/components/layouts/login-page';
import Typography from 'ui/components/atoms/typography';

import Button from 'ui/components/atoms/button';
import TextField from 'ui/components/atoms/text-field';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import useAuxiliaryWindow from 'app/hooks/use-auxiliary-window';

import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import Divider from 'ui/components/atoms/divider';
import useRoutes from 'utils/use-routes';
import { simpleEmailRegex } from 'utils/email-validation';
import { SpatialNavParent, useSetFocus } from 'utils/spatial-nav';
import { useHistory } from 'react-router';

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(600)};
  margin-top: ${rem(40)};
`;

const LoginInputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Typography)`
  margin: 0 ${({ theme }) => theme.spacing.xs};
`;

const StyledDivider = styled(Divider)`
  padding: 0 ${({ theme }) => theme.spacing.l};
  margin: ${rem(30)} 0;
`;

const StyledButton = styled(Button)<{ widthPercentage: number }>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
`;

const BackButton = styled(Button)`
  margin-top: ${rem(110)};
  margin-bottom: ${rem(10)};
`;

const defaultErrors = {
  email: '',
  password: '',
};

const LoginPasswordPage = () => {
  const setFocus = useSetFocus();
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const showSignupButton = useAppState((state) => state.flag.signUpFlowEnabled);

  const services = useServices();
  const dispatch = useDispatch();

  const { routes } = useRoutes();
  const history = useHistory();
  const { config } = useConfig();

  const [{ email: emailError, password: passwordError }, setErrors] = useState(defaultErrors);

  useDismissEvent();
  const { sendWebkitMessage } = useAuxiliaryWindow();

  useEffect(() => {
    sendWebkitMessage({ blankScreen: true });
  });

  const onSubmit = useCallback(async () => {
    try {
      setErrors(defaultErrors);
      if (email.current && password.current) {
        if (!simpleEmailRegex.test(email.current.value)) {
          setErrors({
            password: '',
            email: 'That email is invalid, please check and try again.',
          });
          return;
        }
        const tokens = await services.auth.login({
          username: email.current.value,
          password: password.current.value,
        });

        dispatch(login(tokens));
      }
    } catch (err) {
      setErrors({
        password: 'The email or password is incorrect, please try again.',
        email: '',
      });
    }
  }, [setErrors, dispatch, services.auth]);

  const onEmailKeyDown = useCallback((e) => {
    // submit/enter for sky/web is keyCode 13, amazon keyboard submit is keyCode 9
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
      setFocus('password');
    }
  }, [setFocus]);

  const onPasswordKeyDown = useCallback((e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
      setFocus('login-button');
    }
  }, [setFocus]);

  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const pinFlowDisabled = ['shaw', 'web', 'fiit_studios'].includes(config.APP_TYPE);

  const isStudios = config.APP_TYPE === 'fiit_studios';

  return (
    <LoginPage
      image="https://images-bucket.prod.fiit-static.net/app/uploads/2023/02/28171224/Fiit-splash-no-copy-scaled.jpg"
      footer={(
        <>
          <Typography color="beckersKnop">Need help? </Typography>
          <StyledLink variant="pica">{
            isStudios
              ? (<><span>email</span>&nbsp;<a href="mailto:support@fiit.tv">support@fiit.tv</a></>)
              : 'Visit help.fiit.tv'
          }
          </StyledLink>
        </>
      )}
    >
      <LoginForm>
        <LoginInputContainer>
          <TextField
            id="email"
            label="Email"
            inputRef={email}
            autofocus
            onKeyDown={onEmailKeyDown}
            errorMessage={emailError}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            inputRef={password}
            onKeyDown={onPasswordKeyDown}
            errorMessage={passwordError}
          />
        </LoginInputContainer>
        <ButtonContainer>
          <StyledButton
            widthPercentage={100}
            spatialId="login-button"
            onClick={onSubmit}
            label="Log in"
          />
        </ButtonContainer>
        {/* eslint-disable-next-line no-nested-ternary */}
        {pinFlowDisabled ? (!isStudios ? (
          <BackButton
            label="Back"
            icon={<ChevronLeft />}
            labelPosition="right"
            iconOnly
            onClick={onBackClick}
          />
        ) : null) : (
          <>
            <StyledDivider title="or" />
            <ButtonContainer>
              <SpatialNavParent layout="horizontal">
                <StyledButton
                  widthPercentage={showSignupButton ? 48 : 100}
                  to={{ pathname: routes.LOGIN_BY_PIN?.acceptedPaths[0], state: history.location.state }}
                  label="Log in with PIN"
                />
                {showSignupButton && (
                  <StyledButton
                    widthPercentage={48}
                    label="Sign up"
                    to={{ pathname: routes.SIGNUP?.acceptedPaths[0], state: history.location.state }}
                  />
                )}
              </SpatialNavParent>
            </ButtonContainer>
          </>
        )}

      </LoginForm>
    </LoginPage>
  );
};

LoginPasswordPage.menu = false;

export default LoginPasswordPage;
