import React from 'react';
import styled from 'styled-components';

import ProgressBar from 'ui/components/atoms/progress-bar';

export type MappedSegment = {
  key: string,
  totalTime: number,
  type: string,
};

export type Props = {
  segment: MappedSegment,
  index: number,
  timeline: MappedSegment[],
  current: number,
  duration: number,
};

const IS_REST = ['REST_SECTION', 'TRANSITION_SECTION'];

const StyledRest = styled.div<any>`
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.janosGrey)};
  font-size: 3vw;
  margin: 0 0.25%;
  margin-bottom: -0.4vw;
`;

const StyledProgressBar = styled(ProgressBar)<any>`
  margin: 0 0.25%;
  width: ${({ width }) => width}%;
`;

export const TimelineSegment = (
  { segment: { totalTime, type }, index, timeline, current, duration }: Props,
) => {
  const currentTime = index > 0
    ? current - timeline.slice(0, index).reduce((acc, { totalTime: tt }) => acc + (tt || 0), 0)
    : current;

  if (IS_REST.includes(type)) {
    return (
      <StyledRest
        active={currentTime <= totalTime}
      >&bull;
      </StyledRest>
    );
  }

  return (
    <StyledProgressBar
      currentTime={currentTime}
      totalTime={totalTime}
      width={(totalTime / duration) * 100}
    />
  );
};

export default TimelineSegment;
