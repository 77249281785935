import { PlatformPartnerActionTypes, PlatformPartnerPermissions } from 'actions/platform-partner';
import createReducer from 'utils/create-reducer';

type PlatformPartnerHoldingVideo = {
  id?: string,
  url: string,
};

export type PlatformPartnerState = {
  id: string | null,
  name: string | null,
  permissions: PlatformPartnerPermissions,
  holdingVideo: PlatformPartnerHoldingVideo,
  isChecked: boolean,
  isSet: boolean,
};

const defaultHoldingVideo: PlatformPartnerHoldingVideo = {
  url: 'https://video.fiit-static.net/19a89a80-4e7c-49a2-98e0-40c46f6117ce/index.m3u8',
};

const setPlatformPartner = (_state: boolean, payload: {
  id: string,
  name: string,
  permissions: PlatformPartnerPermissions,
  holdingVideo: PlatformPartnerHoldingVideo,
}) => ({
  id: payload.id,
  name: payload.name,
  permissions: payload.permissions,
  ...(payload?.holdingVideo ? { holdingVideo: payload.holdingVideo } : { holdingVideo: defaultHoldingVideo }),
  isChecked: true,
  isSet: true,
});

const handlers = {
  [PlatformPartnerActionTypes.SET_PLATFORM_PARTNER]: setPlatformPartner,
};

export const initialPlatformPartnerState = {
  id: null,
  name: null,
  permissions: {
    exerciseLibrary: false,
    classBuilder: false,
    liveStudioClass: false,
    scheduler: false,
  },
  holdingVideo: defaultHoldingVideo,
  isChecked: false,
  isSet: false,
};

export const platformPartnerReducer = createReducer<PlatformPartnerState>(handlers);
