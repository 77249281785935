import React, { useMemo } from 'react';
import { MappedCircuit } from 'ui/components/molecules/current-exercise/utils';
import BreakdownSection from 'ui/components/molecules/class-details-extra-information/breakdown-section';
import ExerciseDetailsRow from 'ui/components/molecules/class-details-extra-information/exercise-details-row';

type ClassBreakdownProps = {
  mappedTimeline: MappedCircuit[],
};

const getBreakdownSections = (mappedTimeline: MappedCircuit[]) => (
  mappedTimeline.map(({ name, duration, type }, index) => {
    const isBreakSection = ['INTRO_SECTION', 'TRANSITION_SECTION', 'OUTRO_SECTION'].includes(type || '');
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const subText = `${minutes ? `${minutes}MIN` : ''} ${seconds ? `${seconds}S` : ''}`;

    return (
      <ExerciseDetailsRow
        id={name}
        key={`${name}-${index}`}
        name={name}
        subText={subText}
        isBreakSection={isBreakSection}
        // Boilerplate
        onFocus={() => null}
      />
    );
  })
);

const ClassBreakdown = ({ mappedTimeline }: ClassBreakdownProps) => {
  const sectionRows = useMemo(() => getBreakdownSections(mappedTimeline), [mappedTimeline]);

  return (
    <BreakdownSection leftColumn={sectionRows} />
  );
};

export default ClassBreakdown;
