import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(20)};
  height: ${rem(20)};
`;

function SvgRating({ className = '' }: IconProps) {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 20 20" className={className}>
      <defs>
        <path
          id="rating_svg__a"
          d="M10 16.584L3.82 20 5 12.764 0 7.639l6.91-1.055L10 0l3.09 6.584L20 7.639l-5 5.125L16.18 20z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="rating_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#rating_svg__a" />
        </mask>
        <use fill={theme.colors.black} xlinkHref="#rating_svg__a" />
        <g fill="#FC0" mask="url(#rating_svg__b)">
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgRating;
