import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import { useAppState } from 'state';

export type Props = {
  exercise: string,
  show?: boolean,
  hide?: boolean,
  playbackSpeed?: number,
};

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
`;

const slideDownAndOut = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100vh);
  }
`;

const Wrapper = styled.div<{ show?: boolean, hide?: boolean, disableTransitions: boolean, animationDuration: number }>`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0 3px 3px 0;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.s};
  padding-left: ${({ theme }) => theme.misc.safeScreenPaddingHorizontal + 0.5}rem;
  ${({ show, hide, disableTransitions, animationDuration }) => {
    if (disableTransitions) {
      return '';
    }

    if (show) {
      return css`
        animation: ${slideInFromLeft} ${animationDuration}s ease-in;
        animation-fill-mode: forwards;
      `;
    }
    if (hide) {
      return css`
        animation: ${slideDownAndOut} ${animationDuration}s ease-in;
        animation-fill-mode: forwards;
      `;
    }
    return null;
  }};
`;

const ExerciseTitle = styled(Typography)`
  margin: ${({ theme }) => theme.spacing.s};
  line-height: 100%;
  vertical-align: middle;
  position: relative;
  top: 0.1rem;
  color: ${({ theme }) => theme.colors.black};
`;

const UpNextTitle = styled(Typography)`
  line-height: 100%;
  vertical-align: middle;
  position: relative;
  top: 0.1rem;
  color: ${({ theme }) => theme.colors.black};
`;

const UpNext = ({ exercise, show, hide, playbackSpeed = 1 }: Props) => {
  const { inClassTransitionsEnabled } = useAppState((state) => state.flag);
  const normalAnimationDuration = 1;
  const actualAnimationDuration = normalAnimationDuration / playbackSpeed;

  return (
    <Wrapper
      show={show}
      hide={hide}
      disableTransitions={!inClassTransitionsEnabled}
      animationDuration={actualAnimationDuration}
    >
      <UpNextTitle
        variant="double-pica"
        weight="bold"
        uppercase
      >
        Up next:
      </UpNextTitle>
      <ExerciseTitle
        variant="body-copy"
      >
        {exercise}
      </ExerciseTitle>
    </Wrapper>
  );
};

export default UpNext;
