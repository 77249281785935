import React from 'react';
import styled from 'styled-components';

import ChevronRight from 'ui/components/atoms/icons/chevron-right';
import { IconProps } from 'ui/components/atoms/icons/types';

const Svg = styled(ChevronRight)`
  transform: rotate(180deg);
`;

const ChevronLeft = (props: IconProps) => <Svg {...props} />;

export default ChevronLeft;
