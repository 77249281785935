import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgAirBike = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FFFFFF';
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15.125 1.359a.79.79 0 0 0 .23 1.086l3.64 2.383-1.559 3.47a6.2 6.2 0 0 0-2.106-.21l-.998-5.039a1.56 1.56 0 0 0-1.525-1.263h-4.03A.78.78 0 0 0 8 2.572c0 .433.348.785.778.785h4.029l.997 5.037a6.28 6.28 0 0 0-4.154 4.868L5.882 9.456l.825-.2c1.489-.361 1.406-2.528-.105-2.772l-3.98-.643A1.405 1.405 0 0 0 1 7.237v1.609c0 .914.846 1.588 1.727 1.375l1.38-.335 5.55 5.606q.06.327.15.642l-5.24 5.295H3.333V23h3.111v-1.246l4.044-4.085a6.205 6.205 0 0 0 6.845 2.776V23h3.111v-1.571H18.89v-1.627A6.3 6.3 0 0 0 22 14.357a6.3 6.3 0 0 0-3.127-5.454l1.538-3.426a1.58 1.58 0 0 0-.57-1.968L16.2 1.126a.773.773 0 0 0-1.076.233m-.7 10.168a3.15 3.15 0 0 0-1.758 2.83c0 1.736 1.393 3.143 3.11 3.143 1.719 0 3.112-1.407 3.112-3.143 0-1.677-1.301-3.048-2.94-3.138l.327 1.65a1.57 1.57 0 0 1 1.057 1.488c0 .868-.696 1.572-1.555 1.572-.86 0-1.556-.704-1.556-1.572 0-.47.205-.892.529-1.18z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default SvgAirBike;
