import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgTread = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FFFFFF';
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M18.597 15.602h-3V6.004h.996c.6 0 1.188-.108 1.74-.324l2.496-.96c.623-.24.923-.936.695-1.548a1.207 1.207 0 0 0-1.547-.696l-2.496.96a2.4 2.4 0 0 1-.876.168H8.411c-.66 0-1.2.54-1.2 1.2s.54 1.2 1.2 1.2h4.8v9.598H5.4a3 3 0 0 0 0 5.998h13.197a3 3 0 0 0 0-5.998m0 3.599H5.4a.594.594 0 0 1-.6-.6c0-.336.264-.6.6-.6h13.197c.336 0 .6.264.6.6s-.264.6-.6.6"
      />
      <path
        fill={fill}
        d="M8.4 9.6H6v4.8h2.4zM12 9.6H9.6v4.8H12zM4.8 9.6a3.67 3.67 0 0 0-2.4 3.467V15.6c.624-.664 1.464-1.095 2.4-1.242zM20.4 10.8v4.003c.444.202.852.46 1.2.797v-2.31c0-.999-.468-1.873-1.2-2.49M16.8 9.6v4.74h1.8c.204 0 .408.036.6.06V9.813A3.7 3.7 0 0 0 18 9.6z"
      />
    </Svg>
  );
};
export default SvgTread;
