/* eslint-disable react/no-array-index-key */

import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';

import { ThemeSpacing } from 'ui/theme';
import Typography from 'ui/components/atoms/typography';
import ScrollableText from 'ui/components/atoms/scrollable-text';
import LessonList from 'ui/components/molecules/lesson-list';
import EmptyState from 'ui/components/molecules/empty-state';
import GhostIcon from 'ui/components/atoms/icons/ghost';
import { SpatialNavParent } from 'utils/spatial-nav';
import Rail from 'ui/components/molecules/rail';
import { usePageScroll } from 'utils/use-page-scroll';

export type Props = {
  children: ReactNode[],
  description?: string,
  className?: string,
  itemSpacing?: ThemeSpacing,
  pageTitle?: ReactNode,
  pageTitleUpperCased?: boolean,
  gridTitle?: string,
  onClick?: () => void,
  featureImage?: string,
  filterBar?: ReactNode,
  selectedFilter?: string,
  subtitle?: ReactNode
  columns?: number,
  relatedCollectionsRailCards?: ReactNode[],
};

const Wrapper = styled.div`
  flex: 1;
  margin-left: ${rem(20)};
`;

const ContentGrid = styled(LessonList)`
  margin-top: ${rem(25)};
  position: relative;
  padding-bottom: ${rem(100)};
`;

const AboveHolder = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
`;

const Row = styled.div`
  padding-top: ${rem(100)};
  display: flex;
`;

const HeaderHolder = styled.div<{description?: string}>`
  ${({ description }) => (!description ? `margin-bottom: ${rem(100)}` : null)};
`;

const Description = styled(ScrollableText)`
  height: ${rem(160)};
  width: ${rem(840)};
`;

const ClassesHolder = styled.div`
  padding-top: ${rem(50)};
`;

const Column = styled.div`
  flex: 1;
`;
const ColumnLeft = styled(Column)`
  display: flex;
  align-items: flex-start;
`;

const FeatureImage = styled.div<{ url: string }>`
  height: ${rem(343)};
  width: ${rem(343)};
  background-image: url("${({ url }) => url}");
  background-size: cover;
  margin-left: ${rem(100)};
`;

const FilterBarWrapper = styled.div`
  padding: ${rem(20)} 0;
`;

const StickyHeader = styled.div`
  position: sticky;
  z-index: 1;
  top: -1px;
  background-color: ${({ theme }) => theme.colors.black};
`;

const EmptyStateStyled = styled(EmptyState)`
  margin-top: ${rem(100)};
`;

const ClassGrid = ({
  itemSpacing = 'm',
  pageTitle,
  gridTitle,
  children = [],
  className = '',
  description,
  featureImage,
  filterBar,
  selectedFilter,
  subtitle,
  columns = 4,
  relatedCollectionsRailCards,
}: Props) => {
  const pageScroll = usePageScroll();
  const onFocus = (element: HTMLElement | null) => {
    if (element) {
      pageScroll(element.offsetTop);
    }
  };
  return (
    <Wrapper
      className={className}
      key={selectedFilter}
    >
      <AboveHolder>
        <Row>
          <ColumnLeft>
            {
              (pageTitle || description) && (
                <HeaderHolder description={description} data-test="class-grid-header">
                  { pageTitle }
                  { subtitle }
                  {description && (
                    <Description>
                      { description }
                    </Description>
                  )}
                </HeaderHolder>
              )
            }
          </ColumnLeft>
          <Column>
            {featureImage && (
              <FeatureImage url={featureImage} data-test="feature-image" />
            )}
          </Column>
        </Row>
        <SpatialNavParent layout="horizontal">
          {relatedCollectionsRailCards && relatedCollectionsRailCards?.length > 0 && (
            <Rail title="Class Collections">
              {relatedCollectionsRailCards}
            </Rail>
          )}
        </SpatialNavParent>
      </AboveHolder>
      <StickyHeader>
        {gridTitle && (
          <ClassesHolder>
            <Typography variant="double-pica">{ gridTitle }</Typography>
          </ClassesHolder>
        )}
        {filterBar && (
          <FilterBarWrapper>{ filterBar }</FilterBarWrapper>
        )}
      </StickyHeader>
      {
        children.length ? (
          <SpatialNavParent layout="grid" columns={columns} id="CLASS_GRID_CONTENT">
            <ContentGrid
              className={className}
              itemSpacing={itemSpacing}
              variant="GRID"
              columns={columns}
              onItemFocused={onFocus}
            >
              {
                children
              }
            </ContentGrid>
          </SpatialNavParent>

        ) : (
          <EmptyStateStyled
            icon={<GhostIcon />}
            heading="No classes available yet"
            subheading="Change the filtering to see more hits."
          />
        )
      }
    </Wrapper>
  );
};

export default ClassGrid;
