import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

const SvgPadlock = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} width={24} height={24} viewBox="0 0 24 24">
      <defs>
        <path
          id="padlock_svg__a"
          d="M12 12c.789 0 1.429.64 1.429 1.429v5.142a1.429 1.429 0 11-2.858 0V13.43c0-.79.64-1.429 1.429-1.429zM9.551 7.178v-2.34c0-1.326 1.098-2.435 2.449-2.435 1.35 0 2.449 1.17 2.449 2.372v2.403h2.449V4.775C16.898 2.372 14.705 0 12 0 9.295 0 7.102 2.183 7.102 4.837v2.341H2V24h20V7.178H9.551z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={0.798}>
        <mask id="padlock_svg__b" fill={fill}>
          <use xlinkHref="#padlock_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#padlock_svg__a" />
        <g fill={fill} mask="url(#padlock_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgPadlock;
