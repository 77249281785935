import { useState, useEffect, useCallback, MutableRefObject } from 'react';
import { getKeyDirection } from 'utils/spatial-nav';
import useConfig from 'app/on-tv/config-provider';
import { Key } from 'utils/use-key-event';

const useArrowScrolling = (innerRef: MutableRefObject<HTMLElement | undefined>) => {
  const [scrollable, setScrollable] = useState(false);
  const { config } = useConfig();

  const onKeyDown = useCallback((event) => {
    event.preventDefault();

    const direction = getKeyDirection(event);
    const cur = innerRef.current;

    if ([Key.Escape, config.PLATFORM_RCU_BACK_BUTTON].includes(event.key) && cur?.scrollTop) {
      cur.scrollTop = 0;
      event.stopPropagation();
      return;
    }

    if (!direction || !cur) {
      return;
    }

    const currentPos = cur.scrollTop;
    if (direction === 'UP' && currentPos !== 0) {
      cur.scrollTop -= 20;
      event.stopPropagation();
      return;
    }
    const bottom = cur.scrollHeight - cur.offsetHeight;
    if (direction === 'DOWN' && currentPos < bottom) {
      cur.scrollTop += 20;
      event.stopPropagation();
    }
  }, [innerRef, config.PLATFORM_RCU_BACK_BUTTON]);

  useEffect(() => {
    const cur = innerRef.current;

    if (!cur) {
      return;
    }

    const nowScrollable = cur.scrollHeight > cur.clientHeight;

    setScrollable(nowScrollable);
  }, [innerRef]);

  return { onKeyDown, scrollable };
};

export default useArrowScrolling;
