import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

function WeightIcon({ className = '', color = 'skyBlue' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <defs>
        <path
          id="weight-icon_svg__a"
          d="M4.667 2.667V14H3.333v-2h-2V4.667h2v-2h1.334zm8 0v2h2V12h-2v2h-1.334V2.667h1.334zm-12 4.666v2H0v-2h.667zm15.333 0v2h-.667v-2H16zm-5.333 0v2H5.333v-2h5.334z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="weight-icon_svg__b" fill="#fff">
          <use xlinkHref="#weight-icon_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#weight-icon_svg__a" />
        <g fill={fill} mask="url(#weight-icon_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </Svg>
  );
}

export default WeightIcon;
