export type PlatformPartnerPermissions = {
  exerciseLibrary?: boolean | null,
  classBuilder?: boolean | null,
  liveStudioClass?: boolean | null,
  scheduler?: boolean | null,
};

export enum PlatformPartnerActionTypes {
  SET_PLATFORM_PARTNER = 'SET_PLATFORM_PARTNER',
}

export const setPlatformPartner = (payload: {
  id: string,
  name: string,
  permissions: PlatformPartnerPermissions,
}) => ({
  type: PlatformPartnerActionTypes.SET_PLATFORM_PARTNER,
  payload,
});
export type SetPlatformPartner = typeof setPlatformPartner;

export default { setPlatformPartner };
