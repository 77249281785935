export enum VideoActionTypes {
  TIME_CHANGED = 'VIDEO/TIME_CHANGED',
  PREPARE_STREAM = 'VIDEO/PREPARE_STREAM',
  END_STREAM = 'VIDEO/END_STREAM',
  SET_PAUSE_VISIBLE = 'VIDEO/SET_PAUSE_VISIBLE',
}

type VideoPayload = {
  currentTime: number,
  instance: string,
};

type PrepareStreamPayload = {
  signedCookie: string,
  lessonMediaId: string,
};

type EndStreamPayload = {
  lessonMediaId: string,
};

export const timeChange = (payload: VideoPayload) => ({
  type: VideoActionTypes.TIME_CHANGED,
  payload,
});

export type TimeChange = typeof timeChange;

export const prepareStream = (payload: PrepareStreamPayload) => ({
  type: VideoActionTypes.PREPARE_STREAM,
  payload,
});

export type PrepareStream = typeof prepareStream;

export const endStream = (payload: EndStreamPayload) => ({
  type: VideoActionTypes.END_STREAM,
  payload,
});

export type EndStream = typeof endStream;

export default {
  timeChange,
  prepareStream,
  endStream,
};
