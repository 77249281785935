import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgBikeRpm = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#51D85E';

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.61 5.233a.173.173 0 0 0-.048.008l-3.116.963c-1.36.42-1.784 2.136-.778 3.142 1.006 1.006 2.721.581 3.141-.778l.963-3.117a.168.168 0 0 0-.162-.218Zm-3.223 2.74a.64.64 0 1 0 1.28 0 .64.64 0 0 0-1.28 0Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16ZM5 8H1.76a6.24 6.24 0 1 1 12.48 0H11a3 3 0 0 1-6 0Z"
        fill={fill}
      />
    </Svg>
  );
};

export default SvgBikeRpm;
