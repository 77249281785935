import React, { createContext, useContext, ReactNode } from 'react';
import { Config as DefaultConfig } from 'config';
// @ts-ignore - we're using js so we can pull it more easily into webpack
// eslint-disable-next-line import/extensions
import config from 'app/on-tv/config.js';

export type Config = DefaultConfig & {
  TV_BASE_LINK: string;
  TV_PRODUCT_NAME: string;
  TV_QR_LINK: string;
  MAX_LESSONS_IN_GRID: number;
  BROWSE_PAGE_COLLECTION_RAILS_SLUG: string;
  BROWSE_PAGE_COLLECTION_BROWSE_COLLECTIONS_SLUG: string;
  SHOW_INTRO_VIDEO_ONCE: boolean;
  HIDE_INTRO_VIDEO: boolean;
  BROWSE_PAGE_TRENDING_RAIL_LENGTH: number;
  FAVOURITES_POLLING_INTERVAL: number; // in seconds
  ACTIVE_TRAINING_PLAN_POLLING_INTERVAL: number; // in seconds
  BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS: number;
  BACK_TRANSITION_MINIMUM_DELAY_MS: number;
  FINISH_WORKOUT_DELAY: number; // in seconds
  AMAZON_BILLING_ENABLED: boolean;
  DEFAULT_DEBUG_DASH_MANIFEST: string;
  DEBUG_LESSON_MEDIA_ENABLED: boolean;
  WS_MOCKING_ENABLED: boolean;
  API_ENVIRONMENT: string;
  ENABLE_PLAYREADY_AUTH_PROXY: string;
  ENABLE_CUSTOM_ON_SCREEN_KEYBOARD: boolean;
  TIZEN_KEY_RETRY_MS: number;
  CHROMECAST_CHANNEL: string;
  CHROMECAST_MESSAGE_DELAY_MS: number;
  ENABLE_WORKOUT_STORAGE: boolean;
  WEB_APP_BETA_FEEDBACK_TYPEFORM_URL: string;
  BETA_FEEDBACK_SURVEY_RETAKE_DAYS: number;
  BETA_FEEDBACK_MODAL_MAX_DISMISSAL_COUNT: number;
};

type RawConfigValue = {
  value: any,
  format?: (value: any) => any,
};

const configContext = {
  config: Object.entries<RawConfigValue>(config)
    .reduce((formattedConfig, [key, { value, format }]) => ({
      ...formattedConfig,
      [key]: format ? format(value) : value,
    }), {}) as Config,
};

// defined as an object so later we could add a debug menu using setConfig/useState
const context = createContext(configContext);

export const ConfigProvider = ({ children }: { children: ReactNode }) => (
  <context.Provider value={configContext}>
    { children }
  </context.Provider>
);

export default () => useContext(context);
