import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';

import { rem } from 'ui/helpers';

type Props = {
  name: string,
  duration: string,
};

const Title = styled.div<{ narrow?: boolean }>`
  text-align: left;
  width: ${rem(550)};
  height: ${({ narrow }) => (narrow ? rem(80) : rem(135))};
  padding: 0.75rem 1.125rem;
  background-color: ${({ theme }) => theme.colors.betaGrey};
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(350)};
  height: 100%;
  justify-content: center;
`;

const DurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(110)};
  height: 100%;
  justify-content: center;
`;

const Name = styled(Typography)<{colorTheme: string}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ theme, colorTheme }) => (colorTheme === 'greyed' ? theme.colors.beckersKnop : theme.colors.white)};
`;

const Duration = styled(Typography)<{colorTheme: string}>`
  color: ${({ theme, colorTheme }) => (colorTheme === 'greyed' ? theme.colors.beckersKnop : theme.colors.white)};
`;

const NextLiveExercise = ({ name, duration }: Props) => {
  const narrowGrey = name === 'Rest';
  return (
    <Title narrow={narrowGrey}>
      <TextWrapper>
        <NameWrapper>
          <Name
            colorTheme={narrowGrey ? 'greyed' : 'normal'}
            weight="bold"
            variant="body-copy"
            as="div"
          >
            {name}
          </Name>
        </NameWrapper>
        <DurationWrapper>
          <Duration
            colorTheme={narrowGrey ? 'greyed' : 'normal'}
            weight="bold"
            variant="body-copy"
            as="p"
          >
            {duration}
          </Duration>
        </DurationWrapper>

      </TextWrapper>
    </Title>
  );
};

export default NextLiveExercise;
