import { PlatformPartnerPermissions } from 'actions/platform-partner';

export enum RoutePermissions {
  EXERCISE_LIBRARY = 'EXERCISE_LIBRARY',
  CLASS_BUILDER = 'CLASS_BUILDER',
  LIVE_STUDIO_CLASS = 'LIVE_STUDIO_CLASS',
  SCHEDULER = 'SCHEDULER',
}

const permissionMapping = {
  [RoutePermissions.EXERCISE_LIBRARY]: 'exerciseLibrary',
  [RoutePermissions.CLASS_BUILDER]: 'classBuilder',
  [RoutePermissions.LIVE_STUDIO_CLASS]: 'liveStudioClass',
  [RoutePermissions.SCHEDULER]: 'scheduler',
} as const;

type PermissionKey = typeof permissionMapping[keyof typeof permissionMapping];

const checkPermission = (
  permission: RoutePermissions,
  partnerPermissions: PlatformPartnerPermissions,
): boolean => {
  const mappedPermission = permissionMapping[permission] as PermissionKey;
  return partnerPermissions[mappedPermission] ?? false;
};

export const verifyRoutePermissions = (
  partnerPermissions: PlatformPartnerPermissions,
  requiredPermissions: RoutePermissions[],
): boolean => requiredPermissions.every(
  (permission: RoutePermissions) => checkPermission(permission, partnerPermissions),
);
