import { LessonDurationRange } from 'types/graphql';
import { useQueryParam } from 'app/on-tv/hooks/use-query-param';
import { labelFromLessonDuration } from 'ui/components/atoms/class-length';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import useRoutes, { QueryParams, RouteParams, Route } from 'utils/use-routes';

export enum AllDurations {
  ALL = 'ALL'
}
const DurationRanges = { ...LessonDurationRange, ...AllDurations };
export type DurationRanges = LessonDurationRange | AllDurations;

type OnFilterArgs = {
  duration: string,
  route?: Route,
  query?: QueryParams,
  params?: RouteParams,
};

type UseDurationFilter = {
  durationRange: LessonDurationRange,
  showAllDurations: boolean,
  labelFromLessonDuration: (val: string) => string,
  onFilter: (args: OnFilterArgs) => void,
};

export const useDurationFilter = (intialFilter = AllDurations.ALL as DurationRanges): UseDurationFilter => {
  const history = useHistory();
  const { push, redirect } = useRoutes();
  const queryParams = useQueryParam();
  const durationRangeParams = queryParams.get('durationRange') || AllDurations.ALL;
  const durationRange = (
    LessonDurationRange[durationRangeParams as keyof typeof LessonDurationRange] || AllDurations.ALL
  );

  const onFilter = useCallback(({ duration, route, params, query = {} }: OnFilterArgs) => {
    if (duration === intialFilter) {
      history.goBack();
    } else if (durationRange === intialFilter) {
      push({
        route,
        params,
        queryParams: { ...query, durationRange: duration },
        state: { preventScrollReset: true },
      });
    } else {
      redirect({
        route,
        params,
        queryParams: { ...query, durationRange: duration },
        state: { preventScrollReset: true },
      });
    }
  }, [durationRange, history, intialFilter, push, redirect]);

  return {
    durationRange,
    showAllDurations: durationRange as DurationRanges === AllDurations.ALL,
    labelFromLessonDuration,
    onFilter,
  };
};
