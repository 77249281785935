import React, { memo } from 'react';
import { CircuitType } from 'app/on-tv/types/graphql';

import { MappedCircuit } from 'ui/components/molecules/current-exercise/utils';
import LottieAnimation from 'ui/components/atoms/animated-icons/lottie-animation';
import animationData from 'ui/components/atoms/animated-icons/in-class-animation.json';

type Props = {
  className?: string,
  circuit: MappedCircuit,
  playbackSpeed?: number,
};

const excludedCircuitTypes = [CircuitType.INTRO_SECTION, CircuitType.OUTRO_SECTION];

const InClassAnimation = ({ className = '', circuit, playbackSpeed }: Props) => {
  if (excludedCircuitTypes.includes(circuit.type)) {
    return null;
  }

  return (
    <LottieAnimation
      className={className}
      animationData={animationData}
      dynamicText={circuit.name.toUpperCase()}
      dynamicTextElementIndex={2}
      playbackSpeed={playbackSpeed}
    />
  );
};

export default memo(InClassAnimation);
