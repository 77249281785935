import React, { ElementType, SyntheticEvent } from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';
import ProfileImage from 'ui/components/atoms/profile-image';
import MenuItem from 'ui/components/atoms/menu-item';
import Logo from 'ui/components/atoms/logo';
import useRoutes, { url } from 'utils/use-routes';
import formatName from 'app/on-tv/utils/format-name';

export type MenuItem = {
  id: string,
  icon: ElementType,
  label: string,
  selected: boolean,
  handleAction?: (event: SyntheticEvent) => void,
};

type User = {
  handle?: string,
  firstname?: string | null,
  lastname?: string | null,
  profileImageUrl?: string | null,
};

export type Props = {
  expanded?: boolean,
  user?: User | null,
  items: MenuItem[],
  version: string,
  autofocus?: boolean,
  disableProfile?: boolean,
};

export const width = rem(140);

const Wrapper = styled.div<{
  expanded?: boolean,
}>`
  display: flex;
  flex-direction: column;
  width: ${({ expanded }) => (expanded ? rem(425) : width)};
  height: 100vh; /* TODO 100% */
  padding: ${({ expanded }) => (expanded ? '1.4rem 1.4rem 1.4rem 0' : 0)};
  background-color: ${({ theme }) => theme.colors.black};
`;

const Content = styled.div<{
  expanded?: boolean
}>`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExpandableProfileImage = styled(ProfileImage)<{ expanded?: boolean }>`
  height: ${({ expanded }) => (expanded ? '4rem' : '2rem')};
  width: ${({ expanded }) => (expanded ? '4rem' : '2rem')};
  margin-bottom: ${({ theme, expanded }) => (expanded ? theme.spacing.m : theme.spacing.xs)};
`;

const MenuList = styled.ul<{ expanded?: boolean }>`
  list-style: none;
  width: ${({ expanded }) => (expanded ? '100%' : 'auto')};
`;

const TitleContainer = styled.div<{ expanded?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: ${({ expanded }) => (expanded ? '1.4rem' : 0)};
`;

const LogoContainer = styled.div<{ expanded?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Version = styled(Typography)`
  display: block;
  text-align: center;
  margin-top: 0.5rem;
`;

const SideMenu = ({
  autofocus = false,
  items = [],
  expanded = false,
  user,
  version,
  disableProfile = false,
}: Props) => {
  const name = formatName(user || {});
  const { profileImageUrl: image } = user || {};

  const { routes } = useRoutes();

  return (
    <Wrapper expanded={expanded} data-test-state={expanded ? 'open' : 'closed'}>
      {!disableProfile && (
        <TitleContainer expanded={expanded}>
          <ExpandableProfileImage to={url({ route: routes.PROFILE })} imageUrl={image} expanded={expanded} />
          { expanded && <Typography variant="body-copy" weight="bold" color="wednesdayGrey">{ name }</Typography> }
        </TitleContainer>
      )}
      <Content expanded={expanded}>
        <MenuList expanded={expanded}>
          { items.map((item, i) => (
            <MenuItem
              key={i}
              label={item.label}
              icon={<item.icon selected={item.selected} />}
              expanded={expanded}
              selected={item.selected}
              autofocus={autofocus && item.selected}
              onClick={item.handleAction}
            />
          )) }
        </MenuList>
      </Content>
      <LogoContainer>
        { expanded && (
          <>
            <Logo color="white" />
            <Version data-test="app-version" variant="minion" color="beckersKnop">{version}</Version>
          </>
        ) }
      </LogoContainer>
    </Wrapper>
  );
};

export default SideMenu;
