import { StoredWorkoutTypes } from 'actions/locally-stored-workout';
import createReducer from 'utils/create-reducer';
import { getPersistedState } from 'state/auth';
import storage from 'utils/local-storage-fallback';

export type StoredWorkoutState = {
  workoutId?: number,
  currentTime?: number,
  duration?: number,
  timeOfWorkout: Date,
  lessonId: number,
  lessonMediaId?: string,
  lessonInstanceId: string,
};

type StoreWorkoutPayload = StoredWorkoutState & { userId: number };

const setStoredWorkout = (_state: StoredWorkoutState, payload: StoreWorkoutPayload) => {
  storage.setItem(
    `ACTIVE_WORKOUT:${payload.userId}`,
    JSON.stringify(payload),
  );
  return payload;
};

const removeStoredWorkout = (_state: StoredWorkoutState, userId: number) => {
  storage.removeItem(`ACTIVE_WORKOUT:${userId}`);
};

const getStoredWorkout = (userId: number) : StoredWorkoutState | undefined => {
  const workoutFromStorage = storage.getItem(`ACTIVE_WORKOUT:${userId}`);
  if (!workoutFromStorage) {
    return undefined;
  }
  const parsed = JSON.parse(workoutFromStorage);
  return { ...parsed, timeOfWorkout: new Date(parsed.timeOfWorkout) };
};

const handlers = {
  [StoredWorkoutTypes.SET_WORKOUT]: setStoredWorkout,
  [StoredWorkoutTypes.GET_WORKOUT]: getStoredWorkout,
  [StoredWorkoutTypes.REMOVE_WORKOUT]: removeStoredWorkout,
};

const userId = getPersistedState()?.userId;
export const initialLocallyStoredWorkoutState = userId ? getStoredWorkout(userId) : undefined;
export const locallyStoredWorkoutReducer = createReducer(handlers);
