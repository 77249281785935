import styled from 'styled-components';
import { rem } from 'ui/helpers';

import Button from 'ui/components/atoms/button';
import Typography from 'ui/components/atoms/typography';
import Select from 'react-select';

export const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const ActionButton = styled(Button)`
  align-self: center;
  margin-top: ${rem(18)};
  transition: ease-in-out 0.1s;

  &:hover {
    color: white;
    background-color: #3a52f8;
    opacity: 1;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: ${rem(16)};
`;

export const ToggleOptionsArea = styled.div`
  margin-top: 1rem;
  width: 90%;
`;

export const RepeatScheduleArea = styled.div`
  padding-bottom: 0.5rem;
`;

export const RepeatScheduleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  max-height: 2.5rem;
  margin: 0.5rem 0;
`;

export const SchedulePeriodSelector = styled(Select)`
  width: 10rem;
  align-self: flex-start;
  margin-left: 0.5rem;

  .Select__control {
    border-radius: 15px;
    background-color: black;
    border: none;
    min-height: 2rem;
    box-shadow: none;
  }

  .Select__placeholder {
    color: white;
    padding: 0.15rem 0 0 0.15rem;
  }

  .Select__menu {
    background-color: black;
  }

  .Select__single-value {
    color: white;
    margin-left: 0.15rem;
  }

  .Select__dropdown-indicator {
    color: white;
  }
`;

export const DisabledSchedulePeriodSelector = styled(SchedulePeriodSelector)`
  .Select__single-value {
    color: #4d4d4d;
  }
`;

export const ScheduleInformationArea = styled.div`
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GreyedOutInformationArea = styled(ScheduleInformationArea)`
  color: ${({ theme }) => theme.colors.lightGrey};
  padding-top: 0;
  margin-bottom: 0.5rem;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;

  button {
    margin: 0.25rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
