import { useState, useEffect, useCallback, useContext, MutableRefObject, useLayoutEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import uniqueId from 'lodash/uniqueId';

import { SpatialId, LayoutType } from 'utils/spatial-nav/types';
import { FocusedContext, SpatialNavContext, SpatialParentContext } from 'utils/spatial-nav/context';
import useLogger from 'app/hooks/use-logger';

export type UseSpatialTargetArgs = {
  id?: SpatialId;
  autofocus?: boolean;
  onFocus?: (el: HTMLElement | null) => void,
  elRef: MutableRefObject<HTMLElement | null>,
  disabled?: boolean;
};

export type UseSpatialParentArgs = {
  id?: SpatialId;
  layout: LayoutType,
  columns?: number,
  onFocus?: (el: HTMLElement | null) => void,
  isolated?: boolean,
  forgetFocus?: boolean,
};

type UseSpatialNavArgs = {
  id?: SpatialId;
  layout?: LayoutType,
  columns?: number,
  onFocus?: (el: HTMLElement | null) => void,
  elRef?: MutableRefObject<HTMLElement | null>,
  disabled?: boolean,
  isolated?: boolean,
  forgetFocus?: boolean,
};

// Main hook, used by both useSpatialTarget and useSpatialParent as they do mostly the same thing
// The reason for splitting them is to give semantic meaning to do the difference + tighten up the TS types
const useSpatialNav = ({
  // id is optional - if not supplied a random id is generated - can be used with setFocus
  id,
  // In which direction is the list if there is one laid out - horizontal or vertical
  layout,
  // onFocus callback called when this node becomes focused - or a child if using trackChildren
  onFocus,
  // TODO onBlur hasn't be implemented yet if we need one
  elRef,
  // If layout is grid columns required
  columns,
  // If this node is temporarily unavailable for focussing
  disabled = false,
  // If this node should be removed from the tree so focus cannot be moved outside of this node
  isolated = false,
  // If this node should not remember the lastFocusedChildIndex information
  forgetFocus = false,
}: UseSpatialNavArgs) => {
  const [spatialId] = useState(id || uniqueId());
  const focused = useContextSelector(FocusedContext, (state) => state.includes(spatialId));

  const spatialNav = useContext(SpatialNavContext);
  const treeParentId = useContext(SpatialParentContext);

  const logger = useLogger('spatial-nav');

  const focusedNode = spatialNav.focused ? spatialNav.tree[spatialNav.focused] : null;

  if (layout === 'grid' && !columns) {
    logger.warn('[spatial-nav] Grid layout requires columns to be specified');
  }

  // Layout effect so tree is present before any calls to setFocus etc. - fixes JS-4486
  // Keep this method short + fast.
  useLayoutEffect(() => {
    if (spatialNav.tree[spatialId]?.info) {
      // TODO - sentry error and re-generate the id to try recover?
      throw new Error('Spatial nav id clash');
    }

    // It's easier to have a random parent outside of the main "root" tree than it is have a null parent
    const parentId = isolated ? uniqueId() : treeParentId;

    if (!spatialNav.tree[parentId]) {
      spatialNav.tree[parentId] = {
        children: [],
      };
    }

    const index = spatialNav.tree[parentId].children.length;

    // Build the navigation tree
    // An alternative might be to have each thing generate it's own context and nest them - seems like it might be crazy
    spatialNav.tree[spatialId] = {
      info: {
        id: spatialId,
        parentId,
        columns,
        index,
        focused: false,
        el: elRef?.current,
        layout,
        disabled: false,
        forgetFocus,
        lastFocusedChildIndex: 0,
      },
      children: spatialNav.tree[spatialId] ? spatialNav.tree[spatialId].children : [],
    };

    // TODO check that it doesn't clash - throw developer error if it clashes, might be an issue if we re-order etc.?
    const siblings = spatialNav.tree[parentId].children;
    siblings[index] = spatialId;

    return () => {
      delete spatialNav.tree[spatialId];
      if (isolated) {
        delete spatialNav.tree[parentId];
      }

      // If we're removing the last el, pop until defined - if component re-renders with less elements,
      // need to have the right length
      if (index === siblings.length - 1) {
        do {
          siblings.pop();
        } while (siblings.length && siblings[siblings.length - 1] == null);
      } else {
        delete siblings[index];
      }
    };
  }, [spatialNav.tree, treeParentId, spatialId, layout, columns, elRef, isolated, forgetFocus]);

  useLayoutEffect(() => {
    const node = spatialNav.tree[spatialId];
    if (node?.info) {
      node.info.disabled = disabled;
    }
  }, [spatialNav.tree, disabled, spatialId]);

  useEffect(() => {
    if (focused && onFocus) {
      if (elRef) {
        onFocus(elRef.current);
      } else if (focusedNode && focusedNode.info?.el) {
        onFocus(focusedNode.info.el);
      }
    }
  }, [focused, onFocus, elRef, focusedNode]);

  const setFocus = useCallback(() => {
    spatialNav.setFocus(spatialId);
  }, [spatialNav, spatialId]);

  return {
    spatialId,
    focused,
    setFocus,
  };
};

// Helpers to type the inputs better - could just use directly
export const useSpatialTarget = ({ autofocus = false, ...spatialArgs }: UseSpatialTargetArgs) => {
  const { setFocus, ...spatialInfo } = useSpatialNav(spatialArgs);

  useEffect(() => {
    if (autofocus && spatialArgs.elRef.current) {
      setFocus();
    }
  }, [autofocus, spatialArgs.elRef, setFocus]);

  return { setFocus, ...spatialInfo };
};

export const useSpatialParent = (args: UseSpatialParentArgs) => {
  // TODO check this object creation is ok and not a perf issue
  const { spatialId } = useSpatialNav(args);
  return spatialId;
};

// Find out if any element you have the id for is focused
// If no id is provided, uses the nearest parent id
export const useFocusedState = (targetId?: SpatialId) => {
  const parentId = useContext(SpatialParentContext);
  const focused = useContextSelector(FocusedContext, (state) => state.includes(targetId || parentId));
  return focused;
};

export const useSetFocus = () => {
  const spatialNav = useContext(SpatialNavContext);
  const parentId = useContext(SpatialParentContext);

  const setFocus = useCallback((spatialId?: SpatialId) => {
    spatialNav.setFocus(spatialId || parentId);
  }, [spatialNav, parentId]);

  return setFocus;
};
