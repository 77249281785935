import React, { useEffect, useState, useCallback } from 'react';

import useWebSocket from 'utils/use-web-socket';
import DebugBar from 'ui/components/utils/debug-bar';

const Debug = ({ lessonInstanceId }: { lessonInstanceId: string }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleDebugBar = (e: KeyboardEvent) => {
      if (e.code === 'KeyD') {
        setVisible(!visible);
      }
    };

    document.addEventListener('keydown', toggleDebugBar);

    return () => {
      document.removeEventListener('keydown', toggleDebugBar);
    };
  }, [visible]);

  const { sendMessage } = useWebSocket({
    url: 'ws://localhost:8808?manualControl=true',
    maxRetries: 1,
  });

  const skipSeconds = useCallback((secondsToSkip: number) => {
    const message = JSON.stringify({
      action: 'SKIP_VIDEO',
      lessonInstanceId,
      skipSeconds: secondsToSkip,
    });
    sendMessage(message);
  }, [lessonInstanceId, sendMessage]);

  return <DebugBar visible={visible} skipSeconds={skipSeconds} />;
};

export default Debug;
