import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgSpin = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FFFFFF';
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M8.914 14.804c0 .757-.619 1.37-1.383 1.37a1.377 1.377 0 0 1-1.383-1.37c0-.757.62-1.371 1.383-1.371.764 0 1.383.614 1.383 1.37"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M21.798 3.17a.68.68 0 0 0 0-.97.696.696 0 0 0-.978 0l-2.068 2.05a.14.14 0 0 1-.098.04h-3.065a.69.69 0 0 0-.692.686c0 .379.31.686.692.686h.893l-3.52 5.437a3.5 3.5 0 0 0-.947.493A5.54 5.54 0 0 0 7.79 9.327l-.828-2.789.988-.156c1.344-.214 1.417-2.103.094-2.418l-3.538-.842a1.242 1.242 0 0 0-1.535 1.2v1.403c0 .759.685 1.338 1.441 1.218l1.174-.186.796 2.682C3.879 9.964 2 12.166 2 14.804c0 2.227 1.34 4.144 3.264 5.002v.823H3.88V22h2.766v-1.783q.432.07.884.07a5.54 5.54 0 0 0 4.839-2.825 3.54 3.54 0 0 0 1.847.452l2.687 2.664v1.298h2.766v-1.371h-.884l-3.022-2.996a3.5 3.5 0 0 0 1.88-3.096c0-1.737-1.275-3.178-2.948-3.455l3.43-5.296h.528c.403 0 .79-.16 1.076-.442zM7.53 17.545c1.528 0 2.766-1.227 2.766-2.741s-1.238-2.742-2.766-2.742c-1.527 0-2.765 1.228-2.765 2.742s1.238 2.741 2.765 2.741m7.348-3.132c0 .42-.344.761-.769.761a.765.765 0 0 1-.768-.761c0-.42.344-.762.768-.762s.769.341.769.762"
        clipRule="evenodd"
      />
    </Svg>
  );
};
export default SvgSpin;
