import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgInfo = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 24 24" className={className}>
    <title>02 Icons / Icon </title>
    <defs>
      <path d="M12,19 C13.104,19 14,19.896 14,21 C14,22.104 13.104,23 12,23 C10.896,23 10,22.104 10,21 C10,19.896 10.896,19 12,19 Z M12,1 C13.1045695,1 14,1.8954305 14,3 L14,3 L14,15 C14,16.1045695 13.1045695,17 12,17 C10.8954305,17 10,16.1045695 10,15 L10,15 L10,3 C10,1.8954305 10.8954305,1 12,1 Z" id="exclaim__a" />
    </defs>
    <g id="Overlays-error-states" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Oh-no!-2" transform="translate(-948.000000, -389.000000)">
        <g id="Group-2" transform="translate(560.000000, 350.000000)">
          <g id="03-Elements-/-TV-/-Buttons-/-CTA-/-Icon-only-/-Inactive-Copy" transform="translate(350.000000, 0.000000)">
            <g id="↳-Select-icon-symbol" transform="translate(38.000000, 38.000000)">
              <mask id="exclaim__b" fill="white">
                <use xlinkHref="#exclaim__a" />
              </mask>
              <use id="Combined-Shape" fill="#FFFFFF" xlinkHref="#exclaim__a" />
              <g id="Group" mask="url(#exclaim__b)" fill="#FFFFFF">
                <g id="↳-Select-Icon-colour">
                  <rect id="Background-colour" x="0" y="0" width="24" height="24" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default SvgInfo;
