import React, { useMemo, useRef, useState } from 'react';
import { LessonStatus } from 'app/on-tv/types/graphql';
import styled from 'styled-components';
import useRoutes, { url } from 'utils/use-routes';
import TabBar from 'ui/components/molecules/tab-bar';
import { Trainers } from 'ui/components/layouts/class-details';
import ClassCard from 'ui/components/molecules/class-card';
import Rail from 'ui/components/molecules/rail';
import Button from 'ui/components/atoms/button';
import { SpatialNavParent } from 'utils/spatial-nav';
import { TransformedLessons } from 'app/on-tv/utils/transform-lesson-data';
import { possessivePluralise } from 'ui/components/utils/pluralise';
import ClassBreakdown from 'ui/components/molecules/class-details-extra-information/class-breakdown';
import { rem } from 'ui/helpers';
import { MappedCircuit } from 'ui/components/molecules/current-exercise/utils';
import useConfig from 'config';

type Props = {
  similarClasses: TransformedLessons,
  trainers: Trainers,
  setDismissFocusId: (id: string | null) => void,
  showExtraDetailsContent: boolean,
  onTabBarFocus: () => void,
  mappedTimeline: MappedCircuit[],
};

const Wrapper = styled.div`
  width: 85vw;
`;

const TrainerButtons = styled.div`
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
  position: relative;
`;

const TrainerImage = styled.img`
  height: 100%;
  border-radius: 100%;
  margin: 0;
  position: absolute;
  left: 0;
`;

const ContentSection = styled.div`
  height: ${rem(580)};
  margin-top: 2rem;
  overflow-y: hidden;
`;

const ClassDetailsExtraInformation = ({
  similarClasses,
  trainers,
  setDismissFocusId,
  showExtraDetailsContent,
  onTabBarFocus,
  mappedTimeline,
}: Props) => {
  const { routes } = useRoutes();
  const [selectedTab, setSelectedTab] = useState(0);
  const elRef = useRef<HTMLDivElement | null>(null);
  const { config } = useConfig();

  const tabNames = [
    { name: 'Class breakdown', id: 'class_breakdown' },
    ...(!['fiit_studios'].includes(config.APP_TYPE)
      ? [{ name: 'Similar classes', id: 'similar_classes' }] : []
    ),
  ];

  const tabs = useMemo(() => (
    tabNames.map((tab, i) => ({
      ...tab,
      selected: i === selectedTab,
    }))
  ), [selectedTab, tabNames]);

  const isWeb = config.APP_TYPE === 'web';
  // spatial nav + focus controls both below for non-webapp/tv
  const showExtraContent = isWeb ? true : showExtraDetailsContent;
  const toggleProps = isWeb ? { toggleSelectedTab: setSelectedTab } : null;

  return (
    <Wrapper ref={elRef}>
      <SpatialNavParent id="TAB_BAR" layout="horizontal" onFocus={onTabBarFocus}>
        <TabBar
          tabs={tabs}
          onTabFocus={setSelectedTab}
          {...toggleProps}
        />
      </SpatialNavParent>
      <SpatialNavParent
        layout="vertical"
        onFocus={() => setDismissFocusId('TAB_BAR')}
        forgetFocus
      >
        {showExtraContent && tabs[selectedTab].id === 'similar_classes' && (
          <ContentSection>
            <SpatialNavParent layout="horizontal">
              <TrainerButtons>
                {trainers.map(({ firstname, lastname, slug, avatarImage }, i) => (
                  <StyledButton
                    key={i}
                    label={`${firstname} ${possessivePluralise(lastname)} page`}
                    to={url({ route: routes.TRAINER, params: { slug } })}
                    icon={<TrainerImage src={avatarImage.url} />}
                  />
                ))}
              </TrainerButtons>
            </SpatialNavParent>
            <Rail>
              {similarClasses.map((lesson, i) => (
                <ClassCard
                  key={i}
                  size="large"
                  backgroundImage={lesson.mainImageUrl}
                  duration={lesson.duration}
                  trainer={lesson.trainerName}
                  name={lesson.name}
                  to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
                  locked={lesson.locked}
                  completed={lesson.completed}
                  kettlebells={lesson.kettlebells}
                  dumbbells={lesson.dumbbells}
                  inTesting={lesson.status === LessonStatus.TESTING}
                />
              ))}
            </Rail>
          </ContentSection>
        )}
        {showExtraContent && tabs[selectedTab].id === 'class_breakdown' && (
          <ContentSection>
            <ClassBreakdown mappedTimeline={mappedTimeline} />
          </ContentSection>
        )}
      </SpatialNavParent>
    </Wrapper>
  );
};

export default ClassDetailsExtraInformation;
