import { useEffect, useState, useCallback } from 'react';
import useLogger from 'app/hooks/use-logger';
import ReconnectingWebsocket, { ErrorEvent, CloseEvent, Message } from 'reconnecting-websocket';

export type WebsocketError = ErrorEvent;

export type Props = {
  url: string,
  onOpen?: (sendMessage: (message: Message) => void) => void,
  onMessage?: (message: any) => void,
  onWebsocketError?: (event: ErrorEvent) => void,
  onClose?: (event: CloseEvent) => void,
  onRetriesExceeded?: (event: ErrorEvent) => void,
  maxRetries?: number,
  genericErrorRetry?: number,
};

export type WsResponse = {
  connected: boolean,
  close: () => void,
  sendMessage: (message: Message) => void,
};

export const generateLocalWsUrl = (url?: string) => (
  url || window.location.origin.replace(/(http)(.*)(:\/\/)(.*)/g, 'ws$2$3$4')
);

const voidFn = () => null;

/* istanbul ignore next */
export default ({
  url,
  onOpen = voidFn,
  onWebsocketError = voidFn,
  onMessage = voidFn,
  onClose = voidFn,
  onRetriesExceeded = voidFn,
  maxRetries,
  genericErrorRetry,
}: Props) => {
  const [connection, setConnection] = useState<null | ReconnectingWebsocket>(null);
  const [connected, setConnected] = useState<boolean>(false);
  const [wsConnRetryCount, setWsConnRetryCount] = useState<number>(0);
  const logger = useLogger('use-web-socket');

  const sendMessage = useCallback((message) => {
    if (connection) {
      connection.send(message);
    }
  }, [connection]);

  // If the url changes we need a new WebSocket
  useEffect(() => {
    logger.log(`Creating WebSocket: ${url}`);

    const ws = new ReconnectingWebsocket(url, undefined, {
      startClosed: true,
      maxRetries,
    });
    setConnection(ws);
    setConnected(false);

    logger.log(`WebSocket created: ${url}`);

    // If we unmount or the url changes - close the socket
    return () => {
      logger.log(`Closing existing WebSocket: ${url}`);

      if (ws) {
        ws.close();
      }
    };
  }, [url, maxRetries, logger, genericErrorRetry]);

  // Add listeners for each event - removing + re-attaching when the callback changes
  useEffect(() => {
    if (!connection) {
      return voidFn;
    }

    const openListener = () => {
      logger.log(`WebSocket open: ${connection.url}`);

      setConnected(true);
      setWsConnRetryCount(0);
      onOpen(sendMessage);
    };

    connection.addEventListener('open', openListener);
    return () => {
      connection.removeEventListener('open', openListener);
    };
  }, [connection, onOpen, sendMessage, logger]);

  useEffect(() => {
    if (!connection) {
      return voidFn;
    }

    const messageListener = ({ data }: MessageEvent) => {
      onMessage(JSON.parse(data));
    };

    connection.addEventListener('message', messageListener);
    return () => {
      connection.removeEventListener('message', messageListener);
    };
  }, [connection, onMessage]);

  useEffect(() => {
    if (!connection) {
      return voidFn;
    }

    const closedListener = (event: CloseEvent) => {
      logger.log(`WebSocket closed: ${JSON.stringify({ url: connection.url, event })}`);

      // Can't explicitly detect 401 responses, but code 1006 means abnormal disconnect which happens when we 401
      // If we see that we could refresh the credentials and retry, but it's quite messy to get the auth service
      // In here to do that, and we need to look at our retry strategy anyway because currently it just spams forever
      // The chances of a 401 are slim due to the fact we load the updatesUrl from the Graph API - which needs auth
      // So it would happen if your token expires between starting that request and opening the socket
      // We should close that hole, but I think it's not worth the effort right now.

      setConnected(false);
      onClose(event);
    };

    connection.addEventListener('close', closedListener);
    return () => {
      connection.removeEventListener('close', closedListener);
    };
  }, [connection, onClose, logger]);

  useEffect(() => {
    if (!connection) {
      return voidFn;
    }

    const errorListener = (event: ErrorEvent) => {
      logger.log('WebSocket error', JSON.stringify({ event, url: connection.url, readyState: connection.readyState }));
      setWsConnRetryCount(wsConnRetryCount + 1);
      onWebsocketError(event);
      if (wsConnRetryCount === maxRetries && connection.readyState === connection.CLOSED) {
        onRetriesExceeded(event);
      }
    };

    connection.addEventListener('error', errorListener);

    return () => {
      connection.removeEventListener('error', errorListener);
    };
  }, [connection, maxRetries, wsConnRetryCount, onWebsocketError, onRetriesExceeded, logger]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    logger.log(`WebSocket changed - calling connect: ${connection.url}`);
    connection.reconnect();
  }, [connection, logger]);

  const [state, setState] = useState<WsResponse>({ connected, close: () => {}, sendMessage: () => {} });

  useEffect(() => {
    setState({
      connected,
      close: () => {
        if (connection) {
          connection.close();
        }
      },
      sendMessage,
    });
  }, [connected, connection, sendMessage]);

  return state;
};
