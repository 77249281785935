import React from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';

const StyledLogo = styled(Logo)`
  margin-left: ${rem(12)};
`;

const PoweredBy = () => (
  <>
    <Typography size="s" color="classicGrey">Powered By</Typography>
    <StyledLogo />
  </>
);

export default PoweredBy;
