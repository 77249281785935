import React from 'react';
import styled from 'styled-components';

import { clamp } from 'ui/helpers';

export type Props = {
  className?: string,
  currentTime: number | null,
  totalTime: number,
};

type BarProps = {
  timePassed: number,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100rem;
  position: relative;
  height: 0.3rem;
  max-height: 12px;
`;

const Bar = styled.div.attrs(({ timePassed }: BarProps) => ({
  style: {
    borderRadius: (timePassed === 100 ? '100rem' : '100rem 0 0 100rem'),
    width: `${timePassed}%`,
  },
}))<BarProps>`
  border-radius: 100rem;
  display: block;
  background-color: ${({ theme }) => theme.colors.janosGrey};
  height: 100%;
  width: 100%;
`;

export const ProgressBar = ({ className, currentTime, totalTime }: Props) => {
  const timePassed = clamp((currentTime ? (currentTime / totalTime) * 100 : 0), 0, 100);

  return (
    <Wrapper className={className}>
      <Bar timePassed={timePassed} />
    </Wrapper>
  );
};

export default ProgressBar;
