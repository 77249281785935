import React, { ReactNode } from 'react';
import styled from 'styled-components';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import { rem } from 'ui/helpers';
import { ThemeColors } from 'ui/theme';

export type Props = {
  icon?: ReactNode,
  children: ReactNode,
  onDismiss?: () => void,
  backgroundColour?: ThemeColors,
};

type ModalProps = {
  backgroundColour: ThemeColors;
};

const Wrapper = styled(FullPageOverlay)`
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(100)};
  width: ${rem(100)};
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 1000rem;
  margin-bottom: ${rem(25)};
`;

const Modal = styled.div<ModalProps>`
  background: ${({ theme, backgroundColour }) => theme.colors[backgroundColour]};
  border: 2px solid ${({ theme }) => theme.colors.janosGrey};
  border-radius: ${rem(9)};
  padding: ${rem(50)};
  width: 32rem;
`;

const BackButton = styled(Button)`
  margin-top: ${rem(45)};
`;

const FullPageModal = ({ icon, children, onDismiss, backgroundColour = 'black' }: Props) => (
  <Wrapper transparent>
    { icon && <IconWrapper>{ icon }</IconWrapper> }
    <Modal backgroundColour={backgroundColour}>
      { children }
    </Modal>
    {onDismiss && (
      <BackButton
        onClick={onDismiss}
        iconOnly
        label="Back"
        labelPosition="right"
        icon={<ChevronLeft />}
      />
    )}
  </Wrapper>
);

export default FullPageModal;
