/* eslint-disable max-len */

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  > p,
  ol,
  li {
    margin-bottom: 1rem;
  }
`;

const TermsAndConditions = () => (
  <Wrapper data-test="terms-and-conditions">
    <p>
      Fiit is an online fitness platform which delivers, live, trackable and interactive workouts, delivered directly to the home by the world&apos;s most inspirational fitness trainers.
    </p>
    <p>
      Fiit is operated by Fiit Limited (company number: 10713969) a company registered in England and Wales (&quot;<strong>Fiit</strong>&quot;, &quot;<strong>we</strong>&quot; or &quot;<strong>us</strong>&quot;) with registered offices at 14 East Bay Lane, The Press Centre, Queen Elizabeth Olympic Park, London, England, E15 2GW.
    </p>
    <p>
      When you access our platform, app, website, social media, community channels and other websites that we may operate from time to time (&quot;<strong>Site</strong>&quot;), use the services at the Site (&quot;<strong>Services</strong>&quot;) or purchase our products (&quot;<strong>Products</strong>&quot;), these terms (&quot;<strong>Terms</strong>&quot;) will apply.
    </p>
    <p>
      By using our Services, you are also agreeing (i) that you have read and understood our <a href="/privacy/">Privacy Policy</a> which sets out how we collect, process and use your information; and (ii) to our <a href="/medical-disclaimer/">Medical Disclaimer</a>. Please read these Terms carefully before you start to use our Services. We recommend that you print a copy of these Terms for future reference. If you do not agree to these Terms, you must not use our Services.
    </p>
    <ol>
      <li>GENERAL
        <ol>
          <li>These Terms were last updated on 25 November 2021.</li>
          <li>By using our Site and Services, you are agreeing to these Terms and representing that you are at least 16 years old and that if you are between 16 years and 18 years old, your legal guardian has reviewed and agrees to these terms and is happy for you to access and/or use our Site and Services.</li>
          <li>Fiit reserves the right to amend or change these Terms at any time without notice.  By using the Site and Services after any changes have been posted, you agree to any new terms.</li>
          <li>We may suspend, restrict or terminate your account and your access to our Site and Services with or without notice if we believe that you have breached these Terms. </li>
        </ol>
      </li>
      <li>MEDICAL NOTICE &amp; DISCLAIMER
        <ol>
          <li>As part of our Services, you will have access to health, fitness and wellbeing information and will be able to participate in live or on-demand classes, activities and any other products and/or services which are provided by trainers via the Site (the <strong>&ldquo;Session(s)&rdquo;</strong>). You acknowledge and agree that such information and the Sessions are designed for educational and entertainment purposes only and you will not rely on the information or Sessions as a substitute or replacement for professional medical advice or treatment. You accept that the use of our Services is solely at your own risk.</li>
          <li>You acknowledge that the Sessions may be physically demanding and you understand that it is your responsibility to consult with your doctor prior to participating in the Sessions to ensure that you are fit and well enough to take part, and that your participation in the Sessions will not pose any risks to your health and wellbeing. By accessing our Services and taking part in any of the Sessions, you warrant and represent that you are fit, healthy and able to take part in the Sessions and you accept that you do so voluntarily and entirely at your own risk.</li>
          <li>To the fullest extent permitted by law, you hereby waive, release, covenant not to claim, and discharge Fiit and any of our affiliates, directors, officers, employees, contractors, agents, insurers or suppliers from any and all liability, now or in the future, for any claims or conditions arising out of your participation in any of the Sessions.</li>
        </ol>
      </li>
      <li>FIIT APP TERMS
        <ol>Account registration
          <li>In order to access the Services, you’ll need to register for a Fiit account and have a valid subscription and/or a free trial.</li>
          <li>All information that you provide when opening an account must be accurate and up to date, including your name, address, credit or debit card numbers and expiration dates, and any other payment information. You must notify us about any changes, if your payment method is cancelled or if you become aware of a potential breach of security such as the unauthorised disclosure or use of your username or password.</li>
          <li>You agree that you will take all steps necessary to protect your login details and keep them secret. You agree that you will not share your details or allow someone else to use your account. If you do not keep your login details secret, or if you share your account or details with someone else, you accept full responsibility for the consequences (including being charged for additional subscriptions).</li>
        </ol>
        <ol>Purchasing subscriptions &amp; free trials
          <li>You can purchase a subscription directly on our website at www.fiit.tv or&nbsp;through an app provider such as Apple, Google Play Store or Amazon App Store (&ldquo;App Provider&rdquo;).</li>
          <li>We accept payment from the payment options shown at checkout, including Visa, Mastercard and American Express. We reserve the right to check the accuracy and validity of the payment method used, including its credit status in relation to the order value. We may refuse orders depending on the result of these checks.</li>
          <li>We reserve the right to change our prices at any time. Prices of subscriptions may vary depending on the type of subscription and the country where you are located.</li>
          <li>If the credit card that you use to pay is denominated in a currency other than British pounds sterling (GBP), you acknowledge that you may be charged additional administrative and/or currency conversion fees by the applicable bank or service provider, for which you will be responsible.</li>
          <li>From time to time, we may ourselves or through our partners offer free trials to new users. Each person is entitled to no more than one free trial.</li>
          <li>If during the free trial sign up you choose a subscription and provide payment details to us, you will automatically be charged for the full price of the chosen subscription when the free trial ends, unless you cancel beforehand.</li>
        </ol>
        <ol>Renewals, Cancellations &amp; Refunds
          <li>Where you have subscribed for the Services directly through our website, you have 14 days after signing up (the &ldquo;Cancellation Period&rdquo;) to change your mind and cancel without being charged. Where you have signed up for a free trial, you can cancel any time before the end of the free trial period and you will not be charged. Please note that you may lose any right of withdrawal that you have if you have used the Services during the Cancellation Period or the free trial.</li>
          <li>Following expiry of the Cancellation Period or any free trial, subscriptions are non-refundable and there is no right to refunds or credits unless required by law.</li>
          <li>Your subscription will automatically renew unless you cancel. You may cancel your subscription at any time. Cancelling your subscription will take effect at the end of the current subscription period and no future payments will be taken. You will not receive a refund for any unused portion of your subscription.</li>
          <li>Once you have cancelled, unless otherwise stated, you can continue using the Services until the end of your current subscription period.</li>
          <li>If you have purchased your subscription directly with us from our Site, you can cancel by emailing us at support@fiit.tv or sending us a message through the Live Chat function on the Fiit website.</li>
          <li>Where you have subscribed through an App Provider, you must cancel directly with them.</li>
          <li>Please ensure that you cancel at least 72 hours before the end of the term to avoid any charges being taken.</li>
          <li>Please allow no less than 5 to 7 days for the refund to be processed and sent back to you via the payment method used to subscribe.</li>
          <li>If we suspend or terminate your subscription pursuant to these Terms, you may lose the remainder of any subscription and we will not refund you for the remaining term.</li>
        </ol>
        <ol>Subscriptions via third parties
          <li>Where you obtain your subscription via an App Provider or through a third party such as your employer or gym (&ldquo;Corporate Partner&rdquo;), that third party may be responsible for certain elements of your subscription, such as payment, renewal and cancellation.</li>
          <li>Where you have signed up via an App Provider, their separate terms and conditions will apply.</li>
          <li>Please note that where you have signed up via an App Provider or Corporate Partner, Fiit is not able to cancel any subscriptions or free trials on your behalf, nor are we able to issue refunds to you.  </li>
        </ol>
      </li>
      <li>FIIT SHOP TERMS &amp; CONDITIONS
        <ol><li>The Fiit Shop is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</li></ol>
        <ol>Placing orders and contract creation
          <li>Your order constitutes an offer to us to buy the products specified in your order. We will send you an order acknowledgement email as soon as you place your order, and you will receive an order confirmation email detailing the products you have ordered. These emails do not constitute acceptance of your order.</li>
          <li>Order acceptance and the completion of the contract between us will take place on the despatch to you of the products ordered.</li>
          <li>For most products sent directly from our warehouse, you will be charged when your order is placed. If the product is being delivered direct to you from a supplier, you may be charged at any point between confirmation of your order and despatch. The exact timing of this may vary.</li>
          <li>We reserve the right (in our sole discretion) to reject your order for any reason including:
            <ol>
              <li>the ordered products are unavailable or out of stock;</li>
              <li>our inability to obtain authorisation for your payment;</li>
              <li>based on our internal checks, your order is identified as a suspicious or unusual order (for example, purchase for resale) or an order that might be fraudulent; or</li>
              <li>a pricing or product description error. Should we have taken payment prior to rejection of your order then we will refund you, but please note that it can take up to 10 days for the bank to transfer the funds to you.</li>
            </ol>
          </li>
        </ol>
        <ol>Pricing and payment
          <li>The price of products includes VAT but excludes delivery costs which are added to the total cost of the order before you complete your purchase, unless otherwise indicated.</li>
          <li>We accept payment from the payment options shown at checkout, including from Visa, MasterCard, American Express and Paypal. We reserve the right to check the accuracy and validity of the payment method used including in relation to its credit status for the order value. We may refuse orders depending on the result of these checks.</li>
          <li>Where indicated, we also accept payments via Klarna. For further information or questions regarding your payment please check klarna.com or contact Klarna customer service.</li>
          <li>We reserve the right to change our prices at any time. Prices may vary depending on the country where you are located.</li>
          <li>If the credit card that you use to pay is denominated in a currency other than British pounds sterling (GBP), you acknowledge that you may be charged additional administrative and/or currency conversion fees by the applicable bank or service provider, for which you will be responsible.</li>
          <li>Products that are shipped to countries outside of the UK may be subject to taxes, brokerage fees, customs duties and import fees levied by the destination country, which are your responsibility to pay.</li>
        </ol>
        <ol>Delivery
          <li>The availability of products to your delivery address and the associated delivery cost will be notified to you at the time you make your purchase.</li>
          <li>If you receive a notification during checkout that we are unable to deliver to your address with our standard delivery options, please contact us directly by emailing us at support@fiit.tv or sending us a message through the Live Chat function on the Fiit website to discuss whether we are able to make alternative delivery arrangements for you.</li>
          <li>We aim to deliver all orders to addresses in the UK in 3-5 working days, however, delivery can take up to 10 working days.</li>
        </ol>
        <ol>Cancellations, returns and refunds
          <li>You have the right to change your mind within 14 days of receipt of your products and request a refund. To exercise this right, you must, without undue delay and, in any event, no later than 14 days after your cancellation notice, return the products to us at your own cost.</li>
          <li>Any products must be returned in accordance with our instructions, unused, unworn, in its original condition and include all, tags, parts and accessories that were included within the original packaging. We cannot accept any items for return that are not in their original packaging. Products are returned at your own cost and we are not responsible for packages lost in transit without proof of tracking.</li>
          <li>Due to hygiene reasons, heart rate monitors/trackers may not be returned or exchanged if they have been worn or used.</li>
          <li>If a product is returned in damaged condition, for example, if it is soiled, has missing parts or otherwise has signs of abuse, we reserve the right to refuse a refund or to charge additional restocking fees, at our sole discretion.</li>
          <li>Fiit will not be responsible for any issues with products arising from improper or unreasonable use or maintenance, accidents, excess moisture, insects, improper packing, lightning, power surges, or unauthorized tampering, alteration or modification.</li>
          <li>Where accepted, we will refund you the price you paid for the products by the payment method originally used. If you are in the UK or EU, we will also refund standard delivery costs but, should you choose to keep certain items and only partially cancel your order, the delivery cost will not be refunded.</li>
          <li>We will process any refunds due to you as soon as possible using the same payment method used for the purchase. If you are exercising your right to change your mind then your refund will be made within 14 days from the day on which we receive the product back from you or, if earlier, the day on which you provide us with evidence that you have sent the product back to us.</li>
        </ol>
        <ol>Product warranties and disclaimers
          <li>Unless specific warranties are expressly given when you purchase products from our Site, we do not give any warranties or guarantees in respect of such products over and above those implied by applicable law (these include, for example, that the products are of satisfactory quality and reasonably fit for all the purposes for which products of that kind are commonly supplied).</li>
          <li>We only supply the products for domestic and private use. If you are purchasing products from us as a business: to the fullest extent permissible pursuant to applicable law, we disclaim all warranties, express or implied, including, but not limited to, warranties of satisfactory quality, fitness for a particular purpose and non-infringement.</li>
        </ol>
      </li>
      <li>PROMOTIONAL OFFERS AND DISCOUNTS
        <ol>
          <li>Fiit may offer promotional offers and special discounts from time to time, which will be subject to additional terms and conditions to be read in conjunction with these Terms.</li>
          <li>Unless otherwise expressly stated in the terms and conditions for a particular promotion, promotions cannot be combined and cannot be used in conjunction with any other offer/discount.</li>
          <li>Unless otherwise indicated, Fiit subscription discount codes may only be redeemed for subscriptions purchased directly with us through our Site. If you have such a discount code, you must enter this at the time you are purchasing your subscription.</li>
          <li>Refer A Friend Scheme
            <ol>
              <li>Fiit operates a referral scheme for subscriptions purchased directly with us via the Site (not through an App Provider), where you can earn a referral credit worth &pound;20.00 for each successful referral (&ldquo;Referral Credit&rdquo;).</li>
              <li>Under the scheme, you will receive link for friends and family providing them with a discount and you will earn a Referral Credit for each new user who signs up using the link and maintains a paid subscription for at least one 30 days (excluding any free trial).</li>
              <li>Referral Credits may only be applied to subscription payments made via the Site, are non-refundable and cannot be exchanged for cash.</li>
              <li>The referral scheme is only available for personal and non-commercial use.</li>
              <li>Referral Credits may expire if no activity is conducted by you in relation to your account for 180 or more days or if you cancel your subscription. Fiit may change the value of the Referral Credits at any time.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>WEBSITE &amp; APP TERMS OF USE
        <ol>Licence
          <ol>
            <li>Subject to your compliance with these Terms and for so long as you are permitted by us to access the Site and use the Services, Fiit grants you a limited, non-transferable, non-sublicensable, non-exclusive, revocable licence to access the Site and use the Services for your own personal, non-commercial purposes. This licence includes the right to download the Fiit app and view any text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Site and Services.</li>
            <li>Except as expressly permitted by these Terms, you will not copy, reproduce, modify, redistribute, sell, transfer, create derivative works from, decompile, reverse engineer, or disassemble the Site, Services or any content or materials within them, nor will you take any measures to interfere with or damage the Site or Services.</li>
          </ol>
        </ol>
        <ol>Intellectual property rights
          <li>You acknowledge and agree that all copyright, trademarks and all other intellectual property rights in the Products, Site and Services and all material or content supplied as part of the Products, Site and Services will remain at all times vested in us or our licensors. You are permitted to use this material only as expressly authorised by us in accordance with these Terms. All rights not expressly granted by Fiit in these Terms are reserved.</li>
        </ol>
        <ol>Availability
          <li>We cannot guarantee that you will have uninterrupted access to the Site and our Services at all times. While we will make all effort to avoid it, access may be disrupted from time to time due to necessary maintenance, technical issues, network and system overloads or events outside of our control.</li>
          <li>The inclusion of any products or services on Site at a particular time does not imply or warrant that these products or services will be available at any time. We reserve the right to discontinue any Products or Services at any time.</li>
          <li>You may be required to download and install updates to the Fiit App to maintain access to our Services. Failure to do so may result in certain features and services being unavailable or adversely affected.</li>
        </ol>
        <ol>Accuracy
          <li>We try to be as accurate as possible on the Site about the Services and Products including pricing, availability of size, colour and type, quantity of stock available and any descriptions. However, while try our best to ensure that product information on our website is correct, it is possible that the Site may contain errors or inaccuracies. We therefore reserve the right to correct any errors, inaccuracies or omissions and to change and update information at any time without prior notice.</li>
        </ol>
        <ol>Prohibitions
          <li>In addition to other prohibitions as set forth in these Terms, you are prohibited from using the Products, Services or Site: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way to affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Site, Services or any related website, other websites, or the Internet.</li>
        </ol>
        <ol>External links
          <li>We may provide links to other websites from time to time. These links are provided for your convenience. We do not endorse or take responsibility for the content of those websites, are not responsible for the availability of those websites and will not be liable in any way for any loss or damage which you may suffer by using those websites. If you decide to access linked websites you do so at your own risk.</li>
        </ol>
        <ol>User content &amp; social media
          <li>Any content or materials that you provide or make available through the Site or any social media channels of Fiit (&ldquo;User Content&rdquo;), whether publicly posted or privately transmitted, is your sole responsibility. You represent that all User Content submitted is accurate, complete, up-to-date, and in compliance with these Terms and with all applicable laws, rules and regulations.</li>
          <li>You grant Fiit a worldwide, perpetual, irrevocable, non-exclusive, sublicensable, transferable royalty-free license and right to use, copy, transmit, distribute, publicly perform and display (through all media now known or later created), edit, modify, and make derivative works from the User Content (including, without limitation, translations) for any purpose whatsoever, commercial or otherwise, without compensation to you. In addition, you waive any moral rights (or equivalent rights) or rights of privacy or publicity in your User Content. You represent that you own (or have all rights necessary to grant Fiit the rights to use) all User Content, and that Fiit will not need to obtain licenses from any third party or pay royalties to any third party in order to use such User Content.</li>
          <li>If you make suggestions to Fiit about improving or adding new features or products to the Products, Site or Services or you otherwise provide feedback, products or Services reviews or testimonials, to the extent any licence is required, you hereby grant to Fiit a worldwide, perpetual, irrevocable, non-exclusive, sublicensable, transferable royalty-free license and right to use, copy, modify, create derivative works based upon and otherwise use your suggestions, feedback, reviews and testimonials for any purpose (including for marketing), without any notice, compensation or other obligation to you.</li>
          <li>We have no obligation to monitor, review, filter, moderate or remove any User Content from the Site. Nevertheless, we reserve the right to take any such actions in our sole discretion.</li>
        </ol>
      </li>
      <li>LIABILITIES &amp; INDEMNITIES
        <ol>
          <li>The Site, Products and Services are provided on an &quot;as is&quot; and &quot;as available&quot; basis without any representation or endorsement made and we make no warranties of any kind, whether express or implied, in relation to the Products, Site or Services, including but not limited to, implied warranties of non-infringement, compatibility, security, accuracy, conditions of completeness, or any implied warranty arising from course of dealing or usage or trade.</li>
          <li>To the fullest extent permitted by law, we exclude all conditions, warranties, representations or other terms, which may apply to the Services, Site or Products, whether express or implied. This does not affect your statutory rights as a consumer.</li>
          <li>We will not be liable to you for any lack of performance, or the unavailability or failure of any of our Services or Site, or for any failure by us to comply with these Terms, where such lack, unavailability or failure arises from any cause reasonably beyond our control.</li>
          <li>We will not be liable for any loss or damage caused by a virus, distributed denial-of-services attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our Services or to your downloading of any content from it, or on any website linked to it.</li>
          <li>We will not be liable for any damage that may be caused to any device on which you access or use our Services that is caused in any way by our Services, unless that damage is directly caused by our failure to exercise reasonable skill and care in the provision of the Services.</li>
          <li>We will not be liable, in contract, tort (including, without limitation, negligence), pre-contract or other representations or otherwise out of or in connection with these Terms for:
            <ol>
              <li>any economic losses (including without limitation loss of revenues, profits, contracts, business or anticipated savings);</li>
              <li>any loss of goodwill or reputation; or</li>
              <li>any special or indirect losses, suffered or incurred by that party arising out of or in connection with the provisions of any matter under these Terms.</li>
            </ol>
          </li>
          <li>Nothing in these Terms limits or excludes our liability for:
            <ol>
              <li>death or personal injury by our negligence;</li>
              <li>fraudulent misrepresentation; or</li>
              <li>any other liability that cannot be excluded by law.</li>
            </ol>
          </li>
          <li>Subject to the above, any liability we do have for losses you suffer is strictly limited to the purchase price and the losses that were foreseeable. Loss or damage is only foreseeable where they could be contemplated by you and us at the time of you agreeing to these Terms.</li>
          <li>To the fullest extent permitted by law, you agree to indemnify and hold Fiit harmless from and against all damages, losses, and expenses of any kind (including reasonable legal fees and costs) arising out of:
            <ol>
              <li>your breach of these Terms;</li>
              <li>any allegation that any materials that you submit to us or post on our Services infringe or otherwise violate the intellectual property rights of a third party; and</li>
              <li>your access or use of our Services.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>MISCELLANEOUS
        <ol>
          <li>We collect personal information about you through your use of our Services. All information that we collect about you is subject to our Privacy Policy.</li>
          <li>These Terms do not affect your legal rights. For further information about your legal rights, contact your local authority (for example, in the United Kingdom, this includes the Trading Standards Department or Citizen’s Advice Bureau).</li>
          <li>If any part of these Terms is deemed to be unenforceable, the enforceability of any other part of these Terms will not be affected.</li>
          <li>You may not assign, sub-licence or otherwise transfer your rights or obligations under these Terms to anyone else. You agree that we may assign or transfer any of our rights or obligations under these Terms without notice to you.</li>
          <li>If we delay exercising or fail to exercise or enforce any right available to us under these Terms, such delay or failure does not constitute a waiver of that right or any other rights under these Terms.</li>
          <li>These Terms between you and us shall be governed by and interpreted in accordance with the laws of England and Wales. The courts of England and Wales shall have non-exclusive jurisdiction to resolve any claims, disputes or disagreements relating to these Terms.</li>
        </ol>
      </li>
      <li>HOW TO CONTACT US
        <p>If you have any feedback, questions or complaints or any requests for technical support, then please e-mail us at: support@fiit.tv. You can also write to us at 14 East Bay Lane, The Press Centre, Queen Elizabeth Olympic Park, London, England, E15 2GW.</p>
        <p>We do our best to respond to all queries as soon as possible but we cannot guarantee a response time.</p>
      </li>
    </ol>
  </Wrapper>
);

export default TermsAndConditions;
