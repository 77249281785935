import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #6a42f5;
  width: 2rem;
  height: 2rem;
`;

type Props = {
  className?: string,
};

const TestingBadge = ({ className = '' } : Props) => (
  <Wrapper className={className}>
    <Typography size="xs">TEST</Typography>
  </Wrapper>
);

export default TestingBadge;
