import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(15)};
  height: ${rem(18)};
`;

function SvgPlay({ className = '' }: IconProps) {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 15 18" className={className}>
      <defs>
        <path id="play_svg__a" d="M15 9L0 18V0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="play_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#play_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#play_svg__a" />
        <g fill={theme.colors.white} mask="url(#play_svg__b)">
          <path d="M-6-3h24v24H-6z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgPlay;
