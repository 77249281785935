const hideInitialLoadLogo = (logoId: string, logoTransitionSeconds: number) => {
  const initialLoadLogo = document.getElementById(logoId);
  if (initialLoadLogo) {
    initialLoadLogo.style.opacity = '0%';
    initialLoadLogo.ontransitionend = initialLoadLogo.remove;
    setTimeout(() => {
      // This causes illegal invocation error if not called inside wrapping function.
      initialLoadLogo.remove();
    }, logoTransitionSeconds * 1000);
  }
};

export default hideInitialLoadLogo;
