import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import UserIcon from 'ui/components/atoms/animated-icons/user';

export type Props = {
  className?: string,
  value: number | null,
  disabled?: boolean,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.betaGrey};
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => `${theme.spacing.l}`};
  max-height: ${({ theme }) => theme.misc.segmentHeight};
  margin-bottom: 0.125rem;
`;

const IconWrapper = styled.div`
  width: 1.25rem;
`;

const NoOfUsers = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0 ${({ theme }) => theme.spacing.m};
  text-align: right;
`;

const Label = styled(Typography)`
  letter-spacing: 0.16rem;
  min-width: 1rem;
  max-width: 4.8rem;
  text-align: left;
`;

export const MembersInClassCounter = ({ className, value, disabled }: Props) => (
  <Wrapper className={className}>
    <IconWrapper>
      <UserIcon />
    </IconWrapper>
    <NoOfUsers
      variant="double-pica"
      color={disabled || value == null ? 'wednesdayGrey' : 'beckersKnop'}
      as="p"
      weight="medium"
    >
      { value == null ? '--' : value }
    </NoOfUsers>

    <Label
      variant="minion"
      color="beckersKnop"
      size="s"
      as="p"
      lineHeight="m"
      weight="bold"
    >
      Members in class
    </Label>

  </Wrapper>
);

export default MembersInClassCounter;
