import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

const Rect = styled.rect`
  width: 1rem;
  height: 1rem;
`;

function MovingBody({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
      <g clipPath="url(#clip0_359_9815)">
        <path d="M20.3663 3.00002C20.3663 4.65688 19.0232 6.00002 17.3663 6.00002C15.7095 6.00002 14.3663 4.65688 14.3663 3.00002C14.3663 1.34317 15.7095 2.46877e-05 17.3663 2.46877e-05C19.0232 2.46877e-05 20.3663 1.34317 20.3663 3.00002Z" fill={fill} />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.5394 12.9246L20.1708 13.3403L23.4706 8.32916L20.965 6.67926L19.3151 9.18483L7.82631 1.61958L7.1659 2.6225L7.16589 2.62249L3.84492 7.66578L6.35049 9.31567L8.68197 5.77502L10.3524 6.87495L6.07824 13.3657L1.14217 20.8618L3.64774 22.5117L8.45821 15.2064L12.2569 17.7078L9.25742 22.2628L11.763 23.9127L16.4123 16.8521L16.4123 16.8521L13.9068 15.2022L12.6136 14.3507L15.3635 10.1747L17.6652 11.6904L19.5394 12.9246Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_359_9815">
          <Rect fill={fill} />
        </clipPath>
      </defs>
    </Svg>
  );
}

export default MovingBody;
