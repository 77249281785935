import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import BreakdownSectionRow,
{
  CommonProps as BreakdownSectionRowProps,
} from 'ui/components/molecules/class-details-extra-information/breakdown-section-row';
import { rem } from 'ui/helpers';

type ExerciseDetailsRowProps = BreakdownSectionRowProps & {
  isBreakSection?: boolean,
  name?: string | null,
  subText?: string | null,
};

const ExerciseDetailsContainer = styled(BreakdownSectionRow)<{ isBreakSection: boolean }>`
  align-items: center;
  flex-direction: row;
  padding: 0 2rem;
  min-height: ${({ isBreakSection }) => (isBreakSection ? rem(80) : rem(120))};
  background-color: ${({ theme, isBreakSection }) => (
    isBreakSection ? theme.colors.black : theme.colors.fordDarkCharcoal
  )};
`;

const Title = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${rem(700)};
`;

const TransparentText = styled(Typography)`
  opacity: 0.5;
`;

const ExerciseDetailsRow = ({
  id,
  name,
  subText,
  isBreakSection = false,
  onFocus,
}: ExerciseDetailsRowProps) => (
  <ExerciseDetailsContainer isBreakSection={isBreakSection} id={id} onFocus={onFocus}>
    <Title weight="bold">{name}</Title>
    <TransparentText variant="pica">{subText}</TransparentText>
  </ExerciseDetailsContainer>
);

export default ExerciseDetailsRow;
