import React from 'react';
import styled from 'styled-components';

import useKeyEvent, { Key } from 'utils/use-key-event';

const FixedPanel = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 40%;
  left: 0;
  display: inline-block;
  transform: translateX(${({ visible }) => (visible ? '10%' : '-110%')});
  transition: transform 0.2s;
  overflow: hidden;
  z-index: 10000;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

const DebugHeader = styled.div`
  padding: 5px 10px;
`;

const Button = styled.button`
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.fiitBlue};
  color: ${({ theme }) => theme.colors.white};
  margin: 3px 5px;
`;

export type Props = {
  lessonInstanceId?: string,
  visible: boolean,
  skipSeconds: (seconds: number) => void,
};

const DebugBar = ({ visible, skipSeconds }: Props) => {
  useKeyEvent({
    key: ['.', Key.MediaFastForward, 'ơ'],
    handler: () => skipSeconds(60),
  });

  useKeyEvent({
    key: [',', Key.MediaRewind, 'Ɯ'],
    handler: () => skipSeconds(-60),
  });

  useKeyEvent({
    key: ['/'],
    handler: () => skipSeconds(Infinity),
  });

  return (
    <FixedPanel visible={visible}>
      <DebugHeader>Debug</DebugHeader>
      <Button type="button" onClick={() => skipSeconds(10)}>
        Skip 10s
      </Button>
      <Button type="button" onClick={() => skipSeconds(30)}>
        Skip 30s
      </Button>
      <Button type="button" onClick={() => skipSeconds(60)}>
        Skip 1m
      </Button>
      <Button type="button" onClick={() => skipSeconds(300)}>
        Skip 5m
      </Button>
    </FixedPanel>
  );
};

export default DebugBar;
