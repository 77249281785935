import fetch from 'utils/fetch';
import urlSearchParams from 'utils/url-search-params';

type CreateAuthArgs = {
  url: string;
  clientId: string;
  clientSecret: string;
  magicLinkUrl: string;
};

export type TokenPayload = {
  tokenType: string,
  accessToken: string,
  expiresIn: number,
  refreshToken: string,
};

type MagicLinkResponse = {
  message: string;
};

type RefreshRequest = Promise<TokenPayload>;

const createAuthService = ({ url, clientId, clientSecret, magicLinkUrl }: CreateAuthArgs) => {
  if (!url) {
    throw new Error('No url supplied to auth service');
  }

  if (!clientId) {
    throw new Error('No client supplied to auth service');
  }

  if (!clientSecret) {
    throw new Error('No client secret supplied to auth service');
  }

  if (!magicLinkUrl) {
    throw new Error('No magic link url supplied to auth service');
  }

  let inFlightRefresh: RefreshRequest | null = null;

  const refreshTokenFunc = async ({ refreshToken }: { refreshToken: string }) => {
    const params = urlSearchParams({
      client_id: clientId,
      grant_type: 'refresh_token',
      client_secret: clientSecret,
      refresh_token: refreshToken,
    });

    const { body } = await fetch(url, {
      body: params,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });

    inFlightRefresh = null;
    return body as TokenPayload;
  };

  return {
    inFlightRefresh,
    login: async (credentials: { username: string, password: string }) => {
      const params = urlSearchParams({
        client_id: clientId,
        grant_type: 'password',
        client_secret: clientSecret,
        ...credentials,
      });

      const { body } = await fetch(url, {
        body: params,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      return body as TokenPayload;
    },

    loginWithCode: async (credentials: { code: string }) => {
      const params = urlSearchParams({
        client_id: clientId,
        grant_type: 'authorization_code',
        client_secret: clientSecret,
        ...credentials,
      });

      const { body } = await fetch(url, {
        body: params,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });
      return body as TokenPayload;
    },

    refreshToken: async ({ refreshToken }: { refreshToken: string }) => {
      if (!inFlightRefresh) {
        inFlightRefresh = refreshTokenFunc({ refreshToken });
      }

      return inFlightRefresh;
    },

    requestMagicLink: async ({ email }: {email: string}): Promise<MagicLinkResponse> => {
      const params = urlSearchParams({
        client_id: clientId,
        client_secret: clientSecret,
        email,
      });
      const { body } = await fetch(magicLinkUrl, {
        body: params,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });
      return body;
    },
  };
};

export type AuthService = ReturnType<typeof createAuthService>;

export default createAuthService;
