declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        auxiliaryWindowLocation: {
          postMessage: (url: string) => void,
        },
      },
    },
    auxiliaryWindow?: {
      isConnected?: () => Promise<boolean>,
    },
  }
}

type SendWebkitMessageArgs = {
  blankScreen?: boolean,
};

export const useAuxiliaryWindow = () => {
  const sendWebkitMessage = ({ blankScreen }: SendWebkitMessageArgs = {}) => {
    if (!window.webkit?.messageHandlers) {
      return;
    }
    if (blankScreen) {
      window.webkit.messageHandlers.auxiliaryWindowLocation.postMessage('');
    } else {
      window.webkit.messageHandlers.auxiliaryWindowLocation.postMessage(window.location.href);
    }
  };

  const isAuxiliaryWindowConnected: () => Promise<boolean> = async () => (
    window.auxiliaryWindow?.isConnected
      ? window.auxiliaryWindow?.isConnected()
      : Promise.resolve(false)
  );

  return { sendWebkitMessage, isAuxiliaryWindowConnected };
};

export default useAuxiliaryWindow;
