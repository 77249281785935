import React from 'react';
import styled from 'styled-components';

import UIPanel from 'ui/components/atoms/ui-panel';
import StatCounter from 'ui/components/molecules/stat-counter';
import ZoneBar from 'ui/components/atoms/zone-bar';

import BPM from 'ui/components/atoms/icons/bpm';

export type Props = {
  className?: string,
  currentBpm: number | null,
  zoneStart?: number,
  disabled?: boolean,
  userHeartRateRange?: {
    min: number,
    max: number,
  } | null,
};

const Wrapper = styled(UIPanel)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0.7rem 0.5rem;
`;

const BpmAndZoneWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
`;

export const BPMCounter = ({ className, currentBpm, userHeartRateRange, zoneStart, disabled }: Props) => (
  <Wrapper className={className}>
    <BpmAndZoneWrapper>
      <StatCounter
        label="BPM"
        value={currentBpm}
        icon={<BPM />}
        fontVariant="double-pica"
      />
    </BpmAndZoneWrapper>
    {userHeartRateRange && (
      <ZoneBar
        currentBpm={currentBpm}
        userHeartRateRange={userHeartRateRange}
        disabled={disabled || !zoneStart}
        zoneStart={zoneStart}
      />
    )}
  </Wrapper>
);

export default BPMCounter;
