import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Button from 'ui/components/atoms/button';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

// TODO "accepting" state i.e. currently accepting needs a spinner
// TODO "acceptError" state i.e. "please try again" message inline in the modal
type Props = {
  title: string,
  children: ReactNode,
  onAcceptClick: () => void,
  accepting?: boolean,
  acceptError?: Error,
  autofocus?: boolean,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  align-self: center;
  margin-bottom: ${rem(30)};
`;

const AcceptButton = styled(Button)`
  align-self: center;
  margin-top: ${rem(50)};
  width: ${rem(390)};
`;

const ModalAlertContents = ({ title, children, onAcceptClick, accepting, autofocus = false }: Props) => (
  <Wrapper>
    <Title variant="double-pica">{ title }</Title>
    <div>{ children }</div>
    <AcceptButton
      onClick={onAcceptClick}
      label="Accept"
      variant="cta"
      disabled={accepting}
      autofocus={autofocus}
    />
  </Wrapper>
);

export default ModalAlertContents;
