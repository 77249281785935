import styled from 'styled-components';

/**
 * A building block for panel like TV UI elements
 */
const UIPanel = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.standard};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.7rem;
`;

export default UIPanel;
