import { PrepareStream, VideoActionTypes, EndStream } from 'actions/video';
import createReducer from 'utils/create-reducer';
import { ActionCreator, Handler } from 'actions';
import cookies from 'js-cookie';

type VideoHandler<Action extends ActionCreator> = Handler<VideoState, Action>;

export type VideoState = {
  [lessonMediaId: string]: {
    cookie: string | null,
    pauseVisible: boolean | null,
  },
};

const STREAMING_COOKIES = [
  'CloudFront-Key-Pair-Id',
  'CloudFront-Policy',
  'CloudFront-Signature',
];

const prepareStreamHandler: VideoHandler<PrepareStream> = (state, {
  lessonMediaId,
  signedCookie,
}) => {
  signedCookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.trim().split('=');
    cookies.set(key, value);
  });

  return {
    ...state,
    [lessonMediaId]: {
      ...state[lessonMediaId],
      cookie: signedCookie,
    },
  };
};

const endStreamHandler: VideoHandler<EndStream> = (state, { lessonMediaId }) => {
  STREAMING_COOKIES.forEach((cookie) => cookies.remove(cookie));
  return {
    ...state,
    [lessonMediaId]: {
      ...state[lessonMediaId],
      cookie: null,
    },
  };
};

const handlers = {
  [VideoActionTypes.PREPARE_STREAM]: prepareStreamHandler,
  [VideoActionTypes.END_STREAM]: endStreamHandler,
};

export const initialVideoState = {} as VideoState;
export const videoReducer = createReducer<VideoState>(handlers);
