import { DefaultTheme } from 'styled-components';

type Props = {
  theme: DefaultTheme,
  activeColor?: boolean,
};

export default ({ theme, activeColor }: Props) => `/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: ${activeColor ? theme.colors.active : theme.colors.inActive};
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: ${activeColor ? theme.colors.secondaryActive : theme.colors.secondaryInActive};
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

div[data-test-id="CircularProgressbarWithChildren__children"] {
  transform: translateY(-100%);
  margin-top: 0!important;
}

`;
