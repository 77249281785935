import { useAppState } from 'state';
import { RoutePermissions, verifyRoutePermissions } from 'app/in-studio/utils/route-permissions';
import usePlatformPartner from 'app/in-studio/hooks/use-platform-partner';

type AllRouteConditions = {
  loggedIn: boolean,
  platformChecked: boolean,
  platformSet: boolean,
  permissionsFulfilled: boolean,
};

const useAllRouteConditions = (requiredPermissions: RoutePermissions[]): AllRouteConditions => {
  const loggedIn = useAppState(({ auth }) => auth.loggedIn);
  const platformPartner = usePlatformPartner();
  const { permissions, isChecked: platformChecked, isSet: platformSet } = platformPartner;
  const permissionsFulfilled = verifyRoutePermissions(permissions, requiredPermissions);

  return {
    loggedIn,
    platformChecked,
    platformSet,
    permissionsFulfilled,
  };
};

export default useAllRouteConditions;
