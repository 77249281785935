import { useCallback, useEffect } from 'react';
import useConfig from 'app/on-tv/config-provider';

const CUSTOM_KEYBOARD_PATH = '/comcast-keyboard/appBundle.js';

const keyboardMetaData = {
  name: 'Keyboard',
  identifier: 'com.comcast.ui.Components',
  id: 'APP_com_comcast_ui_Components',
  version: '1.0.1',
};

const settings = {
  appSettings: {
    stage: {
      clearColor: '0x00000000',
      useImageWorker: true,
      tabindex: 0,
      // this is mainly for keyboard dev locally, comcast boxes set the webapp as 720p
      w: window.innerHeight === 720 ? 680 : 1000,
      h: window.innerHeight === 720 ? 433 : 650,
    },
    debug: false,
    version: keyboardMetaData.version,
    id: keyboardMetaData.identifier,
  },
  platformSettings: {
    log: false,
    showVersion: false,
    textureMode: true,
  },
};

type KeyboardConfig = {
  title: string,
  mask: string | null,
  message: string,
};

let keyboard: any = null;
let keyboardLoading = false;
let activeElement: HTMLInputElement | null = null;

// hook used to initialise the custom keyboard and load it in via a script
export const useCustomKeyboardInit = () => {
  const { config } = useConfig();

  useEffect(() => {
    if (!config.ENABLE_CUSTOM_ON_SCREEN_KEYBOARD || keyboardLoading || keyboard) {
      return;
    }

    keyboardLoading = true;
    const scriptTag = document.createElement('script');
    scriptTag.onload = () => {
      keyboard = (global as any)[keyboardMetaData.id](
        settings.appSettings,
        settings.platformSettings,
      );

      const canvas = keyboard.stage.getCanvas();
      canvas.style.position = 'absolute';
      canvas.style.zIndex = '999';
      canvas.style.right = '2rem';
      canvas.style.top = `${(window.innerHeight - settings.appSettings.stage.h) / 2}px`;
      canvas.style.display = 'none';
      canvas.setAttribute('tabindex', '0');
      document.body.appendChild(canvas);

      keyboard.stage.on('done', ({ value } : { value: string }) => {
        if (activeElement) {
          // refocus the element for spatial nav to take back over
          activeElement.value = value;
          activeElement.focus();

          canvas.style.display = 'none';
          activeElement = null;
        }
        keyboard.stage.emit('keyboardInactive');
      });

      keyboard.stage.on('change', ({ value } : { value: string }) => {
        if (activeElement) {
          activeElement.value = value;
        }
      });

      keyboard.stage.on('delete', ({ value }: { value: string }) => {
        if (activeElement) {
          activeElement.value = value;
        }
      });

      keyboard.stage.on('clear', () => {
        if (activeElement) {
          activeElement.value = '';
        }
      });

      keyboardLoading = false;
    };

    // comcasts keyboard bundle in the /static folder
    scriptTag.src = CUSTOM_KEYBOARD_PATH;

    document.body.appendChild(scriptTag);
  }, [config]);
};

// hook used to diplay the custom keyboard on screen
export const useCustomKeyboard = (config: KeyboardConfig) => {
  const { config: appConfig } = useConfig();

  const showKeyboard = useCallback((element: HTMLInputElement | null) => {
    if (!element || !appConfig.ENABLE_CUSTOM_ON_SCREEN_KEYBOARD) {
      return;
    }

    const { value } = element;

    keyboard.stage.emit('keyboardConfig', { ...config, value });
    keyboard.stage.emit('keyboardActive');

    const canvas = keyboard.stage.getCanvas();
    canvas.style.display = 'block';

    activeElement = element;

    // steal focus from spatial nav, delay is to ensure this happens after latest
    // spatial nav focus call
    setTimeout(() => canvas.focus(), 100);
  }, [appConfig.ENABLE_CUSTOM_ON_SCREEN_KEYBOARD, config]);

  const hideKeyboard = () => {
    if (!appConfig.ENABLE_CUSTOM_ON_SCREEN_KEYBOARD) {
      return;
    }

    if (activeElement) {
      const canvas = keyboard.stage.getCanvas();
      // refocus the element for spatial nav to take back over
      activeElement.focus();
      canvas.style.display = 'none';
      activeElement = null;
    }

    if (keyboard) {
      keyboard.stage.emit('keyboardInactive');
    }
  };

  return { showKeyboard, hideKeyboard };
};

export default useCustomKeyboard;
