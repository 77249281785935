import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ApolloError } from 'apollo-client';
import { useHistory } from 'react-router-dom';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import SvgExclaim from 'ui/components/atoms/icons/exclaim';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import Disconnected from 'ui/components/atoms/icons/disconnected';
import { useAppState } from 'state';

type Props = {
  error?: ApolloError | Error | boolean,
  onDismiss?: 'back' | 'hide' | 'action',
  onDismissAction?: () => void,
  message?: string,
  showBackButton?: boolean,
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.3rem;
  height: 3.3rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 2rem;
`;

const Holder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 30rem;
`;

const ButtonHolder = styled.div`
  margin-top: ${({ theme }) => theme.spacing.l};
  display: flex;
  align-items: center;
`;

const Heading = styled(Typography)`
  margin-top: 2rem;
`;

const SubHeading = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing.m};
  text-align: center;
`;

const ActionText = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing.s};
`;

const ErrorOverlay = ({
  error,
  onDismiss = 'back',
  message,
  onDismissAction,
  showBackButton = true,
}: Props) => {
  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const networkOnline = useAppState((state) => state.networkStatus.isOnline);
  const [onMountNetworkOnline] = useState(networkOnline);

  const header = networkOnline
    ? 'Oh no!'
    : 'Not connected';

  const defaultMessage = networkOnline
    ? 'It looks like there\'s a problem, we are working to fix it and will be up and running soon'
    : 'Please check your internet connection and try again';

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);

  useEffect(() => {
    if (window.$badger) {
      window.$badger.errorMetricsHandler(message || defaultMessage, true);
    }
  }, [message, defaultMessage]);

  useEffect(() => {
    if (!onMountNetworkOnline && networkOnline) {
      history.goBack();
    }
  }, [networkOnline, history, onMountNetworkOnline]);

  const onAction = useCallback(() => {
    switch (onDismiss) {
      case 'hide':
        setVisible(false);
        break;
      case 'action':
        if (onDismissAction) {
          onDismissAction();
        }
        break;
      default:
        history.goBack();
        break;
    }
  }, [history, onDismiss, onDismissAction]);

  if (!error || !visible) {
    return null;
  }

  return (
    <FullPageOverlay>
      <Holder>
        <IconWrapper>
          {networkOnline ? (
            <SvgExclaim />
          ) : (
            <Disconnected />
          )}
        </IconWrapper>
        <Heading variant="body-copy" size="l" weight="bold">{header}</Heading>
        <SubHeading variant="body-copy" weight="bold" color="beckersKnop">
          { message || defaultMessage }
        </SubHeading>
        {showBackButton && (
          <ButtonHolder>
            <Button
              onClick={onAction}
              variant="cta"
              icon={<ChevronLeft />}
              iconOnly
              autofocus
            />
            <ActionText variant="body-copy" weight="bold">Back</ActionText>
          </ButtonHolder>
        )}
      </Holder>
    </FullPageOverlay>
  );
};

export default ErrorOverlay;
