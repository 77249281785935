import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

import Button from 'ui/components/atoms/button';

export type Props = {
  icon?: ReactNode,
  onClick?: (event: SyntheticEvent) => void,
  className?: string,
};

const Wrapper = styled.div`
  display: inline;
`;

const FullHeightButton = styled(Button)`
  height: 100%;
  border-radius: 0 2rem 2rem 0;
  text-align: left;
`;

const MenuIcon = ({ onClick, icon, className }: Props) => (
  <Wrapper className={className}>
    <FullHeightButton
      variant="icon"
      onClick={onClick}
      icon={icon}
      iconOnly
    />
  </Wrapper>
);

export default MenuIcon;
