import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => theme.spacing.s} 0;

  :first-child {
    margin-top: 0;
  }

  input {
    transform: scale(1.75);
    margin: 0 1rem 0.15rem 0;
  }
`;

const CheckboxLabel = styled(Typography)`
  cursor: pointer;
`;

export type Props = {
  className?: string,
  label: string,
  description?: string,
  onChange: (checked: boolean) => void,
  checked: boolean,
  disabled?: boolean,
};

const CheckboxField = ({
  className,
  label,
  checked = false,
  onChange,
  disabled = false,
}: Props) => (
  <FieldContainer className={className}>
    <input
      type="checkbox"
      checked={checked}
      onChange={(event) => onChange(event.currentTarget.checked)}
      disabled={disabled}
    />
    <CheckboxLabel onClick={() => onChange(!checked)}>{label}</CheckboxLabel>
  </FieldContainer>
);

export default CheckboxField;
