// TODO: remove those anys
import React from 'react';
import styled from 'styled-components';
import memoize from 'lodash/memoize';

import { v4 } from 'uuid';

import Time from 'ui/components/atoms/time';
import Typography from 'ui/components/atoms/typography';
import TimelineSegment, { MappedSegment } from 'ui/components/atoms/timeline-segment';

export type Props = {
  className?: string,
  currentTime: number,
  duration: number,
  timeline: any[],
};

const IS_REST = ['REST_SECTION'];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 ${({ theme }) => theme.spacing.xs};
  width: 100%;
`;

const StyledTimeTypo = styled(Typography)`
  letter-spacing: 0.2em;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  padding-left: ${({ theme }) => theme.spacing.xs};
  -webkit-text-stroke-width: 1px;
`;

const getTimeline = memoize(
  (timeline: any[]) => timeline
    .map((segment: any, index: number, arr: any[]) => {
      if (arr[index - 1] && arr[index - 1].type === 'INTRO_SECTION') {
        return {
          ...segment,
          activities: [
            ...segment.activities,
            ...arr[index - 1].activities,
          ],
        };
      }

      if (arr[index + 1] && arr[index + 1].type === 'OUTRO_SECTION') {
        return {
          ...segment,
          activities: [
            ...segment.activities,
            ...arr[index + 1].activities,
          ],
        };
      }

      return segment;
    })
    .filter(({ type }) => type && !['INTRO_SECTION', 'OUTRO_SECTION'].includes(type))
    .map(({ activities, type }: any) => ({
      key: v4(),
      totalTime: activities
        .reduce(
          (a: any[], b: { activities: any[]; }) => (b.activities ? [...a, ...b.activities] : [...a, b]), [],
        )
        .reduce(
          (acc: any, { duration: dur }: any) => acc + dur, 0,
        ),
      type,
    })),
);

const getAdjustedDuration = memoize(
  (timeline: any[]) => timeline
    .filter(({ type: t }) => !IS_REST.includes(t))
    .reduce((acc, { totalTime: tt }) => acc + (tt || 0), 0),
);

export const VideoProgressDisplay = ({ className, currentTime, duration, timeline }: Props) => {
  const mappedTimeline = getTimeline(timeline);
  const adjustedDuration = getAdjustedDuration(mappedTimeline);

  return (
    <Wrapper className={className}>
      { mappedTimeline.map(
        (segment: MappedSegment, index: number, array: MappedSegment[]) => (
          <TimelineSegment
            key={segment.key}
            segment={segment}
            index={index}
            timeline={array}
            current={currentTime}
            duration={adjustedDuration}
          />
        ),
      )}
      <StyledTimeTypo
        color="white"
        variant="long-primer"
        as="p"
      >
        <Time time={duration - currentTime} />
      </StyledTimeTypo>
    </Wrapper>
  );
};

export default VideoProgressDisplay;
