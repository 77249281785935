import { useCallback, useEffect, useRef, useState } from 'react';

import mux from 'mux-embed';
import throttle from 'lodash/throttle';
import useConfig from 'config';
import useLogger from 'app/hooks/use-logger';

type PlaybackError = (error: Error) => void;

type ShakaError = Error & { code: number, category: number };

const useHandleVideoPlayback = (
  src: {
    dashUrl?: string | null,
    hlsUrl?: string | null,
  },
  elRef: React.MutableRefObject<HTMLVideoElement | null>,
  playing: boolean,
  seekTolerance: number,
  seekInterval: number,
  onTimeChangeInterval: number,
  urlLoaded: string | null,
  elComponentId: string,
  monitor: boolean,
  monitorMeta: {
    playerName?: string,
    playerType?: string,
    videoId?: string,
    videoTitle?: string,
    videoDuration?: number,
    userId?: number | null,
  },
  playbackSpeed: number,
  loop: boolean,
  onVideoEnd?: EventListener,
  onPlayFailed?: PlaybackError,
  onBuffering?: (isBuffering: boolean) => void,
  onTimeChange?: (currentTime: number) => void,
  seek?: number,
) => {
  const requestedPlaying = useRef<boolean>(playing);
  const playerMux = useRef<any>(null);
  const playerInitTime = useRef<number>(Date.now());
  const { config } = useConfig();
  const logger = useLogger('video-player');
  const [buffering, setBuffering] = useState(false);

  useEffect(() => {
    requestedPlaying.current = playing;
  }, [playing]);

  // Set-up effect - responsible for monitoring the video stream
  useEffect(() => {
    if (!monitor) {
      return () => {};
    }

    playerMux.current = mux.monitor(`.${elComponentId}`, {
      debug: false,
      data: {
        env_key: config.MUX_DATA_ENV_KEY,

        // Player Metadata
        player_name: monitorMeta.playerName,
        player_type: monitorMeta.playerType,
        player_init_time: playerInitTime.current,
        player_version: '0.0.1',

        // Video Metadata
        video_id: monitorMeta.videoId,
        video_title: monitorMeta.videoTitle,
        video_duration: monitorMeta.videoDuration,
        viewer_user_id: monitorMeta.userId,
      },
    });

    return () => {};
  }, [
    monitor,
    monitorMeta.playerName,
    monitorMeta.playerType,
    monitorMeta.videoId,
    monitorMeta.videoTitle,
    monitorMeta.videoDuration,
    monitorMeta.userId,
    config,
    elComponentId,
  ]);

  useEffect(() => {
    // not even got an element - don't bother
    const video = elRef.current;

    if (!video) {
      return;
    }

    if (urlLoaded !== src.dashUrl || !src.dashUrl) {
      return;
    }

    if (playing && video.paused) {
      video.play()
        .then(() => logger.debug('Playing'))
        .catch((error: ShakaError) => {
          // Call to pause() interrupted async play() error - but we're currently !playing, so we're in the right state
          if (!requestedPlaying.current && error?.name === 'AbortError') {
            logger.warn('User paused before play completed - ignored as user has asked to pause', error);
            return;
          }

          logger.error('Failed to play', { ...error, dashUrl: src.dashUrl });

          if (monitor) {
            // TODO
            mux.emit('Video player', 'error', {
              player_error_code: error.code,
              player_error_message: 'Failed to play',
            });
          }

          if (onPlayFailed) {
            onPlayFailed(error);
          }
        });
    }

    if (!playing && !video.paused) {
      video.pause();
      logger.debug('Paused');
    }
  }, [monitor, elRef, src.dashUrl, playing, onPlayFailed, logger, urlLoaded]);

  // Event handler for time changes
  useEffect(() => {
    const video = elRef.current;
    if (!video || !onTimeChange) {
      return () => {};
    }

    const updateTime = throttle(
      (e) => onTimeChange(e.target.currentTime),
      onTimeChangeInterval,
    );

    video.addEventListener('timeupdate', updateTime);
    return () => {
      video.removeEventListener('timeupdate', updateTime);
    };
  }, [elRef, onTimeChange, onTimeChangeInterval, config]);

  // Sometimes when the network disconnects (ethernet disconnect on sky) the buffering event
  // isn't always fired, this ensures that we always show the buffering overlay
  // when the buffer has been used up
  useEffect(() => {
    const video = elRef.current;

    if (!video || !onBuffering) {
      return () => {};
    }

    const triggerBuffering = setInterval(() => {
      const timeRanges = video.buffered;
      const videoBufferedAmount = timeRanges.length ? timeRanges.end(0) : null;

      // not even started to load into the buffer yet
      if (!videoBufferedAmount) {
        return onBuffering(true);
      }

      // at the start of a class we need to make sure we have at least loaded the VIDEO_MINIMUM_LEADING_BUFFER_SECONDS
      // before hiding the buffering screen as this is when shaka starts playing the video
      if (videoBufferedAmount <= config.VIDEO_MINIMUM_LEADING_BUFFER_SECONDS) {
        return onBuffering(true);
      }

      // all other cases, if we've used up the buffer then show the buffering overlay
      const isBuffering = playing &&
        (videoBufferedAmount - 1) < video.currentTime;

      if (isBuffering !== buffering) {
        setBuffering(isBuffering);
        if (isBuffering) {
          logger.info('Buffer empty, player pausing');
        } else {
          logger.debug('No longer buffering, player resuming');
        }
      }

      return onBuffering(isBuffering);
    }, 100);

    return () => {
      clearInterval(triggerBuffering);
    };
  }, [elRef, onBuffering, playing, config.VIDEO_MINIMUM_LEADING_BUFFER_SECONDS, logger, buffering, setBuffering]);

  // Event handler for video finishing
  useEffect(() => {
    const video = elRef.current;
    if (!video || !onVideoEnd) {
      return () => {};
    }

    video.addEventListener('ended', onVideoEnd);
    return () => {
      video.removeEventListener('ended', onVideoEnd);
    };
  }, [elRef, onVideoEnd]);

  // Method for seeking video
  const seekVideo = useCallback(
    throttle(
      (time: number) => {
        const video = elRef.current;
        if (!video) {
          return;
        }

        logger.debug('seeking video', seek);
        video.currentTime = time;
      },
      seekInterval,
    ),
    [elRef, onPlayFailed, seekInterval],
  );

  // Continuous update effect - runs every time seek changes
  useEffect(() => {
    const video = elRef.current;

    if (!video || seek == null) {
      return;
    }

    // If video is before serverTime - tolerance or after server time + tolerance then sync them up
    const { currentTime } = video;
    const outOfSync = currentTime < seek - seekTolerance || currentTime > seek + seekTolerance;

    if (outOfSync) {
      seekVideo(seek);
    }
  }, [
    elRef,
    seek,
    seekTolerance,
    seekVideo,
  ]);

  useEffect(() => {
    const video = elRef.current;
    if (!video) {
      return () => {};
    }

    video.defaultPlaybackRate = playbackSpeed;
    video.loop = loop;
    return () => {};
  }, [elRef, playbackSpeed, loop]);
};

export default useHandleVideoPlayback;
