import React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import Typography from 'ui/components/atoms/typography';

import { rem } from 'ui/helpers';

export type Props = {
  name: string,
  activeColor: boolean,
  className?: string,
};

interface TitleProps {
  theme: DefaultTheme,
  activeColor: boolean;
}

const Title = styled.div<TitleProps>`
  width: ${rem(610)};
  height: ${rem(181)};
  padding: ${rem(36)};
  border-radius: 20px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 4px solid ${({ theme, activeColor }) => (
    activeColor ? theme.colors.active : theme.colors.inActive
  )};
  background: ${({ theme, activeColor }) => (
    activeColor ? theme.colors.secondaryActive : theme.colors.secondaryInActive
  )};
`;

const CurrentLiveExercise = ({ name, activeColor, className }: Props) => (
  <Title className={className} activeColor={activeColor} theme={useTheme()}>
    <Typography
      color="white"
      variant="double-pica"
    >
      {name}
    </Typography>
  </Title>
);

export default CurrentLiveExercise;
