import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(20)};
  height: ${rem(20)};
`;

function SvgCross({ className = '', color }: IconProps) {
  const theme = useTheme();
  const svgFill = color ? theme.colors[color] : theme.colors.white;
  const oddFill = color ? theme.colors[color] : theme.colors.black;

  return (
    <Svg viewBox="0 0 20 20" className={className}>
      <defs>
        <path
          id="cross_svg__a"
          d="M11.735 10L20 18.265 18.264 20 10 11.735 1.735 20 0 18.265 8.264 10 0 1.735 1.735 0 10 8.265 18.264 0 20 1.735z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="cross_svg__b" fill={svgFill}>
          <use xlinkHref="#cross_svg__a" />
        </mask>
        <use fill={oddFill} xlinkHref="#cross_svg__a" />
        <g fill={svgFill} mask="url(#cross_svg__b)">
          <path d="M-2-2h24v24H-2z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgCross;
