import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blackOpaque};
  height: ${rem(80)};
  width: ${rem(200)};
  border-radius: ${rem(3)};
`;

const Title = styled(Typography)`
  max-width: 1rem;
`;

const Value = styled(Typography)`
  margin-right: 0.2rem;
`;

type Props = {
  title: string,
  value?: number | null,
  profileImageUrl?: string | null,
};

const InClassCounter = ({ title, value }: Props) => (
  <Wrapper>
    <Value
      data-test={`class-counter-${title.split(' ').join('-')}`}
      variant="double-pica"
      {...(value == null ? { color: 'wednesdayGrey' } : null)}
    >
      {value == null ? '-' : value}
    </Value>
    <Title variant="minion" weight="bold" color="beckersKnop">{title}</Title>
  </Wrapper>
);

export default InClassCounter;
