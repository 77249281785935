import React from 'react';
import {
  Image,
  LessonDurationRange,
  LessonStatus,
} from 'app/on-tv/types/graphql';
import { RouteConfig } from 'app/on-tv/routes';
import { url, AllRoutes } from 'utils/use-routes';

import { trainerNames, Trainers } from 'ui/components/utils/trainer-names';
import StudioCard from 'ui/components/molecules/studio-card';
import ClassCard from 'ui/components/molecules/class-card';
import Typography from 'ui/components/atoms/typography';

type LessonsToRailNodes = {
  node: {
    id: number,
    permissionsWithReasons?: {
      start: {
        value: boolean,
      }
    },
    mainImage?: {
      url: string,
    } | null,
    durationRange: LessonDurationRange,
    trainers: Trainers,
    name: string,
    workouts?: {
      totalCount: number,
    },
    equipmentLevel: Array<{ id: string, equipment: { value: string } }>,
    favouritedByUser?: boolean,
    status: LessonStatus,
  },
  scheduleId?: number,
};

export const lessonsToRailNodes = (route?: RouteConfig, showCompleted: boolean = true) => (
  ({ node, scheduleId }: LessonsToRailNodes, i: number) => (
    <ClassCard
      key={i}
      size="large"
      backgroundImage={node.mainImage?.url}
      duration={node.durationRange}
      trainer={trainerNames(node.trainers)}
      name={node.name}
      to={url({
        route,
        params: { id: node.id },
        ...(scheduleId ? { query: { scheduleId } } : {}),
      })}
      locked={node.permissionsWithReasons ? !node.permissionsWithReasons?.start.value : true}
      completed={(showCompleted && !!node.workouts?.totalCount) || false}
      kettlebells={!!node.equipmentLevel.find((equ) => equ.equipment.value === 'KETTLEBELL')}
      dumbbells={!!node.equipmentLevel.find((equ) => equ.equipment.value === 'DUMBBELLS')}
      favourited={node.favouritedByUser}
      inTesting={node.status === LessonStatus.TESTING}
    />
  )
);

type CollectionToStudioRailNodes = {
  node: {
    id: string,
    name: string,
    slug: string,
    image?: Pick<Image, 'url' | 'hexColour'> | null,
  },
};

export const collectionToStudioRailNodes = (route?: RouteConfig) => (
  ({ node }: CollectionToStudioRailNodes, i: number) => (
    <StudioCard
      key={i}
      size="studio"
      name={node.name}
      to={url({ route, params: { slug: node.slug } })}
      backgroundImage={node.image?.url}
      backgroundColor={node.image?.hexColour}
    />
  )
);

export const getHeroCardContent = (
  routes: Partial<AllRoutes>,
  promoteUpgrade: boolean,
) => (
  [
    {
      title: 'FIND YOUR NEXT CLASS',
      description: <Typography>What do you feel like doing now?</Typography>,
      to: url({ route: routes.FILTER }),
      backgroundImage: {
        url: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/12/04144727/Find-the-next.jpg?size=1680x380',
      },
    },
    {
      title: 'Meet all the trainers',
      description: <Typography>World-class personal trainers at home</Typography>,
      to: url({ route: routes.TRAINERS }),
      backgroundImage: {
        url:
          'https://images-bucket.prod.fiit-static.net/app/uploads/2020/12/04144724/Meet-the-trainer.jpg?size=1680x380',
      },
    },
    ...(promoteUpgrade ? [{
      title: 'Start your free trial',
      description: <Typography>Choose a membership. Cancel anytime.</Typography>,
      to: url({ route: routes.PREMIUM_UPSELL }),
      backgroundImage: {
        url: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/12/11160217/premium.jpg?size=1680x380',
      },
    }] : []),
    {
      title: 'Start a plan ',
      description: <Typography>Easy to start. Hard to stop. Our training plans get results. Faster.</Typography>,
      to: url({ route: routes.TRAINING_PLANS }),
      backgroundImage: {
        url: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/12/04144720/Start-a-plan.jpg?size=1680x380',
      },
    },
  ]
);
