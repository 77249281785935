import React, { useRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useSpatialTarget, UseSpatialTargetArgs } from 'utils/spatial-nav';

type Props = LinkProps & Omit<UseSpatialTargetArgs, 'elRef'> & { className?: string };

const FocusableLink = ({ id, autofocus, ...linkProps }: Props) => {
  const elRef = useRef<HTMLAnchorElement | null>(null);
  useSpatialTarget({
    elRef,
    id,
    autofocus,
  });

  return <Link innerRef={elRef} {...linkProps} />;
};

export default FocusableLink;
