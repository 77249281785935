import React, { ReactNode, useState, useRef, useCallback, createContext, KeyboardEvent } from 'react';
import { createContext as createSelectableContext } from 'use-context-selector';
import { climbLeft, climbRight, climbDown, climbUp, drillForTarget } from 'utils/spatial-nav/tree-traversal';
import {
  SpatialState,
  SpatialId,
  SpatialStateTree,
  SpatialStateNode,
  SpatialStateNodeInfo,
} from 'utils/spatial-nav/types';
import { useSpatialParent, UseSpatialParentArgs } from 'utils/spatial-nav/hooks';
import useLogger from 'app/hooks/use-logger';
import { preventScrollSupported } from 'utils/spatial-nav/helpers';

const polyfillPreventScroll = !preventScrollSupported();

// Two contexts - spatial nav which never changes the top level object so never causes re-rendering
// Used to hold the main "spatial state"
export const SpatialNavContext = createContext<SpatialState>({ tree: {}, setFocus: () => null });

// Focused context holds the list of ids of currently focused objects - selectable to allow targetted re-renders
export const FocusedContext = createSelectableContext<SpatialId[]>([]);

enum NavDirection {
  UP = 'UP',
  RIGHT = 'RIGHT',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
}

// TODO - move to params on the provider + store in state
const keyMap: Record<number, NavDirection> = {
  38: NavDirection.UP,
  39: NavDirection.RIGHT,
  40: NavDirection.DOWN,
  37: NavDirection.LEFT,
};

export const getKeyDirection = (event: KeyboardEvent) => keyMap[event.keyCode];

// Given the spatial tree, and a target spatialId, return it and all it's parents
const getFocusedStack = (
  tree: SpatialStateTree,
  spatialId: SpatialId,
  acc: SpatialStateNodeInfo[] = [],
): SpatialStateNodeInfo[] => {
  const spatialInfo = tree[spatialId]?.info;

  if (!spatialInfo) {
    return acc;
  }

  acc.push(spatialInfo);

  if (spatialInfo.parentId) {
    return getFocusedStack(tree, spatialInfo.parentId, acc);
  }

  return acc;
};

const getNextFocus = (direction: NavDirection, tree: SpatialStateTree, currentNode: SpatialStateNode) => {
  if (direction === NavDirection.LEFT) {
    return climbLeft(tree, currentNode)?.info?.id;
  }

  if (direction === NavDirection.RIGHT) {
    return climbRight(tree, currentNode)?.info?.id;
  }

  if (direction === NavDirection.UP) {
    return climbUp(tree, currentNode)?.info?.id;
  }

  if (direction === NavDirection.DOWN) {
    return climbDown(tree, currentNode)?.info?.id;
  }

  return null;
};

const getFocusableTarget = (tree: SpatialStateTree, targetId: SpatialId) => {
  const immediateTarget = tree[targetId];

  if (immediateTarget?.info?.el) {
    return immediateTarget.info.id;
  }

  const firstFocusableChild = drillForTarget(tree, immediateTarget, climbRight);
  if (firstFocusableChild?.info?.id) {
    return firstFocusableChild.info.id;
  }

  return targetId;
};

const elementIsFullyInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.bottom <= window.innerHeight &&
    rect.left >= 0 &&
    rect.right <= window.innerWidth
  );
};

const focusElement = (
  el: HTMLElement,
  logIncorrectFocus: (shouldLog: boolean) => void,
) => {
  if (elementIsFullyInViewport(el)) {
    el.focus({ preventScroll: true });
    logIncorrectFocus(document.activeElement !== el);
    return;
  }

  // if the element isn't visible then scroll it smoothly into view, this ensures that custom scrolling
  // doesn't need to be added to every page.
  el.scrollIntoView({ block: 'nearest', behavior: 'smooth' });

  // some platforms don't support focus options and in that case we need to wait until the above scrolling is
  // complete before calling focus otherwise the page will jump. 250ms is how long the scroll takes.
  if (polyfillPreventScroll) {
    setTimeout(() => {
      el.focus();
      logIncorrectFocus(document.activeElement !== el);
    }, 250);
    return;
  }

  el.focus({ preventScroll: true });
  logIncorrectFocus(document.activeElement !== el);
};

type SpatialNavProviderProps = { children: ReactNode };
export const SpatialNavProvider = ({ children }: SpatialNavProviderProps) => {
  const [focused, setFocused] = useState<SpatialId[]>([]);
  const logger = useLogger('spatial-nav');

  const logIncorrectBrowserFocus = useCallback((shouldLog: boolean) => {
    if (shouldLog) {
      logger.warn('Browser focus hasn\'t changed, browser focused on:', document.activeElement);
    }
  }, [logger]);

  const { current: spatialState } = useRef<SpatialState>({
    tree: {},
    // I think this needs some work
    setFocus: (targetId: SpatialId) => {
      const focusableTargetId = getFocusableTarget(spatialState.tree, targetId);

      const focusedStack = getFocusedStack(spatialState.tree, focusableTargetId);
      setFocused(focusedStack.map(({ id }) => id));

      const focusableTargetInfo = spatialState.tree[focusableTargetId]?.info;

      if (!focusableTargetInfo) {
        logger.warn('No info found for focus target id', focusableTargetId);
        return;
      }

      if (focusableTargetInfo.el) {
        logger.debug(`Focus moved to ${focusableTargetId}`);

        focusElement(focusableTargetInfo.el, logIncorrectBrowserFocus);
      } else {
        logger.warn(`Focus moved to un-focusable dom node ${focusableTargetId}`);
      }

      // Set newly focused el
      spatialState.focused = focusableTargetId;

      focusedStack.forEach((value, index, array) => {
        const prev = array[index - 1];
        if (!prev) {
          return;
        }

        // If we are not tracking the previous focus for this parent then skip it
        if (value.forgetFocus) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        value.lastFocusedChildIndex = prev.index;
      });
    },
  });

  // TODO hide behind debug flag
  const buildDebugTree = (spatialId: string): any => ({
    ...spatialState.tree[spatialId].info,
    children: spatialState.tree[spatialId].children.map(buildDebugTree),
  });
  (global as any).__debug_spatial_state = spatialState;
  (global as any).__debug_tree = (spatialId: string = 'root') => buildDebugTree(spatialId);
  (global as any).__debug_current_focus = () => (
    setInterval(() => logger.debug(document.querySelectorAll(':focus')), 1000)
  );

  const onKeyDown = useCallback((event) => {
    // This is keyboard tab - allows focus to be out of sync with focus state - blocked for now
    if (event.keyCode === 9) {
      // TODO - a better solution is to react to the newly focused item and accept that as state - not a use case yet
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    const direction = keyMap[event.keyCode];

    // Not a navigation key press, we don't care;
    if (!direction) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    if (!spatialState.focused) {
      logger.warn('Navigation key press detected but no currently focused element');
      return;
    }

    const currentNode = spatialState.tree[spatialState.focused];

    if (!currentNode) {
      logger.warn('Navigation key press detected but current focused node not found in tree');
      return;
    }

    const nextFocus = getNextFocus(direction, spatialState.tree, currentNode);

    if (nextFocus) {
      spatialState.setFocus(nextFocus);
    }
  }, [spatialState, logger]);

  // Prevent clicks from focusing over els TODO - remove this
  // This blocks focus being changed through other methods e.g. clicking - something that we need to support
  const onFocus = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyDown={onKeyDown} onMouseDown={onFocus}>
      <SpatialNavContext.Provider value={spatialState}>
        <FocusedContext.Provider value={focused}>
          { children }
        </FocusedContext.Provider>
      </SpatialNavContext.Provider>
    </div>
  );
};

export const SpatialParentContext = createContext<string>('root');

type SpatialNavParentProps = {
  children: ReactNode,
} & UseSpatialParentArgs;

export const SpatialNavParent = ({
  children,
  id,
  columns,
  layout,
  onFocus,
  isolated,
  forgetFocus,
}: SpatialNavParentProps) => {
  const spatialId = useSpatialParent({
    id,
    columns,
    layout,
    onFocus,
    isolated,
    forgetFocus,
  });

  return (
    <SpatialParentContext.Provider value={spatialId}>
      { children }
    </SpatialParentContext.Provider>
  );
};
