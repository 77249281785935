import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgBody = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} viewBox="0 0 24 24">
      <defs>
        <path
          id="body-icon_svg__a"
          d="M12 0c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zm11 10h-7v14h-3v-7h-2v7H8V10H1V7h22v3z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="body-icon_svg__b" fill={fill}>
          <use xlinkHref="#body-icon_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#body-icon_svg__a" />
        <g fill={fill} mask="url(#body-icon_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgBody;
