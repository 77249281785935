export enum StoredWorkoutTypes {
  SET_WORKOUT = 'WORKOUT/SET_WORKOUT',
  GET_WORKOUT = 'WORKOUT/GET_WORKOUT',
  REMOVE_WORKOUT = 'WORKOUT/REMOVE_WORKOUT',
}

type StoredWorkoutArgs = {
  workoutId?: number,
  currentTime?: number,
  duration?: number,
  timeOfWorkout: Date,
  lessonId: number,
  lessonMediaId?: string,
  lessonInstanceId: string,
  partyId?: string,
} & { userId: number };

export const setStoredWorkout = (payload: StoredWorkoutArgs) => ({
  type: StoredWorkoutTypes.SET_WORKOUT,
  payload,
});

export type SetStoredWorkout = typeof setStoredWorkout;

export const removeStoredWorkout = (payload: number) => ({
  type: StoredWorkoutTypes.REMOVE_WORKOUT,
  payload,
});

export type RemoveStoredWorkout = typeof removeStoredWorkout;

export default {
  setStoredWorkout,
  removeStoredWorkout,
};
