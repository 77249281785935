import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgLive = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} viewBox="0 0 40 40">
      <g clipPath="url(#Live_svg__a)">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M27.917 29.833h-17.5c-.941 0-1.637-.93-1.282-1.784 1.649-3.952 5.52-6.216 10.031-6.216 4.512 0 8.384 2.264 10.033 6.216.355.855-.34 1.784-1.282 1.784Zm-14.195-16c0-2.941 2.443-5.333 5.444-5.333 3.003 0 5.445 2.392 5.445 5.333 0 2.941-2.442 5.333-5.445 5.333-3 0-5.444-2.392-5.444-5.333Zm18.719 15.515c-.99-4.479-4.085-7.784-8.158-9.284 2.158-1.703 3.417-4.456 2.954-7.471-.536-3.497-3.506-6.296-7.09-6.704C15.196 5.325 11 9.099 11 13.833c0 2.52 1.192 4.765 3.052 6.23-4.075 1.5-7.17 4.806-8.16 9.285-.36 1.628.98 3.152 2.68 3.152H29.76c1.701 0 3.04-1.524 2.68-3.152Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="Live_svg__a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default SvgLive;
