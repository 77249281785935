import { useEffect } from 'react';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { useDispatch, useAppState } from 'state';

import { setStoredInstallation } from 'actions/installation';

import { CreateInstallation as CREATE_INSTALLATION_MUTATION } from 'graphql/mutations/create-installation.gql';

import {
  InstallationPlatform,
  CreateInstallationMutation,
  CreateInstallationMutationVariables,
} from 'app/on-tv/types/graphql';

export type UseInstallationArgs = {
  client: ApolloClient<NormalizedCacheObject>,
  platform: string,
};

const getInstallationPlatform = (platform: string): InstallationPlatform | null => {
  switch (platform.toLowerCase()) {
    case 'sky':
      return InstallationPlatform.SKY;
    case 'amazon':
      return InstallationPlatform.AMAZON;
    case 'comcast':
      return InstallationPlatform.COMCAST;
    case 'virgin':
      return InstallationPlatform.VIRGIN;
    case 'rogers':
      return InstallationPlatform.ROGERS;
    case 'shaw':
      return InstallationPlatform.SHAW;
    case 'videotron':
      return InstallationPlatform.VIDEOTRON;
    case 'web':
      return InstallationPlatform.WEB;
    case 'samsung':
      return InstallationPlatform.SAMSUNG;
    case 'android':
      return InstallationPlatform.ANDROID;
    case 'lg':
      return InstallationPlatform.LG;
    case 'chromecast':
      return InstallationPlatform.CHROMECAST;
    default:
      return null;
  }
};

const useInstallation = ({
  client,
  platform,
}: UseInstallationArgs) => {
  const dispatch = useDispatch();

  const installationId = useAppState((state) => state?.installation?.installationId);

  const installationPlatform = getInstallationPlatform(platform);

  useEffect(() => {
    if (installationPlatform && !installationId) {
      client.mutate<CreateInstallationMutation, CreateInstallationMutationVariables>({
        mutation: CREATE_INSTALLATION_MUTATION,
        variables: {
          platform: installationPlatform,
          userAgent: navigator.userAgent,
        },
      }).then(({ data }) => {
        dispatch(setStoredInstallation({
          installationId: data?.createInstallation.installation.id,
          platform: installationPlatform,
          userAgent: navigator.userAgent,
        }));
      });
    }
  }, [installationId, client, installationPlatform, dispatch]);
};

export default useInstallation;
