import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { rem } from 'ui/helpers';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';

export type Props = {
  image: string,
  children: ReactNode,
  footer: ReactNode,
  headerText?: string,
};

const Wrapper = styled.div<{ image: string }>`
  display: flex;
  flex-direction: column;
  padding: ${rem(120)} ${rem(150)} ${rem(50)};
  height: 100vh;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${({ image }) => `${image}?size=1920x1080`});
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const LogoHolder = styled.div`
  margin-bottom: ${rem(80)};
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Footer = styled.div`
  margin-top: ${rem(50)};
  flex-grow: 0;
  flex-shrink: 0;
`;

const LoginPageLayout = ({ image, children, footer, headerText = 'log in' }: Props) => (
  <Wrapper image={image}>
    <LogoHolder><Logo height="60px" /></LogoHolder>
    <Typography variant="paragon">{headerText}</Typography>
    <Content>
      { children }
    </Content>
    <Footer>{ footer }</Footer>
  </Wrapper>
);

export default LoginPageLayout;
