import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 0.9rem;
  height: 0.9rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgScan = ({ className = '' }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg className={className} viewBox="0 0 24 24">
      <defs>
        <path id="a" d="M12 9a4.8 4.8 0 1 0 0 9.6A4.8 4.8 0 0 0 12 9zM8.4 4.8h7.2V2.4H8.4v2.4zM18 0H6v4.8H0V20h4.8v-2.4H2.4V7.2h19.2v10.4h-2.4V20H24V4.8h-1.2V2.4h-2.4v2.4H18V0zm-6 11.4c1.324 0 2.4 1.076 2.4 2.4 0 1.324-1.076 2.4-2.4 2.4a2.402 2.402 0 0 1-2.4-2.4c0-1.324 1.076-2.4 2.4-2.4z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <mask id="b" fill={theme.colors.white}>
          <use xlinkHref="#a" />
        </mask>
        <use fill={theme.colors.black} xlinkHref="#a" />
        <g fill={theme.colors.black} mask="url(#b)">
          <path d="M0-2h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgScan;
