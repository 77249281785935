import React from 'react';
import { IconProps } from 'ui/components/atoms/icons/types';

const SvgProfile = ({ className = '' }: IconProps) => (
  <svg className={className} viewBox="0 0 16 17">
    <defs>
      <path d="M13.2500383,14.3999816 L2.75053707,14.3999816 C2.1854243,14.3999816 1.76812282,13.8423816 1.98126506,13.3295816 C2.9702124,10.9583816 5.29354452,9.59998156 7.99987936,9.59998156 C10.7070308,9.59998156 13.0303629,10.9583816 14.0193103,13.3295816 C14.2324525,13.8423816 13.815151,14.3999816 13.2500383,14.3999816 M4.73333157,4.79998156 C4.73333157,3.03518156 6.19919489,1.59998156 7.99987936,1.59998156 C9.80138046,1.59998156 11.2664271,3.03518156 11.2664271,4.79998156 C11.2664271,6.56478156 9.80138046,7.99998156 7.99987936,7.99998156 C6.19919489,7.99998156 4.73333157,6.56478156 4.73333157,4.79998156 M15.9645395,14.1087816 C15.3708444,11.4215816 13.513812,9.43838156 11.0696176,8.53838156 C12.3648038,7.51678156 13.120193,5.86478156 12.8425364,4.05598156 C12.5207815,1.95758156 10.7388797,0.278381564 8.58785796,0.0335815637 C5.61856602,-0.304818436 3.10005768,1.95918156 3.10005768,4.79998156 C3.10005768,6.31198156 3.81543164,7.65918156 4.93095771,8.53838156 C2.48594669,9.43838156 0.629730916,11.4215816 0.0352192197,14.1087816 C-0.180372934,15.0855816 0.623197821,15.9999816 1.64317737,15.9999816 L14.3565813,15.9999816 C15.3773775,15.9999816 16.1809483,15.0855816 15.9645395,14.1087816" id="path-1" />
    </defs>
    <g id="03-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03-Elements-/-Social-/-Avatar-/-02-Small-With-icon-dark" transform="translate(-17.000000, -17.000000)">
        <g id="Profile-with-icon">
          <g id="02-Icons-/-Icon-" transform="translate(17.000000, 17.000000)">
            <g id="02-Icons-/-Source-Icons-/-Profile">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <use id="Profile" fill="#000000" fillRule="evenodd" xlinkHref="#path-1" />
              <g id="01-Colours-/-Solids-/-02.-Secondary-/-#5-Black-30%-#B2B2B2" mask="url(#mask-2)" fill="#B2B2B2" fillRule="evenodd">
                <rect id="Background-colour" x="0" y="0" width="16" height="16" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgProfile;
