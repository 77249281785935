import createReducer from 'utils/create-reducer';

import {
  LessonInstanceActionTypes,
  Init,
  JoinLobby,
  LobbyUpdate,
  StartWorkoutCountdown,
  StartWorkoutSession,
  LeaderboardUpdate,
  EndWorkoutSession,
  SetConnectedStatus,
  SetLessonInstanceStatus,
} from 'actions/lesson-instance';
import { TimeChange, VideoActionTypes } from 'actions/video';

import { ActionCreator, Handler } from 'actions';

type LessonInstanceHandler<Action extends ActionCreator> = Handler<LessonInstanceState, Action>;
type VideoHandler<Action extends ActionCreator> = Handler<LessonInstanceState, Action>;

export enum LessonInstanceStatus {
  UNKNOWN = 'UNKNOWN',
  PRE_LOBBY = 'PRE_LOBBY',
  LOBBY = 'LOBBY',
  COUNTDOWN = 'COUNTDOWN',
  IN_SESSION = 'IN_SESSION',
  POST_SESSION = 'POST_SESSION',
}

export type LessonInstanceUser = {
  id: string,
  name: string,
  image?: string
};

export type LobbyEntry = {
  user: LessonInstanceUser,
};

export type LeaderboardEntry = LobbyEntry & {
  position: number | null,
  score: number,
};

type Lobby = {
  entries: LobbyEntry[]
};

type Leaderboard = {
  updatedAt: Date,
  totalUserCount: number,
  entries: LeaderboardEntry[],
  videoSeconds: number,
};

export type LessonInstanceDetails = {
  id: string,
  status: LessonInstanceStatus,
  connected: boolean,
  lobby: Lobby,
  leaderboard: Leaderboard,
  localVideoTime: number,
};

export type LessonInstanceState = {
  [lessonInstanceId: string]: LessonInstanceDetails,
};

const emptyLessonInstance = ({ id }: { id: string }): LessonInstanceDetails => ({
  id,
  status: LessonInstanceStatus.UNKNOWN,
  connected: false,
  lobby: {
    entries: [],
  },
  leaderboard: {
    updatedAt: new Date(),
    totalUserCount: 0,
    entries: [],
    videoSeconds: 0,
  },
  localVideoTime: 0,
});

const mungeLobbyIntoLeaderBoard = (lobby: Lobby): Leaderboard => ({
  updatedAt: new Date(),
  totalUserCount: lobby.entries.length,
  entries: lobby.entries.map((entry) => ({
    ...entry,
    position: null,
    score: 0,
  })),
  videoSeconds: 0,
});

const initHandler: LessonInstanceHandler<Init> = (state, { lessonInstanceId: id }) => ({
  ...state,
  [id]: state[id] || emptyLessonInstance({ id }),
});

const joinLobbyHandler: LessonInstanceHandler<JoinLobby> = (state, { lessonInstanceId: id }) => ({
  ...state,
  [id]: {
    ...state[id],
    status: LessonInstanceStatus.LOBBY,
  },
});

const lobbyUpdateHandler: LessonInstanceHandler<LobbyUpdate> = (state, { lessonInstanceId: id, entries }) => ({
  ...state,
  [id]: {
    ...state[id],
    lobby: {
      entries,
    },
  },
});

const startWorkoutCountdownHandler: LessonInstanceHandler<StartWorkoutCountdown> = (
  state,
  { lessonInstanceId: id },
) => ({
  ...state,
  [id]: {
    ...state[id],
    status: LessonInstanceStatus.COUNTDOWN,
  },
});

const startWorkoutSessionHandler: LessonInstanceHandler<StartWorkoutSession> = (state, { lessonInstanceId: id }) => ({
  ...state,
  [id]: {
    ...state[id],
    status: LessonInstanceStatus.IN_SESSION,
    leaderboard: mungeLobbyIntoLeaderBoard(state[id].lobby),
  },
});

const leaderboardUpdateHandler: LessonInstanceHandler<LeaderboardUpdate> = (
  state,
  { lessonInstanceId: id, ...leaderboard },
) => ({
  ...state,
  [id]: {
    ...state[id],
    // If we've missed the start-workout signal from the pre-lobby/lobby,
    // start when we receive a leaderboard update
    status: [
      LessonInstanceStatus.PRE_LOBBY,
      LessonInstanceStatus.LOBBY,
    ].includes(state[id].status) ? LessonInstanceStatus.IN_SESSION : state[id].status,
    leaderboard: {
      ...leaderboard,
      entries: leaderboard.entries.length ? leaderboard.entries : state[id].leaderboard.entries,
    },
  },
});

const endWorkoutSessionHandler: LessonInstanceHandler<EndWorkoutSession> = (state, { lessonInstanceId: id }) => ({
  ...state,
  [id]: {
    ...state[id],
    status: LessonInstanceStatus.POST_SESSION,
  },
});

const setConnectedStatusHandler: LessonInstanceHandler<SetConnectedStatus> = (state, {
  lessonInstanceId: id,
  connected,
}) => ({
  ...state,
  [id]: {
    ...state[id],
    connected,
  },
});

const setLessonInstanceStatusHandler: LessonInstanceHandler<SetLessonInstanceStatus> = (state, {
  lessonInstanceId: id,
  status,
}) => ({
  ...state,
  [id]: {
    ...state[id],
    status,
  },
});

const timeChangeHandler: VideoHandler<TimeChange> = (state, { instance, currentTime }) => ({
  ...state,
  [instance]: {
    ...state[instance],
    localVideoTime: currentTime,
  },
});

const handlers = {
  [LessonInstanceActionTypes.INIT]: initHandler,
  [LessonInstanceActionTypes.JOIN_LOBBY]: joinLobbyHandler,
  [LessonInstanceActionTypes.LOBBY_UPDATE]: lobbyUpdateHandler,
  [LessonInstanceActionTypes.START_WORKOUT_COUNTDOWN]: startWorkoutCountdownHandler,
  [LessonInstanceActionTypes.START_WORKOUT_SESSION]: startWorkoutSessionHandler,
  [LessonInstanceActionTypes.LEADERBOARD_UPDATE]: leaderboardUpdateHandler,
  [LessonInstanceActionTypes.END_WORKOUT_SESSION]: endWorkoutSessionHandler,
  [LessonInstanceActionTypes.SET_CONNECTED_STATUS]: setConnectedStatusHandler,
  [LessonInstanceActionTypes.SET_LESSON_INSTANCE_STATUS]: setLessonInstanceStatusHandler,
  [VideoActionTypes.TIME_CHANGED]: timeChangeHandler,
};

export const initialLessonInstanceState = {} as LessonInstanceState;
export const lessonInstanceReducer = createReducer<LessonInstanceState>(handlers);
