import React, { Component, useEffect, useRef, forwardRef } from 'react';

import useLogger from 'app/hooks/use-logger';
import DashJsPlayer from 'ui/components/atoms/video-player/dashjs-player';
import ShakaPlayer from 'ui/components/atoms/video-player/shaka-player';
import useVideoPlayerSupport from 'utils/use-video-player-support';

type PlaybackError = (error: Error) => void;

type ErrorProps = {
  onRenderError?: (error: Error | null | undefined, info: any) => void,
  onUnsupportedBrowser?: PlaybackError,
};

type ErrorState = {
  readonly error: Error | null | undefined;
};

type VideoProps = {
  src: {
    dashUrl?: string | null,
    hlsUrl?: string | null,
  },
  playing?: boolean,
  controls?: boolean,
  muted?: boolean,

  // A seek value in seconds can be provided to skip to a time in the video
  seek?: number,
  // Only perform a seek action if the seek value provided falls outside this tolerance in seconds from the currentTime
  seekTolerance?: number,
  // The time in milliseconds between successive seek actions
  seekInterval?: number,
  onVideoEnd?: EventListener,
  onTimeChange?: (currentTime: number) => void,
  // The time in milliseconds between onTimeChange events firing
  onTimeChangeInterval?: number,
  onVideoLoadError?: PlaybackError,
  // playing has been set to true - but we could not play the video - handle by showing a pause state
  onPlayFailed?: PlaybackError,
  // fired when video transitions between playing and buffereing state
  onBuffering?: (isBuffering: boolean) => void,
  monitor?: boolean,
  monitorMeta?: {
    playerName?: string,
    playerType?: string,
    videoId?: string,
    videoTitle?: string,
    videoDuration?: number,
    userId?: number | null,
  },
  className?: string,
  playbackSpeed?: number,
  loop?: boolean,
  isFullHeight?: boolean,
};

export type VideoPlayerProps = VideoProps & ErrorProps;

// TODO - should probably fallback to an error style e.g. white noise video (same as browser unsupported)
class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  componentDidCatch(error: Error | null, info: object) {
    const { onRenderError } = this.props;
    if (onRenderError) {
      onRenderError(error, info);
    }
  }

  render() {
    return this.props.children;
  }
}

const VideoWithErrorBoundary = (props: VideoPlayerProps, ref: any) => {
  const logger = useLogger('video-player:with-error-boundary');
  const { isShakaSupported, shouldUseDashFallback } = useVideoPlayerSupport();

  const { onRenderError, ...rest } = props;

  const innerRef = useRef<HTMLVideoElement | null>(null);
  const videoRef = ref || innerRef;

  // Call error handler for unsupported browsers
  useEffect(() => {
    if (shouldUseDashFallback) {
      logger.warn('Browser not supported for shaka, trying dash.js instead');
    }
  }, [logger, shouldUseDashFallback]);

  return (
    <ErrorBoundary onRenderError={onRenderError}>
      {isShakaSupported ? (
        <ShakaPlayer videoRef={videoRef} {...rest} />
      ) : (
        <DashJsPlayer videoRef={videoRef} {...rest} />
      )}
    </ErrorBoundary>
  );
};

export default forwardRef(VideoWithErrorBoundary);
