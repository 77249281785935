import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import { ThemeColors } from 'ui/theme';

export type Props = {
  title?: string,
  color?: ThemeColors,
  className?: string,
};

const Wrapper = styled.div<{ color?: ThemeColors }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.s} 0;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)};
  opacity: 0.5;
`;

const Title = styled(Typography)`
  text-align: center;
  padding: 0 ${({ theme }) => theme.spacing.m};
`;

const Line = styled.hr`
  flex: 1;
  border-top: 1px solid currentColor;
`;

const Divider = ({ title, color, className }: Props) => (
  <Wrapper className={className} color={color}>
    <Line />
    { title && (
      <Title
        weight="medium"
        variant="body-copy"
        uppercase
      >
        {title}
      </Title>
    )}
    { title && <Line /> }
  </Wrapper>
);

export default Divider;
