import React from 'react';
import { useTheme } from 'styled-components';
import styles from 'ui/components/molecules/progress-circle/styles.css';

type StylesProps = {
  activeColor?: boolean,
};

const Styles = ({ activeColor }: StylesProps) => {
  const theme = useTheme();
  return (
    <style>
      {styles({ theme, activeColor })}
    </style>
  );
};

export default Styles;
