import React from 'react';
import { useTheme } from 'styled-components';

import normalize from 'ui/components/utils/global-styles/normalize.css';
import reset from 'ui/components/utils/global-styles/reset.css';
import fonts from 'ui/components/utils/global-styles/fonts.css';
import { ThemeColors } from 'ui/theme';

import { bodyCopyString } from 'ui/components/atoms/typography';

type Props = {
  background?: ThemeColors,
  color?: ThemeColors,
};

const GlobalStyle = ({ background = 'black', color = 'white' }: Props) => {
  const theme = useTheme();

  return (
    <style>
      {
        `
          ${normalize}
          ${reset}
          ${fonts}

          html {
            font-family: Gilroy, sans-serif;
            font-size: calc(.85vw + .85vh + .42vmin);
            ${bodyCopyString};
          }

          ::-webkit-scrollbar {
            display: none;
          }

          body {
            min-height: 100vh;
            min-width: 100vw;
            background: ${theme.colors[background]};
            color: ${theme.colors[color]};
            overflow-x: hidden;
          }

          *:focus {
            outline: 0;
          }
        `
      }
    </style>
  );
};

GlobalStyle.fontFamily = 'Gilroy';

export default GlobalStyle;
