import React from 'react';

import styled from 'styled-components';

import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';
import Watch from 'ui/components/atoms/icons/watch';

import { lessonDuration } from 'ui/components/utils/lesson-duration';

import { LessonDurationRange } from 'types/graphql';

export type Props = {
  studio: string,
  duration: LessonDurationRange,
};

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.fordDarkCharcoal};
  width: max-content;
  margin-right: ${rem(20)};
  padding: ${rem(6)} ${rem(20)} ${rem(4)};
`;

const DurationIcon = styled(Watch)`
  height: 0.7rem;
  width: 0.7rem;
  margin-right: 0.2rem;
`;

const StyledText = styled(Typography)`
  margin-top: 0.1rem;
`;

const ClassDetailsLabels = ({ studio, duration }: Props) => {
  const textColor = 'beckersKnop';

  return (
    <Wrapper>
      <Label>
        <StyledText variant="pica" color={textColor}>{studio}</StyledText>
      </Label>
      <Label>
        <DurationIcon color={textColor} />
        <StyledText variant="pica" color={textColor}>{lessonDuration(duration)}</StyledText>
      </Label>
    </Wrapper>
  );
};

export default ClassDetailsLabels;
