import { StoredInstallationTypes } from 'actions/installation';
import createReducer from 'utils/create-reducer';
import storage from 'utils/local-storage-fallback';

export type StoredInstallationState = {
  installationId?: string,
  platform?: string,
  userAgent?: string,
};

type StoreInstallationPayload = StoredInstallationState;

const setStoredInstallation = (_state: StoredInstallationState, payload: StoreInstallationPayload) => {
  storage.setItem(
    'INSTALLATION',
    JSON.stringify(payload),
  );
  return payload;
};

const getStoredInstallation = () : StoredInstallationState => {
  const installationFromStorage = storage.getItem('INSTALLATION');
  if (!installationFromStorage) {
    return {};
  }
  return JSON.parse(installationFromStorage);
};

const handlers = {
  [StoredInstallationTypes.SET_INSTALLATION]: setStoredInstallation,
};

export const initialInstallationState = getStoredInstallation();
export const installationReducer = createReducer(handlers);
