import React, { createContext as createReactContext, useContext, useReducer, Dispatch, ReactNode } from 'react';
import { createContext as createSelectableContext, useContextSelector } from 'use-context-selector';
import useConfig, { Config } from 'config';

import { AuthState, authReducer, initialAuthState } from 'state/auth';
import {
  locallyStoredWorkoutReducer,
  initialLocallyStoredWorkoutState,
  StoredWorkoutState,
} from 'state/locally-stored-workout';
import {
  initialInstallationState,
  installationReducer,
  StoredInstallationState,
} from 'state/installation';
import { LessonInstanceState, lessonInstanceReducer, initialLessonInstanceState } from 'state/lesson-instance';
import { VideoState, videoReducer, initialVideoState } from 'state/video';
import { FlagState, flagReducer, initialFlagState } from 'state/flag';
import { ScreenSaverState, screenSaverReducer, initialScreenSaverState } from 'state/screen-saver';
import { ChromecastState, chromecastReducer, initialChromecastState } from 'state/chromecast';

import { AllActions } from 'actions';

import { createLogger } from 'utils/logging';
import { networkStatusReducer, initialNetworkStatusState, NetworkState } from 'state/network-status';
import { platformPartnerReducer, initialPlatformPartnerState, PlatformPartnerState } from 'state/platform-partner';

const logger = createLogger({ label: 'app-state' });

type State = {
  auth: AuthState,
  lessonInstance: LessonInstanceState,
  video: VideoState,
  locallyStoredWorkout: StoredWorkoutState | undefined,
  installation?: StoredInstallationState,
  networkStatus: NetworkState,
  flag: FlagState,
  screenSaver: ScreenSaverState,
  chromecast: ChromecastState,
  platformPartner: PlatformPartnerState,
};

const devTools: any = (window as any)?.__REDUX_DEVTOOLS_EXTENSION__?.connect();
if (devTools) {
  logger.debug('Initializing redux dev tools');
  devTools.init();
}

const combinedReducer = ({
  auth,
  lessonInstance,
  video,
  locallyStoredWorkout,
  installation,
  networkStatus,
  flag,
  screenSaver,
  chromecast,
  platformPartner,
}: State, action: AllActions) => {
  const newState = {
    auth: authReducer(auth, action),
    lessonInstance: lessonInstanceReducer(lessonInstance, action),
    video: videoReducer(video, action),
    locallyStoredWorkout: locallyStoredWorkoutReducer(locallyStoredWorkout, action),
    installation: installationReducer(installation, action),
    networkStatus: networkStatusReducer(networkStatus, action),
    flag: flagReducer(flag, action),
    screenSaver: screenSaverReducer(screenSaver, action),
    chromecast: chromecastReducer(chromecast, action),
    platformPartner: platformPartnerReducer(platformPartner, action),
  };

  if (devTools) {
    devTools.send({ ...action }, newState);
  }

  return newState;
};

const initialState = (config: Config | null) => ({
  auth: initialAuthState,
  lessonInstance: initialLessonInstanceState,
  video: initialVideoState,
  locallyStoredWorkout: initialLocallyStoredWorkoutState,
  installation: initialInstallationState,
  networkStatus: initialNetworkStatusState,
  screenSaver: initialScreenSaverState(config),
  flag: initialFlagState(config),
  chromecast: initialChromecastState,
  platformPartner: initialPlatformPartnerState,
});

export const StateContext = createSelectableContext(initialState(null));
type Selector<T> = (state: State) => T;
export function useAppState<T>(selector: Selector<T>): T {
  return useContextSelector(StateContext, selector);
}

const initialDispatch: Dispatch<AllActions> = () => null;
export const DispatchContext = createReactContext(initialDispatch);
export const useDispatch = () => useContext(DispatchContext);

export const StateProvider = ({ children }: { children: ReactNode }) => {
  const { config } = useConfig();
  const [state, dispatch] = useReducer(combinedReducer, initialState(config));

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        { children }
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};
