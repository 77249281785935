import { ScreenSaverActionTypes } from 'actions/screen-saver';
import createReducer from 'utils/create-reducer';
import { Config } from 'config';

export type ScreenSaverState = {
  enabled: boolean,
  canDisplay: boolean,
  idleTime: number,
};

const setEnabled = (state: ScreenSaverState, payload: boolean) => ({
  ...state,
  enabled: payload,
});

const setCanDisplay = (state: ScreenSaverState, payload: boolean) => ({
  ...state,
  canDisplay: payload,
});

const setIdleTime = (state: ScreenSaverState, payload: number) => ({
  ...state,
  idleTime: payload,
});

const handlers = {
  [ScreenSaverActionTypes.SET_ENABLED]: setEnabled,
  [ScreenSaverActionTypes.SET_CAN_DISPLAY]: setCanDisplay,
  [ScreenSaverActionTypes.SET_IDLE_TIME]: setIdleTime,
};

export const initialScreenSaverState = (config: Config | null) => (
  {
    enabled: config?.SCREEN_SAVER_ENABLED_DEFAULT || false,
    canDisplay: true,
    idleTime: config?.SCREEN_IDLE_TIME_SECS || 900,
  }
);

export const screenSaverReducer = createReducer(handlers);
