/* eslint-disable max-len */

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  > p,
  ol,
  li {
    margin-bottom: 1rem;
  }
`;

const PrivacyPolicy = () => (
  <Wrapper data-test="privacy-policy">
    <p>
      Thank you for choosing Fiit and welcome to the services operated by Fiit Limited via our website available at https://fiit.tv (the &quot;Site&quot;) or any other website, device or platform, along with the paid-for classes with the Fiit trainers available on iOS devices (the &quot;Fiit Product&quot;), and any other services that we may provide in relation to the services, such as customer support, social media, community channels and other websites that we may operate from time to time (together, the &quot;Services&quot;).
    </p>
    <p>
      Fiit is an online platform which delivers trackable and interactive workouts, delivered directly to the home by the world&apos;s most inspirational fitness influencers. We are committed to protecting and respecting your privacy.
    </p>
    <p>
      This policy (together with our Terms and Conditions of Service) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand what personal data we collect, how that data is used and the ways it can be shared by us and you.
    </p>
    <p>
      The data controller of your personal data is Fiit Limited of 14 East Bay Lane, The Press Centre, Queen Elizabeth Olympic Park, London, England, E15 2GW. Our Data Protection Officer is Kerry McClelland (privacy@fiit.tv).
    </p>
    <ol>
      <li>Information we Collect From YouWe will collect and process the following data about you:
        <ol>
          <li>
            Information you give usThis is information about you that you give us by filling in forms on the site, using the Fiit Product or by corresponding with us by phone, e-mail or otherwise. It includes information you provide when you register to use the site, subscribe to the Services, place an order for a subscription via the Site or the Fiit Product, participate in discussion boards or other social media functions, and when you report a problem with the Services.
            The information you give us may include your name, address, e-mail address and phone number, personal description, height, weight, gender and photograph.
            Where you purchase subscriptions via the Site, we may collect certain payment information from you on behalf of our payment service providers, but this information is temporarily cached in a way that is unreadable by us. We do not collect or retain credit card information. Our payment service providers will provide us with information about your purchases so that we know if a purchase has been successful. Where you purchase subscriptions via Apple In-App Purchase, we do not collect or store any payment information from you.
          </li>
          <li>Information we collect about youWhen you use or access our Services we will automatically collect the following information:
            <ol>
              <li>technical information, including the type of device(s) you are using to access our Services, identifiers such as IP address, device identifiers, a Fiit-specific user ID, the country or region that you are accessing our Services from, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</li>
              <li>fitness information collected through your Fiit device and/or other supported fitness devices where relevant to estimate metrics such as your heart rate, number of reps per exercise and calories burned. The information collected through your Fiit device and/or other supported fitness devices where relevant is transferred from your device to our servers;</li>
              <li>information about how you use and interact with the Services, such as which classes you are taking, your fitness metrics, session time and duration and subscriptions purchased;</li>
              <li>information about your visit to our Site, including the full Uniform Resource Locators (URL), clickstream to, through and from the site (including date and time), product information you viewed or searched for’, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page;</li>
              <li>information about your usage of the Fiit Product, including iOS device vendor id, app launches, screen views, screen load and response times, screen interaction information (such as touches and other gestures), length of visits to certain screens, data load times and errors.</li>
              <li>when using an Apple fitness device, information collected through the Apple HealthKit APIs, Google Fit APIs and Motion &amp; Fitness APIs about your heart rate and workouts when using the Services where you have expressly agreed to provide this data.</li>
            </ol>
            We may from time to time ask you for your consent to collect other information from you or your device. For instance, we may invite you to allow us to access your contacts to enable us to invite them to use the Services with you. If we do this or something similar, we will tell you what information we would like to collect, why we need it and what we’ll use it for.
          </li>
          <li>Information we receive from other sources.This includes information we receive about you if you use any of the other services we provide. We are working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies). We will notify you when we receive information about you from them and the purposes for which we intend to use that information. This also includes information that we obtain when you access the Site and/or any of the Services through a social media or other account operated by a third party, such as Facebook, Instagram or Google. Any information about you that we receive from such third parties will be governed by their privacy policy as well as ours.</li>
        </ol>
      </li>
      <li>We use information held about you in the following ways:
        <ol>
          <li>Information you give to us. We will use this information:
            <ol>
              <li>to carry out our obligations arising from any contracts entered into between you and us, for example, the contract which governs how we provide the Services to you;</li>
              <li>for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
              <li>as part of our efforts to keep the Services safe and secure;</li>
              <li>to provide you with information about other goods or services that we may offer that are similar to those that you have already purchased or enquired about;</li>
              <li>to provide you with information about our goods or services that we feel may interest you. If these goods and services are not similar to those that you have already purchased or enquired about, we will contact you by electronic means only if you have consented to this. We may also permit third parties to contact you about any of their goods or services that they feel may interest you but only where you have consented in advance to our passing your details to such third parties for these purposes. If, having opted in to receive marketing information from us or any third parties, you change your mind, you please follow the unsubscribe instructions in the relevant marketing communication or contact us at hello@fiit.tv;</li>
              <li>to administer competitions, which may be run on any of our Services. We will use the personal information that you provide in relation to that competition for the purposes of running the competition. We may also publish the names and entries of competition entrants;</li>
              <li>to notify you about changes to the Services;</li>
              <li>to improve the Services and to ensure that content from the site is presented in the most effective manner for you and for your device(s).</li>
            </ol>
          </li>
          <li>Information we collect about you. We will use this information:
            <ol>
              <li>to administer the Services and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
              <li>to improve the Services to ensure that content is presented in the most effective manner for you and for your device(s);</li>
              <li>to allow you to participate in interactive features of our Services, when you choose to do so;</li>
              <li>as part of our efforts to keep the Services safe and secure;</li>
              <li>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you (including direct marketing communications and advertising within third party websites, apps and Internet-connect devices). We do not use data collected via the Apple HealthKit APIs or Motion &amp; Fitness APIs for this purpose;</li>
              <li>to optimise the Services for you and other users of the Services, for example by recommending relevant Fiit classes based on data collected through use of the Services (such as the data collected via your Fiit device and/or supported fitness device).</li>
              <li>to make suggestions and recommendations to you and other users of the Services about goods or services that may interest you or them (other than as set out in 2.2(f) above). We do not use data collected via the Apple HealthKit APIs or Motion &amp; Fitness APIs for this purpose.</li>
            </ol>
            For the avoidance of doubt, data collected via the Apple HealthKit APIs or Motion &amp; Fitness APIs is only processed for health and fitness purposes and is never shared with third parties.
          </li>
          <li>Information we receive from other sources.We will combine this information with information you give to us and information we collect about you. We will use this information and the combined information for the purposes set out in this Privacy Policy (depending on the types of information we receive). For example, we have a number of social and community channels which we use to communicate with our users. If you choose to interact with those channels, we will receive your information and use it in connection with those channels, including to engage with you, and we may also re-publish your posts to those channels.</li>
          <li>All information.We will also use all information referred to in this section 2:
            <ol>
              <li>to provide you with support through our customer service channels when you need it;</li>
              <li>to contact you, for example as part of customer service or to send you updates about our Services;</li>
              <li>to manage your account and relationship with us and improving your experience when you use our Services;</li>
              <li>to prevent crime or fraud;</li>
              <li>as required or permitted by any applicable law.</li>
            </ol>
          </li>
          <li>Legal grounds for using your information.The legal bases that we rely on when processing your information are:
            <ol>
              <li>As necessary to perform our contracts with you: for processing information to provide the Services to you, including customer support;</li>
              <li>our legitimate interests or the legitimate interests of a third party, where not outweighed by your interests or fundamental rights and freedoms: for processing information for purposes such as analytics, optimising and improving the Services, preventing and addressing fraud, violations of our terms and policies, or other illegal or harmful activity, and marketing. You may have the right to object to such processing, please see &quot;Your rights&quot; below for further information;</li>
              <li>your consent: for processing information that you allow us to receive through the device-based settings you enable (such as access to your contacts), so we can provide the services described when you enable the settings. When we process data that you provide to us based on your consent, you have the right to withdraw this consent at any time. To exercise this right, please use the settings on your device or contact us at hello@fiit.tv for more information;</li>
              <li>compliance with legal obligations: for processing data when the law requires it;</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Disclosure of your InformationYou understand that we can share your personal information with:
        <ol>
          <li style={{ listStyleType: 'none' }}>
            <ol>
              <li>Any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries.</li>
              <li>Selected third parties including:business partners, suppliers and sub-contractors for the performance of any contract we enter into with them;</li>
            </ol>
          </li>
        </ol>
        analytics, A/B testing and search engine providers that assist us in the improvement and optimisation of the Services;
      </li>
    </ol>
    <ul>
      <li>We will disclose your personal information to third parties:
        <ol>
          <li>as reasonably necessary in order to provide the Services to you (for example, by providing your personal information to suppliers we may use to fulfil the Services or to communicate with you);</li>
          <li>where your data is on an anonymous and aggregated basis, meaning you could not be personally identified from it;</li>
          <li>where it is necessary to carry out your instructions (for example, to process a payment instruction we must provide your personal information to our payment processors);</li>
          <li>where we have your consent to do so;</li>
          <li>when you provide information as part of a competition or otherwise interact with our social media channels (for example, by providing a testimonial about one of our Services) we may publish the information you submitted, alongside your name and home country on our Site and social media channels;</li>
          <li>in the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;</li>
          <li>if Fiit Limited or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets; or</li>
          <li>as we reasonably believe is permitted by law or regulation or as is necessary to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of us, our customers, or others.</li>
        </ol>
        Any third parties with whom we share your personal data may be located outside the European Economic Area in a country whose data protection laws may not offer equivalent to those of the country in which you live. Wherever we share your personal data with affiliates or third parties, we will use contractual and other measures to require those third parties to safeguard your personal data and we will ensure that adequate protection measures are in place for that data.
      </li>
      <li>Where we store your personal data and how long we keep itThe data that we collect from you will be stored primarily within the EU (Ireland) but copies of that data will be transferred to, and stored with, a third party service provider located outside the European Economic Area (&quot;EEA&quot;). We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy. Wherever we appoint a third party to host and store your personal data on our behalf, we will use contractual and other measures to require those third parties to safeguard your personal data and we will adequate protection measures are in place for that data.

        All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.

        Unfortunately, the transmission of information via the internet is not completely secure. While we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to the Services; any transmission is at your own risk. Once we have received your data, we will use strict procedures and security features to try to prevent unauthorised access.

        We will keep your information for as long as we consider necessary for the purposes described above, or as long as the law allows.
      </li>
      <li>CookiesWe use cookies to distinguish you from other users of the Services. This helps us to provide you with a good experience when you use or access the Services and also allows us to improve the Services.
        <ol>
          <li>What are Cookies?A cookie is a small text file that can be placed on your device (e.g. computer, tablet, mobile phone) when browsing a website. Two kinds of cookies are used - session cookies and persistent cookies.

            <i>Session cookies</i> are used to remember selections made on a visit to the Site. Session cookies are deleted automatically when you leave the Site or close your browser.

            &nbsp;

            <i>Persistent cookies</i> are used to identify you when you return to the Site and/or to remember specific information about your preferences. These cookies also help us understand user behaviour within the Site, which can assist us in customising content that we believe will be most relevant to your interests. Persistent cookies are stored on the user&apos;s device and are not deleted when the browser is closed - these cookies must be &apos;manually&apos; deleted if you want to remove them.
          </li>
          <li>First and third party cookiesWe set cookies (first party cookies) on web pages, however, where we require additional information and services we also allow other companies to host cookies on our web pages (third party cookies). These partner companies have been carefully selected by us. Third party cookies that our partners set will support the customisation of advertisements viewed elsewhere on the internet and are required to meet contractual obligations they have with us. To learn more about cookies, please visit www.allaboutcookies.org or www.youronlinechoices.eu.</li>
          <li>What do we use cookies for?The cookies we use are categorised into four groups:
            <ol>
              <li><i>Strictly Necessary cookies</i> - these cookies are used for technical reasons and are necessary to enable the Site to operate efficiently so that you can navigate the Site and use the Services with ease. These include, for example, cookies that enable you to log into secure areas of the Site. If these cookies are blocked or disabled, some of the Site’s functions will not be available to you and you may not be able to use the Services.</li>
              <li><i>Performance cookies</i> - these cookies are used to obtain statistics about the number of users of the Site/Services and how such users interact with the Site/Services. These cookies collect information that is aggregated and therefore cannot be used to reveal an individual&apos;s identity. Such information allows us to continuously improve the Site and Services to provide users with a better online experience (such as enabling users to find what they are looking for more easily).</li>
              <li><i>Functionality cookies</i> - these cookies are used to improve the functionality of the Site and make it easier to use the Services. They help us to identify you as a repeat user of the Services and help us remember your preferences. These cookies help us remember information about you that saves you time by making your user experience easier.</li>
              <li><i>Analytical</i> - these cookies gather information about your habits when using the Services (including the pages you have visited and the links you have followed). We may combine such information with other personal data that we have collected about you (for example from third parties) to create a user profile for you. These cookies, in combination with any user profile that you have created assist us to make the Site and Services more relevant to you (i.e. by helping us tailor our content, marketing communications, offers and advertisements to your interests.)</li>
              <li><i>Targeting</i> - these cookies record your visit to our Site, the pages you have visited and the links you have followed. We will use this information to make our Site and Services more relevant to your interests. We may also share this information with third parties for marketing purposes, unless you disable or block cookies as set out below.</li>
            </ol>
          </li>
          <li>How do I control my cookie settings on my computer?Please be aware that if you decide to disable or block cookies, parts of the Site may not function correctly, or at all, and we may not be able to offer some or all of the Services to you.

            Should you choose however to disable or block our cookies on your device you will need to do this through your browser. Click on the &apos;Help&apos; menu on your particular browser to learn how to manage your cookie preferences. Alternatively, you can visit www.aboutcookies.org or www.youronlinechoices.eu for comprehensive information on how to manage cookies.
          </li>
          <li>What about from mobile applications?When you download and/or use the Fiit Product on your device, we automatically collect information from your device, as set out in section 1.2. If you do not wish us to collect information as described in this section, you should stop using our Services.</li>
        </ol>
      </li>
      <li>Your RightsYou have certain rights relating to how we process your personal data, including the right to:
        <ol>
          <li>access the personal data that we may hold about you. If you wish to exercise this right please contact us letting us know that you wish to exercise your right of access and what information in particular you would like to receive. Please note that we may not be able to provide all the information you ask for, for instance if the information includes personal information about another person. Where we are not able to provide you with information that you have asked for, we will endeavour to tell you why. We will try to respond to any request for a right of access as soon as possible, but we will always do so within 1 month of receipt of your request and evidence of relevant ID;</li>
          <li>rectify any inaccurate personal data that we may hold about you. If you realise that any of the information that we hold about you is incorrect, please let us know and we will correct it as soon as we can;</li>
          <li>have your personal data erased in certain circumstances, for example, where it is no longer necessary for us to process your personal data or where you object to your data being processed on certain grounds (for example, for marketing purposes). Please note that to ensure that we do not collect any further information you should also delete our app from your mobile devices and clear our cookies from any device where you have accessed our Services. Where we delete personal information about you, we may still retain some or all of that information for other purposes such as maintaining financial records, protecting or enforcing legal rights, maintaining marketing suppression lists or for technical reasons such as maintaining technical security or our database integrity. We may also retain your information in an anonymised form;</li>
          <li>object to your personal data being processed for direct marketing purposes. If you have chosen to receive direct marketing communications from us, you can change your mind at any time by following the unsubscribe link in any marketing communication that is sent to you. If you have chosen to receive more than one type of marketing communications from us, you may need to opt out of all of them individually. It may take a few days for us to update our records before any opt out is effective; and</li>
          <li>in some circumstances, you may have the right to request that data which you have provided to us is provided to you, so you can transfer this to another data controller.</li>
        </ol>
        Should you wish to exercise any of these rights, please contact us at hello@fiit.tv.

        The site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note these websites have their own privacy policies and we do not accept any liability for these policies. Please check these policies before you submit any personal data to these websites.
      </li>
      <li>Changes to our Privacy &amp; Cookies PolicyAny changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently for any updates.</li>
      <li>ContactQuestions, comments and requests regarding this privacy policy are welcomed and should be addressed to hello@fiit.tv.

        Should you have cause to complain about how we handle your personal data, please contact us in the first instance. We will do our best to resolve your concern. Alternatively, you may prefer to submit a complaint directly to the Information Commissioner’s Office (ICO). For further details on how to complain to the ICO, please follow the links below: https://ico.org.uk/concerns/ or https://ico.org.uk/global/contact-us.
      </li>
    </ul>
  </Wrapper>
);

export default PrivacyPolicy;
