export enum NetworkStatusActionTypes {
  SET_NETWORK_STATUS = 'SET_NETWORK_STATUS',
}

export const setNetworkStatus = (payload: { isOnline: boolean }) => ({
  type: NetworkStatusActionTypes.SET_NETWORK_STATUS,
  payload,
});
export type SetNetworkStatus = typeof setNetworkStatus;

export default { setNetworkStatus };
