import React from 'react';
import styled from 'styled-components';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Spinner from 'ui/components/atoms/animated-spinner';
import Typography from 'ui/components/atoms/typography';

const Holder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LoadingText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing.m};
`;

type Props = {
  text?: string,
};

const LoadingScreen = ({ text }: Props) => (
  <FullPageOverlay noFocus>
    <Holder>
      <Spinner />
      <LoadingText variant="body-copy">{text || 'Loading...'}</LoadingText>
    </Holder>
  </FullPageOverlay>
);

export default LoadingScreen;
