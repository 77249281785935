import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import styled from 'styled-components';

type Props = {
  className?: string,
  animationData: any,
  pause?: boolean,
  autoplay?: boolean,
  loop?: boolean,
  restart?: boolean,
  dynamicText?: string,
  dynamicTextElementIndex?: number,
  playbackSpeed?: number,
};

const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const LottieAnimation = ({
  className = '',
  animationData,
  pause = false,
  autoplay = false,
  loop = false,
  restart = false,
  dynamicText,
  dynamicTextElementIndex,
  playbackSpeed = 1,
}: Props) => {
  const animationContainerRef = useRef(null);
  const animationRef = useRef<AnimationItem | null>(null);

  // handle setup of the animation
  useEffect(() => {
    const container = animationContainerRef.current;
    if (!container) {
      return () => {};
    }

    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });

    // stops updating on every requestAnimationFrame for better performance
    animation.setSubframe(false);
    animation.setSpeed(playbackSpeed);
    animationRef.current = animation;

    return () => animation.destroy();
  }, [animationContainerRef, animationData, autoplay, loop, playbackSpeed]);

  // handle play/pause of the animation
  useEffect(() => {
    const animation = animationRef.current;
    if (!animation) {
      return;
    }

    if (pause) {
      animation.pause();
    }

    if (!pause) {
      animation.play();
    }
  }, [pause]);

  // handle restarting the animation from the beginning
  useEffect(() => {
    const animation = animationRef.current;
    if (!animation) {
      return;
    }

    if (restart) {
      animation.goToAndPlay(0);
    }
  }, [restart]);

  // handle updating text within a dynamic animation (for example our in class animations)
  useEffect(() => {
    const animation = animationRef.current;
    if (!animation || !dynamicText || !dynamicTextElementIndex) {
      return;
    }

    if (animation.renderer.elements[dynamicTextElementIndex]) {
      animation.renderer.elements[dynamicTextElementIndex].updateDocumentData({ t: dynamicText });
      animation.goToAndPlay(0);
    }
  }, [dynamicText, dynamicTextElementIndex]);

  return (
    <AnimationContainer className={className} ref={animationContainerRef} />
  );
};

export default LottieAnimation;
