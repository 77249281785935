import React, { ReactNode, useCallback, useRef } from 'react';

import styled from 'styled-components';
import { ThemeSpacing } from 'ui/theme';
import RailCard from 'ui/components/atoms/rail-card';
import Button from 'ui/components/atoms/button';
import ChevronRight from 'ui/components/atoms/icons/chevron-right';
import { SpatialNavParent } from 'utils/spatial-nav';

type ItemVariant = 'GRID' | 'RAIL';

export type Props = {
  children: ReactNode[],
  className?: string,
  itemSpacing: ThemeSpacing,
  onItemFocused?: (target: HTMLElement | null) => void,
  variant: ItemVariant,
  viewMoreAction?: () => void,
  columns?: number,
  viewMoreLabel?: string,
};

const ViewMoreButton = styled(Button)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div<{variant: ItemVariant}>`
  position: relative;
  ${({ variant }) => (variant === 'RAIL' ? 'display: flex' : '')};
  ${({ variant }) => (variant === 'RAIL' ? 'flex-direction: row' : '')};
`;

// calculation for width uses (100 - columns) to allow extra spacing to the right incase of overscan
const ItemContainer = styled.div<{ itemSpacing: ThemeSpacing, variant: ItemVariant, columns?: number }>`
  scroll-snap-align: start;
  ${({ variant, columns }) => (variant === 'GRID' && columns ? `width: ${(100 - columns) / columns}%` : '')};
  padding-right: ${({ itemSpacing, theme }) => theme.spacing[itemSpacing]};
  ${({ variant, theme, itemSpacing }) => (variant === 'GRID' ? `padding-bottom:${theme.spacing[itemSpacing]}` : '')};
  ${({ variant }) => (variant === 'GRID' ? 'display: inline-block' : '')};
`;

const LessonList = ({
  children,
  className = '',
  itemSpacing,
  onItemFocused,
  variant,
  viewMoreAction,
  columns = 4,
  viewMoreLabel = 'View all',
}: Props) => {
  const viewMoreEl = useRef(null);
  const onViewMoreItemFocused = useCallback(() => {
    if (viewMoreEl.current && onItemFocused) {
      onItemFocused(viewMoreEl.current);
    }
  }, [onItemFocused]);

  return (
    <Wrapper
      variant={variant}
      className={className}
    >
      {
        children
          .map((item, i: number) => (
            <SpatialNavParent layout="vertical" key={i} onFocus={onItemFocused}>
              <ItemContainer
                itemSpacing={itemSpacing}
                variant={variant}
                columns={columns}
              >
                { item }
              </ItemContainer>
            </SpatialNavParent>
          ))
      }
      {
        viewMoreAction && (
          <SpatialNavParent layout="vertical" key="view-more-action" onFocus={onViewMoreItemFocused}>
            <ItemContainer
              itemSpacing={itemSpacing}
              variant={variant}
              ref={viewMoreEl}
            >
              <RailCard size="large" box>
                <ViewMoreButton
                  icon={<ChevronRight />}
                  variant="cta"
                  label={viewMoreLabel}
                  labelPosition="left"
                  iconOnly
                  onClick={viewMoreAction}
                />
              </RailCard>
            </ItemContainer>
          </SpatialNavParent>
        )
      }
    </Wrapper>
  );
};

export default LessonList;
