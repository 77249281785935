import React from 'react';
import Datetime from 'react-datetime';

import Styles from 'ui/components/molecules/date-time-picker/styles';

export type Props = {
  value?: Date,
  initialValue?: Date,
  onChange?: any,
};

export const DateTimePicker = ({ value, initialValue, onChange }: Props) => (
  <>
    <Styles />
    <Datetime
      value={value}
      initialValue={initialValue}
      dateFormat="DD MMM YYYY"
      onChange={onChange}
    />
  </>
);

export default DateTimePicker;
