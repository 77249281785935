/* stylelint-disable property-no-unknown */

import React, { ReactNode, ChangeEvent } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';

import { rem } from 'ui/helpers';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.s} 0;
`;

const Field = styled.input`
  accent-color: ${({ theme }) => theme.colors.fiitBlue};
  width: ${rem(30)};
`;

const Type = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing.l};
`;

const Description = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing.l};
  display: block;
`;

export type Props = {
  label: string,
  description?: string,
  icon?: ReactNode,
  name: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  value: string,
  checked?: boolean,
  bold?: boolean,
};

type TextWrapperProps = {
  hasIcon: boolean;
};

const InputContainer = styled.span`
  display: flex;
  align-items: left;
`;

const IconWrapper = styled.span`
  background-color: ${({ theme }) => theme.colors.janosGrey};
  border-radius: ${rem(30)};
  width: ${rem(60)};
  height: ${rem(60)};
  text-align: center;
  padding-top: ${rem(15)};
  margin: ${rem(10)} ${rem(24)} 0;
`;

const TextWrapper = styled.span<TextWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.hasIcon ? '0' : '0.5rem')};
`;

const RadioField = ({
  name,
  label,
  description,
  icon,
  checked,
  onChange,
  value,
  bold = true,
}: Props) => (
  <FieldContainer>
    <InputContainer>
      <Field
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      { icon && (
        <IconWrapper>
          {icon}
        </IconWrapper>
      )}
      <TextWrapper hasIcon={!!icon}>
        <Type weight={bold ? 'bold' : 'regular'}>{label}</Type>
        { description && <Description>{description}</Description> }
      </TextWrapper>
    </InputContainer>
  </FieldContainer>
);

export default RadioField;
