import styled from 'styled-components';

const ErrorPanel = styled.div`
   color: ${({ theme }) => theme.colors.codeOrange};
   border: 1px solid ${({ theme }) => theme.colors.codeOrange};
   margin: ${({ theme }) => theme.spacing.xl};
   padding: ${({ theme }) => theme.spacing.m};
 `;

export default ErrorPanel;
