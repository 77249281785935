import React, { SyntheticEvent, useCallback } from 'react';
import FullPageTwoAnswer from 'ui/components/layouts/full-page-two-answer';
import Logout from 'ui/components/atoms/icons/logout';

import useDismissEvent from 'app/hooks/use-dismiss-event';

type Props = {
  cancelLogout: () => void,
  logoutAction: () => void,
};

type LogoutDialogProps = {
  cancelOnClick?: (event: SyntheticEvent) => void,
  confirmOnClick?: (event: SyntheticEvent) => void,
};

const LogoutScreen = ({ cancelLogout, logoutAction }: Props) => {
  useDismissEvent(useCallback(() => {
    cancelLogout();
  }, [cancelLogout]), true);

  return (
    <FullPageTwoAnswer
      icon={Logout}
      question="Are you sure you want to log out?"
      optionOne="No"
      optionOneClick={cancelLogout}
      optionTwo="Yes"
      optionTwoClick={logoutAction}
      autofocusButton="one"
    />
  );
};
export default LogoutScreen;
