const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const hexRegex = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
const hexToRgb = (hex: string) => {
  const [, r, g, b] = hexRegex.exec(hex) || [];

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
  };
};

const rgba = (hex: string, alpha: number) => {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const padStart = (str: string, padLength: number, padString: string) => {
  if (str.length > padLength) {
    return str;
  }

  const targetLength = padLength - str.length;

  let returnString = padString;

  if (targetLength > padString.length) {
    returnString += padString.repeat(targetLength / padString.length);
  }

  return returnString.slice(0, targetLength) + str;
};

// From designs - enacted by global-styles
const oneRem = 30;
const rem = (pixelSize: number) => `${pixelSize / oneRem}rem`;

// increase the scrollLimit to avoid cutting off elements due to tv overscan
const overscanCompensation = 50;
const scrollLimit = (elHeight: number, windowHeight: number) => (
  elHeight ? Math.max((elHeight - windowHeight) + overscanCompensation, 0) : 0
);

export {
  rem,
  clamp,
  rgba,
  padStart,
  scrollLimit,
};
