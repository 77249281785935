import { useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';

function useCountdown(date: string, { intervalTime = 1000, onComplete = () => {} } = {}) {
  const [timeLeft, setTimeLeft] = useState<number>(differenceInSeconds(new Date(date), new Date()));

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date(date) <= new Date()) {
        clearInterval(interval);
        setTimeLeft(0);
        onComplete();
      }

      setTimeLeft(differenceInSeconds(new Date(date), new Date()));
    }, intervalTime);

    return () => clearInterval(interval);
  }, [date, intervalTime, onComplete]);

  return Math.max(timeLeft, 0);
}

export default useCountdown;
