import React from 'react';
import styled from 'styled-components';
import { LessonDurationRange } from 'types/graphql';

import Watch from 'ui/components/atoms/icons/watch';
import Typography from 'ui/components/atoms/typography';
import { ThemeColors } from 'ui/theme';

export type Props = {
  duration: LessonDurationRange,
  className?: string,
  showIcon?: boolean,
  color?: ThemeColors,
};

export const labelFromLessonDuration = (val: string) => {
  const dur = val.split('_')[1];
  return dur === '60' ? `${dur}+min` : `${dur}min`;
};

const StyledWatch = styled(Watch)`
  height: 0.8rem;
  width: 0.8rem;
`;

const Length = styled.div`
  padding: ${({ theme }) => theme.spacing.xs};
  text-align: center;
  display: flex;
`;

const Duration = styled(Typography)<{ marginLeft: boolean }>`
  display: inline-block;
  letter-spacing: 0.2em;
  line-height: normal;
  margin-left: ${({ marginLeft }) => (marginLeft ? '0.4rem' : 0)};
`;

const ClassLength = ({ className = '', duration, showIcon = false, color = 'white' }: Props) => (
  <Length className={className}>
    { showIcon && <StyledWatch /> }
    <Duration
      marginLeft={showIcon}
      color={color}
      variant="pica"
      uppercase
    >
      {labelFromLessonDuration(duration)}
    </Duration>
  </Length>
);

export default ClassLength;
