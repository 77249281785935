import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgStudioIcon = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg
      viewBox="0 0 24 24"
      className={className}
    >
      <style>{`.studios_svg__st0{fill:${fill}}`}</style>
      <path
        className="studios_svg__st0"
        d="M16.3 18.7c-4.3 2.4-9.6.8-12-3.4-.6-1.2-1-2.4-1.1-3.7.5-.4.8-.9.8-1.6 0-1.1-.9-2-2-2s-2 .9-2 2c0 .8.4 1.4 1 1.8.1 1.6.5 3.1 1.3 4.6 2.9 5.3 9.6 7.2 15 4.3l1-.6-1.3-1.8-.7.4zM23.7 9c-.3-.5-.7-.8-1.1-.9-.2-.8-.6-1.6-1-2.4C18.7.4 12-1.5 6.6 1.4l-.9.6L7 3.8l.8-.5c4.3-2.4 9.6-.8 12 3.4.4.6.6 1.3.8 2-.5.6-.7 1.5-.2 2.3.5 1 1.8 1.3 2.7.8.8-.6 1.2-1.8.6-2.8z"
      />
      <path
        className="studios_svg__st0"
        d="M13.9 14.5c1.9-1.1 2.6-3.5 1.6-5.4-1.1-1.9-3.5-2.6-5.4-1.6-2 1.1-2.7 3.5-1.6 5.4 1.1 2 3.5 2.7 5.4 1.6z"
      />
    </Svg>
  );
};

export default SvgStudioIcon;
