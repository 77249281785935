import { InMemoryCache, IntrospectionFragmentMatcher, CacheResolverMap } from 'apollo-cache-inmemory';

import introspectionQueryResultData from 'types/graphql';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cacheRedirects: CacheResolverMap = {
  Query: {
    lessonInstanceById: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'lessonInstanceById', id: args.id }),
    workoutById: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Workout', id: args.id }),
  },
};

export default () => new InMemoryCache({
  fragmentMatcher,
  cacheRedirects,
});
