export const pluralise = (number: number, text: string, pluralForm: string) => {
  if (number === 1) {
    return text;
  }
  return pluralForm;
};

export const possessivePluralise = (text: string) => {
  const lastLetter = text[text.length - 1];
  return lastLetter === 's' ? `${text}'` : `${text}'s`;
};
