import React, { createContext, useContext, ReactNode } from 'react';
import { Config as DefaultConfig } from 'config';

// @ts-ignore - we're using js so we can pull it more easily into webpack
// eslint-disable-next-line import/extensions
import config from 'app/in-studio/config.js';

export type Config = DefaultConfig & {
  QR_CODE_HOSTNAME: string,
  STUDIO_LESSON_POLL_INTERVAL_SECONDS: number,
  PRE_LOBBY_COUNTDOWN_SECONDS: number,
  FAVOURITES_POLLING_INTERVAL: number,
};

type RawConfigValue = {
  value: any,
  format?: (value: any) => any,
};

const configContext = {
  config: Object.entries<RawConfigValue>(config)
    .reduce((formattedConfig, [key, { value, format }]) => ({
      ...formattedConfig,
      [key]: format ? format(value) : value,
    }), {}) as Config,
};

// defined as an object so later we could add a debug menu using setConfig/useState
const context = createContext(configContext);

export const ConfigProvider = ({ children }: { children: ReactNode }) => (
  <context.Provider value={configContext}>
    { children }
  </context.Provider>
);

export default () => useContext(context);
