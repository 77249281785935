export enum AuthActionTypes {
  LOGIN = 'AUTH/LOGIN',
  REFRESH_TOKENS = 'AUTH/REFRESH_TOKENS',
  LOGOUT = 'AUTH/LOGOUT',
}

type TokenPayload = {
  tokenType: string,
  accessToken: string,
  expiresIn: number,
  refreshToken: string,
};

export const login = (payload: TokenPayload) => ({
  type: AuthActionTypes.LOGIN,
  payload,
});
export type Login = typeof login;

export const refreshTokens = (payload: TokenPayload) => ({
  type: AuthActionTypes.REFRESH_TOKENS,
  payload,
});
export type RefreshTokens = typeof refreshTokens;

export const logout = () => ({
  type: AuthActionTypes.LOGOUT,
});
export type Logout = typeof logout;

export default { login, refreshTokens, logout };
