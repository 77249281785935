import React from 'react';
import styled from 'styled-components';
import LottieAnimation from 'ui/components/atoms/animated-icons/lottie-animation';

import animationData from './countdown-animation.json';

const StyledLottieAnimation = styled(LottieAnimation)`
  margin: 0;
`;

const AnimatedSVGCountdown = () => (
  <StyledLottieAnimation animationData={animationData} autoplay />
);

export default AnimatedSVGCountdown;
