import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

const SvgHome = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : 'currentColor';
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <g id="Home/Active">
        <path id="fill" fillRule="evenodd" clipRule="evenodd" d="M12.2996 0L1.33325 9.33217V24H11.1301V19.3333H13.5364V24H23.3333V9.3485L12.2996 0Z" fill={fill} />
      </g>
    </Svg>
  );
};

export default SvgHome;
