export enum ChromecastActionTypes {
  ADD_CALLBACK = 'CHROMECAST/ADD_CALLBACK',
}

export type ChromecastCallback = (data: any) => void;

export type ChromecastAddCallbackPayload = {
  messageType: string,
  callback: ChromecastCallback,
};

export const addCallback = (payload: ChromecastAddCallbackPayload) => ({
  type: ChromecastActionTypes.ADD_CALLBACK,
  payload,
});

export type AddCallback = typeof addCallback;

export default {
  addCallback,
};
