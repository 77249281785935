import React, { ElementType, SyntheticEvent } from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';
import MenuIcon from 'ui/components/atoms/menu-icon';

export type MenuItem = {
  id: string,
  icon: ElementType,
  selected: boolean,
  handleAction?: (event: SyntheticEvent) => void,
};

export type Props = {
  items: MenuItem[],
};

export const height = rem(88);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: ${height};
  backdrop-filter: blur(15px);
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mayumiBlack};
  opacity: 60%;
`;

const MenuList = styled.div`
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 0 ${rem(48)};
`;

const BottomMenu = ({
  items = [],
}: Props) => (
  <Wrapper>
    <Content>
      <MenuList>
        { items.map((item, i) => (
          <MenuIcon
            key={i}
            icon={<item.icon selected={item.selected} />}
            onClick={item.handleAction}
          />
        )) }
      </MenuList>
    </Content>
  </Wrapper>
);

export default BottomMenu;
