/* eslint-disable no-confusing-arrow */
import React from 'react';
import styled from 'styled-components';

import { clamp } from 'ui/helpers';

export type Props = {
  className?: string,
  currentBpm: number | null,
  disabled?: boolean,
  zoneStart?: number,
  userHeartRateRange: {
    min: number,
    max: number,
  },
};

type ZoneBarProps = {
  zonePosition: number,
  disabled?: boolean,
};

// Scales with UI, clamped to 12px maximum
const Wrapper = styled.div`
  line-height: 0;
  height: 0.3rem;
  max-height: 12px;
  display: flex;
  align-items: flex-end;
  box-sizing: content-box;
  padding-top: 0.5rem;
`;

const BarSection = styled.div`
  border-radius: 100px;
  display: inline-block;
  height: 100%;
`;

const OutOfZoneBar = styled(BarSection)`
  background-color: ${({ theme }) => theme.colors.janosGrey};
  width: 20%;
`;

// The after tag is the arrow, but it uses border widths to make a triangle, which is no good for scaling by %
// TODO To make it scale with the bar make it a div / image based on % height
const InZoneBar = styled(BarSection)<ZoneBarProps>`
  background: ${({ disabled, theme }) => disabled ? theme.colors.janosGrey : theme.colors.codeOrange};
  background: ${({ disabled, theme }) => disabled
    ? theme.colors.janosGrey
    : `linear-gradient(to right, ${theme.colors.codeOrange} 0%, ${theme.colors.smashingPumpkins})`};
  margin-left: 2.5%;
  position: relative;
  width: 77.5%;

  &::after {
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 10px 0 10px;
    transform: translateX(-50%);
    border-color: ${({ theme }) => theme.colors.white} transparent transparent transparent;
    content: "";
    position: absolute;
    top: -12px;
    left: ${({ zonePosition }) => zonePosition}%;
    transition: left 0.2s ease-in-out;
  }
`;

export const ZoneBar = ({ className, currentBpm, disabled, userHeartRateRange, zoneStart = 0 }: Props) => {
  const { min, max } = userHeartRateRange;
  const userHeartRateReserve = currentBpm ? ((currentBpm - min) / (max - min)) * 100 : 0;
  const zonePosition = clamp((userHeartRateReserve - zoneStart) / (100 - zoneStart), -0.25, 0.95) * 100;

  return (
    <Wrapper className={className}>
      <OutOfZoneBar />
      <InZoneBar
        zonePosition={zonePosition}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default ZoneBar;
