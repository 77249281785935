import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

function SvgDisconnectedIcon({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <defs>
        <path
          id="disconnected_svg__a"
          d="M21.822 2.114a7.178 7.178 0 00-10.175 0L8.573 5.196l1.696 1.7 3.074-3.082a4.784 4.784 0 016.783 0 4.827 4.827 0 010 6.804l-3.073 3.083 1.696 1.7 3.073-3.082a7.23 7.23 0 000-10.205zm-11.235 18.07a4.753 4.753 0 01-3.392 1.41 4.75 4.75 0 01-3.39-1.41 4.821 4.821 0 010-6.802L6.877 10.3 5.18 8.598 2.107 11.68a7.233 7.233 0 000 10.207A7.169 7.169 0 007.195 24a7.17 7.17 0 005.089-2.113l3.073-3.083-1.697-1.702-3.073 3.083zM19.658 18L24 22.31l-1.711 1.662-4.342-4.309L19.658 18zM14.673 7.584l1.695 1.7-7.13 7.15-1.695-1.7 7.13-7.15zM1.71 0l4.342 4.31-1.711 1.662L0 1.663 1.711 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="disconnected_svg__b" fill={fill}>
          <use xlinkHref="#disconnected_svg__a" />
        </mask>
        <use fill={theme.colors.black} xlinkHref="#disconnected_svg__a" />
        <g fill={fill} mask="url(#disconnected_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgDisconnectedIcon;
