import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

const Svg = styled.svg`
  width: 25px;
  height: 25px;
`;

const ErrorIcon = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="25" cy="25" r="24" fill="none" stroke="#FF0000" strokeWidth="3" />
    <line x1="15" y1="15" x2="35" y2="35" stroke="#FF0000" strokeWidth="3" />
    <line x1="35" y1="15" x2="15" y2="35" stroke="#FF0000" strokeWidth="3" />
  </Svg>
);

export default ErrorIcon;
