/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Flipper, Flipped } from 'react-flip-toolkit';

import Typography from 'ui/components/atoms/typography';
import MembersInClassCounter from 'ui/components/molecules/members-in-class-counter';
import LeaderboardEntry, { Props as UserProps } from 'ui/components/molecules/leaderboard-entry';
import AnimatedLoader from 'ui/components/atoms/animated-loader';

export type Props = {
  className?: string,
  currentUser?: number,
  entries: UserProps[],
  totalUserCount?: number,
  isStatic?: boolean,
  showCounter?: boolean,
  updatedAt?: Date,
  connected?: boolean,
  limit?: number,
};

type StyleProps = {
  disconnected: boolean,
};

const MAX_LEADERBOARD_SIZE = 9;
const TOP_LEADERBOARD_SIZE = 3;

const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.standard};
  color: ${({ theme }) => theme.colors.white};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.fordDarkCharcoal}`};
  display: flex;
  flex: 1;
  align-items: center;
  max-height: ${({ theme }) => theme.misc.segmentHeight};
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.s}`};
`;

const Dot = styled.div`
  height: 0.15rem;
  width: 0.15rem;
  background-color: ${({ theme }) => theme.colors.wednesdayGrey};
  border-radius: 50%;
  display: inline-block;
  margin: ${({ theme }) => `${theme.spacing.xs} 0.2rem`};
`;

const ConnectingWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ConnectingText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing.m};
`;

const StyledMembersInClass = styled(MembersInClassCounter)`
  filter: blur(${({ disconnected }: StyleProps) => (disconnected ? '14px' : '0')});
`;

const StyledLeaderboardEntry = styled(LeaderboardEntry)`
  filter: blur(${({ disconnected }: StyleProps) => (disconnected ? '14px' : '0')});
  padding-right: 0.5rem;
  margin: 0.125rem 0;
`;

const StyledAnimatedLoader = styled(AnimatedLoader)`
  height: 1.2rem;
  width: 1.2rem;
`;

const sliceUpUsers = (users: UserProps[], currentUser?: number, limit: number = MAX_LEADERBOARD_SIZE) => {
  const noOfUsers = users.length;

  if (noOfUsers === 0) {
    return { top: [], divider: false, bottom: [] };
  }

  if (noOfUsers <= (limit - 1)) {
    return { top: users, divider: false, bottom: [] };
  }

  if (currentUser) {
    if (currentUser <= (TOP_LEADERBOARD_SIZE + 1)) {
      return {
        top: users.slice(0, limit),
        divider: false,
        bottom: [],
      };
    }

    if (currentUser >= noOfUsers - (limit - 1)) {
      return {
        top: [],
        divider: true,
        bottom: users.slice(noOfUsers - (limit - 1), noOfUsers),
      };
    }

    return {
      top: users.slice(0, TOP_LEADERBOARD_SIZE),
      divider: true,
      bottom: users.slice(
        currentUser - Math.floor(limit / 3),
        currentUser + Math.ceil(limit / 3) + 1,
      ),
    };
  }

  return {
    top: users.slice(0, limit),
    divider: false,
    bottom: [],
  };
};

const ConnectingOverlay = () => (
  <ConnectingWrapper>
    <StyledAnimatedLoader />
    <ConnectingText variant="double-pica" as="p">Connecting...</ConnectingText>
  </ConnectingWrapper>
);

export const Leaderboard = ({
  className = '',
  currentUser,
  entries,
  isStatic = false,
  totalUserCount,
  connected,
  updatedAt,
  showCounter = false,
  limit,
}: Props) => {
  const totalCount = totalUserCount || entries.length;

  if (isStatic) {
    return (
      <Wrapper className={className}>
        { !connected && <ConnectingOverlay /> }
        { showCounter && <StyledMembersInClass disconnected={!connected} value={entries.length} /> }
        { entries.map((entry) => (
          <StyledLeaderboardEntry disconnected={!connected} isStatic key={entry.user.id} {...entry} />
        ))}
      </Wrapper>
    );
  }

  const copy = entries.slice();

  const { top, divider, bottom } = sliceUpUsers(copy, currentUser, limit);

  return (
    <Flipper flipKey={updatedAt?.toISOString()}>
      <Wrapper className={className}>
        { !connected && <ConnectingOverlay /> }
        { showCounter && <StyledMembersInClass disconnected={!connected} value={totalCount} /> }
        { top.map((entry) => (
          <Flipped key={entry.user.id} flipId={String(entry.user.id)}>
            <StyledLeaderboardEntry disconnected={!connected} {...entry} />
          </Flipped>
        ))}
        { divider && (
        <Divider>
          <Dot />
          <Dot />
          <Dot />
        </Divider>
        )}
        { bottom.map((entry) => (
          <Flipped key={entry.user.id} flipId={String(entry.user.id)}>
            <StyledLeaderboardEntry disconnected={!connected} {...entry} />
          </Flipped>
        ))}
      </Wrapper>
    </Flipper>
  );
};

export default Leaderboard;
