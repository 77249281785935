import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';

import routes, { RouteConfig } from 'app/in-studio/routes';
import BottomMenuContent, { MenuItem, height as menuHeight } from 'ui/components/molecules/bottom-menu';
import TopMenuContent from 'ui/components/molecules/top-menu';
import ChevronLeftIcon from 'ui/components/atoms/icons/chevron-left';
import HomeIcon from 'ui/components/atoms/icons/home';
import FilterIcon from 'ui/components/atoms/icons/filter';
import ProfileIcon from 'ui/components/atoms/icons/profile';

import { useAppState } from 'state';
import useRoutes from 'utils/use-routes';
import styled from 'styled-components';
import { PageContext } from 'utils/use-page-scroll';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import useDelayOnBack from 'utils/use-delay-on-back';
import { analytics } from 'utils/analytics';
import Logo from 'ui/components/atoms/logo';

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

type MenuContainerProps = {
  onClick?: () => void,
  children?: ReactNode,
  className?: string,
};

const TopMenuContainer = styled.div`
  height: ${menuHeight};
`;

const MenuContainer = styled.div`
  flex-shrink: 0;
  height: ${menuHeight};
  z-index: 2;
  position: absolute;
  bottom: 0;
`;

const addPathToList = (arr: string[], { acceptedPaths }: { acceptedPaths?: string[] }) => {
  if (acceptedPaths !== undefined) {
    arr.push(...acceptedPaths);
  }
  return arr;
};
const routeConfig = Object.entries(routes).map(([path, config]) => ({ path, ...config }));

const menuPathsConfig = {
  exact: routeConfig
    .filter(({ menu, exact }) => !!menu && exact)
    .reduce(addPathToList, [] as string[]),
  partial: routeConfig
    .filter(({ menu, exact }) => !!menu && !exact)
    .reduce(addPathToList, [] as string[]),
};

const itemSelected = (acceptedRoutes = routeConfig, currentUrl: string, exact?: boolean) => {
  const paramRegex = /\/:[A-z]*/;
  // Strip out the params, we only care about the main part matching.
  return acceptedRoutes
    .map((route) => ({
      ...route,
      acceptedPaths: route.acceptedPaths.map((path) => path.replace(paramRegex, '')),
    }))
    .find(({ acceptedPaths }: typeof routeConfig[0]) => (
      acceptedPaths.find((path) => (exact ? currentUrl === path : currentUrl.includes(path)))
    ));
};

const TopMenu = () => {
  const exactMatch = useRouteMatch({ path: menuPathsConfig.exact, exact: true });
  const partialMatch = useRouteMatch({ path: menuPathsConfig.partial, exact: false });
  const showMenu = !!(exactMatch || partialMatch);
  const history = useHistory();
  const location = useLocation();

  const isHome = !!itemSelected([routes.BROWSE], location.pathname, true);

  if (!showMenu) {
    return null;
  }

  const menuItemsList: MenuItem[] = isHome ? [] : [
    {
      id: 'back',
      icon: ChevronLeftIcon,
      selected: false,
      handleAction: () => history.goBack(),
    },
  ];

  const logo = isHome && <Logo />;

  return (
    <TopMenuContainer
      data-test="menu-wrapper"
    >
      <div>
        <TopMenuContent
          items={menuItemsList}
          title=""
          logo={logo}
        />
      </div>
    </TopMenuContainer>
  );
};

const BottomMenu = () => {
  const exactMatch = useRouteMatch({ path: menuPathsConfig.exact, exact: true });
  const partialMatch = useRouteMatch({ path: menuPathsConfig.partial, exact: false });
  const showMenu = !!(exactMatch || partialMatch);
  const { redirect } = useRoutes();

  const menuAction = (route: RouteConfig) => () => {
    redirect({ route, replaceStack: true });
  };

  if (!showMenu) {
    return null;
  }

  const menuItemsList: MenuItem[] = [
    {
      id: 'home',
      icon: HomeIcon,
      selected: false,
      handleAction: menuAction(routes.BROWSE),
    },
    {
      id: 'filter',
      icon: FilterIcon,
      selected: false,
      handleAction: menuAction(routes.FILTER),
    },
    {
      id: 'profile',
      icon: ProfileIcon,
      selected: false,
      handleAction: menuAction(routes.PROFILE),
    },
  ];

  return (
    <MenuContainer
      data-test="menu-wrapper"
    >
      <div>
        <BottomMenuContent
          items={menuItemsList}
        />
      </div>
    </MenuContainer>
  );
};

const PageContent = styled.div<{ showBottomMenu: boolean }>`
  height: 100vh;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  ${({ showBottomMenu }) => (showBottomMenu && `padding-bottom: ${menuHeight}`)};
`;

type PageContainerProps = {
  children: JSX.Element,
};

const loginRoutes = ['/login'];

const PageContainer = ({ children }: PageContainerProps) => {
  const userId = useAppState((state) => state.auth.userId);
  const pageContentRef = useRef<HTMLDivElement | null>(null);
  const delayed = useDelayOnBack();
  const location = useLocation();
  const exactMatch = useRouteMatch({ path: menuPathsConfig.exact, exact: true });
  const partialMatch = useRouteMatch({ path: menuPathsConfig.partial, exact: false });
  const showBottomMenu = !!(exactMatch || partialMatch);

  // https://segment.com/docs/connections/spec/identify recommends making an Identify call
  // Upon loading any pages that are accessible by a logged in user
  useEffect(() => {
    if (userId) {
      analytics.identify(userId.toString());
    }
  }, [userId, location]);

  useEffect(() => {
    if (pageContentRef.current && !location.state?.preventScrollReset) {
      pageContentRef.current.scrollTo(0, 0);
    }

    const route = itemSelected(routeConfig, location.pathname);

    if (route && !loginRoutes.includes(location.pathname)) {
      analytics.page(route.path, { path: location.pathname });
    }
  }, [location]);

  const scrollTo = useCallback((top: number) => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTo({ top, behavior: 'smooth' });
    }
  }, []);

  if (delayed) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <PageContext.Provider value={scrollTo}>
        <PageContent ref={pageContentRef} showBottomMenu={showBottomMenu}>
          <TopMenu />
          {children}
        </PageContent>
      </PageContext.Provider>
      <BottomMenu />
    </Wrapper>
  );
};

export default PageContainer;
