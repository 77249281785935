import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

import useKeyEvent, { Key } from 'utils/use-key-event';
import useConfig from 'app/on-tv/config-provider';

type Callback = (history: History<any>) => void;

const useDismissEvent = (onDismiss?: Callback, priority = false) => {
  const history = useHistory();
  const { config } = useConfig();

  const handler = useCallback(() => {
    if (onDismiss) {
      onDismiss(history);
      return;
    }

    history.goBack();
  }, [history, onDismiss]);

  return useKeyEvent({
    key: [Key.Escape, config.PLATFORM_RCU_BACK_BUTTON],
    handler,
    priority,
  });
};

export default useDismissEvent;
