import React from 'react';
import { padStart } from 'ui/helpers';

export type Props = {
  time?: number | null,
};

const Time = ({ time }: Props) => {
  if (!time || time < 0) {
    return <span>00:00</span>;
  }

  let seconds = Math.round(time);

  const hours = Math.floor(time / (60 * 60));
  seconds -= hours * (60 * 60);

  const hoursStr = (hours && hours !== 0) ? `${String(hours)}:` : '';

  const minutes = Math.floor(seconds / (60));
  seconds -= minutes * (60);

  const minutesStr = (minutes && minutes !== 0) ? `${padStart(String(minutes), 2, '0')}:` : '00:';

  return <span>{`${hoursStr}${minutesStr}${padStart(String(seconds), 2, '0')}`}</span>;
};

export default Time;
