import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';

type BreakdownProps = {
  leftColumn: ReactNode[],
  rightColumn?: ReactNode[] | ReactNode | null,
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  height: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(830)};
  height: 100%;
  overflow-y: scroll;
`;

const RightColumn = styled(Column)`
  padding-left: ${rem(20)};
`;

const BreakdownSection = ({ leftColumn, rightColumn }: BreakdownProps) => (
  <Wrapper>
    <Column>{leftColumn}</Column>
    <RightColumn>{rightColumn}</RightColumn>
  </Wrapper>
);

export default BreakdownSection;
