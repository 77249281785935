import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import useConfig from 'config';

import {
  StudioType,
  LessonDurationRange,
  LessonDifficulty,
  UserFitnessGoal,
} from 'app/on-tv/types/graphql';
import onTvRoutes, { RouteConfig } from 'app/on-tv/routes';
import inStudioRoutes from 'app/in-studio/routes';
import useLogger from 'app/hooks/use-logger';
import urlSearchParams from 'utils/url-search-params';

export type AllRoutes = Record<keyof typeof onTvRoutes | keyof typeof inStudioRoutes, RouteConfig>;

export type Route = AllRoutes[keyof AllRoutes];

export type RouteParams = { [key: string]: string | number };

type LessonFilterQueryParams = (
  StudioType[] |
  LessonDurationRange[] |
  LessonDifficulty[] |
  UserFitnessGoal[] |
  number[] |
  string[]
);
export type QueryParams = { [key: string]: string | number | boolean | LessonFilterQueryParams | null };

type ManipulateNavigationStack = (path: string, search?: string, state?: any) => void;

type RouteContextType = {
  routes: Partial<AllRoutes>,
  replaceNavigationStack?: ManipulateNavigationStack,
  pushNavigationStack?: ManipulateNavigationStack,
};

export const RouteContext = React.createContext<RouteContextType>({} as RouteContextType);

export const replaceParams = (route?: Route, params: RouteParams = {}) => {
  if (!route?.acceptedPaths[0]) {
    throw new Error('Attempted to parse undefined route.');
  }

  return Object.entries(params).reduce(
    (path, [key, value]) => path.replace(`:${key}`, value.toString()), route.acceptedPaths[0],
  );
};

export const addSearchParams = (path: string, query: QueryParams) => {
  if (!Object.keys(query).length) {
    return path;
  }
  return `${path}?${urlSearchParams(query)}`;
};

type UrlArgs = {
  route?: Route,
  params?: { [key: string]: string | number },
  query?: { [key: string]: string | number | boolean },
};

type RouteArgs = {
  route?: Route,
  params?: RouteParams,
  queryParams?: QueryParams,
  replaceStack?: boolean,
  state?: {
    [key: string]: any,
  }
};

export const url = ({
  route,
  params = {},
  query = {},
}: UrlArgs) => addSearchParams(replaceParams(route, params), query);

const useRoutes = () => {
  const history = useHistory();
  const { routes, replaceNavigationStack } = useContext(RouteContext);
  const logger = useLogger('routing');
  const { config } = useConfig();

  const isWeb = config.APP_TYPE === 'web';

  const push = useCallback(({
    route,
    params = {},
    queryParams = {},
    replaceStack = false,
    state,
  }: RouteArgs) => {
    if (!route?.acceptedPaths[0]) {
      logger.error('Attempted to navigate to undefined route.');
      return;
    }

    const path = replaceParams(route, params);
    if (!path) {
      return;
    }

    if (!isWeb && replaceStack && replaceNavigationStack) {
      replaceNavigationStack(path, urlSearchParams(queryParams), state);
      return;
    }

    const query = addSearchParams(path, queryParams);

    history.push(query, state);
  }, [history, replaceNavigationStack, logger, isWeb]);

  const redirect = useCallback(({
    route,
    params = {},
    queryParams = {},
    replaceStack = false,
    state,
  } : RouteArgs) => {
    if (!route?.acceptedPaths[0]) {
      logger.error('Attempted to redirect to undefined route.');
      return;
    }

    const path = replaceParams(route, params);
    if (!path) {
      return;
    }

    if (!isWeb && replaceStack && replaceNavigationStack) {
      replaceNavigationStack(path, urlSearchParams(queryParams), state);
      return;
    }

    const query = addSearchParams(path, queryParams);

    history.replace(query, state);
  }, [history, replaceNavigationStack, logger, isWeb]);

  return {
    push,
    redirect,
    routes,
  };
};

export default useRoutes;
