import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

function SvgFavourite({ className = '' }: IconProps) {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 24 20" className={className}>
      <defs>
        <path
          id="hollow_heart_svg__a"
          d="M18.058 0a5.96 5.96 0 00-4.207 1.701l-1.868 1.83-1.834-1.83A5.963 5.963 0 005.942 0C4.37 0 2.834.62 1.733 1.701A5.742 5.742 0 000 5.828c0 1.54.631 3.045 1.734 4.125L11.979 20 22.223 9.953C23.369 8.83 24 7.363 24 5.828a5.806 5.806 0 00-1.734-4.127A5.96 5.96 0 0018.059 0m0 2.25c1.002 0 1.938.375 2.632 1.058a3.546 3.546 0 011.059 2.52c0 .927-.392 1.822-1.102 2.518l-8.669 8.502-8.67-8.502A3.545 3.545 0 012.25 5.828c0-.956.377-1.851 1.059-2.52A3.78 3.78 0 015.941 2.25c1.003 0 1.937.375 2.618 1.043l1.836 1.833 1.575 1.572 1.588-1.559 1.87-1.833a3.726 3.726 0 012.63-1.056"
        />
      </defs>
      <g fill={theme.colors.beckersKnop} fillRule="evenodd">
        <mask id="hollow_heart_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#hollow_heart_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#hollow_heart_svg__a" />
        <g fill={theme.colors.white} mask="url(#hollow_heart_svg__b)">
          <path d="M0-2h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgFavourite;
