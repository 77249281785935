import React, { useState, ChangeEvent, useCallback } from 'react';
import { Moment } from 'moment';
import { add, endOfDay, format } from 'date-fns';

import { useMutation } from '@apollo/react-hooks';
import { useTheme } from 'styled-components';
import useRoutes, { url } from 'utils/use-routes';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import FullPageModal from 'ui/components/layouts/full-page-modal';
import Typography from 'ui/components/atoms/typography';
import RadioField from 'ui/components/atoms/radio-field';
import LiveIcon from 'ui/components/atoms/icons/live';
import VirtualIcon from 'ui/components/atoms/icons/virtual';
import CrossIcon from 'ui/components/atoms/icons/cross';
import Button from 'ui/components/atoms/button';
import Spinner from 'ui/components/atoms/animated-spinner';
import DateTimePicker from 'ui/components/molecules/date-time-picker';
import CheckboxField from 'ui/components/atoms/checkbox-field';

import NotificationPopup, { NotificationPopupType } from 'ui/components/atoms/notification-popup';
import { useNotificationPopup } from 'ui/components/atoms/notification-popup/hooks';

import {
  GroupClassType,
  LessonInstancePlaybackType,
  CreateInstanceMutation,
  CreateInstanceMutationVariables,
  LeaderboardDisplayType,
  SchedulePeriod,
  CreateInstanceScheduleMutation,
  CreateInstanceScheduleMutationVariables,
} from 'app/in-studio/types/graphql';

import { CreateInstance as SCHEDULE_LESSON_MUTATION } from 'app/in-studio/pages/lesson/graphql/schedule-lesson.gql';
import {
  CreateInstanceSchedule as CREATE_SCHEDULE_MUTATION,
} from 'app/in-studio/pages/lesson/graphql/create-lesson-schedule.gql';

import {
  ExitButtonWrapper,
  ContentWrapper,
  RadioWrapper,
  ActionButton,
  Title,
  ToggleOptionsArea,
  RepeatScheduleArea,
  RepeatScheduleRow,
  SchedulePeriodSelector,
  ScheduleInformationArea,
  GreyedOutInformationArea,
  ButtonWrapper,
} from 'app/in-studio/pages/lesson/schedule-modals/styled-components';

import { useAppState } from 'state';

type Props = {
  onDismiss: () => void,
  lessonId: number,
  userId: number,
  supportsLive: boolean,
};

type SchedulePeriodOption = {
  value: string | null,
  label: string,
};

const noRepeat = {
  label: 'don\'t repeat',
  value: null,
};

const ScheduleModal = ({ onDismiss, lessonId, userId, supportsLive }: Props) => {
  const { colors } = useTheme();
  const { routes } = useRoutes();
  const [isScheduled, setIsScheduled] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isLeaderboardOn, setIsLeaderboardOn] = useState(true);
  const [playbackType, setPlaybackType] = useState(
    supportsLive ? LessonInstancePlaybackType.LIVE : LessonInstancePlaybackType.VIRTUAL,
  );

  const [dateTime, setDateTime] = useState<Date>(add(new Date(), { minutes: 20 }));
  const [repeatingPeriod, setRepeatingPeriod] = useState<SchedulePeriodOption>(noRepeat);
  const [isRepeating, setIsRepeating] = useState(false);
  const [endsAt, setEndsAt] = useState<Date>(endOfDay(new Date()));

  const [isLoading, setIsLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const { showNotification, promptNotification } = useNotificationPopup();

  const platformPartnerId = useAppState((state) => state.platformPartner.id);

  useDismissEvent(useCallback(() => {
    onDismiss();
  }, [onDismiss]), true);

  const [scheduleLesson] = useMutation<CreateInstanceMutation, CreateInstanceMutationVariables>(
    SCHEDULE_LESSON_MUTATION,
    {
      onCompleted: () => {
        setIsLoading(false);
        setIsScheduled(true);
      },
      onError: () => {
        setIsLoading(false);
        setNotificationMessage('There has been a problem while scheduling a group class.');
        promptNotification();
      },
    },
  );

  const [createLessonInstanceSchedule] = useMutation<
  CreateInstanceScheduleMutation,
  CreateInstanceScheduleMutationVariables
  >(
    CREATE_SCHEDULE_MUTATION,
    {
      onCompleted: () => {
        setIsLoading(false);
        setIsScheduled(true);
      },
      onError: () => {
        setIsLoading(false);
        setNotificationMessage('There has been a problem while creating a schedule of classes.');
        promptNotification();
      },
    },
  );

  const onSubmit = async () => {
    setIsLoading(true);

    if (isRepeating && repeatingPeriod) {
      createLessonInstanceSchedule({
        variables: {
          startsAt: dateTime.toISOString(),
          endsAt: endsAt.toISOString(),
          schedulePeriod: repeatingPeriod.value as SchedulePeriod,
          playbackType,
          classType: isPublic ? GroupClassType.PUBLIC_USER_OWNED : GroupClassType.PRIVATE_USER_OWNED,
          lobbyDuration: 480,
          owner: { id: userId },
          lessonId,
          ...(platformPartnerId ? { platformPartnerId } : {}),
          ...(!isLeaderboardOn ? { leaderboardDisplayType: LeaderboardDisplayType.NONE } : {}),
        },
      });
    } else {
      scheduleLesson({
        variables: {
          date: dateTime.toISOString(),
          playbackType,
          classType: isPublic ? GroupClassType.PUBLIC_USER_OWNED : GroupClassType.PRIVATE_USER_OWNED,
          lobbyDuration: 480,
          owner: { id: userId },
          lessonId,
          ...(platformPartnerId ? { platformPartnerId } : {}),
          ...(!isLeaderboardOn ? { leaderboardDisplayType: LeaderboardDisplayType.NONE } : {}),
        },
      });
    }
  };

  const onRadioSelected = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setPlaybackType(value === 'live' ? LessonInstancePlaybackType.LIVE : LessonInstancePlaybackType.VIRTUAL);
  };

  const getEndsAtFromStartAndPeriod = (start: Date, period: SchedulePeriod): Date => {
    switch (period) {
      case SchedulePeriod.EVERY_DAY:
        return add(start, { days: 1 });
      case SchedulePeriod.EVERY_WEEK:
        return add(start, { days: 7 });
      case SchedulePeriod.EVERY_FORTNIGHT:
        return add(start, { days: 14 });
      case SchedulePeriod.EVERY_MONTH:
        return add(start, { months: 1 });
      default:
        return start;
    }
  };

  const handleSelectChange = (selectedOption: SchedulePeriodOption) => {
    setRepeatingPeriod(selectedOption);
    setIsRepeating(selectedOption.value !== null);
    setEndsAt(getEndsAtFromStartAndPeriod(dateTime, selectedOption.value as SchedulePeriod));
  };

  return (
    <FullPageModal backgroundColour="fordDarkCharcoal">
      {showNotification && (
        <NotificationPopup
          type={NotificationPopupType.ERROR}
          message={notificationMessage}
        />
      )}
      <ContentWrapper>
        <ExitButtonWrapper>
          <Button iconOnly icon={<CrossIcon />} onClick={() => onDismiss()} />
        </ExitButtonWrapper>
        {
          isScheduled ? (
            <>
              <Title weight="bold" variant="double-pica">Success!</Title>
              <Typography>{isRepeating ? 'Schedule of classes added' : 'Class scheduled'}</Typography>
              <ScheduleInformationArea>
                <Typography weight="bold">{format(dateTime, 'dd MMM yyyy, HH:mm')}</Typography>
                <Typography weight="bold">{playbackType}</Typography>
              </ScheduleInformationArea>
              {(isRepeating && repeatingPeriod) && (
                <GreyedOutInformationArea>
                  <Typography>Repeats {repeatingPeriod.label}</Typography>
                  <Typography>Ends {format(endsAt, 'dd MMM yyyy, HH:mm')}</Typography>
                </GreyedOutInformationArea>
              )}
              <ButtonWrapper>
                <ActionButton
                  to={url({ route: routes.SCHEDULE })}
                  label="See scheduled classes"
                  variant="cta"
                  autofocus
                />
              </ButtonWrapper>
            </>
          ) : (
            <>
              <Title weight="bold" variant="double-pica">Schedule a class</Title>
              <Typography>Pick a date and time</Typography>
              <RepeatScheduleArea>
                <RepeatScheduleRow>
                  <DateTimePicker
                    value={dateTime}
                    initialValue={dateTime}
                    onChange={(date: Moment): void => setDateTime(date.toDate() || new Date())}
                  />
                  <SchedulePeriodSelector
                    classNamePrefix="Select"
                    value={repeatingPeriod}
                    isSearchable={false}
                    onChange={(optionValue) => {
                      const value = optionValue as SchedulePeriodOption;
                      handleSelectChange(value);
                    }}
                    options={
                      [
                        noRepeat,
                        ...Object.keys(SchedulePeriod).map((key) => ({
                          value: key,
                          label: key.toLowerCase().replace('_', ' '),
                        })),
                      ]
                    }
                    styles={{
                      option: (baseStyle, state) => ({
                        ...baseStyle,
                        color: state.isSelected ? colors.fiitBlue : colors.white,
                        backgroundColor: state.isFocused ? colors.janosGrey : colors.black,
                      }),
                    }}
                  />
                </RepeatScheduleRow>
                {isRepeating && (
                  <RepeatScheduleRow>
                    <Typography>Repeat until:&nbsp;</Typography>
                    <DateTimePicker
                      value={endsAt}
                      initialValue={endsAt}
                      onChange={(date: Moment): void => setEndsAt(endOfDay(date.toDate()) || endOfDay(new Date()))}
                    />
                  </RepeatScheduleRow>
                )}
              </RepeatScheduleArea>
              <RadioWrapper>
                {supportsLive && (
                  <RadioField
                    name="playback-type"
                    label="Live"
                    value="live"
                    description="In-person class, lead by trainer at the gym"
                    checked={playbackType === LessonInstancePlaybackType.LIVE}
                    icon={<LiveIcon />}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onRadioSelected(event)}
                  />
                )}
                <RadioField
                  name="playback-type"
                  label="Virtual"
                  value="virtual"
                  description="Show pre-recorded class on screen"
                  checked={playbackType === LessonInstancePlaybackType.VIRTUAL}
                  icon={<VirtualIcon />}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onRadioSelected(event)}
                />
              </RadioWrapper>
              <ToggleOptionsArea>
                <CheckboxField
                  label="Make class public"
                  onChange={(checked) => setIsPublic(checked)}
                  checked={isPublic}
                />
                <CheckboxField
                  label="Show the leaderboard"
                  onChange={(checked) => setIsLeaderboardOn(checked)}
                  checked={isLeaderboardOn}
                />
              </ToggleOptionsArea>
              <ButtonWrapper>
                <ActionButton
                  onClick={() => onSubmit()}
                  label="Schedule"
                  variant="cta"
                  autofocus
                  icon={isLoading && <Spinner />}
                />
              </ButtonWrapper>
            </>
          )
        }
      </ContentWrapper>
    </FullPageModal>
  );
};

export default ScheduleModal;
