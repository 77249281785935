import { NetworkStatusActionTypes } from 'actions/network-status';
import createReducer from 'utils/create-reducer';

export type NetworkState = {
  isOnline: boolean,
};

const setNetworkStatus = (_state: boolean, payload: { isOnline: boolean }) => payload;

const handlers = {
  [NetworkStatusActionTypes.SET_NETWORK_STATUS]: setNetworkStatus,
};

export const initialNetworkStatusState = { isOnline: true };

export const networkStatusReducer = createReducer(handlers);
