import React, { Dispatch, SetStateAction, useState, ElementType } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import useLogger from 'app/hooks/use-logger';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import useRoutes from 'utils/use-routes';

import {
  Filter as FILTER,
  GetFilterOptions as GET_FILTER_OPTIONS,
} from 'app/in-studio/pages/filter/filter.gql';
import {
  StudioType,
  EquipmentOrder,
  LessonDurationRange,
  LessonDifficulty,
  MuscleGroup,
  FilterQuery,
  FilterQueryVariables,
  GetFilterOptionsQuery,
  GetFilterOptionsQueryVariables,
  StudioOrder,
  LessonOrder,
  UserFitnessGoal,
} from 'app/in-studio/types/graphql';

import Button from 'ui/components/atoms/button';
import Typography from 'ui/components/atoms/typography';
import ArrowRight from 'ui/components/atoms/icons/arrow-right';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import WatchIcon from 'ui/components/atoms/icons/watch';
import ScaleIcon from 'ui/components/atoms/icons/scale';
import StudioIcon from 'ui/components/atoms/icons/studio';
import BodyIcon from 'ui/components/atoms/icons/body';
import MovingBodyIcon from 'ui/components/atoms/icons/moving-body';
import EquipmentIcon from 'ui/components/atoms/icons/weight';

import { labelFromLessonDuration } from 'ui/components/atoms/class-length';

const Wrapper = styled.div`
  margin: 0 2rem 2rem;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const FilterButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
`;

const FilterButton = styled(Button)`
  margin: 0.5rem;
`;

const SubmitButton = styled(Button)<{ isDisabled: boolean }>`
  ${({ isDisabled }) => `opacity: ${isDisabled ? '0.3' : '1'};`}

  &:focus {
    ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}
  }
`;

const ClearAllButton = styled(Button)`
  opacity: 0.5;
`;

const FilterButtonWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const PageTitle = styled(Typography)`
  margin-bottom: 2rem;
`;

const FilterTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
`;

const FilterRowIcon = styled.div`
  margin-right: 0.5rem;
`;

const getEnumLabel = (val: string, isDurationRange: boolean = false) => {
  if (isDurationRange) {
    return labelFromLessonDuration(val);
  }

  const parsed = val.split('_').join(' ');
  const first = parsed.slice(0, 1);
  const rest = parsed.slice(1, parsed.length).toLowerCase();
  return `${first}${rest}`;
};

export type FilterValue = (
  Partial<StudioType> |
  Partial<StudioType> |
  Partial<LessonDurationRange> |
  Partial<LessonDifficulty> |
  Partial<MuscleGroup> |
  number |
  string
);

type FilterState = (
  StudioType |
  LessonDurationRange |
  LessonDifficulty |
  UserFitnessGoal |
  string |
  number
);

type FiltersArray = Array<{
  icon: ElementType,
  title: string,
  filters: Array<{
    selectFilter: () => void,
    label: string,
    selected: boolean,
    value: FilterValue,
  }>
}>;

type PageContentProps = {
  lessonCount?: number,
  loadingLessonCount: boolean,
  bodyPartFilters: Array<{ id: number, displayName: string }>,
  equipmentFilters: Array<{ id: string, shortDisplay: string }>,
  trainingGoalFilters: Array<{ id: UserFitnessGoal, displayName: string }>,
  studioFilters: StudioType[],
  setSelectedStudios: Dispatch<SetStateAction<StudioType[]>>,
  setSelectedDurations: Dispatch<SetStateAction<LessonDurationRange[]>>,
  setSelectedDifficulties: Dispatch<SetStateAction<LessonDifficulty[]>>,
  setSelectedBodyPartIds: Dispatch<SetStateAction<number[]>>,
  setSelectedEquipmentIds: Dispatch<SetStateAction<string[]>>,
  setSelectedTrainingGoals: Dispatch<SetStateAction<UserFitnessGoal[]>>,
  selectedStudios: StudioType[],
  selectedDurations: LessonDurationRange[],
  selectedDifficulties: LessonDifficulty[],
  selectedTrainingGoals: UserFitnessGoal[],
  selectedBodyPartIds: number[],
  selectedEquipmentIds: string[],
  showLessonCount: boolean,
};

const PageContent = ({
  lessonCount,
  loadingLessonCount,
  equipmentFilters,
  studioFilters,
  trainingGoalFilters,
  bodyPartFilters,
  setSelectedStudios,
  setSelectedTrainingGoals,
  setSelectedDurations,
  setSelectedDifficulties,
  setSelectedEquipmentIds,
  setSelectedBodyPartIds,
  selectedStudios,
  selectedBodyPartIds,
  selectedEquipmentIds,
  selectedTrainingGoals,
  selectedDurations,
  selectedDifficulties,
  showLessonCount,
} : PageContentProps) => {
  const { routes, push } = useRoutes();
  const logger = useLogger('in-studio:filter-page:content');

  const submitSearch = () => {
    push({
      route: routes.LESSONS,
      params: { lessonOrder: LessonOrder.WORKOUTS_COMPLETED },
      queryParams: {
        fromFilters: true,
        ...(selectedEquipmentIds.length ? { equipmentId: selectedEquipmentIds } : {}),
        ...(selectedStudios.length ? { studioId: selectedStudios } : {}),
        ...(selectedDurations.length ? { durationRange: selectedDurations } : {}),
        ...(selectedDifficulties.length ? { difficulty: selectedDifficulties } : {}),
        ...(selectedTrainingGoals.length ? { trainingGoalId: selectedTrainingGoals } : {}),
        ...(selectedBodyPartIds.length ? { bodyPartId: selectedBodyPartIds } : {}),
      },
    });
  };

  const toggleFilter = (prev: FilterState[], filterValue: any) => {
    if (prev.includes(filterValue)) {
      return prev.filter((value) => value !== filterValue);
    }
    return [...prev, filterValue];
  };

  const handleFilterClick = (filterValue: FilterValue, condition: string) => {
    switch (condition) {
      case 'studio':
        return setSelectedStudios((prev) => toggleFilter(prev, filterValue));
      case 'duration':
        return setSelectedDurations((prev) => toggleFilter(prev, filterValue));
      case 'difficulty':
        return setSelectedDifficulties((prev) => toggleFilter(prev, filterValue));
      case 'body-part':
        return setSelectedBodyPartIds((prev) => toggleFilter(prev, filterValue));
      case 'equipment':
        return setSelectedEquipmentIds((prev) => toggleFilter(prev, filterValue));
      case 'training-goal':
        return setSelectedTrainingGoals((prev) => toggleFilter(prev, filterValue));
      default:
        logger.error('Invalid condition passed to handleFilterClick', { condition });
        return <ErrorOverlay error onDismiss="back" />;
    }
  };

  const clearAllFilters = () => {
    setSelectedStudios([]);
    setSelectedDurations([]);
    setSelectedDifficulties([]);
    setSelectedBodyPartIds([]);
    setSelectedEquipmentIds([]);
    setSelectedTrainingGoals([]);
  };

  const filters: FiltersArray = [
    {
      icon: WatchIcon,
      title: 'Duration',
      filters: [
        ...Object.values(LessonDurationRange).map((value) => ({
          selectFilter: () => handleFilterClick(value, 'duration'),
          label: getEnumLabel(value, true),
          selected: selectedDurations.includes(value),
          value,
        })),
      ],
    },
    {
      icon: StudioIcon,
      title: 'Studio',
      filters: [
        ...studioFilters.map((value) => ({
          selectFilter: () => handleFilterClick(value, 'studio'),
          label: getEnumLabel(value),
          selected: selectedStudios.includes(value),
          value,
        })),
      ],
    },
    {
      icon: ScaleIcon,
      title: 'Class level',
      filters: [
        ...Object.values(LessonDifficulty).map((value) => ({
          selectFilter: () => handleFilterClick(value, 'difficulty'),
          label: getEnumLabel(value),
          selected: selectedDifficulties.includes(value),
          value,
        })),
      ],
    },
    {
      icon: BodyIcon,
      title: 'Target body part',
      filters: [
        ...bodyPartFilters.map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'body-part'),
          label: getEnumLabel(displayName),
          selected: selectedBodyPartIds.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: EquipmentIcon,
      title: 'Equipment',
      filters: [
        ...equipmentFilters.map(({ id, shortDisplay }) => ({
          selectFilter: () => handleFilterClick(id, 'equipment'),
          label: getEnumLabel(shortDisplay),
          selected: selectedEquipmentIds.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: MovingBodyIcon,
      title: 'Best for',
      filters: [
        ...trainingGoalFilters.map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'training-goal'),
          label: getEnumLabel(displayName),
          selected: selectedTrainingGoals.includes(id),
          value: id,
        })),
      ],
    },
  ];

  const classesLabelCount = `(${loadingLessonCount ? '...' : lessonCount})`;
  const isDisabled = lessonCount === 0;
  return (
    <Wrapper>
      <PageTitle color="whiteOpaque" variant="pica">Filters</PageTitle>
      {filters.map(({ title, filters: rowFilters, icon }, filterRowIndex) => (
        <FilterSection key={title}>
          <FilterTitleContainer>
            <FilterRowIcon color="white" as={icon} />
            <Typography variant="double-pica" size="m">{title}</Typography>
          </FilterTitleContainer>
          <FilterButtonContainer data-test={`filter-buttons-${title.split(' ').join('-').toLowerCase()}`}>
            {rowFilters.map(({ label, selected, selectFilter, value }, i: number) => (
              <FilterButton
                key={value}
                label={label}
                autofocus={filterRowIndex === 0 && i === 0}
                onClick={selectFilter}
                selected={selected}
              />
            ))}
          </FilterButtonContainer>
        </FilterSection>
      ))}
      <FilterButtonWrapper>
        <ClearAllButton
          label="Clear all"
          onClick={clearAllFilters}
        />
        <SubmitButton
          label={`Show classes ${showLessonCount ? classesLabelCount : ''}`}
          icon={<ArrowRight color="white" />}
          isDisabled={isDisabled}
          onClick={isDisabled ? () => {} : submitSearch}
        />
      </FilterButtonWrapper>
    </Wrapper>
  );
};

const FilterPage = () => {
  const [selectedStudios, setSelectedStudios] = useState<StudioType[]>([]);
  const [selectedDurations, setSelectedDurations] = useState<LessonDurationRange[]>([]);
  const [selectedDifficulties, setSelectedDifficulties] = useState<LessonDifficulty[]>([]);
  const [selectedBodyPartIds, setSelectedBodyPartIds] = useState<number[]>([]);
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<string[]>([]);
  const [selectedTrainingGoals, setSelectedTrainingGoals] = useState<UserFitnessGoal[]>([]);
  const logger = useLogger('in-studio:filter-page');

  useDismissEvent();

  const { data, loading, error } = useQuery<FilterQuery, FilterQueryVariables>(FILTER, {
    variables: {
      lessonsMaxLength: 100,
      lessonCondition: {
        ...(selectedStudios.length ? { studioId: selectedStudios } : {}),
        ...(selectedDurations.length ? { durationRange: selectedDurations } : {}),
        ...(selectedDifficulties.length ? { difficulty: selectedDifficulties } : {}),
        ...(selectedBodyPartIds.length ? { bodyPartId: selectedBodyPartIds } : {}),
        ...(selectedEquipmentIds.length ? { equipmentId: selectedEquipmentIds } : {}),
        ...(selectedTrainingGoals.length ? { trainingGoalId: selectedTrainingGoals } : {}),
      },
    },
    onError: (e) => logger.error('FilterQuery error', { error: e }),
  });

  const {
    data: filterOptionsData,
    loading: filterOptionsLoading,
    error: filterOptionsError,
  } = useQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GET_FILTER_OPTIONS, {
    variables: {
      studioCount: 10,
      studioOrderBy: StudioOrder.NATURAL,
      equipmentCount: 10,
      equipmentOrderBy: EquipmentOrder.VALUE,
    },
    onError: (e) => logger.error('GetFilterOptionsQuery graphQL error', { error: e }),
  });

  // We only want to show a loading screen when we don't have all studios, no need to show when
  // getting the updated lesson count
  if (filterOptionsLoading) {
    return <LoadingScreen />;
  }

  if (error || filterOptionsError || !filterOptionsData) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const {
    allStudios,
    allBodyParts,
    allEquipment,
    allTrainingGoals,
  } = filterOptionsData;
  const studioFilters = allStudios?.edges?.map(({ node }) => node.id) || [];
  const equipmentFilters = allEquipment?.edges?.map(({ node: { id, shortDisplay } }) => ({ id, shortDisplay })) || [];
  const showLessonCount = Boolean(
    selectedTrainingGoals.length ||
    selectedEquipmentIds.length ||
    selectedBodyPartIds.length ||
    selectedDifficulties.length ||
    selectedDurations.length ||
    selectedStudios.length,
  );

  return (
    <PageContent
      lessonCount={Math.min(data?.allLessons.totalCount || 0, 100)}
      loadingLessonCount={loading}
      showLessonCount={showLessonCount}
      studioFilters={studioFilters}
      trainingGoalFilters={allTrainingGoals}
      equipmentFilters={equipmentFilters}
      bodyPartFilters={allBodyParts}
      setSelectedTrainingGoals={setSelectedTrainingGoals}
      setSelectedEquipmentIds={setSelectedEquipmentIds}
      setSelectedStudios={setSelectedStudios}
      setSelectedDurations={setSelectedDurations}
      setSelectedDifficulties={setSelectedDifficulties}
      setSelectedBodyPartIds={setSelectedBodyPartIds}
      selectedStudios={selectedStudios}
      selectedTrainingGoals={selectedTrainingGoals}
      selectedEquipmentIds={selectedEquipmentIds}
      selectedDurations={selectedDurations}
      selectedDifficulties={selectedDifficulties}
      selectedBodyPartIds={selectedBodyPartIds}
    />
  );
};

FilterPage.menu = true;
export default FilterPage;
