import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Typography, { TypographyVariant } from 'ui/components/atoms/typography';
import StatCounterLabel from 'ui/components/molecules/stat-counter-label';

export type Props = {
  className?: string,
  value?: number | null,
  label: string,
  icon: ReactNode,
  disabled?: boolean,
  fontVariant?: TypographyVariant,
  labelVariant?: TypographyVariant,
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

// TODO width is actually 2 * typography.paragon.fontSize really?
// Is 4 characters wide basically
const Value = styled(Typography)`
  text-align: right;
  min-width: 2rem;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -0.3rem; /* Single line only, so making it's content box fit it's line height. */
  margin-right: ${({ theme }) => theme.spacing.xs};
`;

export const StatCounter = ({
  className,
  value,
  icon,
  label,
  labelVariant,
  disabled,
  fontVariant = 'paragon',
}: Props) => (
  <Wrapper className={className}>
    <Value
      variant={fontVariant}
      weight="bold"
      color={disabled || value == null ? 'wednesdayGrey' : undefined}
      data-test={`class-metric-${label}`}
    >
      { value == null ? '-' : value }
    </Value>
    <StatCounterLabel label={label} icon={icon} labelVariant={labelVariant} />
  </Wrapper>
);

export default StatCounter;
