import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Button from 'ui/components/atoms/button';
import Typography from 'ui/components/atoms/typography';
import { SpatialNavParent } from 'utils/spatial-nav';

import { rem } from 'ui/helpers';
import { ThemeColors } from 'ui/theme';

type IconProps = {
  className?: string,
  color?: ThemeColors,
};

export type Props = {
  icon?: ({ className, color }: IconProps) => JSX.Element,
  transparent?: boolean,
  question: string,
  optionOne: string,
  optionOneClick?: (event: SyntheticEvent) => void,
  optionTwo: string,
  optionTwoClick?: (event: SyntheticEvent) => void,
  autofocusButton?: 'one' | 'two',
  description?: string,
  useSetWidthButton?: boolean,
};

const Buttons = styled.div`
  margin: ${rem(35)} auto;
  max-width: 70%;
  display: flex;
  justify-content: center;
`;

const NestedWrapper = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)<{ useSetWidthButton?: boolean }>`
  ${({ useSetWidthButton }) => (useSetWidthButton ? `width: ${rem(200)}` : '')};
  margin: 0 ${rem(22)};
  display: inline-block;
  flex: 1;
`;

const Icon = styled.div`
  border-radius: ${rem(1000)};
  border: 2px solid ${({ theme }) => theme.colors.white};
  padding: ${rem(36)};
  width: ${rem(100)} !important;
  height: ${rem(100)} !important;
  margin-bottom: ${rem(50)};
`;

const Description = styled(Typography)`
  max-width: 70%;
  margin: auto;
  padding-top: 1rem;
`;

const FullPageTwoAnswer = ({
  icon,
  transparent = false,
  question,
  optionOne,
  optionOneClick,
  autofocusButton,
  optionTwo,
  optionTwoClick,
  description,
  useSetWidthButton = true,
}: Props) => (
  <FullPageOverlay transparent={transparent}>
    <SpatialNavParent layout="horizontal">
      <NestedWrapper>
        {icon && <Icon as={icon} />}
        <Typography as="p" variant="double-pica">{question}</Typography>
        {description && (
          <Description as="p" variant="body-copy">{description}</Description>
        )}
        <Buttons>
          <StyledButton
            useSetWidthButton={useSetWidthButton}
            label={optionOne}
            onClick={optionOneClick}
            autofocus={autofocusButton === 'one'}
          />
          <StyledButton
            useSetWidthButton={useSetWidthButton}
            label={optionTwo}
            onClick={optionTwoClick}
            autofocus={autofocusButton === 'two'}
          />
        </Buttons>
      </NestedWrapper>
    </SpatialNavParent>
  </FullPageOverlay>
);

export default FullPageTwoAnswer;
