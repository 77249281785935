
import React, { ReactElement, RefObject, useRef } from 'react';

import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import { useSpatialTarget } from 'utils/spatial-nav';
import useArrowScrolling from 'app/on-tv/utils/arrow-scrolling';

export type Props = {
  children: string | ReactElement,
  innerRef?: RefObject<any>,
  className?: string,
  focused?: boolean,
  rightHandScrollbar?: boolean,
  fadeText?: boolean,
};

const StyledScrollable = styled.div<{focused: boolean, rightHandScrollbar: boolean, fadeText: boolean }>`
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-left: -${rem(19)};
  white-space: break-spaces;

  &::-webkit-scrollbar {
    display: block;
    width: ${rem(6)};
    background-color: ${({ theme }) => theme.colors.janosGrey};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ focused, theme }) => (focused ? theme.colors.fiitBlue : theme.colors.white)};
    border-radius: 8px;
  }

  ${({ rightHandScrollbar }) => (rightHandScrollbar ? '' : 'transform: scaleX(-1)')};
  ${({ fadeText }) => (fadeText ? `
    &::before {
      content:"";
      width: 98%;
      height: 100%;
      position: absolute;
      background: linear-gradient(to bottom, transparent 80%, black 100%);
    }
  ` : '')}
`;

const ContentDiv = styled.div<{ rightHandScrollbar: boolean }>`
  ${({ rightHandScrollbar }) => (rightHandScrollbar ? '' : 'transform: scaleX(-1)')};
  padding-left: ${rem(15)};
`;

const ScrollableText = ({ children, innerRef, className, rightHandScrollbar = false, fadeText = false }: Props) => {
  const elRef = useRef<HTMLDivElement>();
  const ref = innerRef || elRef;
  const { onKeyDown, scrollable } = useArrowScrolling(elRef);
  const { focused } = useSpatialTarget({
    elRef: ref,
    disabled: !scrollable,
  });
  const tabIndex = scrollable ? 0 : -1;

  return (
    <StyledScrollable
      className={className}
      ref={ref}
      focused={focused}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      rightHandScrollbar={rightHandScrollbar}
      fadeText={fadeText}
    >
      <ContentDiv rightHandScrollbar={rightHandScrollbar}>
        <Typography variant="body-copy">{children}</Typography>
      </ContentDiv>
    </StyledScrollable>
  );
};

export default ScrollableText;
