import { isSupported, CookieStorage } from 'local-storage-fallback';

export const hasLocalStorage = isSupported('localStorage');

const getStorageOption = () => {
  if (hasLocalStorage) {
    return window.localStorage;
  }

  // if we're using cookies we set the expiry date to be 10 years in the future to so they persist
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 10);

  return new CookieStorage({ expires });
};

const storage = getStorageOption();

export default storage;
