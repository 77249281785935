/* eslint-disable consistent-return */
import React, { useEffect } from 'react';

import useConfig from 'config';
import IdleOrErrorLayout from 'ui/components/layouts/idle-or-error';

export type Props = {
  redirectUrl?: string,
  redirect?: boolean,
};

const IdleOrError = ({ redirectUrl = '/upcoming', redirect = true }: Props) => {
  const { config } = useConfig();

  useEffect(() => {
    if (redirect) {
      const timeout = setTimeout(() => {
        window.location.href = redirectUrl;
      }, config.IDLE_OR_ERROR_DISPLAY_SECONDS * 1000);

      return () => clearTimeout(timeout);
    }
  }, [config.IDLE_OR_ERROR_DISPLAY_SECONDS, redirect, redirectUrl]);

  return (
    <IdleOrErrorLayout />
  );
};

export default IdleOrError;
