import React, { useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import { useSpatialTarget } from 'utils/spatial-nav';
import { Link } from 'react-router-dom';
import CardFocus from 'ui/components/atoms/card-focus';
import CardBadge from 'ui/components/atoms/card-badge';
import Padlock from 'ui/components/atoms/icons/padlock';

export type Props = {
  backgroundImage: string,
  className?: string,
  title: string,
  description: string,
  autofocus?: boolean,
  to?: string,
  locked?: boolean | null,
  comingSoon?: boolean,
};

const Wrapper = styled.div<{ locked?: boolean | null, url: string, lowOpacity?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: baseline;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${rem(8)};
  padding: ${({ theme }) => theme.spacing.m};
  position: relative;
  height: ${rem(200)};
  max-height: ${rem(200)};
  ${({ locked, url }) => {
    if (locked) {
      return `background:
      linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.40) 0%,
        rgba(0, 0, 0, 0.40) 100%
      ),
      url(${url}),
      lightgray 50%`;
    }
    return `background-image: url(${url})`;
  }};
  background-position: center;
  ${({ lowOpacity }) => lowOpacity && 'opacity: 0.5'};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem(420)};
  white-space: normal;
`;

const CardLink = styled(Link)`
  width: 100%;
  display: flex;
`;

const CardDiv = styled.div`
  width: 100%;
  display: flex;
`;

const StyledCardFocus = styled(CardFocus)<{ focus: boolean }>`
  border: 1px solid ${({ focus, theme }) => (focus ? theme.colors.black : theme.colors.fordDarkCharcoal)};
  margin-right: ${rem(24)};
`;

const Title = styled(Typography)<{ lowOpacity?: boolean }>`
  ${({ lowOpacity }) => lowOpacity && 'opacity: 0.8'};
`;

const Description = styled(Typography)`
  margin-top: ${rem(8)};
  opacity: 0.8;
`;

const LockedContainer = styled(Typography)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

const StyledPadlock = styled(Padlock)`
  width: 1rem;
`;

const HeroCard = ({
  backgroundImage,
  className,
  title,
  description,
  autofocus = false,
  to,
  locked,
  comingSoon,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
  });

  const Card = (
    <StyledCardFocus box focus={focused} boxSize="large" data-test={`hero-card-${title}`}>
      <Wrapper
        className={className}
        ref={elRef}
        url={backgroundImage}
        locked={locked}
        lowOpacity={comingSoon}
      >
        <Content>
          <Title variant="double-pica" color="white" lowOpacity={comingSoon}>
            {title}
          </Title>
          <Description variant="body-copy" color="white">
            {description}
          </Description>
          {locked && (
            <LockedContainer>
              <CardBadge outline={false}>
                <StyledPadlock />
              </CardBadge>
            </LockedContainer>
          )}
        </Content>
      </Wrapper>
    </StyledCardFocus>
  );

  if (!to) {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <CardDiv tabIndex={0} ref={elRef}>
        {Card}
      </CardDiv>
    );
  }

  return (
    <CardLink ref={elRef} to={to} data-test="card-link">
      { Card }
    </CardLink>
  );
};

export default HeroCard;
