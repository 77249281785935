import React, { useEffect, useState } from 'react';
import * as dashjs from 'dashjs';
import styled from 'styled-components';
import useHandleVideoPlayback from 'ui/components/atoms/video-player/useHandleVideoPlayback';
import { VideoPlayerProps } from 'ui/components/atoms/video-player';
import useConfig from 'config';

const StyledVideo = styled.video<{isFullHeight?: boolean}>`
  ${({ isFullHeight }) => (isFullHeight ? 'height: 100%' : 'width: 100%')};
`;

type Props = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>,
  dashUrl: string | null,
  className: string,
};

const DashJsPlayer = ({
  src,
  videoRef,
  controls = false,
  muted = false,
  playing = true,
  seek,
  seekTolerance = 3,
  seekInterval = 2000,
  playbackSpeed = 1,
  onVideoEnd,
  onTimeChange,
  onTimeChangeInterval = 1000,
  onPlayFailed,
  onBuffering,
  monitor = false,
  monitorMeta = {},
  className = '',
  loop = false,
  isFullHeight,
}: VideoPlayerProps & { videoRef: React.MutableRefObject<HTMLVideoElement | null> }) => {
  const [urlLoaded, setUrlLoaded] = useState<string | null>(null);
  const { config } = useConfig();

  useEffect(() => {
    const video = videoRef.current;
    const { dashUrl } = src;

    if (!video || !dashUrl) {
      return () => {};
    }

    const dashPlayer = dashjs.MediaPlayer().create();

    dashPlayer.initialize();

    dashPlayer.updateSettings({
      streaming: {
        bufferToKeep: config.VIDEO_TRAILING_BUFFER_SECONDS,
        bufferTimeAtTopQuality: config.VIDEO_MAXIMUM_LEADING_BUFFER_SECONDS,
        // Time in seconds for a gap to be considered small. By default the player
        // jumps small gaps
        smallGapLimit: config.VIDEO_STALL_SKIP_SECONDS,
      },
    });

    dashPlayer.attachView(video);
    dashPlayer.attachSource(dashUrl);

    const loadedUrl = () => setUrlLoaded(dashUrl);
    dashPlayer.on(dashjs.MediaPlayer.events.CAN_PLAY, loadedUrl);

    return () => {
      dashPlayer.off(dashjs.MediaPlayer.events.CAN_PLAY, loadedUrl);
      dashPlayer.destroy();
    };
  }, [
    config.VIDEO_MAXIMUM_LEADING_BUFFER_SECONDS,
    config.VIDEO_STALL_SKIP_SECONDS,
    config.VIDEO_TRAILING_BUFFER_SECONDS,
    src,
    videoRef,
  ]);

  useHandleVideoPlayback(
    src,
    videoRef,
    playing,
    seekTolerance,
    seekInterval,
    onTimeChangeInterval,
    urlLoaded,
    StyledVideo.styledComponentId,
    monitor,
    monitorMeta,
    playbackSpeed,
    loop,
    onVideoEnd,
    onPlayFailed,
    onBuffering,
    onTimeChange,
    seek,
  );

  return (
    <StyledVideo
      ref={videoRef}
      className={className}
      controls={controls}
      muted={muted}
      isFullHeight={isFullHeight}
    />
  );
};

export default DashJsPlayer;
