import React, { Component } from 'react';
import { createLogger } from 'utils/logging';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

type State = {
  error?: Error;
};

const logger = createLogger({ label: 'app-render' });

type Props = {
  children: React.ReactNode,
  errorText?: string,
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    logger.error(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorOverlay
          error={this.state.error}
          message={this.props.errorText || 'Something went wrong.'}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
