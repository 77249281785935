import React from 'react';
import styled from 'styled-components';

import UIPanel from 'ui/components/atoms/ui-panel';
import Typography from 'ui/components/atoms/typography';
import Time from 'ui/components/atoms/time';

export type Props = {
  className?: string,
  currentTime: number,
  duration: number,
  name: string,
  disabled?: boolean,
};

const Wrapper = styled(UIPanel)`
  display: flex;
  justify-content: space-between;
`;

const TimeSpan = styled(Typography)`
  display: inline-block;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  margin-right: ${({ theme }) => theme.spacing.xs};
  text-align: right;
`;

// Lazy version - we truncate the text and only show it on one line
// Ios has some logic to measure how long it'll be and reduce the font size to fit, and then wrap etc.
// Our initial requirement is gym group i.e. we control the screen size. When we do big fiit this needs solving
const Exercise = styled(Typography)`
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
`;

export const ExerciseDetails = ({ className, currentTime = 0, duration, name, disabled }: Props) => (
  <Wrapper className={className}>
    <TimeSpan
      variant="double-pica"
      color={disabled ? 'wednesdayGrey' : undefined}
      weight="medium"
      lineHeight="m"
    >
      <Time time={duration - currentTime} />
    </TimeSpan>
    <Exercise
      variant="double-pica"
      weight="medium"
      lineHeight="m"
    >
      { name || 'Unknown exercise' }
    </Exercise>

  </Wrapper>
);

export default ExerciseDetails;
