import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

function SvgTick({ className = '' }: IconProps) {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <defs>
        <path
          id="tick_svg__a"
          d="M14 2l-8.614 8-.002-.001V10L0 5l2.153-2 3.231 3 6.463-6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(5 8)">
        <mask id="tick_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#tick_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#tick_svg__a" />
        <g fill={theme.colors.white} mask="url(#tick_svg__b)">
          <path d="M-5-8h24v24H-5z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgTick;
