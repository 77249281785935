import React, { useRef, ReactNode, Dispatch, SetStateAction } from 'react';

import styled from 'styled-components';
import { useSpatialTarget } from 'utils/spatial-nav';

export type CommonProps = {
  id: string,
  onFocus: Dispatch<SetStateAction<string>>,
  className?: string,
  isBreakSection?: boolean,
};

type BreakdownSectionRowProps = CommonProps & {
  children: ReactNode[],
};

const WorkoutSectionContainer = styled.button<{focused: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;

  &:focus {
    background-color: ${({ theme }) => theme.colors.fiitBlue};
    transition: all 0.2s;
  }
`;

const BreakdownSectionRow = ({
  id,
  isBreakSection = false,
  onFocus,
  children,
  className = '',
}: BreakdownSectionRowProps) => {
  const elRef = useRef<HTMLButtonElement | null>(null);

  const { focused } = useSpatialTarget({
    elRef,
    disabled: isBreakSection,
    onFocus: () => {
      onFocus(id);
      if (elRef.current) {
        elRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    },
  });

  return (
    <WorkoutSectionContainer ref={elRef} className={className} focused={focused}>
      {children}
    </WorkoutSectionContainer>
  );
};

export default BreakdownSectionRow;
