// TODO help type this!?
function createReducer<State>(
  handlers: any,
) {
  return (state: State, action: any) => {
    const handler = handlers[action.type];
    return handler ? handler(state, action.payload) : state;
  };
}

export default createReducer;
