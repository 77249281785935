import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgRanking = ({ className = '', color } : IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : theme.colors.white;
  return (
    <Svg className={className} x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
      <path d="M9 2H15V22H9V2Z" fill={fill} />
      <path d="M2 7H8V22H2V7Z" fill={fill} />
      <path d="M22 11H16V22H22V11Z" fill={fill} />
    </Svg>
  );
};

export default SvgRanking;
