import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import {
  FavouriteLessonMutation,
  FavouriteLessonMutationVariables,
  UnfavouriteLessonMutation,
  UnfavouriteLessonMutationVariables,
} from 'app/on-tv/types/graphql';
import { FavouriteLesson as FAVOURITE_MUTATION } from 'app/on-tv/pages/lesson/favourite-lesson.gql';
import { UnfavouriteLesson as UNFAVOURITE_MUTATION } from 'app/on-tv/pages/lesson/unfavourite-lesson.gql';
import Button from 'ui/components/atoms/button';
import FavouriteOutline from 'ui/components/atoms/icons/favourite-outline';
import Favourite from 'ui/components/atoms/icons/favourite';
import { analytics } from 'utils/analytics';

type Props = {
  userId: number,
  lessonId: number,
  favourited: boolean,
  showLabel?: boolean,
  autofocus?: boolean,
  shouldUpdateIconColor?: boolean,
};

const FavouriteButton = ({
  userId,
  lessonId,
  favourited,
  showLabel = false,
  autofocus,
  shouldUpdateIconColor,
} : Props) => {
  const favouriteMutationArgs = {
    variables: {
      input: {
        user: { id: userId },
        lessonId,
      },
    },
    update: (cache: any) => {
      cache.writeFragment({
        id: `Lesson:${lessonId}`,
        fragment: gql`
          fragment UpdateFavourite on Lesson {
            __typename
            id
            favouritedByUser(
              user: {
                id: $userId
              },
            )
          }
        `,
        data: {
          __typename: 'Lesson',
          id: lessonId,
          favouritedByUser: !favourited,
        },
        variables: {
          userId,
        },
      });
    },
  };

  const [favouriteLesson] = useMutation<FavouriteLessonMutation, FavouriteLessonMutationVariables>(
    FAVOURITE_MUTATION,
    {
      ...favouriteMutationArgs,
      onCompleted: () => analytics.track('FavouriteLesson', { lessonId }),
    },
  );

  const [unFavouriteLesson] = useMutation<UnfavouriteLessonMutation, UnfavouriteLessonMutationVariables>(
    UNFAVOURITE_MUTATION,
    { ...favouriteMutationArgs },
  );

  const toggleFavouriteLesson = () => {
    if (favourited) {
      return unFavouriteLesson();
    }
    return favouriteLesson();
  };

  const label = showLabel && (favourited ? 'Remove from favourites' : 'Add to favourites');

  return (
    <Button
      icon={favourited ? <Favourite /> : <FavouriteOutline />}
      iconOnly={!showLabel}
      onClick={toggleFavouriteLesson}
      shouldUpdateIconColor={shouldUpdateIconColor}
      selected={favourited && shouldUpdateIconColor}
      label={label || undefined}
      autofocus={autofocus}
    />
  );
};

export default FavouriteButton;
