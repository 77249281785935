import { useCallback } from 'react';
import { useDispatch } from 'state';
import { LessonInstanceByIdQuery, LessonInstancePlaybackType } from 'app/in-studio/types/graphql';
import { endStream } from 'actions/video';

type EndVideoProps = {
  lessonInstance: LessonInstance,
  onEnd: () => void,
};

type LessonInstance = LessonInstanceByIdQuery['lessonInstanceById'];

const useSessionEnd = ({ lessonInstance, onEnd }: EndVideoProps) => {
  const dispatch = useDispatch();
  return (
    useCallback(
      () => {
        if (lessonInstance.playbackType === LessonInstancePlaybackType.LIVE) {
          onEnd();
        }

        // Virtual - in theory, we have a video
        if (!lessonInstance?.lesson.defaultMediaOption) {
          return;
        }

        // Must run before dispatch, as video is umounted then.
        onEnd();

        dispatch(
          endStream({
            lessonMediaId: lessonInstance.lesson.defaultMediaOption.id,
          }),
        );
      },
      [dispatch, lessonInstance, onEnd],
    ));
};

export default useSessionEnd;
