import camelify from 'utils/camelify';
import { createLogger } from 'utils/logging';

const logger = createLogger({ label: 'fetch' });

type FetchResponse = {
  body: any,
  status: number,
  headers: Headers,
};

const FAILED_LOGIN_ATTEMPT_ERROR_MESSAGES = ['invalid_grant', 'invalid_request'];

// Thrown when a request comes back that is not 20X to allow code checks
export class RequestError extends Error {
  public status: number;

  public body: any;

  constructor(message: any, status = 500, body: any) {
    super(message);
    this.status = status;
    this.body = body || null;
  }
}

const getBody = async (response: any) => {
  const { status, headers } = response;

  if (status === 204) {
    return null;
  }

  const contentType = headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }

  return response.text();
};

const wrappedFetch = async (url: string, init?: any): Promise<FetchResponse> => {
  const response = await fetch(url, init);

  const responseBody = await getBody(response);
  const { status, headers } = response;

  // Response was non successy - throw an error instead
  if (!response.ok) {
    const bodyMessage = responseBody ? responseBody.error || responseBody.message || responseBody : null;
    const error = new RequestError(bodyMessage || 'Unknown Error', status, responseBody);
    if (FAILED_LOGIN_ATTEMPT_ERROR_MESSAGES.includes(bodyMessage)) {
      logger.warn(error, { url });
    } else {
      logger.error(error, { url });
    }
    throw error;
  }

  return { body: camelify(responseBody), status, headers };
};

export default wrappedFetch;
