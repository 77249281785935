
import { useQuery } from '@apollo/react-hooks';
import { useAppState, useDispatch } from 'state';

import { setPlatformPartner, PlatformPartnerPermissions } from 'actions/platform-partner';

import { PlatformPartnerByUrlQuery, PlatformPartnerByUrlQueryVariables } from 'app/in-studio/types/graphql';
import { PlatformPartnerByUrl as PLATFORM_PARTNER_QUERY } from 'app/in-studio/graphql/queries/platform-partner.gql';

type PlatformPartner = {
  name: string | null,
  permissions: PlatformPartnerPermissions,
  isChecked: boolean,
  isSet: boolean,
};

const usePlatformPartner = (): PlatformPartner => {
  const platformPartner = useAppState((state) => state.platformPartner);
  const dispatch = useDispatch();

  const { data, loading } =
    useQuery<PlatformPartnerByUrlQuery, PlatformPartnerByUrlQueryVariables>(PLATFORM_PARTNER_QUERY, {
      variables: {
        url: window.location.origin,
      },
      skip: !!platformPartner.name,
    });

  const { platformPartnerByUrl } = data || {};

  if (!platformPartner.name && !loading) {
    if (platformPartnerByUrl) {
      // we've checked and got a response, and the partner exists
      dispatch(setPlatformPartner(platformPartnerByUrl));
      return { ...platformPartnerByUrl, isChecked: true, isSet: true };
    }
    // we've checked and got a response, but it doesnt exist.
    return { ...platformPartner, isChecked: true };
  }

  // if no data to be set
  return platformPartner;
};

export default usePlatformPartner;
