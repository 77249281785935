import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

type EditIconProps = IconProps & {
  onClick?: () => void;
};

const Svg = styled.svg`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const SvgEdit = ({ className = '', onClick }: EditIconProps) => (
  <Svg onClick={onClick} className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.666 5.33301H5.33268C4.62544 5.33301 3.94716 5.61396 3.44706 6.11406C2.94697 6.61415 2.66602 7.29243 2.66602 7.99967V26.6663C2.66602 27.3736 2.94697 28.0519 3.44706 28.552C3.94716 29.0521 4.62544 29.333 5.33268 29.333H23.9993C24.7066 29.333 25.3849 29.0521 25.885 28.552C26.3851 28.0519 26.666 27.3736 26.666 26.6663V17.333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24.666 3.33331C25.1964 2.80288 25.9159 2.50488 26.666 2.50488C27.4162 2.50488 28.1356 2.80288 28.666 3.33331C29.1964 3.86374 29.4944 4.58316 29.4944 5.33331C29.4944 6.08346 29.1964 6.80288 28.666 7.33331L15.9993 20L10.666 21.3333L11.9993 16L24.666 3.33331Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default SvgEdit;
