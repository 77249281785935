import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

function SvgKettlebell({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.81211 6.14382L5.37462 4.84445C6.93115 3.69855 9.06886 3.69855 10.6254 4.84445L10.1879 6.14382C9.54023 5.78773 8.79414 5.58485 8 5.58485C7.20586 5.58485 6.45977 5.78773 5.81211 6.14382ZM4.56982 7.15022L3.84937 5.01044C3.71613 4.61471 3.83817 4.1785 4.15807 3.90704C6.36886 2.03099 9.63114 2.03099 11.8419 3.90703C12.1618 4.1785 12.2839 4.61471 12.1506 5.01044L11.4302 7.15023C12.0974 7.92442 12.5 8.92739 12.5 10.0231C12.5 11.437 11.8296 12.6965 10.7853 13.5093C10.3036 13.8841 9.68272 14 9.07244 14H8H6.92756C6.31728 14 5.69636 13.8841 5.21474 13.5093C4.17037 12.6965 3.5 11.437 3.5 10.0231C3.5 8.92739 3.90258 7.92442 4.56982 7.15022Z" fill={fill} />
    </Svg>
  );
}

export default SvgKettlebell;
