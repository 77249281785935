import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgFilter = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 24 24" className={className}>
    <path
      d="M24 21.6H6V24H3.6v-2.4H0v-2.4h3.6v-2.4H6v4.8h1.2v-2.4H24v2.4zm0-8.4h-3.6v-2.4h-1.2v2.4h1.2-1.2v2.4h-2.4v-2.4H0v-2.4h16.8V8.4h2.4v2.4H24v2.4zM10.8 4.8v2.4H8.4V4.8H0V2.4h8.4V0h2.4v4.8zM24 2.4v2.4H12V2.4h12z"
    />
  </Svg>
);

export default SvgFilter;
