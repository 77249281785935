import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';
import { rem } from 'ui/helpers';

export type Props = {
  icon?: ReactNode,
  heading?: string,
  subheading?: string,
  buttonLabel?: string,
  className?: string,
  onAction?: () => void,
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Heading = styled(Typography)`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(5)};
`;

const Subheading = styled(Typography)`
  margin-bottom: ${rem(40)};
`;

const EmptyState = ({
  icon,
  heading,
  subheading,
  buttonLabel,
  className,
  onAction,
}: Props) => (
  <Wrapper className={className}>
    { icon }
    <Heading variant="pica">{ heading }</Heading>
    <Subheading variant="long-primer">{ subheading }</Subheading>
    {
      buttonLabel && (
        <Button
          onClick={onAction}
          label={buttonLabel}
          variant="cta"
          autofocus
        />
      )
    }
  </Wrapper>
);

export default EmptyState;
