import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import ScanIcon from 'ui/components/atoms/icons/scan';
import QRCode from 'ui/components/atoms/qr-code';

export type Props = {
  url?: string,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.s};
  padding-top: ${({ theme }) => theme.spacing.xs};
  width: 100%;
`;

const Text = styled(Typography)`
  display: block;
  padding-top: ${({ theme }) => theme.spacing.xs};
  padding-bottom: ${({ theme }) => theme.spacing.s};
`;

const Scan = styled(ScanIcon)`
  margin-left: ${({ theme }) => theme.spacing.s};
  margin-top: -${({ theme }) => theme.spacing.xs};
  margin-bottom: -${({ theme }) => theme.spacing.xxs};
`;

const ConnectQRCode = ({ url }: Props) => (
  <Wrapper>
    {url
      ? (
        <>
          <Text variant="pica" weight="medium" color="black" size="xs">Scan to join <Scan /></Text>
          <QRCode url={url} />
        </>
      )
      : <span>Loading...</span>}
  </Wrapper>
);

export default ConnectQRCode;
