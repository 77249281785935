/* eslint-disable react/jsx-props-no-spreading, no-restricted-imports */
import React, { useEffect, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import useConfig from 'app/in-studio/config-provider';

import {
  init,
  joinLobby,
  LessonInstanceActionTypes,
  StartWorkoutCountdown,
  startWorkoutSession,
  webSocketMessage,
  endWorkoutSession,
  setConnectedStatus,
} from 'actions/lesson-instance';

import { useAppState, useDispatch } from 'state';
import useWebSocket, { generateLocalWsUrl } from 'utils/use-web-socket';
import { LessonInstanceStatus } from 'state/lesson-instance';
import useLogger from 'app/hooks/use-logger';

import InSession from 'app/pages/in-session';
import Lobby from 'app/in-studio/pages/lesson-instance/lobby';
import PostSession from 'app/in-studio/pages/lesson-instance/post-session';
import Idle from 'app/pages/idle-or-error';

import { LessonInstanceByIdQuery, LessonInstanceType } from 'app/in-studio/types/graphql';

import useSessionEnd from 'utils/use-session-end';
import {
  LessonInstanceById as LESSON_INSTANCE_QUERY,
} from 'app/in-studio/pages/lesson-instance/lesson-instance-by-id.gql';

import ClassInstanceLoadingScreen from 'ui/components/molecules/class-instance-loading-screen';

type ComponentProps = { id: string };

export type Props = RouteComponentProps<ComponentProps>;

type LessonInstanceProps = {
  lessonInstance: LessonInstanceByIdQuery['lessonInstanceById'],
};

const LessonInstanceDetails = ({ lessonInstance }: LessonInstanceProps) => {
  const { config } = useConfig();

  const { id } = lessonInstance;

  const dispatch = useDispatch();
  const status = useAppState((state) => state.lessonInstance[id]?.status || LessonInstanceStatus.UNKNOWN);
  const userId = useAppState((state) => state.auth.userId);

  const [initialLoading, setInitialLoading] = useState(lessonInstance.type === LessonInstanceType.INDIVIDUAL);

  // When id changes initialise a state object for it
  useEffect(() => {
    dispatch(init({ lessonInstanceId: id }));
  }, [id, dispatch]);

  const lessonInstanceUpdatesUrl = config.LESSON_INSTANCE_UPDATES_URL || `wss://${window.location.hostname}/ws`;

  const connection = useWebSocket({
    url: `${generateLocalWsUrl(lessonInstanceUpdatesUrl)}?lessonInstanceId=${id}&isListener=true`,
    onMessage: useCallback((message) => {
      const action = webSocketMessage({ lessonInstanceId: id, message });
      // If we're starting the countdown, we should start the workout soon
      if (action.type === LessonInstanceActionTypes.START_WORKOUT_COUNTDOWN) {
        setTimeout(() => {
          dispatch(startWorkoutSession({ lessonInstanceId: id }));
        }, (action as ReturnType<StartWorkoutCountdown>).payload.workoutStartDelaySeconds * 1000);
      }

      dispatch(action);
    }, [id, dispatch]),
  });

  useEffect(() => {
    dispatch(setConnectedStatus({
      lessonInstanceId: id,
      connected: connection.connected,
    }));
  }, [id, connection.connected, dispatch]);

  const onEnd = () => {
    connection.close();

    dispatch(endWorkoutSession({ lessonInstanceId: id }));
  };

  const onSessionEnd = useSessionEnd({ lessonInstance, onEnd });

  const onBuffering = useCallback((isBuffering: boolean) => {
    if (!isBuffering && initialLoading) {
      setInitialLoading(false);
    }
  }, [initialLoading]);

  if (
    [
      LessonInstanceStatus.PRE_LOBBY,
      LessonInstanceStatus.UNKNOWN,
      LessonInstanceStatus.LOBBY,
    ].includes(status)
  ) {
    return (
      <Lobby
        lessonInstance={lessonInstance}
      />
    );
  }

  if (
    [
      LessonInstanceStatus.COUNTDOWN,
      LessonInstanceStatus.IN_SESSION,
    ].includes(status)
  ) {
    return (
      <>
        {
          initialLoading && (
            <ClassInstanceLoadingScreen
              name={lessonInstance.lesson.name}
              trainers={lessonInstance.lesson.trainers}
              backgroundImage={lessonInstance.lesson.mainImage?.url}
              duration={lessonInstance.lesson.durationRange}
            />
          )
        }
        <InSession
          userId={userId}
          lessonInstanceStatus={status}
          lessonInstance={lessonInstance}
          onSessionEnd={onSessionEnd}
          playing
          syncToLeaderboard
          onBuffering={onBuffering}
        />
      </>
    );
  }

  if (status === LessonInstanceStatus.POST_SESSION) {
    return <PostSession lessonInstance={lessonInstance} />;
  }

  return <Idle />;
};

const LessonInstanceLoader = ({ id }: ComponentProps) => {
  const dispatch = useDispatch();
  const logger = useLogger('in-studio:lesson-instance-page');

  const { loading, error, data } = useQuery<LessonInstanceByIdQuery>(LESSON_INSTANCE_QUERY, {
    variables: { id },
    onCompleted: ({ lessonInstanceById }) => {
      logger.debug(`lesson instance loaded: ${lessonInstanceById.id}`);

      const actionArgs = { lessonInstanceId: lessonInstanceById.id };

      // If the lobby is closed that means the workout has already started, skip the lobby, go straight to workout
      const action = lessonInstanceById.lobbyStatus === 'CLOSED'
        ? startWorkoutSession(actionArgs)
        : joinLobby(actionArgs);

      dispatch(action);
    },
    onError: (e) => logger.error('LessonInstanceByIdQuery error', { error: e }),
  });

  if (loading) {
    return <Idle redirect={false} />;
  }

  if (error) {
    return <Idle />;
  }

  if (!data?.lessonInstanceById) {
    return <Idle />;
  }

  const { lessonInstanceById: lessonInstance } = data;

  return <LessonInstanceDetails lessonInstance={lessonInstance} />;
};

const LessonInstancePage = ({ match: { params: { id } } }: Props) => (
  id ? <LessonInstanceLoader id={id} /> : <p>No id screen/redirect</p>
);

export default LessonInstancePage;
