import {
  LessonOrder,
  LessonMediaType,
} from 'types/graphql';

export default {
  lessonOrderBy: LessonOrder.RECOMMENDED,
  lessonConditions: {
    mediaType: [LessonMediaType.DASH],
  },
};
