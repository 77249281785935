import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SpatialNavParent } from 'utils/spatial-nav';

type Props = {
  children: ReactNode,
  transparent?: boolean,
  className?: string,
  backgroundImage?: string,
  centered?: boolean,
  noFocus?: boolean,
  layout?: 'vertical' | 'horizontal',
};

const Wrapper = styled.div<{
  backgroundImage?: string,
  transparent?: boolean,
  layout: 'vertical' | 'horizontal',
  centered: boolean,
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: ${({ centered }) => (centered ? 'center' : 'start')};
  justify-content: ${({ centered }) => (centered ? 'center' : 'start')};
  flex-direction: ${({ layout }) => (layout === 'vertical' ? 'column' : 'row')};
  background: ${
  ({ backgroundImage, transparent, theme }) => {
    if (backgroundImage) {
      return `url(${backgroundImage}) ${theme.colors.black}`;
    }
    return transparent ? 'rgba(0,0,0,0.85)' : theme.colors.black;
  }
};
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 4;
`;

const FullPageOverlay = ({
  className = '',
  children,
  transparent = false,
  backgroundImage,
  centered = true,
  layout = 'horizontal',
}: Props) => (
  <SpatialNavParent layout="vertical" isolated>
    <Wrapper
      layout={layout}
      className={className}
      transparent={transparent}
      backgroundImage={backgroundImage}
      centered={centered}
    >
      { children }
    </Wrapper>
  </SpatialNavParent>
);

export default FullPageOverlay;
