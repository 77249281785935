import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { rem } from 'ui/helpers';

import Styles from 'ui/components/molecules/progress-circle/styles';

export type Props = {
  children: ReactNode,
  value: number,
  maxValue?: number,
  activeColor?: boolean,
};

const StyledCircularProgressBarWithChildren = styled(CircularProgressbarWithChildren)`
  width: ${rem(360)};
  height: ${rem(360)};
`;

export const ProgressCircle = ({ children, value, maxValue, activeColor }: Props) => (
  <>
    <Styles activeColor={activeColor} />
    <StyledCircularProgressBarWithChildren value={value} maxValue={maxValue} strokeWidth={5}>
      {children}
    </StyledCircularProgressBarWithChildren>
  </>
);

export default ProgressCircle;
