import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Typography, { TypographyVariant } from 'ui/components/atoms/typography';

export type Props = {
  className?: string,
  icon: ReactNode,
  label: string,
  labelVariant?: TypographyVariant,
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Icon = styled.div`
  line-height: 0;
  margin-bottom: 0.2rem;
`;

export const StatCounterLabel = ({ className, icon, label, labelVariant = 'minion' }: Props) => (
  <Wrapper className={className}>
    <Icon>{ icon }</Icon>
    <Typography
      variant={labelVariant}
      weight={labelVariant === 'minion' ? 'bold' : 'light'}
      color="wednesdayGrey"
    >
      { label }
    </Typography>
  </Wrapper>
);

export default StatCounterLabel;
