import { useEffect } from 'react';
import { Key as KeyEnum } from 'ts-key-enum';

export const platformBackKeyCodes = {
  RCUBack: 461,
  [KeyEnum.Backspace]: 8,
  SamBack: 10009,
};

// TODO - could move this mapping to config so we can configure based on the remote / default to standard browsers?
export const legacyKeyCode = {
  ...platformBackKeyCodes,
  [KeyEnum.Escape]: 27,
  ' ': 32,
  ',': 188,
  '.': 190,
  '/': 191,
  ơ: 417,
  Ɯ: 412,
  [KeyEnum.F8]: 119,
  [KeyEnum.MediaPlayPause]: 179,
  [KeyEnum.MediaPause]: 19,
  [KeyEnum.MediaPlay]: 415,
  [KeyEnum.MediaFastForward]: 228,
  [KeyEnum.MediaRewind]: 227,
  [KeyEnum.Enter]: 13,
  [KeyEnum.MediaStop]: 413,
};

type SupportedKeys = keyof typeof legacyKeyCode;

type UseKeyEvent = {
  key: SupportedKeys | SupportedKeys[],
  eventType?: 'keydown' | 'keypress' | 'keyup',
  handler: (event: KeyboardEvent) => void,
  priority?: boolean
};

const useKeyEvent = ({
  key,
  handler,
  eventType = 'keydown',
  priority = false,
}: UseKeyEvent) => {
  useEffect(() => {
    const keys: SupportedKeys[] = Array.isArray(key) ? key : [key];
    const legacyKeyCodes = keys.map((targetKey) => legacyKeyCode[targetKey]);

    const listener = (event: KeyboardEvent) => {
      if (event.key && (keys as string[]).includes(event.key)) {
        handler(event);

        if (priority) {
          event.stopPropagation();
        }

        return;
      }

      if (legacyKeyCodes.includes(event.keyCode)) {
        handler(event);
        if (priority) {
          event.stopPropagation();
        }
      }
    };

    if (priority) {
      window.document.addEventListener(eventType, listener);
    } else {
      window.addEventListener(eventType, listener);
    }

    return () => {
      if (priority) {
        window.document.removeEventListener(eventType, listener);
      } else {
        window.removeEventListener(eventType, listener);
      }
    };
  }, [eventType, handler, key, priority]);

  return null;
};

export { Key } from 'ts-key-enum';
export default useKeyEvent;
