import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import useConfig from 'app/in-studio/config-provider';

import Button from 'ui/components/atoms/button';
import Typography from 'ui/components/atoms/typography';
import SvgLogout from 'ui/components/atoms/icons/logout';
import ProfileIcon from 'ui/components/atoms/icons/profile';
import FavouriteIcon from 'ui/components/atoms/icons/favourite';
import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import EmptyState from 'ui/components/molecules/empty-state';
import FavouriteOutline from 'ui/components/atoms/icons/favourite-outline';
import ClassCard from 'ui/components/molecules/class-card';
import TabBar from 'ui/components/molecules/full-width-tab-bar-icon';
import { height as menuHeight } from 'ui/components/molecules/bottom-menu';
import ClassGrid from 'ui/components/layouts/class-grid-page';
import { rem } from 'ui/helpers';

import styled from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  ProfileQuery,
  ProfileQueryVariables,
  FavouritesQuery,
  FavouritesQueryVariables,
  FavouriteLessonOrder,
} from 'app/in-studio/types/graphql';
import { Profile as PROFILE_QUERY } from 'app/in-studio/pages/profile/profile-query.gql';
import { Favourites as FAVOURITES_QUERY } from 'app/in-studio/pages/profile/favourites-query.gql';
import { useAppState, useDispatch } from 'state';
import { logout } from 'actions/auth';
import LogoutScreen from 'app/in-studio/organisms/logout-screen';
import transformLessonData from 'app/in-studio/utils/transform-lesson-data';
import useRoutes, { url } from 'utils/use-routes';
import { WebsocketClientContext } from 'utils/apollo-client';

type ComponentProps = {
  slug: string,
};

export type Props = RouteComponentProps<ComponentProps>;

const ProfileWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: relative;
  padding-bottom: ${menuHeight};
`;

const Heading = styled(Typography)`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(20)};
`;

const Subheading = styled(Typography)`
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.beckersKnop};
  max-width: ${rem(1000)};
`;

const Version = styled(Typography)`
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.classicGrey};
  max-width: ${rem(1000)};
`;

const StyledEmptyState = styled(EmptyState)`
  margin-top: ${rem(40)};
`;

const ProfileTab = () => {
  const client = useApolloClient();
  const { client: wsClient } = useContext(WebsocketClientContext);
  const dispatch = useDispatch();
  const { redirect, routes } = useRoutes();
  const { config } = useConfig();

  const [logoutVisible, setLogoutVisible] = useState(false);
  const showLogout = useCallback(() => {
    setLogoutVisible(true);
  }, []);

  const cancelLogout = useCallback(() => {
    setLogoutVisible(false);
  }, []);

  const logoutAction = useCallback(async () => {
    setLogoutVisible(false);
    await client.clearStore();
    await wsClient?.close();
    dispatch(logout());
    redirect({ route: routes.LOGIN, replaceStack: true, queryParams: { hideIntroVideo: true } });
  }, [wsClient, client, dispatch, redirect, routes.LOGIN]);

  const { data, refetch: refetchUser } = useQuery<ProfileQuery, ProfileQueryVariables>(PROFILE_QUERY);

  const userId = useAppState((state) => state.auth.userId);
  const platformPartner = useAppState((state) => state.platformPartner);

  useEffect(() => {
    refetchUser();
  }, [userId, refetchUser]);

  const user = data?.auth?.user;

  return (
    <>
      <Heading variant="double-pica" weight="bold">{platformPartner.name}</Heading>
      <Subheading>
        {user?.email}
      </Subheading>
      <Version>
        Web App Version: {config.VERSION}
      </Version>
      <Button
        label="Log out"
        variant="cta"
        autofocus
        onClick={showLogout}
        icon={<SvgLogout />}
      />
      {logoutVisible && <LogoutScreen cancelLogout={cancelLogout} logoutAction={logoutAction} />}
    </>
  );
};

const FavouritesTab = () => {
  const userId = useAppState((state) => state.auth.userId);

  const { routes, redirect } = useRoutes();

  const { config } = useConfig();

  const { loading, error, data } = useQuery<FavouritesQuery, FavouritesQueryVariables>(
    FAVOURITES_QUERY, {
      variables: {
        userId,
        lessonFirst: 100,
        lessonOrderBy: FavouriteLessonOrder.FAVOURITED_AT,
      },
      pollInterval: config.FAVOURITES_POLLING_INTERVAL * 1000,
    },
  );

  const lessons = useMemo(() => transformLessonData(data?.user?.favouriteLessons?.edges), [data]);

  const emptyStateOnAction = useCallback(() => redirect({ route: routes.BROWSE }), [redirect, routes]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if ((data?.user?.favouriteLessons.totalCount === 0) || !userId) {
    return (
      <StyledEmptyState
        icon={<FavouriteOutline />}
        heading="No favourite classes yet"
        subheading="Tap the heart on your favourite workouts within the studio app to find them easily here."
        buttonLabel="Find Classes"
        onAction={emptyStateOnAction}
      />
    );
  }

  return (
    <ClassGrid>
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            trainer={lesson.trainerName}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            dumbbells={lesson.dumbbells}
            kettlebells={lesson.kettlebells}
            favourited={lesson.favourited}
            autofocus={index === 0}
            isWithinGrid
          />
        ))
      }
    </ClassGrid>
  );
};

const ProfilePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabNames = [
    { name: 'Profile', id: 'profile', icon: ProfileIcon },
    { name: 'Favourites', id: 'favourites', icon: FavouriteIcon },
  ];

  const tabs = useMemo(() => (
    tabNames.map((tab, i) => ({
      ...tab,
      selected: i === selectedTab,
    }))
  ), [selectedTab, tabNames]);

  useDismissEvent();

  return (
    <>
      <TabBar
        tabs={tabs}
        toggleSelectedTab={setSelectedTab}
      />
      {tabs[selectedTab].id === 'profile' && (
        <ProfileWrapper>
          <ProfileTab />
        </ProfileWrapper>
      )}
      {tabs[selectedTab].id === 'favourites' && (
        <FavouritesTab />
      )}
    </>

  );
};

ProfilePage.menu = true;

export default ProfilePage;
