export enum ScreenSaverActionTypes {
  SET_ENABLED = 'SCREEN_SAVER/SET_ENABLED',
  SET_CAN_DISPLAY = 'SCREEN_SAVER/SET_CAN_DISPLAY',
  SET_IDLE_TIME = 'SCREEN_SAVER/SET_IDLE_TIME',
}

export const setEnabled = (payload: boolean) => ({
  type: ScreenSaverActionTypes.SET_ENABLED,
  payload,
});

export type SetEnabled = typeof setEnabled;

export const setCanDisplay = (payload: boolean) => ({
  type: ScreenSaverActionTypes.SET_CAN_DISPLAY,
  payload,
});

export type SetCanDisplay = typeof setCanDisplay;

export const setIdleTime = (payload: boolean) => ({
  type: ScreenSaverActionTypes.SET_IDLE_TIME,
  payload,
});

export type SetIdleTime = typeof setIdleTime;

export default {
  setEnabled,
  setCanDisplay,
  setIdleTime,
};
