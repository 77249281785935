import React from 'react';
import styles from 'ui/components/molecules/date-time-picker/styles.css';

const Styles = () => (
  <style>
    {styles}
  </style>
);

export default Styles;
