import React, { ReactNode, useCallback, useRef } from 'react';
import styled from 'styled-components';
import capitalize from 'lodash/fp/capitalize';

import { ThemeSpacing } from 'ui/theme';
import Typography from 'ui/components/atoms/typography';
import LessonList from 'ui/components/molecules/lesson-list';
import { SpatialNavParent } from 'utils/spatial-nav';

export type Props = {
  children: ReactNode[],
  className?: string,
  itemSpacing?: ThemeSpacing,
  title?: string,
  onFocus?: (target: HTMLElement | null) => void,
  viewMoreAction?: () => void,
  viewMoreLabel?: string,
};

const Wrapper = styled.div`
  flex: 1;
`;

const TitleHolder = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};
  text-align: left;
`;

const LessonListWrapper = styled.div`
  flex: 1;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
`;

const Rail = ({
  itemSpacing = 's',
  title,
  children,
  className = '',
  viewMoreAction,
  onFocus,
  viewMoreLabel,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const listWrapperRef = useRef<HTMLDivElement | null>(null);

  const onItemFocused = useCallback((element: HTMLElement | null) => {
    if (element && listWrapperRef.current) {
      listWrapperRef.current.scrollTo({
        left: element.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, []);

  const onNavFocus = useCallback(() => {
    if (onFocus) {
      onFocus(wrapperRef.current);
    }
  }, [onFocus]);

  if (children.length === 0) {
    return null;
  }

  return (
    <Wrapper
      className={className}
      data-test={`rail-${(title || 'unknown').toLowerCase().replace(' ', '-')}`}
      ref={wrapperRef}
    >
      { title && (
        <TitleHolder>
          <Typography variant="double-pica">{ capitalize(title) }</Typography>
        </TitleHolder>
      )}
      <SpatialNavParent layout="horizontal" onFocus={onNavFocus}>
        <LessonListWrapper ref={listWrapperRef}>
          <LessonList
            variant="RAIL"
            onItemFocused={onItemFocused}
            itemSpacing={itemSpacing}
            viewMoreAction={viewMoreAction}
            viewMoreLabel={viewMoreLabel}
          >
            {
              children
            }
          </LessonList>
        </LessonListWrapper>
      </SpatialNavParent>
    </Wrapper>
  );
};

export default Rail;
