import React from 'react';
import styled from 'styled-components';

import Logo from 'ui/components/atoms/logo';

type Props = {
  className?: string,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Upcoming = ({ className = '' }: Props) => (
  <Wrapper className={className}>
    <Logo color="white" />
  </Wrapper>
);

export default Upcoming;
