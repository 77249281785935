import React, { FC } from 'react';
import styled from 'styled-components';

import useCountdown from 'utils/use-countdown';

import Typography from 'ui/components/atoms/typography';
import Time from 'ui/components/atoms/time';

export type Props = {
  text: string,
  workoutStartTime: string
  className?: string;
};

const Wrapper = styled.div`
  background-color: transparent;
  text-align: right;
`;

const Info = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.spacing.xs};
`;

const CountdownText = styled(Typography)`
  font-variant-numeric: tabular-nums;
  letter-spacing: 0.1rem;
`;

export const ClassCountdown: FC<Props> = ({ workoutStartTime, text, className }: Props) => {
  const timeLeft = useCountdown(workoutStartTime);

  return (
    <Wrapper className={className}>
      <Info
        color="white"
        variant="body-copy"
        as="p"
      >
        {text}
      </Info>
      <CountdownText
        color="white"
        weight="bold"
        variant="paragon"
        as="p"
      >
        <Time time={timeLeft} />
      </CountdownText>
    </Wrapper>
  );
};

export default ClassCountdown;
