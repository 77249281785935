import React from 'react';
import styled from 'styled-components';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';

import Cross from 'ui/components/atoms/icons/cross';
import Button from 'ui/components/atoms/button';

import handleExternalLink from 'utils/external-link';

const LogoContainer = styled.div`
  position: absolute;
  left: 1em;
  top: 1em;
  z-index: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 2rem;
  margin-bottom: 2rem;
`;

const Wrapper = styled.div`
  background-color: black;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 10%;
  text-align: center;
`;

const ErrorMessageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorTitle = styled(Typography)`
  margin-bottom: 1rem;
`;

const ButtonLink = styled(Button)`
  margin-top: 1.5rem;
`;

const handleHelpLink = () => {
  handleExternalLink('https://fiit.tv');
};

const UnrecognisedPartnerPage = () => (
  <Wrapper>
    <LogoContainer>
      <Logo color="white" />
    </LogoContainer>
    <ErrorMessageArea>
      <IconWrapper>
        <Cross />
      </IconWrapper>
      <ErrorTitle as="div" variant="double-pica">Unrecognised Platform</ErrorTitle>
      <Typography as="div" variant="body-copy">
        We&apos;re sorry but the partner entered does not exist
      </Typography>
      <ButtonLink
        label="Help"
        onClick={handleHelpLink}
        autofocus
      />
    </ErrorMessageArea>
  </Wrapper>
);

UnrecognisedPartnerPage.menu = false;

export default UnrecognisedPartnerPage;
