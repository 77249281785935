import React, { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { useSpatialTarget, SpatialNavParent } from 'utils/spatial-nav';
import { format } from 'date-fns';

type TabProps = {
  active: boolean,
  children: ReactNode,
  onClick: () => void,
  onTabFocus: () => void,
  autofocus: boolean,
  disabled?: boolean,
};

type Props = {
  trainingPlanCurrentWeek?: number,
  startedAt?: string,
  endsAt?: string,
  tabs: Array<{
    name: string,
    selected: boolean,
  }>
  autofocus?: boolean,
  className?: string,
  onFocus?: (el: HTMLElement | null) => void,
  toggleSelectedTab?: (i: number) => void,
  onTabFocus?: (i: number) => void,
};

const TabBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.beckersKnop};
`;

const TabContainer = styled.button<{ disabled?: boolean, active?: boolean, focused?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.janosGrey : theme.colors.beckersKnop)};
  margin: 0 0.5rem -1px 0.5rem;
  ${({ active, theme }) => (active ? `
    border-bottom: 3px solid;
    color: ${theme.colors.white};
  ` : '')};
  ${({ focused, theme }) => (focused ? `
    border-bottom: 3px solid ${theme.colors.fiitBlue};
  ` : '')};
`;

const DateRange = styled(Typography)`
  position: absolute;
  right: 2.5rem;
`;

const Tab = ({ active, children, onClick, autofocus, disabled, onTabFocus }: TabProps) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({ elRef, autofocus, disabled, onFocus: onTabFocus });

  return (
    <TabContainer
      ref={elRef}
      active={active}
      focused={focused}
      onClick={onClick}
    >
      {children}
    </TabContainer>
  );
};

const TabBar = ({
  trainingPlanCurrentWeek,
  tabs,
  startedAt,
  endsAt,
  toggleSelectedTab,
  autofocus = false,
  className = '',
  onFocus,
  onTabFocus,
}: Props) => (
  <SpatialNavParent layout="horizontal" onFocus={onFocus}>
    <TabBarWrapper data-test="tab-bar" className={className}>
      {tabs.map(({ selected, name }, i) => (
        <Tab
          key={i}
          active={selected}
          onClick={() => (toggleSelectedTab ? toggleSelectedTab(i) : null)}
          autofocus={autofocus && selected}
          disabled={(trainingPlanCurrentWeek && trainingPlanCurrentWeek <= i) || false}
          onTabFocus={() => (onTabFocus ? onTabFocus(i) : null)}
        >
          <Typography weight="bold">{name}</Typography>
        </Tab>
      ))}
      {startedAt && endsAt && (
        <DateRange variant="pica" color="beckersKnop">
          {format(new Date(startedAt), 'dd LLL')} - {format(new Date(endsAt), 'dd LLL')}
        </DateRange>
      )}
    </TabBarWrapper>
  </SpatialNavParent>
);

export default TabBar;
