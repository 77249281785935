import React, { ReactNode, useRef, ElementType } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

type TabProps = {
  active: boolean,
  children: ReactNode,
  onClick: () => void,
};

type Props = {
  tabs: Array<{
    name: string,
    selected: boolean,
    icon?: ElementType,
  }>
  toggleSelectedTab?: (i: number) => void,
  className?: string,
};

const TabBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: center;
`;

const TabContainer = styled.button<{ active?: boolean }>`
  padding: 0 0 ${rem(16)} 0;
  ${({ active, theme }) => (active ? `
    border-bottom: 3px solid;
    color: ${theme.colors.white};
  ` : `
    border-bottom: 3px solid;
    color: ${theme.colors.classicGrey}
  `)};
  width: 100%;
`;

const StyledIcon = styled.div`
  height: ${rem(24)};
  width: ${rem(24)};
  margin-right: ${rem(16)};
  margin-bottom: ${rem(-3)};
`;

const Tab = ({ active, children, onClick }: TabProps) => {
  const elRef = useRef(null);

  return (
    <TabContainer
      ref={elRef}
      active={active}
      onClick={onClick}
    >
      {children}
    </TabContainer>
  );
};

const FullWidthTabBarIcon = ({
  tabs,
  toggleSelectedTab,
  className = '',
}: Props) => (
  <TabBarWrapper data-test="tab-bar" className={className}>
    {tabs.map(({ selected, name, icon }, i) => (
      <Tab
        key={i}
        active={selected}
        onClick={() => (toggleSelectedTab ? toggleSelectedTab(i) : null)}
      >
        {icon ? <StyledIcon as={icon} /> : null }
        <Typography weight="bold">{name}</Typography>
      </Tab>
    ))}
  </TabBarWrapper>
);

export default FullWidthTabBarIcon;
