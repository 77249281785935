import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ChevronRight from 'ui/components/atoms/icons/chevron-right';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

export type BoxSize = 'small' | 'large' | 'trainer' | 'studio' | 'upsell';

type Props = {
  onClick?: () => void,
  focus?: boolean,
  boxSize?: BoxSize,
  box?: boolean,
  children: ReactNode,
  isWithinGrid?: boolean,
  headerText?: string,
  headerHighlight?: boolean,
};

const Wrapper = styled.div<{ focus: boolean, isWithinGrid: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  border-radius: 0.067rem;
  padding: 0.167rem;
  background-color: ${({ focus, theme }) => (focus ? theme.colors.fiitBlue : 'transparent')};
  ${({ isWithinGrid }) => (isWithinGrid ? 'width: 100% !important' : '')}
  flex-direction: column;
`;

const Header = styled(Typography)<{ highlight: boolean }>`
  background-color: ${({ highlight, theme }) => (highlight ? theme.colors.fiitBlue : theme.colors.black)};
  padding: ${rem(12)} ${rem(20)};
`;

const Box = styled.div<{ size: string, visible: boolean }>`
  display: flex;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.067rem;
  height: ${({ size }) => (size === 'large' ? '1.8rem' : '1.5rem')};
  width: ${({ size }) => (size === 'large' ? '1.8rem' : '1.5rem')};
  background: ${({ theme }) => theme.colors.fiitBlue};
  z-index: 1;
`;

const CardFocus = (
  {
    onClick,
    focus = false,
    isWithinGrid = false,
    boxSize = 'small',
    box,
    headerText,
    headerHighlight = false,
    children,
    ...props
  }: Props,
) => (
  <Wrapper focus={focus} isWithinGrid={isWithinGrid} {...props} onClick={onClick}>
    {headerText && <Header highlight={headerHighlight} variant="pica">{headerText}</Header>}
    {box && (
    <Box visible={focus} size={boxSize}>
      <ChevronRight />
    </Box>
    )}
    { children }
  </Wrapper>

);

export default CardFocus;
