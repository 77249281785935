import React, { useState } from 'react';
import styled from 'styled-components';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Spinner from 'ui/components/atoms/animated-spinner';
import ClassDetailsBasicInformation from 'ui/components/molecules/class-details-basic-information';
import { LessonDurationRange } from 'types/graphql';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useTimeout from 'utils/use-timeout';
import useConfig from 'config';
import useLogger from 'app/hooks/use-logger';

export type Trainers = Array<{
  firstname: string,
}>;

export type Props = {
  name: string,
  trainers: Trainers,
  duration?: LessonDurationRange,
  backgroundImage?: string,
};

const Holder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: ${({ theme }) => theme.spacing.m};
`;

const Overlay = styled(FullPageOverlay)`
  z-index: 1;
`;

const ClassInstanceLoadingScreen = ({ backgroundImage, name, trainers, duration }: Props) => {
  const { config } = useConfig();
  const [timedOut, setTimedOut] = useState(false);
  const logger = useLogger('class-instance-loading-page');
  useTimeout(() => {
    setTimedOut(true);
  }, (config.VIDEO_BUFFERING_TIMEOUT) * 1000);

  if (timedOut) {
    logger.warn('Initial buffering of video timed out, showing error page');
    return (
      <ErrorOverlay error onDismiss="back" />
    );
  }

  return (
    <Overlay backgroundImage={backgroundImage}>
      <Holder>
        <ClassDetailsBasicInformation name={name} trainers={trainers} duration={duration} />
        <StyledSpinner />
      </Holder>
    </Overlay>
  );
};

export default ClassInstanceLoadingScreen;
