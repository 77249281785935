import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(22)};
  height: ${rem(21)};
`;

function SvgSchedule({ className = '' }: IconProps) {
  const theme = useTheme();

  return (
    <Svg viewBox="0 0 22 21" className={className}>
      <defs>
        <path
          id="schedule_svg__a"
          d="M3.587 3v1.793h4.484V3h1.793v1.793h.929L7.205 8.38H1.793v10.761h14.348v-6.644l1.793-1.79v10.228H0V4.793h1.793V3h1.794zm13-2.076l4.609 4.609L10.973 15.76H6.359v-4.614L16.587.924zm-2.26 4.706L8.19 11.75v2.18h2.136l.044-.017 6.13-6.109-2.174-2.174zm2.25-2.271L15.542 4.39l2.174 2.174 1.033-1.032-2.174-2.174z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="schedule_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#schedule_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#schedule_svg__a" />
        <g fill={theme.colors.white} mask="url(#schedule_svg__b)">
          <path d="M-2 0h24v24H-2z" />
        </g>
      </g>
    </Svg>
  );
}

export default SvgSchedule;
