export enum FlagActionTypes {
  SET_MAT_CONNECTIVITY_FLOW_ENABLED = 'FLAG/SET_MAT_CONNECTIVITY_FLOW_ENABLED',
  SET_CK_CONNECTIVITY_FLOW_ENABLED = 'FLAG/SET_CK_CONNECTIVITY_FLOW_ENABLED',
  SET_IN_CLASS_ANIMATIONS_ENABLED = 'FLAG/SET_IN_CLASS_ANIMATIONS_ENABLED',
  SET_IN_CLASS_TRANSITIONS_ENABLED = 'FLAG/SET_IN_CLASS_TRANSITIONS_ENABLED',
  SET_DYNAMIC_HERO_TRANSITION_ENABLED = 'FLAG/SET_DYNAMIC_HERO_TRANSITION_ENABLED',
  SET_SIGN_UP_FLOW_ENABLED = 'FLAG/SET_SIGN_UP_FLOW_ENABLED',
  SET_CLASS_DETAILS_EXTRA_ENABLED = 'FLAG/SET_CLASS_DETAILS_EXTRA_ENABLED',
  SET_RECORDING_MODE_ENABLED = 'FLAG/SET_RECORDING_MODE_ENABLED',
  SET_NOT_LOGGED_IN_MODE_ENABLED = 'FLAG/SET_NOT_LOGGED_IN_MODE_ENABLED'
}

export const setMatConnectivityFlowEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_MAT_CONNECTIVITY_FLOW_ENABLED,
  payload,
});

export type SetMatConnectivityFlowEnabled = typeof setMatConnectivityFlowEnabled;

export const setCkConnectivityFlowEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_CK_CONNECTIVITY_FLOW_ENABLED,
  payload,
});

export type SetCkConnectivityFlowEnabled = typeof setCkConnectivityFlowEnabled;

export const setInClassAnimationsEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_IN_CLASS_ANIMATIONS_ENABLED,
  payload,
});

export type SetInClassAnimationsEnabled = typeof setInClassAnimationsEnabled;

export const setInClassTransitionsEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_IN_CLASS_TRANSITIONS_ENABLED,
  payload,
});

export type SetInClassTransitionsEnabled = typeof setInClassTransitionsEnabled;

export const setDynamicHeroTransitionEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_DYNAMIC_HERO_TRANSITION_ENABLED,
  payload,
});

export type SetDynamicHeroTransitionEnabled = typeof setDynamicHeroTransitionEnabled;

export const setSignUpFlowEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_SIGN_UP_FLOW_ENABLED,
  payload,
});

export type SetSignUpFlowEnabled = typeof setSignUpFlowEnabled;

export const setClassDetailsExtraEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_CLASS_DETAILS_EXTRA_ENABLED,
  payload,
});

export type SetClassDetailsExtraEnabled = typeof setClassDetailsExtraEnabled;

export const setRecordingModeEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_RECORDING_MODE_ENABLED,
  payload,
});

export type SetRecordingModeEnabled = typeof setRecordingModeEnabled;

export const setNotLoggedInModeEnabled = (payload: boolean) => ({
  type: FlagActionTypes.SET_NOT_LOGGED_IN_MODE_ENABLED,
  payload,
});

export type SetNotLoggedInModeEnabled = typeof setNotLoggedInModeEnabled;

export default {
  setMatConnectivityFlowEnabled,
  setCkConnectivityFlowEnabled,
  setInClassAnimationsEnabled,
  setDynamicHeroTransitionEnabled,
  setSignUpFlowEnabled,
  setClassDetailsExtraEnabled,
  setRecordingModeEnabled,
  setNotLoggedInModeEnabled,
};
