import { useEffect, useRef } from 'react';

const useTimeout = (callback: () => void, delay: number) => {
  const callbackRef = useRef<() => void>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (callbackRef.current) {
        callbackRef.current();
      }
    }, delay);
    return () => clearTimeout(id);
  }, [delay]);
};

export default useTimeout;
