import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import shaka from 'shaka-player';
import mux from 'mux-embed';

import useConfig from 'config';
import useLogger from 'app/hooks/use-logger';
import useHandleVideoPlayback from 'ui/components/atoms/video-player/useHandleVideoPlayback';
import { VideoPlayerProps } from 'ui/components/atoms/video-player';

// Install all the polyfills globally so it only happens once, and doesn't delay video play
shaka.polyfill.installAll();

type ShakaError = Error & { code: number, category: number };

const StyledVideo = styled.video<{isFullHeight?: boolean}>`
  ${({ isFullHeight }) => (isFullHeight ? 'height: 100%' : 'width: 100%')};
`;

const ShakaPlayer = ({
  src,
  videoRef: elRef,
  controls = false,
  muted = false,
  playing = true,
  seek,
  seekTolerance = 3,
  seekInterval = 2000,
  onVideoEnd,
  onTimeChange,
  onTimeChangeInterval = 1000,
  onVideoLoadError,
  onPlayFailed,
  onBuffering,
  monitor = false,
  monitorMeta = {},
  className = '',
  playbackSpeed = 1,
  loop = false,
  isFullHeight,
}: VideoPlayerProps & { videoRef: React.MutableRefObject<HTMLVideoElement | null> }) => {
  const { config } = useConfig();
  const logger = useLogger('video-player');

  // State to hold the shaka player in - starts in "detached" mode. TODO there are no shaka types.
  const player = useRef<any>(new shaka.Player());

  // State to hold if the target url has loaded yet
  const [urlLoaded, setUrlLoaded] = useState<string | null>(null);

  // Set-up effect - responsible for attaching the video to the shaka player
  useEffect(() => {
    const video = elRef.current;
    const currentPlayer = player.current;

    if (!video) {
      return () => {};
    }

    logger.debug('Attaching video element');

    currentPlayer.attach(video)
      .then(() => logger.debug('Video element attached'))
      .catch(() => logger.error('Failed to attach video element'));

    return () => {
      currentPlayer.detach()
        .then(() => logger.debug('Video element detached'))
        .catch(() => logger.error('Failed to attach video element'));
    };
  }, [
    elRef,
    logger,
  ]);

  // Set-up effect - responsible for configuring the shaka player
  useEffect(() => {
    const currentPlayer = player.current;

    const configuration = {
      // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.StreamingConfiguration
      streaming: {
        // X seconds of buffered content behind currentTime - in low memory envs keep this low especially if no re-winds
        bufferBehind: config.VIDEO_TRAILING_BUFFER_SECONDS,
        // We require X seconds of content before we play - if we drop below this we enter the buffering state
        rebufferingGoal: config.VIDEO_MINIMUM_LEADING_BUFFER_SECONDS,
        // We aim to buffer X seconds ahead - in low memory envs restrict it, it's a balance between stability/memory
        bufferingGoal: config.VIDEO_MAXIMUM_LEADING_BUFFER_SECONDS,
        // The number of seconds that the player will skip forward when a stall has been detected.
        stallSkip: config.VIDEO_STALL_SKIP_SECONDS,
      },
      // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.AbrConfiguration
      abr: {
        defaultBandwidthEstimate: config.VIDEO_DEFAULT_BANDWIDTH_ESTIMATE,
        restrictions: {
          maxHeight: config.VIDEO_MAX_RESOLUTION_HEIGHT,
        },
      },
      manifest: {
        dash: {
          // minBufferTime can be supplied in a dash manifest file
          // As we're setting rebufferingGoal above we need to ignore the buffer time from the manifest
          ignoreMinBufferTime: true,
        },
      },
    };

    logger.debug('Updating shaka configuration', configuration);

    const valid = currentPlayer.configure(configuration);

    if (valid) {
      logger.debug('Configuration accepted');
    } else {
      logger.error('Invalid config supplied to shaka player', configuration);
    }
  }, [
    config,
    logger,
  ]);

  // When we have a player and a url - load the url into the player
  // TODO offline source version of this for TGG
  useEffect(() => {
    const currentPlayer = player.current;
    const srcUrl = src.dashUrl;
    if (!currentPlayer || !srcUrl) {
      return () => null;
    }

    logger.debug('Loading url');
    currentPlayer.load(srcUrl)
      .then(() => {
        setUrlLoaded(srcUrl);
        logger.debug('Loaded url', { url: srcUrl });
      })
      .catch((error: ShakaError) => {
        setUrlLoaded(null);

        const { category } = error;

        logger.error(`Failed to load url - category ${category}`, { error, url: srcUrl });

        if (monitor) {
          mux.emit(`.${StyledVideo.styledComponentId}`, 'error', {
            player_error_code: error.code,
            player_error_message: `Failed to load url - category ${category}`,
          });
        }

        if (onVideoLoadError) {
          onVideoLoadError(error);
        }
      });

    return () => {
      currentPlayer.unload()
        .then(() => logger.debug('Unloaded', srcUrl))
        .catch(() => logger.error('Failed to unload', { url: srcUrl }));

      logger.debug('Set url unloaded');
      setUrlLoaded(null);
      logger.debug('Destroying player');
      currentPlayer.destroy();
    };
  }, [monitor, player, src.dashUrl, onVideoLoadError, logger]);

  useHandleVideoPlayback(
    src,
    elRef,
    playing,
    seekTolerance,
    seekInterval,
    onTimeChangeInterval,
    urlLoaded,
    StyledVideo.styledComponentId,
    monitor,
    monitorMeta,
    playbackSpeed,
    loop,
    onVideoEnd,
    onPlayFailed,
    onBuffering,
    onTimeChange,
    seek,
  );

  return (
    <StyledVideo
      ref={elRef}
      controls={controls}
      muted={muted}
      className={className}
      isFullHeight={isFullHeight}
    />
  );
};

export default ShakaPlayer;
