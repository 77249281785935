import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AnimatedSVGCountdown from 'ui/components/atoms/animated-countdown';

export type Props = {
  backgroundImage?: string;
  height?: number | string,
  width?: number | string,
};

const MAGIC_ANIMATION_SWIPE_TIME = 9550;

const AnimatedCountdownContainer = styled.div<{
  backgroundImage?: string,
  clearBackground?: boolean,
}>`
  ${({ backgroundImage, clearBackground, theme }) => {
    if (clearBackground) {
      return 'background-color: transparent;';
    }

    if (backgroundImage) {
      return `background: url(${backgroundImage}) no-repeat;
      background-position: center;
      background-size: cover;`;
    }

    return `background-color: ${theme.colors.black};`;
  }}
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const AnimatedCountdownWrapper = ({ backgroundImage }: Props) => {
  const [clearBackground, setBackGroundIsClear] = useState(false);

  useEffect(() => {
    setTimeout(() => setBackGroundIsClear(true), MAGIC_ANIMATION_SWIPE_TIME);
  });

  return (
    <AnimatedCountdownContainer
      backgroundImage={backgroundImage}
      clearBackground={clearBackground}
    >
      <AnimatedSVGCountdown />
    </AnimatedCountdownContainer>
  );
};

export default AnimatedCountdownWrapper;
