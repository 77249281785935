type StatsObject = {
  score?: number | null,
  kcal?: number | null,
  reps?: number | null,
};

type StatKey = keyof StatsObject;

export type StatMetric = 'BPM' | 'POINTS' | 'KCAL' | 'MINUTES' | 'REPS' | 'TOTAL_SCORE' | 'RPM' | 'SPEED';

const statsAcceptableDifference: { [key: string]: number } = {
  score: 100,
  kcal: 10,
  reps: 5,
};

const parseValue = (value: any) => {
  if ([0, null, undefined, [], [undefined]].includes(value) || typeof value !== 'number') {
    return 0;
  }
  return value;
};

const hasNotableDiscrepancy = (
  acceptableDiscrepancy: number,
  finalValue?: number | null,
  comparatorValue?: number | null,
) => Math.abs(parseValue(finalValue) - parseValue(comparatorValue)) > acceptableDiscrepancy;

export const hasStatsDiscrepancy = (
  finishWorkoutStats: StatsObject,
  lastStatsPacket: StatsObject,
  exact: boolean = false,
) => (
  Object.keys(finishWorkoutStats).find((key) => hasNotableDiscrepancy(
    // Set acceptable discrepancy to 1 even when exact because client and server aggregates and rounds independently so
    // we almost never get an exact match even without lost stats packets
    exact ? 1 : statsAcceptableDifference[key],
    finishWorkoutStats[key as StatKey],
    lastStatsPacket[key as StatKey],
  ))
);

export const roundStatMetricForDisplay = (statMetric: StatMetric, value: number) => {
  switch (statMetric) {
    case 'BPM':
    case 'POINTS':
    case 'KCAL':
    case 'REPS':
    case 'TOTAL_SCORE':
    case 'RPM':
    case 'SPEED':
      return Math.floor(value);
    case 'MINUTES':
      return Math.ceil(value);
    default:
      return value;
  }
};
