import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const PersonalBestIcon = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const iconColor = theme.colors[color];

  return (
    <Svg viewBox="0 0 18 24" className={className}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke={iconColor}
          strokeWidth={1.333}
          d="M16.429 14.527c0 4.042-3.237 7.318-7.23 7.318-3.994 0-7.23-3.276-7.23-7.318S5.204 7.21 9.198 7.21c3.993 0 7.23 3.276 7.23 7.318zM5.081 8.318L2.455 6.233V1.398h13.483v4.835l-2.663 2.061"
        />
        <path
          fill={iconColor}
          d="M10.86 14.925l1.993-1.719-2.525-.045-1.13-2.642-1.13 2.642-2.524-.08 1.994 1.844-.599 2.63 2.26-1.327 2.259 1.327z"
        />
      </g>
    </Svg>
  );
};

export default PersonalBestIcon;
