import { useState, useRef, useEffect } from 'react';

export const useNotificationPopup = () => {
  const [showNotification, setShowNotification] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const promptNotification = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setShowNotification(true);

    timeoutRef.current = setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return {
    showNotification,
    promptNotification,
  };
};
