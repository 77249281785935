import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgBikeKcal = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FF6B00';

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <defs>
        <path
          id="fiit-points_svg__a"
          d="M8 13.267L3.056 16 4 10.211l-4-4.1 5.528-.844L8 0l2.472 5.267L16 6.11l-4 4.1.944 5.789z"
        />
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49 2.293c-.04 0-.078 0-.117.002a7.766 7.766 0 0 0-1.796.18C1.832 3.524.75.002.75 0c0-.001-.48 2.531.86 4.647C2.95 6.762 4.77 6.763 6.487 6.59c.607-.061 1.086-.13 1.497-.164a2.758 2.758 0 0 1 2.89 1.318 2.77 2.77 0 0 1-1.658 4.072 2.758 2.758 0 0 1-2.986-1.08 2.77 2.77 0 0 1-.497-1.745s.129-1.155-1.391-1.413a5.748 5.748 0 0 1-.353-.07 6.666 6.666 0 0 1-1.986-.542 6.854 6.854 0 0 0-.354 2.18C1.65 12.93 4.713 16 8.491 16c3.779 0 6.842-3.069 6.842-6.853 0-3.785-3.063-6.854-6.843-6.854Z"
        fill={fill}
      />
    </Svg>
  );
};

export default SvgBikeKcal;
