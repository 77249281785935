export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  RepCountingProfileId: any,
  RepCountingProfileVersionId: any,
  DateTime: string,
  ConnectedKitId: any,
  VIDEO_ID: string,
  UUID: string,
  URL: string,
  PlatformPartnerId: any,
  MovementStandardId: any,
  UserHandle: string,
  LessonInstanceId: string,
  HexColour: string,
  CollectionListId: string,
  CollectionId: string,
  EquipmentLevelId: string,
  EquipmentId: string,
  MusicGenreId: string,
  JSONObject: any,
  TrainingPlanListId: any,
  Weeks: string,
  Seconds: number,
  Email: string,
  Date: string,
  TrainingPlanReviewAchievementId: string,
  LessonMediaId: string,
  MusicId: string,
  MusicTrackId: string,
  TrackInfoId: string,
  LessonVideoRevisionId: string,
  BrandId: string,
  DeviceId: string,
  DeviceWorkoutMetadataId: string,
  UserChallengeBlockGoalId: any,
  UserChallengeBlockId: any,
  UserChallengeId: any,
  ChallengeId: any,
  PrizeId: string,
  ChallengeBlockId: any,
  ChallengeBlockGoalId: any,
  PartnerId: any,
  PerkId: any,
  TierId: any,
  ChallengeListId: any,
  GraphQLHeartRate: string,
  WorkoutReactionId: any,
  SingleEmoji: any,
  UserBehaviourReportId: string,
  PartyId: any,
  InstallationId: any,
  LobbyEntryId: any,
  TeamId: any,
  CustomEquipmentId: any,
  RandomSpinPrizeId: any,
  UserRandomSpinPrizeSpinId: any,
  DeepLinkAttemptInfoId: any,
  CollectionUpdateId: any,
  Markdown: string,
  LessonInstanceScheduleId: any,
  TeamBehaviourReportId: any,
  Days: string,
  CommunicationMessageGroupId: string,
  Slug: any,
  FreeSubCodeId: any,
  Upload: string,
  PaidSubCodeId: any,
  TrainingPlanRecommendationId: any,
  LessonPrescribedWeightsId: any,
  Time: string,
  Fraction: any,
  UserChallengeBlockGoalWorkoutId: any,
};

export type AchievementInsights = {
   __typename?: 'AchievementInsights',
  vsHighest?: Maybe<InsightProps>,
  vsFirst?: Maybe<InsightProps>,
  displayText?: Maybe<Scalars['String']>,
};

export type ActivityCumulativeMetricSummary = {
  id: Scalars['Int'],
  sum?: Maybe<Scalars['Float']>,
  recordedAt?: Maybe<Scalars['DateTime']>,
};

export type ActivityHistory = {
   __typename?: 'ActivityHistory',
  activity?: Maybe<TimelineActivity>,
  personalBest?: Maybe<ActivityPersonalBest>,
};

export type ActivityHistoryConnection = {
   __typename?: 'ActivityHistoryConnection',
  edges?: Maybe<Array<ActivityHistoryEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ActivityHistoryEdge = {
   __typename?: 'ActivityHistoryEdge',
  node: ActivityHistory,
};

export enum ActivityHistoryOrder {
  RECORDED_AT = 'RECORDED_AT',
  CURRENT_PB = 'CURRENT_PB'
}

export type ActivityInstantaneousMetricSummary = {
  id: Scalars['Int'],
  avg?: Maybe<Scalars['Float']>,
  max?: Maybe<Scalars['Float']>,
  recordedAt?: Maybe<Scalars['DateTime']>,
};

export type ActivityMetrics = {
   __typename?: 'ActivityMetrics',
  bpm?: Maybe<BpmMetricConfig>,
  reps?: Maybe<RepsMetricConfig>,
  score?: Maybe<ScoreMetricConfig>,
  totalScore?: Maybe<TotalScoreMetricConfig>,
  kcal?: Maybe<KcalMetricConfig>,
  motion?: Maybe<MotionMetricConfig>,
  workRate?: Maybe<WorkRateMetricConfig>,
  bikeKcal?: Maybe<BikeKcalMetricConfig>,
  bikeRpm?: Maybe<BikeRpmMetricConfig>,
  bikeSpeed?: Maybe<BikeSpeedMetricConfig>,
  bikeWatts?: Maybe<BikeWattsMetricConfig>,
  bikeTotalScore?: Maybe<BikeTotalScoreMetricConfig>,
  bikeDistance?: Maybe<BikeDistanceMetricConfig>,
  treadKcal?: Maybe<TreadKcalMetricConfig>,
  treadSpeed?: Maybe<TreadSpeedMetricConfig>,
  treadTotalScore?: Maybe<TreadTotalScoreMetricConfig>,
  treadWatts?: Maybe<TreadWattsMetricConfig>,
  treadRpm?: Maybe<TreadRpmMetricConfig>,
  treadDistance?: Maybe<TreadDistanceMetricConfig>,
  rowKcal?: Maybe<RowKcalMetricConfig>,
  rowSpeed?: Maybe<RowSpeedMetricConfig>,
  rowWatts?: Maybe<RowWattsMetricConfig>,
  rowTotalScore?: Maybe<RowTotalScoreMetricConfig>,
  rowDistance?: Maybe<RowDistanceMetricConfig>,
  rowResistance?: Maybe<RowResistanceMetricConfig>,
  rowSpm?: Maybe<RowSpmMetricConfig>,
  spinKcal?: Maybe<SpinKcalMetricConfig>,
  spinRpm?: Maybe<SpinRpmMetricConfig>,
  spinSpeed?: Maybe<SpinSpeedMetricConfig>,
  spinWatts?: Maybe<SpinWattsMetricConfig>,
  spinTotalScore?: Maybe<SpinTotalScoreMetricConfig>,
  spinDistance?: Maybe<SpinDistanceMetricConfig>,
  spinResistance?: Maybe<SpinResistanceMetricConfig>,
};

export type ActivityPersonalBest = {
   __typename?: 'ActivityPersonalBest',
  reps?: Maybe<PersonalBestMetric>,
  bikeKcal?: Maybe<PersonalBestMetric>,
  score?: Maybe<PersonalBestMetric>,
  treadKcal?: Maybe<PersonalBestMetric>,
  spinKcal?: Maybe<PersonalBestMetric>,
  rowKcal?: Maybe<PersonalBestMetric>,
};

export enum ActivityTypeInput {
  TRANSITION = 'TRANSITION',
  SET = 'SET',
  CIRCUIT = 'CIRCUIT'
}

export type Address = {
   __typename?: 'Address',
  id: Scalars['Int'],
  addressOne: Scalars['String'],
  addressTwo?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  region?: Maybe<Scalars['String']>,
  country: CountryCode,
  postalCode: Scalars['String'],
};

export type AddressInput = {
  id?: Maybe<Scalars['Int']>,
  addressOne?: Maybe<Scalars['String']>,
  addressTwo?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  country?: Maybe<CountryCode>,
  postalCode?: Maybe<Scalars['String']>,
};

export type AgeRange = {
  from?: Maybe<Scalars['Int']>,
  to?: Maybe<Scalars['Int']>,
};

export type AggregationSummary = {
   __typename?: 'AggregationSummary',
  summary?: Maybe<MetricsSummary>,
  completed: Scalars['Int'],
  finished?: Maybe<Scalars['Int']>,
  highlights?: Maybe<Array<ActivityHistory>>,
  activities: ActivityHistoryConnection,
  workouts: WorkoutConnection,
};


export type AggregationSummaryActivitiesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ActivityHistoryOrder>
};


export type AggregationSummaryWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>
};

export type Agreement = {
   __typename?: 'Agreement',
  id: Scalars['Int'],
  type: AgreementType,
  text: Scalars['Markdown'],
  url: Scalars['URL'],
  published: Scalars['Boolean'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  acceptedByUser: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type AgreementAcceptedByUserArgs = {
  user: UserInput
};

export type AgreementCondition = {
  type?: Maybe<AgreementType>,
  published?: Maybe<Scalars['Boolean']>,
};

export type AgreementConnection = {
   __typename?: 'AgreementConnection',
  edges?: Maybe<Array<AgreementEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type AgreementEdge = {
   __typename?: 'AgreementEdge',
  node: Agreement,
};

export enum AgreementOrder {
  PUBLISHED_AT = 'PUBLISHED_AT'
}

export enum AgreementType {
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  HEALTH_DISCLAIMER = 'HEALTH_DISCLAIMER',
  POSTNATAL = 'POSTNATAL',
  BREATHWORK = 'BREATHWORK'
}

export type AllReactionsCondition = {
  user?: Maybe<UserInput>,
};

export type AnimationVideo = {
   __typename?: 'AnimationVideo',
  videoName: Scalars['String'],
};

export type AnimationVideoInput = {
  videoName: Scalars['String'],
};

export enum AttributeName {
  COLOR = 'COLOR',
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  RRP = 'RRP'
}

export type Attribution = {
   __typename?: 'Attribution',
  id: Scalars['String'],
  description: Scalars['String'],
  parent?: Maybe<Attribution>,
};

export type AttributionEdge = {
   __typename?: 'AttributionEdge',
  node: Attribution,
};

export enum AttributionOrder {
  DESCRIPTION = 'DESCRIPTION'
}

export type Auth = {
   __typename?: 'Auth',
  user?: Maybe<User>,
  permissions: AuthPermissions,
  features: AuthFeatures,
};

export type AuthFeatureAllowSkippingPremiumOverlay = {
   __typename?: 'AuthFeatureAllowSkippingPremiumOverlay',
  value: Scalars['Boolean'],
};

export type AuthFeatureAlphaContent = {
   __typename?: 'AuthFeatureAlphaContent',
  connectedKit: Scalars['Boolean'],
  search: Scalars['Boolean'],
  tread: Scalars['Boolean'],
  chromecast: Scalars['Boolean'],
  summaryViewData: Scalars['Boolean'],
  teams: Scalars['Boolean'],
  xpLevels: Scalars['Boolean'],
  randomSpinPrizes: Scalars['Boolean'],
};

export type AuthFeatureAutoLogout = {
   __typename?: 'AuthFeatureAutoLogout',
  value: Scalars['Boolean'],
  time: Scalars['Seconds'],
};

export type AuthFeatureCrowdfundContentEnabledPermission = {
   __typename?: 'AuthFeatureCrowdfundContentEnabledPermission',
  value: Scalars['Boolean'],
};

export type AuthFeatureDebugPermission = {
   __typename?: 'AuthFeatureDebugPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<AuthFeatureDebugReason>>,
};

export enum AuthFeatureDebugReason {
  INSUFFICIENT_SCOPES = 'INSUFFICIENT_SCOPES'
}

export type AuthFeatureOfferAddToCalender = {
   __typename?: 'AuthFeatureOfferAddToCalender',
  value: Scalars['Boolean'],
};

export type AuthFeatureOfferConnectAppleHealth = {
   __typename?: 'AuthFeatureOfferConnectAppleHealth',
  value: Scalars['Boolean'],
};

export type AuthFeatureOfferShareClassTrainerOrPlan = {
   __typename?: 'AuthFeatureOfferShareClassTrainerOrPlan',
  value: Scalars['Boolean'],
};

export type AuthFeatureOfferShareProfileUrl = {
   __typename?: 'AuthFeatureOfferShareProfileUrl',
  value: Scalars['Boolean'],
};

export type AuthFeaturePerksPermission = {
   __typename?: 'AuthFeaturePerksPermission',
  value: Scalars['Boolean'],
};

export type AuthFeaturePromoteFacebookGroup = {
   __typename?: 'AuthFeaturePromoteFacebookGroup',
  value: Scalars['Boolean'],
};

export type AuthFeaturePromoteInAppHero = {
   __typename?: 'AuthFeaturePromoteInAppHero',
  text?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  imageUrl?: Maybe<Scalars['String']>,
  hexColour?: Maybe<Scalars['HexColour']>,
  enabled: Scalars['Boolean'],
};

export type AuthFeaturePromoteUpgrade = {
   __typename?: 'AuthFeaturePromoteUpgrade',
  value: Scalars['Boolean'],
};

export type AuthFeatureRealTimeStatsRdsPermission = {
   __typename?: 'AuthFeatureRealTimeStatsRdsPermission',
  value: Scalars['Boolean'],
};

export type AuthFeatureRealTimeStatsWsPermission = {
   __typename?: 'AuthFeatureRealTimeStatsWsPermission',
  value: Scalars['Boolean'],
};

export type AuthFeatures = {
   __typename?: 'AuthFeatures',
  allowSkippingPremiumOverlay: AuthFeatureAllowSkippingPremiumOverlay,
  autoLogout: AuthFeatureAutoLogout,
  offerConnectAppleHealth: AuthFeatureOfferConnectAppleHealth,
  offerAddToCalender: AuthFeatureOfferAddToCalender,
  offerShareClassTrainerOrPlan: AuthFeatureOfferShareClassTrainerOrPlan,
  offerShareProfileUrl: AuthFeatureOfferShareProfileUrl,
  promoteFacebookGroup: AuthFeaturePromoteFacebookGroup,
  promoteUpgrade: AuthFeaturePromoteUpgrade,
  alphaContent: AuthFeatureAlphaContent,
  debug: AuthFeatureDebugPermission,
  realTimeStatsWs: AuthFeatureRealTimeStatsWsPermission,
  crowdfundContentEnabled: AuthFeatureCrowdfundContentEnabledPermission,
  realTimeStatsRds: AuthFeatureRealTimeStatsRdsPermission,
  promoteInAppHero: AuthFeaturePromoteInAppHero,
  storyly: AuthFeatureStorylyPermission,
  perks: AuthFeaturePerksPermission,
};

export type AuthFeatureStorylyPermission = {
   __typename?: 'AuthFeatureStorylyPermission',
  value: Scalars['Boolean'],
};

export type AuthPermissions = {
   __typename?: 'AuthPermissions',
  referAFriend: Scalars['Boolean'],
  useTracker: Scalars['Boolean'],
  useDevices: Scalars['Boolean'],
  payViaApple: Scalars['Boolean'],
  useAccelerometer: Scalars['Boolean'],
  useFiitRepTracking: Scalars['Boolean'],
  sendAccelerometerStats: Scalars['Boolean'],
  usePayOrder: Scalars['Boolean'],
  useCkContent: Scalars['Boolean'],
  useLeaderboards: Scalars['Boolean'],
  useSpecificLeaderboards: LeaderboardsPermissions,
  useYouVsYou: Scalars['Boolean'],
  useProgressionScore: Scalars['Boolean'],
  useAllTimeStats: Scalars['Boolean'],
  useTrophies: Scalars['Boolean'],
  useStreaks: Scalars['Boolean'],
  useWorkoutSummaryStats: Scalars['Boolean'],
  useTutorials: Scalars['Boolean'],
  useFavourites: FavouriteLessonsTrainers,
  useShareProfile: Scalars['Boolean'],
  canCreateTeams: Scalars['Boolean'],
  canPauseTrainingPlans: Scalars['Boolean'],
  canScheduleLiveClasses: Scalars['Boolean'],
};

export type AuthRemoteAppPinInput = {
  pin: Scalars['String'],
};

export type AuthRemoteAppPinResponse = {
   __typename?: 'AuthRemoteAppPinResponse',
  success: Scalars['Boolean'],
};

export type BikeDistanceMetricConfig = {
   __typename?: 'BikeDistanceMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type BikeKcalMetricConfig = {
   __typename?: 'BikeKcalMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type BikeRpmMetricConfig = {
   __typename?: 'BikeRPMMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type BikeSpeedMetricConfig = {
   __typename?: 'BikeSpeedMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type BikeTotalScoreMetricConfig = {
   __typename?: 'BikeTotalScoreMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};

export type BikeWattsMetricConfig = {
   __typename?: 'BikeWattsMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type Blog = {
   __typename?: 'Blog',
  id: Scalars['Int'],
  title: Scalars['String'],
  blurb: Scalars['String'],
  url: Scalars['URL'],
  image: Image,
  authoredAt: Scalars['DateTime'],
};

export type BlogConnection = {
   __typename?: 'BlogConnection',
  edges: Array<BlogEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type BlogEdge = {
   __typename?: 'BlogEdge',
  node: Blog,
};

export enum BlogOrder {
  UPDATED_AT = 'UPDATED_AT',
  AUTHORED_AT = 'AUTHORED_AT'
}

export enum BodyLocation {
  OTHER = 'OTHER',
  CHEST = 'CHEST',
  WRIST = 'WRIST',
  FINGER = 'FINGER',
  HAND = 'HAND',
  EAR_LOBE = 'EAR_LOBE',
  FOOT = 'FOOT'
}

export type BodyPart = {
   __typename?: 'BodyPart',
  id: Scalars['Int'],
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type BpmMetricConfig = {
   __typename?: 'BpmMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type Brand = {
   __typename?: 'Brand',
  id: Scalars['BrandId'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
};


export type BulkPatchFreeSubCodeInput = {
  expiresAt?: Maybe<Scalars['DateTime']>,
};

export type BulkPatchFreeSubCodesResponse = {
   __typename?: 'BulkPatchFreeSubCodesResponse',
  patchedCount: Scalars['Int'],
};

export type BulkPatchPaidSubCodeInput = {
  expiresAt?: Maybe<Scalars['DateTime']>,
  teamId?: Maybe<Scalars['TeamId']>,
};

export type BulkPatchPaidSubCodesResponse = {
   __typename?: 'BulkPatchPaidSubCodesResponse',
  patchedCount: Scalars['Int'],
};

export type BulkPatchUserSubscriptionInput = {
  paymentProvider: PaymentProvider,
};

export type BulkPatchUserSubscriptionResponse = {
   __typename?: 'BulkPatchUserSubscriptionResponse',
  patchedCount: Scalars['Int'],
};

export enum Calibration {
  abdominalCrunches = 'abdominalCrunches',
  plank = 'plank',
  forLunges = 'forLunges',
  birdDog = 'birdDog',
  runningInPlace = 'runningInPlace',
  pushUps = 'pushUps',
  jumpingJacks = 'jumpingJacks',
  squats = 'squats',
  mountainClimbers = 'mountainClimbers',
  pushUpsWithRotation = 'pushUpsWithRotation',
  sidePlank = 'sidePlank',
  tricepsDips = 'tricepsDips',
  diamondPushUps = 'diamondPushUps'
}

export type CancelBetterSpaceSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelBetterSpaceSubscriptionResponse = {
   __typename?: 'CancelBetterSpaceSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelGympassSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelGympassSubscriptionResponse = {
   __typename?: 'CancelGympassSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelHearstSubscriptionInput = {
  customerId: Scalars['String'],
  subscriptionType: Scalars['String'],
};

export type CancelHearstSubscriptionResponse = {
   __typename?: 'CancelHearstSubscriptionResponse',
  authorizedThirdPartyMembershipId: Scalars['String'],
};

export type CancelHekaSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelHekaSubscriptionResponse = {
   __typename?: 'CancelHekaSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelHussleSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelHussleSubscriptionResponse = {
   __typename?: 'CancelHussleSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelMoveGbSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelMoveGbSubscriptionResponse = {
   __typename?: 'CancelMoveGBSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelPartnerSubscriptionInput = {
  partnerId: Scalars['PartnerId'],
  email: Scalars['Email'],
};

export type CancelPartnerSubscriptionResponse = {
   __typename?: 'CancelPartnerSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelPingSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelPingSubscriptionResponse = {
   __typename?: 'CancelPingSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelProviderSubscriptionInput = {
  email: Scalars['Email'],
  paymentProviderId: PaymentProvider,
};

export type CancelProviderSubscriptionResponse = {
   __typename?: 'CancelProviderSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CancelTggSubscriptionInput = {
  tggMembershipId: Scalars['String'],
};

export type CancelTggSubscriptionResponse = {
   __typename?: 'CancelTggSubscriptionResponse',
  tggMembershipId: Scalars['String'],
};

export type CancelYuLifeSubscriptionInput = {
  email: Scalars['Email'],
};

export type CancelYuLifeSubscriptionResponse = {
   __typename?: 'CancelYuLifeSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type Challenge = {
   __typename?: 'Challenge',
  id: Scalars['ChallengeId'],
  name: Scalars['String'],
  status: ChallengeStatus,
  slug: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  coverImage?: Maybe<Image>,
  video?: Maybe<Video>,
  prize?: Maybe<Prize>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  startsAt: Scalars['DateTime'],
  endsAt?: Maybe<Scalars['DateTime']>,
  publishedAt?: Maybe<Scalars['DateTime']>,
  blocks: Array<ChallengeBlock>,
  premium: Scalars['Boolean'],
  permissionsWithReasons: ChallengePermissionsWithReasons,
  userCount: Scalars['Int'],
  userChallenges: UserChallengeConnection,
  partner?: Maybe<Partner>,
  badgeImage?: Maybe<Image>,
  completionMessage?: Maybe<Scalars['String']>,
  xpReward?: Maybe<Scalars['Int']>,
  tier?: Maybe<Array<Tier>>,
  type: ChallengeType,
  challengePrivacyLevel: ChallengePrivacyLevel,
  promoted: Scalars['Boolean'],
  recommendedNextChallengeLists: ChallengeListConnection,
};


export type ChallengeBlocksArgs = {
  orderBy?: Maybe<ChallengeBlockOrder>,
  condition?: Maybe<ChallengeBlockCondition>
};


export type ChallengeUserChallengesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<UserChallengeOrder>,
  condition?: Maybe<UserChallengeCondition>
};


export type ChallengeRecommendedNextChallengeListsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ChallengeListOrder>
};

export type ChallengeBlock = {
   __typename?: 'ChallengeBlock',
  id: Scalars['ChallengeBlockId'],
  challenge: Challenge,
  description?: Maybe<Scalars['String']>,
  durationUnit: ChallengeBlockDurationUnit,
  durationValue: Scalars['Int'],
  startsAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  achievementType: WorkoutAchievementType,
  goals: Array<ChallengeBlockGoal>,
  metrics: ChallengeBlockMetrics,
};

export type ChallengeBlockCondition = {
  startsAfter?: Maybe<Scalars['DateTime']>,
  startsBefore?: Maybe<Scalars['DateTime']>,
  endsBefore?: Maybe<Scalars['DateTime']>,
  endsAfter?: Maybe<Scalars['DateTime']>,
};

export enum ChallengeBlockDurationUnit {
  DAYS = 'DAYS'
}

export type ChallengeBlockGoal = {
   __typename?: 'ChallengeBlockGoal',
  id: Scalars['ChallengeBlockGoalId'],
  challengeBlock: ChallengeBlock,
  goalValue: Scalars['Int'],
  achievementValue?: Maybe<Scalars['Int']>,
  collection?: Maybe<Collection>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  trainingPlanList?: Maybe<TrainingPlanList>,
};


export type ChallengeBlockGoalInput = {
  goalValue: Scalars['Int'],
  achievementValue?: Maybe<Scalars['Int']>,
  collectionId?: Maybe<Scalars['CollectionId']>,
  trainingPlanListId?: Maybe<Scalars['TrainingPlanListId']>,
};


export type ChallengeBlockInput = {
  description: Scalars['String'],
  durationUnit: ChallengeBlockDurationUnit,
  durationValue: Scalars['Int'],
  achievementType: WorkoutAchievementType,
  goal: Array<ChallengeBlockGoalInput>,
};

export type ChallengeBlockMetrics = {
   __typename?: 'ChallengeBlockMetrics',
  progress: ChallengeBlockProgress,
};

export enum ChallengeBlockOrder {
  STARTS_AT = 'STARTS_AT'
}

export type ChallengeBlockProgress = {
   __typename?: 'ChallengeBlockProgress',
  median: Scalars['Float'],
};

export type ChallengeCondition = {
  startsAfter?: Maybe<Scalars['DateTime']>,
  startsBefore?: Maybe<Scalars['DateTime']>,
  endsBefore?: Maybe<Scalars['DateTime']>,
  endsAfter?: Maybe<Scalars['DateTime']>,
  status?: Maybe<Array<ChallengeStatus>>,
  type?: Maybe<Array<ChallengeType>>,
  challengePrivacyLevel?: Maybe<Array<ChallengePrivacyLevel>>,
};

export type ChallengeConnection = {
   __typename?: 'ChallengeConnection',
  edges?: Maybe<Array<ChallengeEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ChallengeEdge = {
   __typename?: 'ChallengeEdge',
  node: Challenge,
};


export type ChallengeList = {
   __typename?: 'ChallengeList',
  id: Scalars['ChallengeListId'],
  challenges: ChallengeConnection,
};


export type ChallengeListChallengesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ListedChallengeOrder>,
  condition?: Maybe<ChallengeCondition>
};

export type ChallengeListConnection = {
   __typename?: 'ChallengeListConnection',
  edges?: Maybe<Array<ChallengeListEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ChallengeListEdge = {
   __typename?: 'ChallengeListEdge',
  node: ChallengeList,
};


export enum ChallengeListOrder {
  CREATED_AT = 'CREATED_AT'
}

export enum ChallengeOrder {
  CREATED_AT = 'CREATED_AT',
  STARTS_AT = 'STARTS_AT',
  PROMOTED = 'PROMOTED'
}

export type ChallengePermissionsWithReasons = {
   __typename?: 'ChallengePermissionsWithReasons',
  start: ChallengeStartPermission,
};

export enum ChallengePrivacyLevel {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type ChallengeStartPermission = {
   __typename?: 'ChallengeStartPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<ChallengeStartPermissionReason>>,
};

export enum ChallengeStartPermissionReason {
  NO_USER = 'NO_USER',
  LOCKED = 'LOCKED',
  PREMIUM = 'PREMIUM',
  ALREADY_SIGNED_UP = 'ALREADY_SIGNED_UP',
  ENDED = 'ENDED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

export enum ChallengeStatus {
  DRAFT = 'DRAFT',
  TESTING = 'TESTING',
  PUBLISHED = 'PUBLISHED',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED'
}

export enum ChallengeType {
  SET_TIME = 'SET_TIME',
  ANY_TIME = 'ANY_TIME'
}

export type CheckCreateTggSubscriptionInput = {
  email: Scalars['Email'],
  tggMembershipId: Scalars['String'],
};

export type CheckCreateTggSubscriptionResponse = {
   __typename?: 'CheckCreateTggSubscriptionResponse',
  success: Scalars['Boolean'],
};

export enum CircuitType {
  INTRO_SECTION = 'INTRO_SECTION',
  WARMUP_SECTION = 'WARMUP_SECTION',
  TRANSITION_SECTION = 'TRANSITION_SECTION',
  REST_SECTION = 'REST_SECTION',
  WORKOUT_SECTION = 'WORKOUT_SECTION',
  COOLDOWN_SECTION = 'COOLDOWN_SECTION',
  OUTRO_SECTION = 'OUTRO_SECTION',
  HIIT = 'HIIT'
}

export type ClaimRandomSpinPrizeInput = {
  userRandomSpinPrizeSpinId: Scalars['UserRandomSpinPrizeSpinId'],
};

export type ClientMetricsSummaryInput = {
  time?: Maybe<CumulativeMetricSummary>,
  kcal?: Maybe<CumulativeMetricSummary>,
  reps?: Maybe<CumulativeMetricSummary>,
  score?: Maybe<CumulativeMetricSummary>,
  totalScore?: Maybe<CumulativeMetricSummary>,
  workRate?: Maybe<CumulativeMetricSummary>,
  bikeKcal?: Maybe<CumulativeMetricSummary>,
  bikeDistance?: Maybe<CumulativeMetricSummary>,
  bikeTotalScore?: Maybe<CumulativeMetricSummary>,
  bpm?: Maybe<InstantaneousMetricSummary>,
  bikeRpm?: Maybe<InstantaneousMetricSummary>,
  bikeSpeed?: Maybe<InstantaneousMetricSummary>,
  bikeWatts?: Maybe<InstantaneousMetricSummary>,
  BikeRPM?: Maybe<InstantaneousMetricSummary>,
  treadKcal?: Maybe<CumulativeMetricSummary>,
  treadTotalScore?: Maybe<CumulativeMetricSummary>,
  treadDistance?: Maybe<CumulativeMetricSummary>,
  treadSpeed?: Maybe<InstantaneousMetricSummary>,
  treadRpm?: Maybe<InstantaneousMetricSummary>,
  treadWatts?: Maybe<InstantaneousMetricSummary>,
  rowKcal?: Maybe<CumulativeMetricSummary>,
  rowTotalScore?: Maybe<CumulativeMetricSummary>,
  rowDistance?: Maybe<CumulativeMetricSummary>,
  rowSpeed?: Maybe<InstantaneousMetricSummary>,
  rowWatts?: Maybe<InstantaneousMetricSummary>,
  rowSpm?: Maybe<InstantaneousMetricSummary>,
  rowResistance?: Maybe<InstantaneousMetricSummary>,
  spinKcal?: Maybe<CumulativeMetricSummary>,
  spinTotalScore?: Maybe<CumulativeMetricSummary>,
  spinDistance?: Maybe<CumulativeMetricSummary>,
  spinSpeed?: Maybe<InstantaneousMetricSummary>,
  spinRpm?: Maybe<InstantaneousMetricSummary>,
  spinWatts?: Maybe<InstantaneousMetricSummary>,
  spinResistance?: Maybe<InstantaneousMetricSummary>,
};

export type Collection = {
   __typename?: 'Collection',
  id: Scalars['CollectionId'],
  slug: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  image: Image,
  lessons: LessonConnection,
  conditions: CollectionConditions,
  lessonConditions: Scalars['JSONObject'],
  relatedCollectionList?: Maybe<CollectionList>,
  labels?: Maybe<Array<CollectionLabel>>,
  trainingPlanList?: Maybe<TrainingPlanList>,
  updates: CollectionUpdateConnection,
};


export type CollectionLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonOrder>,
  condition?: Maybe<LessonCondition>
};


export type CollectionUpdatesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<CollectionUpdateOrder>,
  condition?: Maybe<CollectionUpdateCondition>
};

export type CollectionConditionInput = {
  lessonType?: Maybe<Array<Scalars['Int']>>,
  lessonOutcome?: Maybe<Array<Scalars['Int']>>,
  equipmentLevel?: Maybe<Array<Scalars['EquipmentLevelId']>>,
  studio?: Maybe<Array<StudioType>>,
};

export type CollectionConditions = {
   __typename?: 'CollectionConditions',
  studio?: Maybe<Array<Studio>>,
  trainer?: Maybe<Array<Trainer>>,
  muscle?: Maybe<Array<Muscle>>,
  bodyPart?: Maybe<Array<BodyPart>>,
  lessonType?: Maybe<Array<LessonType>>,
  lessonOutcome?: Maybe<Array<LessonOutcome>>,
  lessonRecoverySport?: Maybe<Array<LessonRecoverySport>>,
  equipmentLevel?: Maybe<Array<EquipmentLevel>>,
  trainingGoal?: Maybe<Array<TrainingGoal>>,
  musicGenre?: Maybe<Array<MusicGenre>>,
  difficulty?: Maybe<Array<LessonDifficulty>>,
  durationRange?: Maybe<Array<LessonDurationRange>>,
};

export type CollectionConnection = {
   __typename?: 'CollectionConnection',
  edges: Array<CollectionEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type CollectionEdge = {
   __typename?: 'CollectionEdge',
  node: Collection,
};


export enum CollectionLabel {
  NEW = 'NEW'
}

export type CollectionList = {
   __typename?: 'CollectionList',
  id: Scalars['CollectionListId'],
  slug: Scalars['String'],
  name: Scalars['String'],
  collections: CollectionConnection,
};


export type CollectionListCollectionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<CollectionOrder>
};

export type CollectionListConnection = {
   __typename?: 'CollectionListConnection',
  edges: Array<CollectionListEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type CollectionListEdge = {
   __typename?: 'CollectionListEdge',
  node: CollectionList,
};


export enum CollectionListOrder {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export enum CollectionOrder {
  DISPLAY_ORDER = 'DISPLAY_ORDER',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type CollectionUpdate = {
   __typename?: 'CollectionUpdate',
  id: Scalars['CollectionUpdateId'],
  conditions?: Maybe<CollectionConditions>,
  lessonConditions?: Maybe<Scalars['JSONObject']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  collection: Collection,
  updated: Scalars['Boolean'],
  updateDate: Scalars['Date'],
};

export type CollectionUpdateCondition = {
  updated?: Maybe<Scalars['Boolean']>,
  inFuture?: Maybe<Scalars['Boolean']>,
};

export type CollectionUpdateConnection = {
   __typename?: 'CollectionUpdateConnection',
  edges: Array<CollectionUpdateEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type CollectionUpdateEdge = {
   __typename?: 'CollectionUpdateEdge',
  node: CollectionUpdate,
};


export enum CollectionUpdateOrder {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATE_DATE = 'UPDATE_DATE'
}

export type CommunicationChannel = {
   __typename?: 'CommunicationChannel',
  id: Scalars['Int'],
  type: CommunicationChannelType,
  medium: CommunicationChannelMedium,
};

export type CommunicationChannelMedium = {
   __typename?: 'CommunicationChannelMedium',
  id: Scalars['String'],
  name: Scalars['String'],
};

export enum CommunicationChannelType {
  MARKETING = 'MARKETING',
  TRANSACTIONAL = 'TRANSACTIONAL'
}

export type CommunicationMessageGroup = {
   __typename?: 'CommunicationMessageGroup',
  id: Scalars['CommunicationMessageGroupId'],
  name: Scalars['String'],
  description: Scalars['String'],
  displayOrder: Scalars['Int'],
  messageTypes: Array<CommunicationMessageType>,
};


export type CommunicationMessageGroupMessageTypesArgs = {
  condition?: Maybe<CommunicationMessageTypeCondition>
};


export enum CommunicationMessageGroupOrder {
  DISPLAY_ORDER = 'DISPLAY_ORDER'
}

export type CommunicationMessageType = {
   __typename?: 'CommunicationMessageType',
  id: Scalars['Int'],
  channel: CommunicationChannel,
  subscribedByUser: Scalars['Boolean'],
};


export type CommunicationMessageTypeSubscribedByUserArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};

export type CommunicationMessageTypeCondition = {
  channelType?: Maybe<CommunicationChannelType>,
};

export type ConnectedKit = {
   __typename?: 'ConnectedKit',
  id: Scalars['ConnectedKitId'],
  displayName: Scalars['String'],
  value: Scalars['String'],
};


export enum ConnectedKitOrder {
  DISPLAY_NAME = 'DISPLAY_NAME'
}

export type ContentVideo = {
   __typename?: 'ContentVideo',
  id: ContentVideoId,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  video?: Maybe<Video>,
};

export type ContentVideoConnection = {
   __typename?: 'ContentVideoConnection',
  edges: Array<ContentVideoEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ContentVideoDetails = {
   __typename?: 'ContentVideoDetails',
  id: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  video: Video,
};

export type ContentVideoEdge = {
   __typename?: 'ContentVideoEdge',
  node: ContentVideoDetails,
};

export enum ContentVideoId {
  INTRO = 'INTRO',
  PREVIEW = 'PREVIEW',
  INDUCTION = 'INDUCTION',
  DEVICE = 'DEVICE',
  JUMP_SQUATS = 'JUMP_SQUATS',
  LUNGES = 'LUNGES',
  SWIPE_FOR_STATS = 'SWIPE_FOR_STATS',
  SWIPE_FOR_LEADERBOARD = 'SWIPE_FOR_LEADERBOARD',
  PAUSE_EXIT_CLASS = 'PAUSE_EXIT_CLASS',
  COUNT_REPS = 'COUNT_REPS',
  CONNECT_YOUR_TV = 'CONNECT_YOUR_TV'
}

export enum ContentVideoOrder {
  TITLE = 'TITLE',
  PUBLISHED_AT = 'PUBLISHED_AT'
}

export type ContentVideoToTrainerInput = {
  contentVideoId: Scalars['String'],
  trainerId: Scalars['Int'],
};

export enum CountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateAgreementInput = {
  type: AgreementType,
  url: Scalars['URL'],
};

export type CreateAttributionInput = {
  parent: Scalars['String'],
  description: Scalars['String'],
};

export type CreateBetterSpaceSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreateBetterSpaceSubscriptionResponse = {
   __typename?: 'CreateBetterSpaceSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreateBlogInput = {
  title: Scalars['String'],
  blurb: Scalars['String'],
  url: Scalars['URL'],
  imageId: Scalars['Int'],
  authoredAt: Scalars['DateTime'],
};

export type CreateBodyPartInput = {
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type CreateBrandInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
};

export type CreateChallengeBlockGoalInput = {
  goalValue: Scalars['Int'],
  achievementValue?: Maybe<Scalars['Int']>,
  collectionId?: Maybe<Scalars['CollectionId']>,
  trainingPlanListId?: Maybe<Scalars['TrainingPlanListId']>,
};

export type CreateChallengeBlockInput = {
  description?: Maybe<Scalars['String']>,
  durationUnit: ChallengeBlockDurationUnit,
  durationValue: Scalars['Int'],
  startsAt?: Maybe<Scalars['DateTime']>,
  achievementType: WorkoutAchievementType,
  goal?: Maybe<Array<CreateChallengeBlockGoalInput>>,
};

export type CreateChallengeInput = {
  name: Scalars['String'],
  slug?: Maybe<Scalars['Slug']>,
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  coverImageId?: Maybe<Scalars['Int']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  prizeId?: Maybe<Scalars['String']>,
  startsAt: Scalars['DateTime'],
  endsAt?: Maybe<Scalars['DateTime']>,
  block?: Maybe<Array<CreateChallengeBlockInput>>,
  premium: Scalars['Boolean'],
  partnerId?: Maybe<Scalars['String']>,
  badgeImageId?: Maybe<Scalars['Int']>,
  completionMessage?: Maybe<Scalars['String']>,
  xpReward?: Maybe<Scalars['Int']>,
  tier?: Maybe<Array<Scalars['TierId']>>,
  type?: Maybe<ChallengeType>,
  challengePrivacyLevel?: Maybe<ChallengePrivacyLevel>,
  promoted?: Maybe<Scalars['Boolean']>,
};

export type CreateChallengeListInput = {
  challenges: Array<Scalars['ChallengeId']>,
};

export type CreateCollectionInput = {
  slug: Scalars['Slug'],
  name: Scalars['String'],
  description: Scalars['String'],
  imageId: Scalars['Int'],
  lessonConditions: Scalars['JSONObject'],
  relatedCollectionListId?: Maybe<Scalars['CollectionListId']>,
  trainingPlanListId?: Maybe<Scalars['TrainingPlanListId']>,
};

export type CreateCollectionListInput = {
  slug: Scalars['Slug'],
  name: Scalars['String'],
  collections: Array<Scalars['CollectionId']>,
};

export type CreateCollectionUpdateInput = {
  collectionId: Scalars['CollectionId'],
  updateDate: Scalars['Date'],
  lessonConditions?: Maybe<Scalars['JSONObject']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type CreateCommunicationMessageGroupInput = {
  name: Scalars['String'],
  description: Scalars['String'],
  messageTypeIds: Array<Scalars['Int']>,
  displayOrder: Scalars['Int'],
};

export type CreateCommunicationMessageGroupResponse = {
   __typename?: 'CreateCommunicationMessageGroupResponse',
  communicationMessageGroup: CommunicationMessageGroup,
};

export type CreateConnectedKitInput = {
  displayName: Scalars['String'],
  value: Scalars['String'],
};

export type CreateContentVideoInput = {
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  videoId: Scalars['VIDEO_ID'],
};

export type CreateCustomEquipmentInput = {
  equipmentName: Scalars['String'],
  show?: Maybe<Scalars['Boolean']>,
};

export type CreateDeepLinkAttemptInfoInput = {
  email: Scalars['Email'],
  deepLinkPath: Scalars['String'],
  marketingOptIn: Scalars['Boolean'],
};

export type CreateEquipmentInput = {
  value: Scalars['String'],
  type: Scalars['String'],
  shortDisplay: Scalars['String'],
  longDisplay: Scalars['String'],
  description: Scalars['String'],
  imageId: Scalars['Int'],
};

export type CreateEquipmentLevelInput = {
  value: Scalars['String'],
  displayName: Scalars['String'],
  description: Scalars['String'],
  equipmentId: Scalars['EquipmentId'],
};

export type CreateExerciseInput = {
  name: Scalars['String'],
  family: ExerciseFamily,
  calibration?: Maybe<Calibration>,
  muscle: MuscleGroup,
  doubleSided?: Maybe<Scalars['Boolean']>,
  supportedCircuitTypes: Array<ExerciseSupportedCircuitTypeInput>,
  supportedStudios: Array<ExerciseSupportedStudioInput>,
  difficulty: ExerciseDifficulty,
  wahooCalibrationId?: Maybe<Scalars['Int']>,
  repCountingProfileId?: Maybe<Scalars['RepCountingProfileId']>,
  active?: Maybe<Scalars['Boolean']>,
  connectedKitId?: Maybe<Scalars['ConnectedKitId']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
};

export type CreateGympassSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreateGympassSubscriptionResponse = {
   __typename?: 'CreateGympassSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreateHearstSubscriptionInput = {
  customerId: Scalars['String'],
  subscriptionType: Scalars['String'],
};

export type CreateHearstSubscriptionResponse = {
   __typename?: 'CreateHearstSubscriptionResponse',
  authorizedThirdPartyMembershipId: Scalars['String'],
};

export type CreateHekaSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreateHekaSubscriptionResponse = {
   __typename?: 'CreateHekaSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreateHussleSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreateHussleSubscriptionResponse = {
   __typename?: 'CreateHussleSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreateImageInput = {
  url: Scalars['URL'],
  horizontalAlignment?: Maybe<ImageHorizontalAlignment>,
  verticalAlignment?: Maybe<ImageVerticalAlignment>,
  hexColour?: Maybe<Scalars['HexColour']>,
};

export type CreateInstallationInput = {
  platform: InstallationPlatform,
  userAgent?: Maybe<Scalars['String']>,
};

export type CreateInstallationResponse = {
   __typename?: 'CreateInstallationResponse',
  installation: Installation,
};

export type CreateLessonInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  studioId: StudioType,
  difficulty: LessonDifficulty,
  mainImageId?: Maybe<Scalars['Int']>,
  durationRange: LessonDurationRange,
  muscle: Array<LessonMuscleInput>,
  trainer: Array<LessonTrainerInput>,
  countdownBeep: Scalars['Boolean'],
  finishingBeep: Scalars['Boolean'],
  plan: Array<PlanActivityInput>,
  exerciseDisplay?: Maybe<ExerciseDisplay>,
  filmAt?: Maybe<Scalars['DateTime']>,
  releaseAt?: Maybe<Scalars['DateTime']>,
  number?: Maybe<Scalars['Int']>,
  trainerMusic?: Maybe<Scalars['String']>,
  rxId?: Maybe<Scalars['VIDEO_ID']>,
  ledPage?: Maybe<Scalars['Int']>,
  premium?: Maybe<Scalars['Boolean']>,
  lessonType?: Maybe<Array<LessonTypeInput>>,
  bodyPart?: Maybe<Array<LessonBodyPartInput>>,
  lessonRecoverySport?: Maybe<Array<LessonRecoverySportInput>>,
  lessonOutcome?: Maybe<Array<LessonOutcomeInput>>,
  trainingGoal?: Maybe<Array<TrainingGoalInput>>,
  musicGenreId?: Maybe<Scalars['MusicGenreId']>,
  scheduledOnly?: Maybe<Scalars['Boolean']>,
  planOnly?: Maybe<Scalars['Boolean']>,
  ckSpecialAccess?: Maybe<Scalars['Boolean']>,
  concept?: Maybe<LessonConcept>,
  heroMetric?: Maybe<MetricTypes>,
  tier?: Maybe<Array<Scalars['TierId']>>,
  supportsLive?: Maybe<Scalars['Boolean']>,
};

export type CreateLessonInstanceInput = {
  lessonId: Scalars['Int'],
  type?: Maybe<LessonInstanceType>,
  joinability?: Maybe<LessonInstanceJoinability>,
  groupClassType?: Maybe<GroupClassType>,
  lobbyDurationSeconds: Scalars['Seconds'],
  workoutStartTime?: Maybe<Scalars['DateTime']>,
  prizeId?: Maybe<Scalars['String']>,
  primaryOwner?: Maybe<UserInput>,
  memberLimit?: Maybe<Scalars['Int']>,
  lessonMediaId?: Maybe<Scalars['LessonMediaId']>,
  playbackType?: Maybe<LessonInstancePlaybackType>,
  leaderboardDisplayType?: Maybe<LeaderboardDisplayType>,
  platformPartnerId?: Maybe<Scalars['PlatformPartnerId']>,
};

export type CreateLessonInstanceScheduleInput = {
  lessonId: Scalars['Int'],
  joinability?: Maybe<LessonInstanceJoinability>,
  groupClassType?: Maybe<GroupClassType>,
  lobbyDurationSeconds: Scalars['Seconds'],
  workoutStartTime?: Maybe<Scalars['DateTime']>,
  prizeId?: Maybe<Scalars['String']>,
  primaryOwner?: Maybe<UserInput>,
  memberLimit?: Maybe<Scalars['Int']>,
  lessonMediaId?: Maybe<Scalars['LessonMediaId']>,
  playbackType?: Maybe<LessonInstancePlaybackType>,
  leaderboardDisplayType?: Maybe<LeaderboardDisplayType>,
  schedulePeriod: SchedulePeriod,
  endsAt: Scalars['DateTime'],
  platformPartnerId?: Maybe<Scalars['PlatformPartnerId']>,
};

export type CreateLessonMediaInput = {
  lessonId: Scalars['Int'],
  musicId: Scalars['MusicId'],
  lessonVideoRevisionId: Scalars['LessonVideoRevisionId'],
  commercial: Scalars['Boolean'],
  hlsUuid: Scalars['UUID'],
  dashUuid?: Maybe<Scalars['UUID']>,
  uploadedByUserId?: Maybe<Scalars['Int']>,
  uploadedByUser?: Maybe<UserInput>,
  brandId?: Maybe<Scalars['BrandId']>,
};

export type CreateLessonOutcomeInput = {
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type CreateLessonRecoverySportInput = {
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type CreateLessonTypeInput = {
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type CreateLessonVideoRevisionInput = {
  videoId: Scalars['VIDEO_ID'],
  lessonId: Scalars['Int'],
  uploadedByUserId?: Maybe<Scalars['Int']>,
  uploadedByUser?: Maybe<UserInput>,
  displayName: Scalars['String'],
  brandId?: Maybe<Scalars['BrandId']>,
};

export type CreateMoveGbSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreateMoveGbSubscriptionResponse = {
   __typename?: 'CreateMoveGBSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreateMovementStandardInput = {
  exerciseId: Scalars['Int'],
  videoUuid: Scalars['UUID'],
  platformPartnerId?: Maybe<Scalars['String']>,
};

export type CreateMusicGenreInput = {
  displayName: Scalars['String'],
  value: Scalars['String'],
};

export type CreateMusicInput = {
  lessonId: Scalars['Int'],
  uploadedByUserId?: Maybe<Scalars['Int']>,
  uploadedByUser?: Maybe<UserInput>,
  displayName: Scalars['String'],
  commercial?: Maybe<Scalars['Boolean']>,
  type?: Maybe<MusicType>,
  tracks: Array<CreateMusicTrackInput>,
};

export type CreateMusicTrackInput = {
  mixStartSeconds: Scalars['Seconds'],
  mixEndSeconds: Scalars['Seconds'],
  trackStartSeconds: Scalars['Seconds'],
  trackEndSeconds: Scalars['Seconds'],
  info: CreateTrackInfoInput,
};

export type CreatePartnerInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  logoImageId: Scalars['Int'],
  type: PartnerType,
  superAdminUserId: Scalars['Int'],
  subscriptionLimit?: Maybe<Scalars['Int']>,
};

export type CreatePartnerSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
  partnerId: Scalars['PartnerId'],
};

export type CreatePartnerSubscriptionResponse = {
   __typename?: 'CreatePartnerSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreatePartyInput = {
  lessonMediaId: Scalars['LessonMediaId'],
  lessonInstanceId?: Maybe<Scalars['LessonInstanceId']>,
  hostUserId: Scalars['Int'],
  hostInstallationId?: Maybe<Scalars['InstallationId']>,
  hostScheduleEntryId?: Maybe<Scalars['Int']>,
};

export type CreatePartyResponse = {
   __typename?: 'CreatePartyResponse',
  party: Party,
};

export type CreatePerkInput = {
  title: Scalars['String'],
  description: Scalars['String'],
  partnerId: Scalars['PartnerId'],
  active?: Maybe<Scalars['Boolean']>,
  activeFrom?: Maybe<Scalars['DateTime']>,
  expiresAt?: Maybe<Scalars['DateTime']>,
  tier?: Maybe<Array<Scalars['TierId']>>,
};

export type CreatePingSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreatePingSubscriptionResponse = {
   __typename?: 'CreatePingSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreatePlatformPartnerInput = {
  name: Scalars['String'],
  url?: Maybe<Scalars['URL']>,
  holdingVideoId?: Maybe<Scalars['VIDEO_ID']>,
  exerciseLibrary?: Maybe<Scalars['Boolean']>,
  classBuilder?: Maybe<Scalars['Boolean']>,
  liveStudioClass?: Maybe<Scalars['Boolean']>,
  scheduler?: Maybe<Scalars['Boolean']>,
};

export type CreatePrizeInput = {
  description: Scalars['String'],
  imageId: Scalars['Int'],
};

export type CreateProviderSubscriptionFromCodeInput = {
  code: Scalars['String'],
  email: Scalars['String'],
  marketingOptIn: Scalars['Boolean'],
  attributionId?: Maybe<Scalars['String']>,
};

export type CreateProviderSubscriptionFromCodeResponse = {
   __typename?: 'CreateProviderSubscriptionFromCodeResponse',
  redeemed: Scalars['Boolean'],
  code: Scalars['String'],
};

export type CreateProviderSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
  paymentProviderId: PaymentProvider,
  subscriptionTypeId: SubscriptionTypeId,
  end?: Maybe<Scalars['DateTime']>,
  attributionId?: Maybe<Scalars['String']>,
  teamId?: Maybe<Scalars['TeamId']>,
};

export type CreateProviderSubscriptionResponse = {
   __typename?: 'CreateProviderSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type CreatePushTokenInput = {
  token: Scalars['String'],
  deviceId: Scalars['String'],
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  platform: PushTokenPlatform,
};

export type CreateRandomSpinPrizeInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  weight: Scalars['Int'],
  spinVariant: SpinVariant,
  active: Scalars['Boolean'],
  startsAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
  initialQuantity?: Maybe<Scalars['Int']>,
  imageId?: Maybe<Scalars['Int']>,
  shopifyPrizeRule?: Maybe<Scalars['String']>,
  type: RandomSpinPrizeType,
  partnerCoupon?: Maybe<Scalars['String']>,
  partnerUrl?: Maybe<Scalars['URL']>,
  percentageOff?: Maybe<Scalars['Float']>,
  freeTrialDays?: Maybe<Scalars['Int']>,
  maxWinsPerUser?: Maybe<Scalars['Int']>,
};

export type CreateRemoteAppPinInput = {
  clientId: Scalars['String'],
  connectionId: Scalars['String'],
};

export type CreateRemoteAppPinResponse = {
   __typename?: 'CreateRemoteAppPinResponse',
  pin: Scalars['String'],
  expiresAt: Scalars['DateTime'],
};

export type CreateRepCountingProfileInput = {
  displayName: Scalars['String'],
};

export type CreateRepCountingProfileVersionInput = {
  repCountingProfileId: Scalars['RepCountingProfileId'],
  schemaVersion: Scalars['Int'],
  modelPayload: Scalars['JSONObject'],
};

export type CreateRepCountingProfileVersionResponse = {
   __typename?: 'CreateRepCountingProfileVersionResponse',
  ids: Array<Scalars['RepCountingProfileVersionId']>,
};

export type CreateSpotlightLessonInput = {
  lessonId: Scalars['Int'],
  activeFrom: Scalars['DateTime'],
};

export type CreateSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
  source?: Maybe<Scalars['String']>,
  planId: Scalars['String'],
  total: Scalars['Int'],
  couponId?: Maybe<Scalars['String']>,
  trialPeriodDays?: Maybe<Scalars['Int']>,
  subscriptionAnalyticsBlacklisted?: Maybe<Scalars['Boolean']>,
  meta?: Maybe<CreateSubscriptionMetaInput>,
  userTestEvent?: Maybe<UserTestEvent>,
};

export type CreateSubscriptionMetaInput = {
  selectedCountry?: Maybe<CountryCode>,
  ipCountry?: Maybe<CountryCode>,
};

export type CreateSubscriptionPlanInput = {
  period: SubscriptionPlanPeriod,
  cycle: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  popular: Scalars['Boolean'],
  requiresTracker: Scalars['Boolean'],
  trialLengthDays?: Maybe<Scalars['Days']>,
  appleSku?: Maybe<Scalars['String']>,
  appleActive: Scalars['Boolean'],
  amazonSku?: Maybe<Scalars['String']>,
  amazonActive: Scalars['Boolean'],
  androidSku?: Maybe<Scalars['String']>,
  androidActive: Scalars['Boolean'],
  international?: Maybe<Scalars['Boolean']>,
  subscriptionType: SubscriptionTypeId,
};

export type CreateSubscriptionResponse = {
   __typename?: 'CreateSubscriptionResponse',
  success: Scalars['Boolean'],
  customer: SubscriptionCustomer,
  paymentIntentSecret?: Maybe<Scalars['String']>,
  setupIntentSecret?: Maybe<Scalars['String']>,
  subscription: StripeSubscription,
};

export type CreateTeamBehaviourReportInput = {
  reporter: UserInput,
  reportedTeamId: Scalars['TeamId'],
};

export type CreateTeamInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  profileImageId?: Maybe<Scalars['Int']>,
  bannerImageId?: Maybe<Scalars['Int']>,
  primaryOwnerUserId?: Maybe<Scalars['Int']>,
  privacyLevel?: Maybe<PrivacyLevelId>,
  profileImageKey?: Maybe<Scalars['String']>,
  bannerImageKey?: Maybe<Scalars['String']>,
  teamPrivacyLevel: TeamPrivacyLevel,
  promoted?: Maybe<Scalars['Boolean']>,
};

export type CreateTggOrderInput = {
  tggMembershipId: Scalars['String'],
  items: Array<TggOrderItemInput>,
};

export type CreateTggOrderResponse = {
   __typename?: 'CreateTggOrderResponse',
  tggMembershipId: Scalars['String'],
};

export type CreateTggSubscriptionInput = {
  customerDetails: TggCustomerDetailsInput,
};

export type CreateTggSubscriptionResponse = {
   __typename?: 'CreateTggSubscriptionResponse',
  tggMembershipId: Scalars['String'],
};

export type CreateTrackInfoInput = {
  commercial?: Maybe<Scalars['Boolean']>,
  providerCode: Scalars['String'],
  trackKey: Scalars['String'],
  imageKey?: Maybe<Scalars['String']>,
  trackDisplayName?: Maybe<Scalars['String']>,
  artistDisplayName?: Maybe<Scalars['String']>,
  parentalWarningType?: Maybe<ParentalWarningType>,
  isrc?: Maybe<Scalars['String']>,
  icpn?: Maybe<Scalars['String']>,
  grid?: Maybe<Scalars['String']>,
  catalogNumber?: Maybe<Scalars['String']>,
  mainReleaseTitle?: Maybe<Scalars['String']>,
};

export type CreateTrainerInput = {
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  instagramHandle: Scalars['String'],
  instagramId?: Maybe<Scalars['Int']>,
  facebookHandle?: Maybe<Scalars['String']>,
  twitterHandle?: Maybe<Scalars['String']>,
  instaId?: Maybe<Scalars['String']>,
  longBio?: Maybe<Scalars['String']>,
  bio: Scalars['String'],
  focus?: Maybe<Scalars['String']>,
  shortFocus?: Maybe<Scalars['String']>,
  quote?: Maybe<Array<TrainerQuoteInput>>,
  coverImageId: Scalars['Int'],
  avatarImageId: Scalars['Int'],
  profileImage?: Maybe<Array<CreateImageInput>>,
  blog?: Maybe<Array<CreateBlogInput>>,
  premium: Scalars['Boolean'],
};

export type CreateTrainingPlanInput = {
  name: Scalars['String'],
  slug: Scalars['Slug'],
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  durationWeeks: Scalars['Weeks'],
  trainingGoalId: UserFitnessGoal,
  coverImageId?: Maybe<Scalars['Int']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  tagline?: Maybe<Scalars['String']>,
  premium: Scalars['Boolean'],
  rewardTypeId?: Maybe<RewardTypeId>,
  brandId?: Maybe<Scalars['BrandId']>,
  cardImageId?: Maybe<Scalars['Int']>,
  equipment: Scalars['Boolean'],
  promoted?: Maybe<Scalars['Boolean']>,
  xpReward?: Maybe<Scalars['Int']>,
  tier?: Maybe<Array<Scalars['TierId']>>,
};

export type CreateTrainingPlanListInput = {
  trainingPlans: Array<Scalars['Int']>,
};

export type CreateTrainingPlanRecommendationInput = {
  trainingPlanId: Scalars['Int'],
  trainingGoalId: UserFitnessGoal,
  activityLevel: UserActivityLevel,
  equipmentId?: Maybe<Scalars['CustomEquipmentId']>,
  subscriptionTypeId: SubscriptionTypeId,
};

export type CreateTrainingPlanScheduleInput = {
  lessonId: Scalars['Int'],
  weekNumber: Scalars['Int'],
  position: Scalars['Int'],
};

export type CreateTrainingPlanTemplateInput = {
  level: LessonDifficulty,
  trainingPlanId: Scalars['Int'],
  schedule: Array<CreateTrainingPlanScheduleInput>,
};

export type CreateTrainingPlanWeeklyBlog = {
  weekNumber: Scalars['Int'],
  blogId: Scalars['Int'],
};

export type CreateTrainingPlanWeeklyOverview = {
  weekNumber: Scalars['Int'],
  overview: Scalars['String'],
};

export type CreateTrainingPlanWeeklyTutorial = {
  weekNumber: Scalars['Int'],
  tutorialId: Scalars['Int'],
  displayOrder: Scalars['Int'],
};

export type CreateTrainingPlanWeeklyVideo = {
  weekNumber: Scalars['Int'],
  videoId: Scalars['VIDEO_ID'],
};

export type CreateTutorialInput = {
  name: Scalars['String'],
  trainerId: Scalars['Int'],
  muscle: Array<TutorialMuscleInput>,
  description?: Maybe<Scalars['String']>,
  mainImageId?: Maybe<Scalars['Int']>,
  releaseAt?: Maybe<Scalars['DateTime']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  ledPage?: Maybe<Scalars['Int']>,
  plan: Array<PlanActivityInput>,
  planOnly?: Maybe<Scalars['Boolean']>,
  exerciseDisplay?: Maybe<ExerciseDisplay>,
  studioId?: Maybe<StudioType>,
  heroMetric?: Maybe<MetricTypes>,
};

export type CreateUserBehaviourReportInput = {
  reporter: UserInput,
  reported: UserInput,
};

export type CreateUserInput = {
  firstname?: Maybe<Scalars['String']>,
  lastname?: Maybe<Scalars['String']>,
  dob?: Maybe<Scalars['Date']>,
  height?: Maybe<Scalars['Int']>,
  activityLevel?: Maybe<UserActivityLevel>,
  weight?: Maybe<Scalars['Float']>,
  gender?: Maybe<Gender>,
  sex?: Maybe<Sex>,
  fitnessGoal?: Maybe<UserFitnessGoal>,
  trainingGoalId?: Maybe<UserFitnessGoal>,
  phoneNumber?: Maybe<Scalars['String']>,
  unitSystemPreference?: Maybe<UnitSystem>,
  attributionId?: Maybe<Scalars['String']>,
  subscriptionAnalyticsBlacklisted?: Maybe<Scalars['Boolean']>,
  maxHr?: Maybe<Scalars['Int']>,
};

export type CreateUserRandomSpinPrizeSpinInput = {
  user: UserInput,
  randomSpinPrizeId?: Maybe<Scalars['RandomSpinPrizeId']>,
  status: SpinStatus,
  spinVariant: SpinVariant,
  coupon?: Maybe<Scalars['String']>,
};

export type CreateUserTrainingPlanCancelledReviewInput = {
  userTrainingPlanId: Scalars['Int'],
  incompleteReason: UserTrainingPlanCancelReason,
  general?: Maybe<Scalars['String']>,
};

export type CreateUserTrainingPlanCompletedReviewInput = {
  userTrainingPlanId: Scalars['Int'],
  rating: Scalars['Int'],
  general?: Maybe<Scalars['String']>,
  intensityStrength?: Maybe<OptionsIntensity>,
  intensityCardio?: Maybe<OptionsIntensity>,
  intensityRebalance?: Maybe<OptionsIntensity>,
  planLevel?: Maybe<OptionsAmount>,
  intensityExpectations?: Maybe<OptionsIntensity>,
  achievementResponse?: Maybe<Array<TrainingPlanAchievement>>,
};

export type CreateUserTrainingPlanExpiredReviewInput = {
  userTrainingPlanId: Scalars['Int'],
  rating: Scalars['Int'],
  general?: Maybe<Scalars['String']>,
  intensityStrength?: Maybe<OptionsIntensity>,
  intensityCardio?: Maybe<OptionsIntensity>,
  intensityRebalance?: Maybe<OptionsIntensity>,
  planLevel?: Maybe<OptionsAmount>,
  intensityExpectations?: Maybe<OptionsIntensity>,
  incompleteReason: UserTrainingPlanCancelReason,
};

export type CreateUserTrainingPlanReviewInput = {
  userTrainingPlanId: Scalars['Int'],
  general?: Maybe<Scalars['String']>,
  incompleteReason?: Maybe<UserTrainingPlanCancelReason>,
  cancelReason?: Maybe<UserTrainingPlanCancelReason>,
};

export type CreateVideoInput = {
  id: Scalars['VIDEO_ID'],
  type: VideoType,
  description: Scalars['String'],
  previewImg?: Maybe<Scalars['URL']>,
  lessonId?: Maybe<Scalars['Int']>,
};

export type CreateWorkoutReactionInput = {
  reaction: Scalars['SingleEmoji'],
  user: UserInput,
  workoutId: Scalars['Int'],
};

export type CreateWorkoutReviewInput = {
  workoutId: Scalars['Int'],
  overall: Scalars['Int'],
  trainer?: Maybe<Scalars['Int']>,
  difficulty?: Maybe<Scalars['Int']>,
  music?: Maybe<Scalars['Int']>,
  trackingAccuracy?: Maybe<Scalars['Int']>,
  general?: Maybe<Scalars['String']>,
  trainerAudioMusic?: Maybe<Scalars['Int']>,
};

export type CreateYuLifeSubscriptionInput = {
  customerDetails: CustomerDetailsInput,
};

export type CreateYuLifeSubscriptionResponse = {
   __typename?: 'CreateYuLifeSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export enum CumulativeMetricShowState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  HIDDEN = 'HIDDEN'
}

export type CumulativeMetricSummary = {
  workout?: Maybe<WorkoutCumulativeMetricSummary>,
  activities?: Maybe<Array<ActivityCumulativeMetricSummary>>,
};

export type CumulativeWorkoutMetric = {
   __typename?: 'CumulativeWorkoutMetric',
  time: Scalars['Float'],
  delta: Scalars['Float'],
  total: Scalars['Float'],
};

export enum CurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP'
}

export type CustomEquipment = {
   __typename?: 'CustomEquipment',
  id: Scalars['CustomEquipmentId'],
  equipmentName: Scalars['String'],
  show?: Maybe<Scalars['Boolean']>,
};

export type CustomEquipmentCondition = {
  show?: Maybe<Scalars['Boolean']>,
};

export type CustomEquipmentConnection = {
   __typename?: 'CustomEquipmentConnection',
  edges?: Maybe<Array<CustomEquipmentEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type CustomEquipmentEdge = {
   __typename?: 'CustomEquipmentEdge',
  node: CustomEquipment,
};


export enum CustomEquipmentOrder {
  EQUIPMENT_NAME = 'EQUIPMENT_NAME'
}

export type CustomerDetailsInput = {
  email: Scalars['Email'],
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  address?: Maybe<AddressInput>,
  billingAddress?: Maybe<AddressInput>,
  shippingAddress?: Maybe<AddressInput>,
  gender?: Maybe<Gender>,
  subscriptionAnalyticsBlacklisted?: Maybe<Scalars['Boolean']>,
};




export type DeepLinkAttemptInfo = {
   __typename?: 'DeepLinkAttemptInfo',
  id: Scalars['DeepLinkAttemptInfoId'],
  deepLinkPath: Scalars['String'],
  fulfilled: Scalars['Boolean'],
  user?: Maybe<User>,
};

export type DeepLinkAttemptInfoCondition = {
  fulfilled?: Maybe<Scalars['Boolean']>,
};

export type DeepLinkAttemptInfoConnection = {
   __typename?: 'DeepLinkAttemptInfoConnection',
  edges?: Maybe<Array<DeepLinkAttemptInfoEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type DeepLinkAttemptInfoEdge = {
   __typename?: 'DeepLinkAttemptInfoEdge',
  node: DeepLinkAttemptInfo,
};


export enum DeepLinkAttemptInfoOrder {
  CREATED_AT = 'CREATED_AT'
}

export type DeletedBodyPart = {
   __typename?: 'DeletedBodyPart',
  id: Scalars['Int'],
};

export type DeletedEquipment = {
   __typename?: 'DeletedEquipment',
  id: Scalars['EquipmentId'],
};

export type DeletedEquipmentLevel = {
   __typename?: 'DeletedEquipmentLevel',
  id: Scalars['EquipmentLevelId'],
};

export type DeletedLessonOutcome = {
   __typename?: 'DeletedLessonOutcome',
  id: Scalars['Int'],
};

export type DeletedLessonRecoverySport = {
   __typename?: 'DeletedLessonRecoverySport',
  id: Scalars['Int'],
};

export type DeletedLessonType = {
   __typename?: 'DeletedLessonType',
  id: Scalars['Int'],
};

export type DeletedTeam = {
   __typename?: 'DeletedTeam',
  id: Scalars['TeamId'],
};

export type DeletedTrainingPlanRecommendation = {
   __typename?: 'DeletedTrainingPlanRecommendation',
  id: Scalars['TrainingPlanRecommendationId'],
};

export type DeletedWorkoutReaction = {
   __typename?: 'DeletedWorkoutReaction',
  id: Scalars['WorkoutReactionId'],
};

export type DeleteInfo = {
   __typename?: 'DeleteInfo',
  count: Scalars['Int'],
  errors?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type DeleteMusicGenreByIdResponse = {
   __typename?: 'DeleteMusicGenreByIdResponse',
  id: Scalars['MusicGenreId'],
};

export type DeleteSuccessResponse = {
   __typename?: 'DeleteSuccessResponse',
  success: Scalars['Boolean'],
  errorMessage?: Maybe<Scalars['String']>,
};

export type DeleteUserResponse = {
   __typename?: 'DeleteUserResponse',
  auth: DeleteInfo,
  workout: DeleteInfo,
  stripe: DeleteInfo,
  intercom: DeleteInfo,
  iterable: DeleteInfo,
  segment: DeleteInfo,
};

export type Device = {
   __typename?: 'Device',
  id: Scalars['DeviceId'],
  name: Scalars['String'],
  bodyLocation?: Maybe<BodyLocation>,
  user: User,
  workoutMetadata?: Maybe<Array<DeviceWorkoutMetadata>>,
  type?: Maybe<DeviceType>,
  autoReconnect: Scalars['Boolean'],
};


export type DeviceInput = {
  name: Scalars['String'],
  bodyLocation?: Maybe<BodyLocation>,
  workoutMetadata?: Maybe<DeviceWorkoutMetadataInput>,
  type?: Maybe<DeviceType>,
  autoReconnect?: Maybe<Scalars['Boolean']>,
};

export enum DeviceType {
  HEART_RATE_TRACKER = 'HEART_RATE_TRACKER',
  BIKE = 'BIKE',
  TREAD = 'TREAD',
  ROWER = 'ROWER',
  SPINBIKE = 'SPINBIKE'
}

export type DeviceWorkoutMetadata = {
   __typename?: 'DeviceWorkoutMetadata',
  id: Scalars['DeviceWorkoutMetadataId'],
  device: Device,
  workout: Workout,
  batteryLevelStart?: Maybe<Scalars['Int']>,
  batteryLevelEnd?: Maybe<Scalars['Int']>,
};


export type DeviceWorkoutMetadataInput = {
  batteryLevelStart?: Maybe<Scalars['Int']>,
  batteryLevelEnd?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
};


export type Equipment = {
   __typename?: 'Equipment',
  id: Scalars['EquipmentId'],
  value: Scalars['String'],
  type: Scalars['String'],
  shortDisplay: Scalars['String'],
  longDisplay: Scalars['String'],
  description: Scalars['String'],
  image: Image,
  levels: Array<EquipmentLevel>,
};

export type EquipmentConnection = {
   __typename?: 'EquipmentConnection',
  edges?: Maybe<Array<EquipmentEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type EquipmentEdge = {
   __typename?: 'EquipmentEdge',
  node: Equipment,
};


export type EquipmentLevel = {
   __typename?: 'EquipmentLevel',
  id: Scalars['EquipmentLevelId'],
  equipment: Equipment,
  value: Scalars['String'],
  displayName: Scalars['String'],
  description: Scalars['String'],
};


export enum EquipmentOrder {
  VALUE = 'VALUE'
}

export type Exercise = {
   __typename?: 'Exercise',
  id: Scalars['Int'],
  name: Scalars['String'],
  family: ExerciseFamily,
  calibration?: Maybe<Calibration>,
  muscle: MuscleGroup,
  doubleSided: Scalars['Boolean'],
  supportedCircuitTypes: Array<ExerciseSupportedCircuitType>,
  supportedStudios: Array<ExerciseSupportedStudio>,
  difficulty: ExerciseDifficulty,
  wahooCalibration?: Maybe<WahooCalibration>,
  repCountingProfile?: Maybe<RepCountingProfile>,
  active: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  connectedKit?: Maybe<ConnectedKit>,
  video?: Maybe<Video>,
  movementStandard?: Maybe<MovementStandard>,
  allMovementStandards: MovementStandardConnection,
};


export type ExerciseMovementStandardArgs = {
  platformPartnerId?: Maybe<Scalars['PlatformPartnerId']>
};


export type ExerciseAllMovementStandardsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<MovementStandardCondition>,
  orderBy?: Maybe<MovementStandardOrder>
};

export type ExerciseCondition = {
  family?: Maybe<Array<ExerciseFamily>>,
  muscle?: Maybe<Array<MuscleGroup>>,
  active?: Maybe<Array<Scalars['Boolean']>>,
  difficulty?: Maybe<Array<ExerciseDifficulty>>,
  circuitType?: Maybe<Array<SupportedCircuitType>>,
  studio?: Maybe<Array<StudioType>>,
  nameLike?: Maybe<Scalars['String']>,
};

export type ExerciseConnection = {
   __typename?: 'ExerciseConnection',
  edges?: Maybe<Array<ExerciseEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export enum ExerciseDifficulty {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED'
}

export enum ExerciseDisplay {
  NONE = 'NONE',
  NAME_ONLY = 'NAME_ONLY',
  NAME_AND_TIME = 'NAME_AND_TIME'
}

export type ExerciseEdge = {
   __typename?: 'ExerciseEdge',
  node: Exercise,
};

export enum ExerciseFamily {
  PILATES = 'PILATES',
  CRUNCH = 'CRUNCH',
  PLANK = 'PLANK',
  LUNGE = 'LUNGE',
  MISC = 'MISC',
  SQUAT = 'SQUAT',
  WARMUP = 'WARMUP',
  COOLDOWN = 'COOLDOWN',
  COMBO = 'COMBO',
  BURPEE = 'BURPEE',
  RUNNING = 'RUNNING',
  PUSH_UP = 'PUSH_UP',
  JUMPS = 'JUMPS',
  BRIDGES = 'BRIDGES',
  MOUNTAIN_CLIMBERS = 'MOUNTAIN_CLIMBERS',
  TRICEP_DIPS = 'TRICEP_DIPS',
  STRETCHES = 'STRETCHES'
}

export enum ExerciseOrder {
  NAME = 'NAME',
  CREATED_AT = 'CREATED_AT'
}

export type ExerciseSupportedCircuitType = {
   __typename?: 'ExerciseSupportedCircuitType',
  circuitType: SupportedCircuitType,
};

export type ExerciseSupportedCircuitTypeInput = {
  circuitType: SupportedCircuitType,
};

export type ExerciseSupportedStudio = {
   __typename?: 'ExerciseSupportedStudio',
  studioId?: Maybe<StudioType>,
};

export type ExerciseSupportedStudioInput = {
  studioId?: Maybe<StudioType>,
};

export type ExplainIncompleteWorkoutResponse = {
   __typename?: 'ExplainIncompleteWorkoutResponse',
  workout: Workout,
};

export type Facet = {
   __typename?: 'Facet',
  label: Scalars['String'],
  options?: Maybe<Array<FacetOption>>,
};

export type FacetOption = {
   __typename?: 'FacetOption',
  label: Scalars['String'],
  value: Scalars['String'],
};

export type FairPlayDrmKeyExchangeInput = {
  lessonMediaId: Scalars['LessonMediaId'],
  assetId: Scalars['String'],
  spc: Scalars['String'],
};

export type FairPlayDrmKeyExchangeResponse = {
   __typename?: 'FairPlayDRMKeyExchangeResponse',
  ckc: Scalars['String'],
};

export type FavouriteLessonInput = {
  lessonId: Scalars['Int'],
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
};

export enum FavouriteLessonOrder {
  FAVOURITED_AT = 'FAVOURITED_AT',
  PUBLISHED_AT = 'PUBLISHED_AT',
  WORKOUTS_COMPLETED = 'WORKOUTS_COMPLETED',
  OVERALL_RATING = 'OVERALL_RATING'
}

export type FavouriteLessonsTrainers = {
   __typename?: 'FavouriteLessonsTrainers',
  lessons: Scalars['Boolean'],
  trainers: Scalars['Boolean'],
};

export type FavouriteTrainerInput = {
  trainerId: Scalars['Int'],
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
};

export enum FavouriteTrainerOrder {
  FAVOURITED_AT = 'FAVOURITED_AT'
}

export type FinishWorkoutInput = {
  outcome: WorkoutOutcome,
  aborted?: Maybe<Scalars['Boolean']>,
  deviceWorkoutMetadata?: Maybe<DeviceWorkoutMetadataInput>,
  allDeviceWorkoutMetadata?: Maybe<Array<DeviceWorkoutMetadataInput>>,
  clientMetricsSummary?: Maybe<ClientMetricsSummaryInput>,
};

export type FollowerCondition = {
  user?: Maybe<UserInput>,
};

export enum FollowerOrder {
  FOLLOWED_AT = 'FOLLOWED_AT'
}

export type FollowUserResponse = {
   __typename?: 'FollowUserResponse',
  user: User,
  follower: User,
};


export type FreeSubCode = {
   __typename?: 'FreeSubCode',
  id: Scalars['String'],
  subscriptionProvider: PaymentProvider,
  subscriptionSku: Scalars['String'],
  redemptionLimit?: Maybe<Scalars['Int']>,
  expiresAt?: Maybe<Scalars['DateTime']>,
  durationValue: Scalars['Int'],
  durationUnit: FreeSubCodeDurationUnit,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  partnerId: FreeSubCodePartnerId,
  campaignId?: Maybe<Scalars['String']>,
};

export enum FreeSubCodeDurationUnit {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}


export enum FreeSubCodePartnerId {
  TGG = 'TGG',
  SKY_VIP = 'SKY_VIP',
  DUBAI_FITNESS_CHALLENGE = 'DUBAI_FITNESS_CHALLENGE',
  SAMSUNG_EE = 'SAMSUNG_EE',
  SAMSUNG = 'SAMSUNG',
  MOVEMBER = 'MOVEMBER',
  CARD_TESTS = 'CARD_TESTS',
  TEACHERS = 'TEACHERS',
  NHS = 'NHS',
  SAMSUNG_EMPLOYEES = 'SAMSUNG_EMPLOYEES',
  NRG = 'NRG',
  BOSE = 'BOSE',
  LOCKE = 'LOCKE',
  SHANGRI_LA = 'SHANGRI_LA',
  FITBIT = 'FITBIT',
  VOXI = 'VOXI',
  HUGGG = 'HUGGG',
  USA_ADS = 'USA_ADS',
  REACHDESK = 'REACHDESK',
  FIIT = 'FIIT',
  GREEN_ROOM = 'GREEN_ROOM',
  YULIFE = 'YULIFE',
  VITALITY = 'VITALITY',
  KEISER = 'KEISER',
  HEALTHKEY = 'HEALTHKEY',
  ACCENTURE = 'ACCENTURE',
  HEKA = 'HEKA',
  FULHAM_REACH_BC = 'FULHAM_REACH_BC',
  CORTLAND = 'CORTLAND',
  FORMA = 'FORMA',
  VERTUS = 'VERTUS',
  GET_LIVING = 'GET_LIVING',
  MODA = 'MODA',
  IHG = 'IHG',
  MARKS_SPENCER = 'MARKS_SPENCER',
  EGYM_WELLPASS = 'EGYM_WELLPASS',
  IMATTA = 'IMATTA',
  VIDEOTRON = 'VIDEOTRON',
  PERKBOX_FLEXI = 'PERKBOX_FLEXI'
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export type GenerateAndEmailFreeSubCodeInput = {
  expiresAt?: Maybe<Scalars['DateTime']>,
  durationValue: Scalars['Int'],
  durationUnit: FreeSubCodeDurationUnit,
  partnerId: FreeSubCodePartnerId,
  campaignId: Scalars['String'],
  email: Scalars['String'],
};

export type GenerateAndEmailFreeSubCodeResponse = {
   __typename?: 'GenerateAndEmailFreeSubCodeResponse',
  code: Scalars['String'],
};

export type GenerateFreeSubCodesInput = {
  id?: Maybe<Scalars['FreeSubCodeId']>,
  count: Scalars['Int'],
  redemptionLimit?: Maybe<Scalars['Int']>,
  expiresAt?: Maybe<Scalars['DateTime']>,
  durationValue: Scalars['Int'],
  durationUnit: FreeSubCodeDurationUnit,
  partnerId: FreeSubCodePartnerId,
  campaignId: Scalars['String'],
};

export type GenerateFreeSubCodesResponse = {
   __typename?: 'GenerateFreeSubCodesResponse',
  generatedCount: Scalars['Int'],
  campaignId?: Maybe<Scalars['String']>,
};

export type GeneratePaidSubCodesInput = {
  id?: Maybe<Scalars['PaidSubCodeId']>,
  count: Scalars['Int'],
  expiresAt?: Maybe<Scalars['DateTime']>,
  durationValue?: Maybe<Scalars['Int']>,
  durationUnit?: Maybe<PaidSubCodeDurationUnit>,
  subscriptionProvider: PaymentProvider,
  subscriptionInterval: SubscriptionInterval,
  campaignId: Scalars['String'],
  subscriptionType: SubscriptionTypeId,
  teamId?: Maybe<Scalars['TeamId']>,
};

export type GeneratePaidSubCodesResponse = {
   __typename?: 'GeneratePaidSubCodesResponse',
  generatedCount: Scalars['Int'],
};

export type GrantAuthGroupsResponse = {
   __typename?: 'GrantAuthGroupsResponse',
  user: User,
};


export enum GroupClassType {
  FIIT_CLUB = 'FIIT_CLUB',
  PRIVATE_USER_OWNED = 'PRIVATE_USER_OWNED',
  PUBLIC_USER_OWNED = 'PUBLIC_USER_OWNED'
}


export type Image = {
   __typename?: 'Image',
  id: Scalars['Int'],
  url: Scalars['URL'],
  horizontalAlignment: ImageHorizontalAlignment,
  verticalAlignment: ImageVerticalAlignment,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  hexColour?: Maybe<Scalars['HexColour']>,
};

export type ImageConnection = {
   __typename?: 'ImageConnection',
  edges?: Maybe<Array<ImageEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ImageEdge = {
   __typename?: 'ImageEdge',
  node: Image,
};

export enum ImageHorizontalAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT'
}

export enum ImageOrder {
  CREATED_AT = 'CREATED_AT'
}

export enum ImageVerticalAlignment {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM'
}

export type Induction = {
   __typename?: 'Induction',
  intro?: Maybe<Video>,
  preview?: Maybe<Video>,
  induction?: Maybe<Video>,
  deviceDemo?: Maybe<Video>,
  jumpSquatsDemo?: Maybe<Video>,
  lungesDemo?: Maybe<Video>,
};

export type InsightProps = {
   __typename?: 'InsightProps',
  type?: Maybe<InsightTypes>,
  value?: Maybe<Scalars['String']>,
};

export type Insights = {
   __typename?: 'Insights',
  achievements?: Maybe<AchievementInsights>,
};

export enum InsightTypes {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  NEUTRAL = 'NEUTRAL'
}

export type Installation = {
   __typename?: 'Installation',
  id: Scalars['InstallationId'],
  platform: InstallationPlatform,
  userAgent?: Maybe<Scalars['String']>,
};


export enum InstallationPlatform {
  SKY = 'SKY',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  AMAZON = 'AMAZON',
  TGG = 'TGG',
  COMCAST = 'COMCAST',
  SAMSUNG = 'SAMSUNG',
  LG = 'LG',
  WEB = 'WEB',
  ANDROID_TV = 'ANDROID_TV',
  CHROMECAST = 'CHROMECAST',
  ROGERS = 'ROGERS',
  SHAW = 'SHAW',
  FIIT_EVENTS = 'FIIT_EVENTS',
  FIIT_STUDIOS = 'FIIT_STUDIOS',
  VIDEOTRON = 'VIDEOTRON',
  AIRBIKE_INTEGRATED_SCREEN = 'AIRBIKE_INTEGRATED_SCREEN',
  VIRGIN = 'VIRGIN'
}

export type InstantaneousActivityHistory = {
   __typename?: 'InstantaneousActivityHistory',
  key: Scalars['String'],
  personalBest?: Maybe<ActivityPersonalBest>,
};

export type InstantaneousMetricSummary = {
  workout?: Maybe<WorkoutInstantaneousMetricSummary>,
  activities?: Maybe<Array<ActivityInstantaneousMetricSummary>>,
};

export type InstantaneousWorkoutMetric = {
   __typename?: 'InstantaneousWorkoutMetric',
  time: Scalars['Float'],
  value: Scalars['Float'],
};

export type JoinLobbyInput = {
  lessonInstanceId: Scalars['LessonInstanceId'],
  partyId?: Maybe<Scalars['PartyId']>,
  installationId?: Maybe<Scalars['InstallationId']>,
  trackerConnected?: Maybe<Scalars['Boolean']>,
  equipmentConnected?: Maybe<Scalars['Boolean']>,
  user: UserInput,
};

export type JoinLobbyResponse = {
   __typename?: 'JoinLobbyResponse',
  lobbyEntry: LobbyEntry,
};

export type JoinTeamInput = {
  teamId: Scalars['TeamId'],
  user: UserInput,
};


export type KcalMetricConfig = {
   __typename?: 'KcalMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};

export enum LeaderboardDisplayType {
  FULL_LEADERBOARD = 'FULL_LEADERBOARD',
  USER_COUNT = 'USER_COUNT',
  USER_LIST = 'USER_LIST',
  NONE = 'NONE'
}

export type LeaderboardEntry = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  position: Scalars['Int'],
  value: Scalars['Int'],
};

export type LeaderboardsPermissions = {
   __typename?: 'LeaderboardsPermissions',
  inClass: Scalars['Boolean'],
  workoutSummary: Scalars['Boolean'],
  classDetails: Scalars['Boolean'],
};

export type LeaderboardWorkoutCondition = {
  state?: Maybe<Array<WorkoutState>>,
  includeAnonymous?: Maybe<Array<Scalars['Boolean']>>,
};

export type LeaveLobbyInput = {
  lobbyEntryId?: Maybe<Scalars['LobbyEntryId']>,
  lessonInstanceId?: Maybe<Scalars['LessonInstanceId']>,
  user?: Maybe<UserInput>,
};

export type LeaveLobbyResponse = {
   __typename?: 'LeaveLobbyResponse',
  lobbyEntry?: Maybe<LobbyEntry>,
};

export type LeaveTeamInput = {
  teamId: Scalars['TeamId'],
  user: UserInput,
};

export type LeaveUserChallengeInput = {
  id: Scalars['UserChallengeId'],
};

export type Lesson = {
   __typename?: 'Lesson',
  id: Scalars['Int'],
  video?: Maybe<Scalars['URL']>,
  media?: Maybe<Video>,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  studio: Studio,
  muscles: Array<Muscle>,
  musicGenre?: Maybe<MusicGenre>,
  difficulty: LessonDifficulty,
  exerciseDisplay: ExerciseDisplay,
  isNew: Scalars['Boolean'],
  isAvailable: Scalars['Boolean'],
  permissions: LessonPermission,
  permissionsWithReasons: LessonPermissionsWithReasons,
  filmAt?: Maybe<Scalars['DateTime']>,
  releaseAt?: Maybe<Scalars['DateTime']>,
  tiers?: Maybe<Array<Tier>>,
  trainers: Array<Trainer>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  heroImg?: Maybe<Scalars['URL']>,
  mainImage?: Maybe<Image>,
  status: LessonStatus,
  publishedAt?: Maybe<Scalars['DateTime']>,
  countdownBeep: Scalars['Boolean'],
  finishingBeep: Scalars['Boolean'],
  plan: Array<PlanCircuit>,
  timeline: Array<TimelineCircuit>,
  durationRange: LessonDurationRange,
  duration: Scalars['Int'],
  number?: Maybe<Scalars['Int']>,
  relatedLessons: LessonConnection,
  workouts: WorkoutConnection,
  leaderboard: Array<LessonLeaderboardEntry>,
  userLeaderboard: UserLeaderboardConnection,
  instances: LessonInstanceConnection,
  favouritedByUser: Scalars['Boolean'],
  review: LessonReview,
  editingUser?: Maybe<User>,
  editingFrom?: Maybe<Scalars['DateTime']>,
  trainerMusic?: Maybe<Scalars['String']>,
  badges: Array<LessonBadge>,
  ledPage?: Maybe<Scalars['Int']>,
  premium: Scalars['Boolean'],
  scheduledOnly: Scalars['Boolean'],
  planOnly: Scalars['Boolean'],
  types: Array<LessonType>,
  bodyParts: Array<BodyPart>,
  recoverySports: Array<LessonRecoverySport>,
  outcomes: Array<LessonOutcome>,
  trainingGoals: Array<TrainingGoal>,
  equipmentLevel: Array<EquipmentLevel>,
  pipelineVideoRevisions?: Maybe<Array<LessonVideoRevision>>,
  pipelineMusicVersions?: Maybe<Array<MusicInfo>>,
  mediaOptions: Array<LessonMedia>,
  defaultMediaOption?: Maybe<LessonMedia>,
  rxId?: Maybe<Scalars['VIDEO_ID']>,
  metrics: LessonMetrics,
  requiredAgreements: Array<AgreementType>,
  neededAgreements: Array<Agreement>,
  workoutCount: Scalars['Int'],
  social: LessonSocial,
  uiConfig: LessonUiConfig,
  ckSpecialAccess: Scalars['Boolean'],
  concept?: Maybe<LessonConcept>,
  heroMetric?: Maybe<MetricTypes>,
  supportsLive: Scalars['Boolean'],
  intensity: LessonIntensity,
};


export type LessonTrainersArgs = {
  orderBy?: Maybe<TrainerOrder>,
  direction?: Maybe<OrderByDirection>
};


export type LessonRelatedLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<LessonCondition>
};


export type LessonWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>,
  condition?: Maybe<LessonWorkoutCondition>
};


export type LessonLeaderboardArgs = {
  user?: Maybe<UserInput>,
  size: Scalars['Int'],
  rankBy?: LessonWorkoutRank,
  direction?: OrderByDirection,
  condition?: Maybe<LeaderboardWorkoutCondition>
};


export type LessonUserLeaderboardArgs = {
  user?: Maybe<UserInput>,
  size: Scalars['Int'],
  offset?: Maybe<Scalars['Int']>,
  direction?: OrderByDirection,
  condition?: Maybe<UserLeaderboardCondition>
};


export type LessonInstancesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonInstanceOrder>,
  condition?: Maybe<LessonInstanceCondition>
};


export type LessonFavouritedByUserArgs = {
  id?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};


export type LessonPipelineVideoRevisionsArgs = {
  condition?: Maybe<PipelineVersionCondition>
};


export type LessonPipelineMusicVersionsArgs = {
  condition?: Maybe<PipelineVersionCondition>
};


export type LessonMediaOptionsArgs = {
  condition?: Maybe<LessonMediaCondition>
};


export type LessonDefaultMediaOptionArgs = {
  condition?: Maybe<LessonMediaCondition>,
  preference?: Maybe<LessonMediaPreference>
};

export type LessonBadge = {
   __typename?: 'LessonBadge',
  type: LessonBadgeType,
};

export enum LessonBadgeType {
  MOST_POPULAR = 'MOST_POPULAR',
  NEW_TRAINER = 'NEW_TRAINER',
  TOP_RATED = 'TOP_RATED',
  NEW = 'NEW'
}

export type LessonBodyPartInput = {
  id: Scalars['Int'],
};

export enum LessonConcept {
  METCON = 'METCON',
  SPRINT = 'SPRINT',
  ENDURE = 'ENDURE',
  STRENGTH = 'STRENGTH'
}

export type LessonCondition = {
  id?: Maybe<Array<Scalars['Int']>>,
  studioId?: Maybe<Array<StudioType>>,
  studioFamily?: Maybe<Array<StudioFamily>>,
  trainerId?: Maybe<Array<Scalars['Int']>>,
  difficulty?: Maybe<Array<LessonDifficulty>>,
  status?: Maybe<Array<LessonStatus>>,
  durationRange?: Maybe<Array<LessonDurationRange>>,
  muscleId?: Maybe<Array<Scalars['Int']>>,
  lessonTypeId?: Maybe<Array<Scalars['Int']>>,
  bodyPartId?: Maybe<Array<Scalars['Int']>>,
  lessonRecoverySportId?: Maybe<Array<Scalars['Int']>>,
  lessonOutcomeId?: Maybe<Array<Scalars['Int']>>,
  trainingGoalId?: Maybe<Array<UserFitnessGoal>>,
  equipmentLevelId?: Maybe<Array<Scalars['EquipmentLevelId']>>,
  equipmentId?: Maybe<Array<Scalars['EquipmentId']>>,
  musicGenre?: Maybe<Array<Scalars['MusicGenreId']>>,
  scheduledOnly?: Maybe<Array<Scalars['Boolean']>>,
  planOnly?: Maybe<Array<Scalars['Boolean']>>,
  premium?: Maybe<Array<Scalars['Boolean']>>,
  mediaType?: Maybe<Array<LessonMediaType>>,
  hasEquipment?: Maybe<Scalars['Boolean']>,
  searchQueryString?: Maybe<Scalars['String']>,
  ckSpecialAccess?: Maybe<Scalars['Boolean']>,
  concept?: Maybe<Array<LessonConcept>>,
  completedByUser?: Maybe<Scalars['Boolean']>,
  freeForMe?: Maybe<Scalars['Boolean']>,
  heroMetric?: Maybe<Array<MetricTypes>>,
};

export type LessonConnection = {
   __typename?: 'LessonConnection',
  edges?: Maybe<Array<LessonEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
  totalSearchCount?: Maybe<Scalars['Int']>,
};

export enum LessonDifficulty {
  ENTRY_LEVEL = 'ENTRY_LEVEL',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  ALL_LEVELS = 'ALL_LEVELS'
}

export enum LessonDurationRange {
  ABOUT_10_MINUTES = 'ABOUT_10_MINUTES',
  ABOUT_25_MINUTES = 'ABOUT_25_MINUTES',
  ABOUT_40_MINUTES = 'ABOUT_40_MINUTES',
  ABOUT_60_MINUTES = 'ABOUT_60_MINUTES'
}

export type LessonEdge = {
   __typename?: 'LessonEdge',
  node: Lesson,
};

export type LessonFavouritePermission = {
   __typename?: 'LessonFavouritePermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<LessonFavouritePermissionReason>>,
};

export enum LessonFavouritePermissionReason {
  SCHEDULED_ONLY = 'SCHEDULED_ONLY'
}

export type LessonInstance = {
   __typename?: 'LessonInstance',
  id: Scalars['LessonInstanceId'],
  lesson: Lesson,
  primaryOwner?: Maybe<User>,
  type: LessonInstanceType,
  groupClassType: GroupClassType,
  joinability: LessonInstanceJoinability,
  updatesUrl: Scalars['String'],
  workoutStartDelaySeconds: Scalars['Seconds'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  lobbyStatus: LessonInstanceLobbyStatus,
  playState?: Maybe<LessonInstancePlayState>,
  lastPausedAt?: Maybe<Scalars['DateTime']>,
  lastResumedAt?: Maybe<Scalars['DateTime']>,
  lobbyDurationSeconds?: Maybe<Scalars['Seconds']>,
  workoutStartTime?: Maybe<Scalars['DateTime']>,
  cancelledAt?: Maybe<Scalars['DateTime']>,
  leaderboard: Array<LessonInstanceLeaderboardEntry>,
  permissions: LessonInstancePermissions,
  prize?: Maybe<Prize>,
  userSignUpCount: Scalars['Int'],
  userSignUps: UserConnection,
  workouts: WorkoutConnection,
  lobbyMusicUrl: Scalars['URL'],
  lobbyEntries: LobbyEntryConnection,
  leaderboardDisplayType?: Maybe<LeaderboardDisplayType>,
  leaderboardMetric?: Maybe<MetricTypes>,
  userLeaderboard: UserLeaderboardConnection,
  lessonMedia?: Maybe<LessonMedia>,
  memberLimit?: Maybe<Scalars['Int']>,
  playbackType: LessonInstancePlaybackType,
  schedule?: Maybe<LessonInstanceSchedule>,
  platformPartner?: Maybe<PlatformPartner>,
};


export type LessonInstanceLeaderboardArgs = {
  user: UserInput,
  size: Scalars['Int'],
  direction?: OrderByDirection,
  rankBy?: LessonInstanceWorkoutRank,
  condition?: Maybe<LeaderboardWorkoutCondition>
};


export type LessonInstanceUserSignUpsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<UserCondition>,
  orderBy?: Maybe<UserOrder>
};


export type LessonInstanceWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>,
  condition?: Maybe<LessonWorkoutCondition>
};


export type LessonInstanceLobbyEntriesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy: LobbyEntryOrder,
  condition?: Maybe<LobbyEntryCondition>
};


export type LessonInstanceUserLeaderboardArgs = {
  user?: Maybe<UserInput>,
  size: Scalars['Int'],
  offset?: Maybe<Scalars['Int']>,
  direction?: OrderByDirection,
  condition?: Maybe<UserLeaderboardCondition>
};

export type LessonInstanceCondition = {
  type?: Maybe<Array<LessonInstanceType>>,
  joinability?: Maybe<Array<LessonInstanceJoinability>>,
  lobbyStatus?: Maybe<Array<LessonInstanceLobbyStatus>>,
  groupClassType?: Maybe<Array<GroupClassType>>,
  primaryOwner?: Maybe<Array<UserInput>>,
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  excludeCancelled?: Maybe<Scalars['Boolean']>,
  playbackType?: Maybe<Array<LessonInstancePlaybackType>>,
  hasPlatformPartner?: Maybe<Scalars['Boolean']>,
};

export type LessonInstanceConnection = {
   __typename?: 'LessonInstanceConnection',
  edges?: Maybe<Array<LessonInstanceEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LessonInstanceCurrentTimeEvent = {
   __typename?: 'LessonInstanceCurrentTimeEvent',
  clientTime: Scalars['DateTime'],
  videoTime: Scalars['Seconds'],
};

export type LessonInstanceEdge = {
   __typename?: 'LessonInstanceEdge',
  node: LessonInstance,
};

export type LessonInstanceEditPermission = {
   __typename?: 'LessonInstanceEditPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<LessonInstanceEditPermissionReason>>,
};

export enum LessonInstanceEditPermissionReason {
  PUBLIC_JOINABILITY = 'PUBLIC_JOINABILITY',
  NOT_PRIMARY_OWNER = 'NOT_PRIMARY_OWNER',
  UNKNOWN = 'UNKNOWN'
}


export enum LessonInstanceJoinability {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type LessonInstanceJoinPermission = {
   __typename?: 'LessonInstanceJoinPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<LessonInstanceJoinPermissionReason>>,
};

export enum LessonInstanceJoinPermissionReason {
  CANCELLED = 'CANCELLED',
  PRIVATE = 'PRIVATE',
  PREMIUM = 'PREMIUM',
  LOBBY_NOT_YET_OPEN = 'LOBBY_NOT_YET_OPEN',
  LOBBY_CLOSED = 'LOBBY_CLOSED',
  FULL = 'FULL'
}

export type LessonInstanceLeaderboardEntry = {
   __typename?: 'LessonInstanceLeaderboardEntry',
  rank: Scalars['Int'],
  value?: Maybe<Scalars['Float']>,
  workout: Workout,
};

export enum LessonInstanceLobbyStatus {
  NOT_CREATED = 'NOT_CREATED',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export enum LessonInstanceOrder {
  CREATED_AT = 'CREATED_AT',
  WORKOUT_START_TIME = 'WORKOUT_START_TIME'
}

export type LessonInstancePermissions = {
   __typename?: 'LessonInstancePermissions',
  join: LessonInstanceJoinPermission,
  edit: LessonInstanceEditPermission,
};

export enum LessonInstancePlaybackType {
  LIVE = 'LIVE',
  VIRTUAL = 'VIRTUAL'
}

export enum LessonInstancePlayState {
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING'
}

export type LessonInstanceSchedule = {
   __typename?: 'LessonInstanceSchedule',
  id: Scalars['LessonInstanceScheduleId'],
  schedulePeriod: SchedulePeriod,
  startsAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
  lessonInstance: LessonInstanceConnection,
  isCancelled: Scalars['Boolean'],
};


export type LessonInstanceScheduleLessonInstanceArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonInstanceOrder>,
  condition?: Maybe<LessonInstanceCondition>
};

export type LessonInstanceScheduleCondition = {
  isCancelled?: Maybe<Scalars['Boolean']>,
};

export type LessonInstanceScheduleConnection = {
   __typename?: 'LessonInstanceScheduleConnection',
  edges?: Maybe<Array<LessonInstanceScheduleEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LessonInstanceScheduleEdge = {
   __typename?: 'LessonInstanceScheduleEdge',
  node: LessonInstanceSchedule,
};


export enum LessonInstanceScheduleOrder {
  CREATED_AT = 'CREATED_AT',
  STARTS_AT = 'STARTS_AT',
  ENDS_AT = 'ENDS_AT'
}

export type LessonInstanceSignUpInput = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  lessonInstanceId: Scalars['LessonInstanceId'],
};

export enum LessonInstanceType {
  VIRTUAL_SYNC = 'VIRTUAL_SYNC',
  INDIVIDUAL = 'INDIVIDUAL'
}

export type LessonInstanceUpdatedEvent = {
   __typename?: 'LessonInstanceUpdatedEvent',
  lessonInstance: LessonInstance,
  prev: PrevLessonInstanceEvent,
};

export enum LessonInstanceWorkoutRank {
  LEADERBOARD_POSITION = 'LEADERBOARD_POSITION'
}

export type LessonIntensity = {
   __typename?: 'LessonIntensity',
  userScore: Scalars['Float'],
  normalisedScore: Scalars['Float'],
};

export type LessonLeaderboardEntry = {
   __typename?: 'LessonLeaderboardEntry',
  rank: Scalars['Int'],
  value?: Maybe<Scalars['Float']>,
  workout: Workout,
};

export type LessonMedia = {
   __typename?: 'LessonMedia',
  id: Scalars['LessonMediaId'],
  lesson: Lesson,
  music: MusicInfo,
  trainerAudioOnly: Scalars['Boolean'],
  videoRevision?: Maybe<LessonVideoRevision>,
  commercial: Scalars['Boolean'],
  premium: Scalars['Boolean'],
  hlsUuid: Scalars['UUID'],
  hlsUrl: Scalars['URL'],
  dashUuid?: Maybe<Scalars['UUID']>,
  dashUrl?: Maybe<Scalars['URL']>,
  status: LessonMediaStatus,
  uploadedBy: User,
  priority: Scalars['Int'],
  permissions: LessonMediaPermission,
  createdAt: Scalars['DateTime'],
  branding?: Maybe<Brand>,
};

export type LessonMediaCondition = {
  status?: Maybe<Array<LessonMediaStatus>>,
  streamResourceType?: Maybe<Array<StreamResourceType>>,
};


export type LessonMediaPermission = {
   __typename?: 'LessonMediaPermission',
  stream: LessonMediaStream,
};

export type LessonMediaPreference = {
  brand?: Maybe<Array<Scalars['BrandId']>>,
  commercial?: Maybe<Array<Scalars['Boolean']>>,
};

export enum LessonMediaStatus {
  PUBLISHED = 'PUBLISHED',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED',
  TESTING = 'TESTING'
}

export type LessonMediaStream = {
   __typename?: 'LessonMediaStream',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<LessonMediaStreamPermissionReason>>,
};

export enum LessonMediaStreamPermissionReason {
  PREMIUM = 'PREMIUM',
  SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
  INVALID_COUNTRY = 'INVALID_COUNTRY'
}

export enum LessonMediaType {
  DASH = 'DASH',
  HLS = 'HLS'
}

export type LessonMetrics = {
   __typename?: 'LessonMetrics',
  reps?: Maybe<LessonMetricSummary>,
  kcal?: Maybe<LessonMetricSummary>,
  bpm?: Maybe<LessonMetricSummary>,
  score?: Maybe<LessonMetricSummary>,
  time?: Maybe<LessonMetricSummary>,
  bikeKcal?: Maybe<LessonMetricSummary>,
  bikeRpm?: Maybe<LessonMetricSummary>,
  bikeSpeed?: Maybe<LessonMetricSummary>,
  bikeWatts?: Maybe<LessonMetricSummary>,
  bikeTotalScore?: Maybe<LessonMetricSummary>,
  bikeDistance?: Maybe<LessonMetricSummary>,
  treadKcal?: Maybe<LessonMetricSummary>,
  treadSpeed?: Maybe<LessonMetricSummary>,
  treadTotalScore?: Maybe<LessonMetricSummary>,
  treadWatts?: Maybe<LessonMetricSummary>,
  treadRpm?: Maybe<LessonMetricSummary>,
  treadDistance?: Maybe<LessonMetricSummary>,
  rowKcal?: Maybe<LessonMetricSummary>,
  rowSpeed?: Maybe<LessonMetricSummary>,
  rowWatts?: Maybe<LessonMetricSummary>,
  rowTotalScore?: Maybe<LessonMetricSummary>,
  rowDistance?: Maybe<LessonMetricSummary>,
  rowResistance?: Maybe<LessonMetricSummary>,
  rowSpm?: Maybe<LessonMetricSummary>,
  spinKcal?: Maybe<LessonMetricSummary>,
  spinRpm?: Maybe<LessonMetricSummary>,
  spinSpeed?: Maybe<LessonMetricSummary>,
  spinWatts?: Maybe<LessonMetricSummary>,
  spinTotalScore?: Maybe<LessonMetricSummary>,
  spinDistance?: Maybe<LessonMetricSummary>,
  spinResistance?: Maybe<LessonMetricSummary>,
};

export type LessonMetricSummary = {
   __typename?: 'LessonMetricSummary',
  average?: Maybe<Scalars['Float']>,
};

export type LessonMuscleInput = {
  id: Scalars['Int'],
};

export enum LessonOrder {
  NAME = 'NAME',
  CREATED_AT = 'CREATED_AT',
  PUBLISHED_AT = 'PUBLISHED_AT',
  UPDATED_AT = 'UPDATED_AT',
  RECOMMENDED = 'RECOMMENDED',
  POPULAR = 'POPULAR',
  OVERALL_RATING = 'OVERALL_RATING',
  NUMBER = 'NUMBER',
  WORKOUTS_COMPLETED = 'WORKOUTS_COMPLETED',
  OPENSEARCH_RANK = 'OPENSEARCH_RANK',
  MATCHES_TRAINING_GOAL = 'MATCHES_TRAINING_GOAL'
}

export type LessonOutcome = {
   __typename?: 'LessonOutcome',
  id: Scalars['Int'],
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type LessonOutcomeInput = {
  id: Scalars['Int'],
};

export type LessonPermission = {
   __typename?: 'LessonPermission',
  start: Scalars['Boolean'],
};

export type LessonPermissionsWithReasons = {
   __typename?: 'LessonPermissionsWithReasons',
  start: LessonStartPermission,
  favourite: LessonFavouritePermission,
  schedule: LessonSchedulePermission,
};

export type LessonPrescribedWeights = {
   __typename?: 'LessonPrescribedWeights',
  id: Scalars['LessonPrescribedWeightsId'],
  gender: Gender,
  weightDivision: WeightDivision,
  equipmentLevelId: Scalars['EquipmentLevelId'],
  weight: Scalars['Int'],
  lesson: Lesson,
  equipmentLevel: EquipmentLevel,
};

export type LessonPrescribedWeightsEdge = {
   __typename?: 'LessonPrescribedWeightsEdge',
  node: LessonPrescribedWeights,
};


export type LessonPushEventContext = {
  lessonId: Scalars['Int'],
};

export enum LessonPushEventName {
  SOCIAL_INTERACTION_LOBBY = 'SOCIAL_INTERACTION_LOBBY',
  SOCIAL_INTERACTION_SUMMARY = 'SOCIAL_INTERACTION_SUMMARY'
}

export type LessonRecoverySport = {
   __typename?: 'LessonRecoverySport',
  id: Scalars['Int'],
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type LessonRecoverySportInput = {
  id: Scalars['Int'],
};

export type LessonReview = {
   __typename?: 'LessonReview',
  overall: LessonReviewSummary,
};

export type LessonReviewSummary = {
   __typename?: 'LessonReviewSummary',
  avg?: Maybe<Scalars['Float']>,
  count: Scalars['Int'],
};

export type LessonSchedulePermission = {
   __typename?: 'LessonSchedulePermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<LessonSchedulePermissionReason>>,
};

export enum LessonSchedulePermissionReason {
  NO_USER = 'NO_USER',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

export type LessonSocial = {
   __typename?: 'LessonSocial',
  workoutsCount: Scalars['Int'],
  users: Array<User>,
};


export type LessonSocialUsersArgs = {
  limit: Scalars['Int']
};

export type LessonStartPermission = {
   __typename?: 'LessonStartPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<LessonStartPermissionReason>>,
};

export enum LessonStartPermissionReason {
  NO_USER = 'NO_USER',
  STATUS = 'STATUS',
  PREMIUM = 'PREMIUM',
  SCHEDULED_ONLY = 'SCHEDULED_ONLY',
  CK_SPECIAL_ACCESS_ONLY = 'CK_SPECIAL_ACCESS_ONLY',
  NOT_ON_PLAN = 'NOT_ON_PLAN',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

export enum LessonStatus {
  DRAFT = 'DRAFT',
  TESTING = 'TESTING',
  PUBLISHED = 'PUBLISHED',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED'
}

export type LessonTrainerInput = {
  id: Scalars['Int'],
};

export type LessonType = {
   __typename?: 'LessonType',
  id: Scalars['Int'],
  value: Scalars['String'],
  displayName: Scalars['String'],
};

export type LessonTypeInput = {
  id: Scalars['Int'],
};

export type LessonUiConfig = {
   __typename?: 'LessonUiConfig',
  showBpmWithZone: Scalars['Boolean'],
  showExerciseTitle: Scalars['Boolean'],
  showPBForActivityTotal: Scalars['Boolean'],
};

export type LessonVideoRevision = {
   __typename?: 'LessonVideoRevision',
  id: Scalars['LessonVideoRevisionId'],
  videoId: Scalars['VIDEO_ID'],
  lesson: Lesson,
  uploadedBy: User,
  displayName: Scalars['String'],
  uploaded: Scalars['Boolean'],
  brandId?: Maybe<Scalars['BrandId']>,
  createdAt: Scalars['DateTime'],
};

export type LessonVideoRevisionConnection = {
   __typename?: 'LessonVideoRevisionConnection',
  edges: Array<LessonVideoRevisionEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LessonVideoRevisionEdge = {
   __typename?: 'LessonVideoRevisionEdge',
  node: LessonVideoRevision,
};


export type LessonWorkoutCondition = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  state?: Maybe<Array<WorkoutState>>,
  includeAnonymous?: Maybe<Array<Scalars['Boolean']>>,
};

export enum LessonWorkoutRank {
  HERO_VALUE = 'HERO_VALUE'
}

export enum ListedChallengeOrder {
  DISPLAY_ORDER = 'DISPLAY_ORDER'
}

export enum ListedTrainingPlanOrder {
  DISPLAY_ORDER = 'DISPLAY_ORDER'
}

export type LobbyEntry = {
   __typename?: 'LobbyEntry',
  id: Scalars['LobbyEntryId'],
  lessonInstance: LessonInstance,
  party?: Maybe<Party>,
  user: User,
  leftAt?: Maybe<Scalars['DateTime']>,
  trackerConnected: Scalars['Boolean'],
  equipmentConnected: Scalars['Boolean'],
};

export type LobbyEntryCondition = {
  user?: Maybe<UserInput>,
  installationId?: Maybe<Scalars['InstallationId']>,
};

export type LobbyEntryConnection = {
   __typename?: 'LobbyEntryConnection',
  edges: Array<LobbyEntryEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LobbyEntryCreatedEvent = {
   __typename?: 'LobbyEntryCreatedEvent',
  lobbyEntry: LobbyEntry,
};

export type LobbyEntryEdge = {
   __typename?: 'LobbyEntryEdge',
  node: LobbyEntry,
};


export enum LobbyEntryOrder {
  CREATED_AT = 'CREATED_AT'
}

export type LobbyEntryUpdatedEvent = {
   __typename?: 'LobbyEntryUpdatedEvent',
  lobbyEntry: LobbyEntry,
  prev: PrevLobbyEntryEvent,
};


export type MetricsSummary = {
   __typename?: 'MetricsSummary',
  time?: Maybe<SingleMetricSummary>,
  bpm?: Maybe<SingleMetricSummary>,
  kcal?: Maybe<SingleMetricSummary>,
  reps?: Maybe<SingleMetricSummary>,
  score?: Maybe<SingleMetricSummary>,
  workRate?: Maybe<SingleMetricSummary>,
  bikeKcal?: Maybe<SingleMetricSummary>,
  bikeRpm?: Maybe<SingleMetricSummary>,
  bikeTotalScore?: Maybe<SingleMetricSummary>,
  bikeDistance?: Maybe<SingleMetricSummary>,
  treadKcal?: Maybe<SingleMetricSummary>,
  treadSpeed?: Maybe<SingleMetricSummary>,
  treadTotalScore?: Maybe<SingleMetricSummary>,
  treadDistance?: Maybe<SingleMetricSummary>,
  rowKcal?: Maybe<SingleMetricSummary>,
  rowTotalScore?: Maybe<SingleMetricSummary>,
  rowDistance?: Maybe<SingleMetricSummary>,
  rowSpeed?: Maybe<SingleMetricSummary>,
  spinKcal?: Maybe<SingleMetricSummary>,
  spinRpm?: Maybe<SingleMetricSummary>,
  spinTotalScore?: Maybe<SingleMetricSummary>,
  spinDistance?: Maybe<SingleMetricSummary>,
  spinSpeed?: Maybe<SingleMetricSummary>,
};

export enum MetricTypes {
  REPS = 'REPS',
  SCORE = 'SCORE',
  POINTS = 'POINTS',
  BIKE_TOTAL_SCORE = 'BIKE_TOTAL_SCORE',
  TREAD_TOTAL_SCORE = 'TREAD_TOTAL_SCORE',
  SPIN_TOTAL_SCORE = 'SPIN_TOTAL_SCORE',
  SPIN_DISTANCE = 'SPIN_DISTANCE',
  SPIN_KCAL = 'SPIN_KCAL',
  ROW_TOTAL_SCORE = 'ROW_TOTAL_SCORE'
}

export type Money = {
   __typename?: 'Money',
  currency: CurrencyCode,
  amount: Scalars['Int'],
};

export type MotionMetric = {
  counter: Scalars['Int'],
  accel?: Maybe<Array<Scalars['Float']>>,
};

export type MotionMetricByTime = {
  chunkElapsedTime: Scalars['Float'],
  samples?: Maybe<Array<Maybe<MotionMetric>>>,
};

export type MotionMetricConfig = {
   __typename?: 'MotionMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type MovementStandard = {
   __typename?: 'MovementStandard',
  id: Scalars['MovementStandardId'],
  exercise: Exercise,
  videoUuid: Scalars['UUID'],
  platformPartner?: Maybe<PlatformPartner>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  videoUrl: Scalars['URL'],
};

export type MovementStandardCondition = {
  platformPartnerId?: Maybe<Array<Scalars['PlatformPartnerId']>>,
};

export type MovementStandardConnection = {
   __typename?: 'MovementStandardConnection',
  edges?: Maybe<Array<MovementStandardEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type MovementStandardEdge = {
   __typename?: 'MovementStandardEdge',
  node: MovementStandard,
};


export enum MovementStandardOrder {
  CREATED_AT = 'CREATED_AT'
}

export type Muscle = {
   __typename?: 'Muscle',
  id: Scalars['Int'],
  group: MuscleGroup,
};

export enum MuscleGroup {
  FULL_BODY = 'FULL_BODY',
  CORE = 'CORE',
  LOWER_BODY = 'LOWER_BODY',
  UPPER_BODY = 'UPPER_BODY'
}

export type MusicGenre = {
   __typename?: 'MusicGenre',
  id: Scalars['MusicGenreId'],
  displayName: Scalars['String'],
  value: Scalars['String'],
};



export type MusicInfo = {
   __typename?: 'MusicInfo',
  id: Scalars['MusicId'],
  displayName: Scalars['String'],
  uploadedBy: User,
  lesson: Lesson,
  premium: Scalars['Boolean'],
  commercial: Scalars['Boolean'],
  tracks: Array<MusicTrack>,
  versions?: Maybe<Array<MusicVersion>>,
  createdAt: Scalars['DateTime'],
  uploaded: Scalars['Boolean'],
  type: MusicType,
};


export type MusicInfoTracksArgs = {
  orderBy?: Maybe<TrackInfoOrder>
};

export type MusicTrack = {
   __typename?: 'MusicTrack',
  id: Scalars['MusicTrackId'],
  mixStartSeconds: Scalars['Seconds'],
  mixEndSeconds: Scalars['Seconds'],
  trackStartSeconds: Scalars['Seconds'],
  trackEndSeconds: Scalars['Seconds'],
  info: TrackInfo,
  music: MusicInfo,
};


export enum MusicType {
  COMMERCIAL = 'COMMERCIAL',
  ROYALTY_FREE = 'ROYALTY_FREE',
  TRAINER_AUDIO_ONLY = 'TRAINER_AUDIO_ONLY'
}

export type MusicVersion = {
   __typename?: 'MusicVersion',
  id: Scalars['UUID'],
  musicId: Scalars['MusicId'],
  musicInfo: MusicInfo,
};

export type Mutation = {
   __typename?: 'Mutation',
  dummy?: Maybe<Scalars['String']>,
  createAgreement?: Maybe<Agreement>,
  patchAgreementById?: Maybe<Agreement>,
  createAttribution: Attribution,
  createBlog: Blog,
  patchBlogById?: Maybe<Blog>,
  createBodyPart: BodyPart,
  patchBodyPartById: BodyPart,
  deleteBodyPartById: DeletedBodyPart,
  createBrand?: Maybe<Brand>,
  patchBrandById?: Maybe<Brand>,
  createChallenge: Challenge,
  patchChallengeById: Challenge,
  sendChallengeInvite: SendChallengeInviteResponse,
  createChallengeList: ChallengeList,
  patchChallengeListById?: Maybe<ChallengeList>,
  createCollection: Collection,
  patchCollectionById: Collection,
  createCollectionList: CollectionList,
  patchCollectionListById: CollectionList,
  createCollectionUpdate: CollectionUpdate,
  patchCollectionUpdateById: CollectionUpdate,
  createCommunicationMessageGroup: CreateCommunicationMessageGroupResponse,
  patchCommunicationMessageGroupById: PatchCommunicationMessageGroupResponse,
  createConnectedKit: ConnectedKit,
  patchConnectedKitById: ConnectedKit,
  createContentVideo?: Maybe<ContentVideoDetails>,
  patchContentVideoById?: Maybe<ContentVideoDetails>,
  addContentVideoToTrainer?: Maybe<ContentVideoDetails>,
  createCustomEquipment?: Maybe<CustomEquipment>,
  patchCustomEquipmentById?: Maybe<CustomEquipment>,
  createDeepLinkAttemptInfo?: Maybe<DeepLinkAttemptInfo>,
  patchDeepLinkAttemptInfoById?: Maybe<DeepLinkAttemptInfo>,
  createEquipment: Equipment,
  deleteEquipmentById: DeletedEquipment,
  patchEquipmentById: Equipment,
  createEquipmentLevel: EquipmentLevel,
  deleteEquipmentLevelById: DeletedEquipmentLevel,
  patchEquipmentLevelById: EquipmentLevel,
  createExercise?: Maybe<Exercise>,
  patchExerciseById?: Maybe<Exercise>,
  generateFreeSubCodes: GenerateFreeSubCodesResponse,
  generateAndEmailFreeSubCode: GenerateAndEmailFreeSubCodeResponse,
  redeemFreeSubCode: RedeemFreeSubCodeResponse,
  patchFreeSubCode: FreeSubCode,
  bulkPatchFreeSubCodes?: Maybe<BulkPatchFreeSubCodesResponse>,
  createImage?: Maybe<Image>,
  uploadImage?: Maybe<Image>,
  uploadImageSeparateInputs?: Maybe<Image>,
  patchImageById?: Maybe<Image>,
  createInstallation: CreateInstallationResponse,
  subscribeToCommunicationMessageTypes: SubscribeToCommunicationMessageTypesResponse,
  unsubscribeFromCommunicationMessageTypes: UnsubscribeFromCommunicationMessageTypesResponse,
  createLesson?: Maybe<Lesson>,
  patchLessonById?: Maybe<Lesson>,
  favouriteLesson: User,
  unfavouriteLesson: User,
  releaseLessonEditLockById: Lesson,
  createLessonInstance: LessonInstance,
  patchLessonInstanceById: LessonInstance,
  signUpForLessonInstance: User,
  removeSignUpForLessonInstance: User,
  sendLessonInstanceInvite: SendLessonInstanceInviteResponse,
  publishLessonInstanceCurrentTime: PublishLessonInstanceCurrentTimeResponse,
  createLessonInstanceSchedule: LessonInstanceSchedule,
  cancelLessonInstanceScheduleById: LessonInstanceSchedule,
  patchLessonInstanceScheduleById: LessonInstanceSchedule,
  createLessonMedia?: Maybe<LessonMedia>,
  patchLessonMediaById?: Maybe<LessonMedia>,
  createLessonOutcome: LessonOutcome,
  patchLessonOutcomeById: LessonOutcome,
  deleteLessonOutcomeById: DeletedLessonOutcome,
  createLessonRecoverySport: LessonRecoverySport,
  patchLessonRecoverySportById: LessonRecoverySport,
  deleteLessonRecoverySportById?: Maybe<DeletedLessonRecoverySport>,
  createLessonType: LessonType,
  patchLessonTypeById: LessonType,
  deleteLessonTypeById: DeletedLessonType,
  createLessonVideoRevision: LessonVideoRevision,
  patchLessonVideoRevision: LessonVideoRevision,
  joinLobby: JoinLobbyResponse,
  leaveLobby: LeaveLobbyResponse,
  patchLobbyEntry: PatchLobbyEntryResponse,
  createMovementStandard: MovementStandard,
  patchMovementStandardById: MovementStandard,
  createMusic: MusicInfo,
  patchMusic: MusicInfo,
  createMusicGenre: MusicGenre,
  patchMusicGenreById: MusicGenre,
  deleteMusicGenreById: DeleteMusicGenreByIdResponse,
  payOrder: PayOrderResponse,
  createTggOrder: CreateTggOrderResponse,
  generatePaidSubCodes: GeneratePaidSubCodesResponse,
  patchPaidSubCode: PaidSubCode,
  bulkPatchPaidSubCodes?: Maybe<BulkPatchPaidSubCodesResponse>,
  createPartner?: Maybe<Partner>,
  patchPartnerById?: Maybe<Partner>,
  createParty: CreatePartyResponse,
  patchPartyById: PatchPartyResponse,
  reopenParty: ReopenPartyResponse,
  createPerk: Perk,
  patchPerkById: Perk,
  createPlatformPartner?: Maybe<PlatformPartner>,
  patchPlatformPartnerById?: Maybe<PlatformPartner>,
  setUserPrivacyGroupLevel: SetUserPrivacyGroupLevelResponse,
  createPrize: Prize,
  patchPrizeById: Prize,
  createPushToken?: Maybe<PushToken>,
  deletePushTokenByToken?: Maybe<PushToken>,
  createRandomSpinPrize?: Maybe<RandomSpinPrize>,
  patchRandomSpinPrizeById?: Maybe<RandomSpinPrize>,
  spinForRandomPrize?: Maybe<RandomSpinPrize>,
  createRemoteAppPin: CreateRemoteAppPinResponse,
  authRemoteAppPin: AuthRemoteAppPinResponse,
  createRepCountingProfile: RepCountingProfile,
  patchRepCountingProfileById: RepCountingProfile,
  createRepCountingProfileVersionsBulk: CreateRepCountingProfileVersionResponse,
  patchRepCountingProfileVersionById?: Maybe<RepCountingProfileVersion>,
  createSpotlightLesson: SpotlightLesson,
  patchSpotlightLessonById: SpotlightLesson,
  patchStudioById: Studio,
  createSubscription?: Maybe<CreateSubscriptionResponse>,
  createProviderSubscription?: Maybe<CreateProviderSubscriptionResponse>,
  cancelProviderSubscription?: Maybe<CancelProviderSubscriptionResponse>,
  createGympassSubscription: CreateGympassSubscriptionResponse,
  cancelGympassSubscription: CancelGympassSubscriptionResponse,
  createMoveGBSubscription: CreateMoveGbSubscriptionResponse,
  cancelMoveGBSubscription: CancelMoveGbSubscriptionResponse,
  createHussleSubscription: CreateHussleSubscriptionResponse,
  cancelHussleSubscription: CancelHussleSubscriptionResponse,
  checkCreateTggSubscription?: Maybe<CheckCreateTggSubscriptionResponse>,
  createTggSubscription: CreateTggSubscriptionResponse,
  reactivateTggSubscription: ReactivateTggSubscriptionResponse,
  cancelTggSubscription: CancelTggSubscriptionResponse,
  createBetterSpaceSubscription: CreateBetterSpaceSubscriptionResponse,
  cancelBetterSpaceSubscription: CancelBetterSpaceSubscriptionResponse,
  createHekaSubscription: CreateHekaSubscriptionResponse,
  cancelHekaSubscription: CancelHekaSubscriptionResponse,
  createYuLifeSubscription: CreateYuLifeSubscriptionResponse,
  cancelYuLifeSubscription: CancelYuLifeSubscriptionResponse,
  createPingSubscription: CreatePingSubscriptionResponse,
  cancelPingSubscription: CancelPingSubscriptionResponse,
  createHearstSubscription: CreateHearstSubscriptionResponse,
  redeemHearstSubscription: RedeemHearstSubscriptionResponse,
  cancelHearstSubscription: CancelHearstSubscriptionResponse,
  createProviderSubscriptionFromCode: CreateProviderSubscriptionFromCodeResponse,
  bulkPatchUserSubscription: BulkPatchUserSubscriptionResponse,
  createPartnerSubscription?: Maybe<CreatePartnerSubscriptionResponse>,
  cancelPartnerSubscription?: Maybe<CancelPartnerSubscriptionResponse>,
  createSubscriptionPlan?: Maybe<SubscriptionPlan>,
  patchSubscriptionPlanById?: Maybe<SubscriptionPlan>,
  createTeam?: Maybe<Team>,
  patchTeamById?: Maybe<Team>,
  deleteTeamById?: Maybe<DeletedTeam>,
  joinTeam: Team,
  leaveTeam: Team,
  createTeamBehaviourReport: TeamBehaviourReport,
  patchTeamBehaviourReportById: TeamBehaviourReport,
  createTrainer?: Maybe<Trainer>,
  patchTrainerById?: Maybe<Trainer>,
  favouriteTrainer: User,
  unfavouriteTrainer: User,
  createTrainingPlan: TrainingPlan,
  patchTrainingPlanById?: Maybe<TrainingPlan>,
  startTrainingPlan: UserTrainingPlan,
  pauseTrainingPlan: UserTrainingPlan,
  resumeTrainingPlan: UserTrainingPlan,
  cancelTrainingPlanById?: Maybe<UserTrainingPlan>,
  createUserTrainingPlanReview?: Maybe<UserTrainingPlanReview>,
  createUserTrainingPlanCompletedReview?: Maybe<UserTrainingPlanReview>,
  createUserTrainingPlanCancelledReview?: Maybe<UserTrainingPlanReview>,
  createUserTrainingPlanExpiredReview?: Maybe<UserTrainingPlanReview>,
  patchUserTrainingPlanReviewById?: Maybe<UserTrainingPlanReview>,
  createTrainingPlanList: TrainingPlanList,
  patchTrainingPlanListById?: Maybe<TrainingPlanList>,
  createTrainingPlanRecommendation: TrainingPlanRecommendation,
  patchTrainingPlanRecommendationById: TrainingPlanRecommendation,
  deleteTrainingPlanRecommendationById: DeletedTrainingPlanRecommendation,
  createTrainingPlanTemplate: TrainingPlanTemplate,
  patchTrainingPlanTemplateById?: Maybe<TrainingPlanTemplate>,
  createTutorial: Tutorial,
  patchTutorialById: Tutorial,
  releaseTutorialEditLockById: Tutorial,
  createUser?: Maybe<User>,
  patchUserById?: Maybe<User>,
  patchUser?: Maybe<User>,
  patchUserEmailById?: Maybe<User>,
  deleteUserById: DeleteUserResponse,
  deleteUserByEmail: DeleteUserResponse,
  deleteUserByStripeSubAndEmail: DeleteSuccessResponse,
  followUser: FollowUserResponse,
  unfollowUser: UnfollowUserResponse,
  removeFollower: RemoveFollowerResponse,
  grantAuthGroupsToUser: GrantAuthGroupsResponse,
  revokeAuthGroupsFromUser: RevokeAuthGroupsResponse,
  signUpFreeUserWithEmail?: Maybe<User>,
  createUserBehaviourReport: UserBehaviourReport,
  patchUserBehaviourReportById: UserBehaviourReport,
  startChallenge: UserChallenge,
  leaveChallengeById: UserChallenge,
  setUserInteractionLevel: SetUserInteractionLevelResponse,
  sendUserPushEventForLesson: SendUserPushEventResponse,
  createUserRandomSpinPrizeSpin?: Maybe<UserRandomSpinPrizeSpin>,
  claimRandomSpinPrize?: Maybe<UserRandomSpinPrizeSpin>,
  createVideo: Video,
  patchVideoById: Video,
  startWorkout: Workout,
  startStreamableWorkout: StartWorkoutResponse,
  updateWorkoutStatsById?: Maybe<Workout>,
  finishWorkoutById?: Maybe<Workout>,
  explainIncompleteWorkoutById: ExplainIncompleteWorkoutResponse,
  prepareStream: PrepareStreamResponse,
  fairPlayDRMKeyExchange: FairPlayDrmKeyExchangeResponse,
  patchWorkoutById: Workout,
  patchWorkoutStateById: Workout,
  patchWorkoutHeroValueById: Workout,
  createWorkoutReaction?: Maybe<WorkoutReaction>,
  patchWorkoutReactionById?: Maybe<WorkoutReaction>,
  deleteWorkoutReactionById?: Maybe<DeletedWorkoutReaction>,
  createWorkoutReview: WorkoutReview,
};


export type MutationCreateAgreementArgs = {
  input: CreateAgreementInput
};


export type MutationPatchAgreementByIdArgs = {
  id: Scalars['Int'],
  input: PatchAgreementInput
};


export type MutationCreateAttributionArgs = {
  input: CreateAttributionInput
};


export type MutationCreateBlogArgs = {
  input: CreateBlogInput
};


export type MutationPatchBlogByIdArgs = {
  id: Scalars['Int'],
  input: PatchBlogInput
};


export type MutationCreateBodyPartArgs = {
  input: CreateBodyPartInput
};


export type MutationPatchBodyPartByIdArgs = {
  id: Scalars['Int'],
  input: PatchBodyPartInput
};


export type MutationDeleteBodyPartByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput
};


export type MutationPatchBrandByIdArgs = {
  id: Scalars['BrandId'],
  input: PatchBrandInput
};


export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput
};


export type MutationPatchChallengeByIdArgs = {
  id: Scalars['ChallengeId'],
  input: PatchChallengeInput
};


export type MutationSendChallengeInviteArgs = {
  input: SendChallengeInviteInput
};


export type MutationCreateChallengeListArgs = {
  input: CreateChallengeListInput
};


export type MutationPatchChallengeListByIdArgs = {
  id: Scalars['ChallengeListId'],
  input: PatchChallengeListInput
};


export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput
};


export type MutationPatchCollectionByIdArgs = {
  id: Scalars['CollectionId'],
  input: PatchCollectionInput
};


export type MutationCreateCollectionListArgs = {
  input: CreateCollectionListInput
};


export type MutationPatchCollectionListByIdArgs = {
  id: Scalars['CollectionListId'],
  input: PatchCollectionListInput
};


export type MutationCreateCollectionUpdateArgs = {
  input: CreateCollectionUpdateInput
};


export type MutationPatchCollectionUpdateByIdArgs = {
  id: Scalars['CollectionUpdateId'],
  input: PatchCollectionUpdateInput
};


export type MutationCreateCommunicationMessageGroupArgs = {
  input: CreateCommunicationMessageGroupInput
};


export type MutationPatchCommunicationMessageGroupByIdArgs = {
  id: Scalars['CommunicationMessageGroupId'],
  input: PatchCommunicationMessageGroupInput
};


export type MutationCreateConnectedKitArgs = {
  input: CreateConnectedKitInput
};


export type MutationPatchConnectedKitByIdArgs = {
  id: Scalars['ConnectedKitId'],
  input: PatchConnectedKitInput
};


export type MutationCreateContentVideoArgs = {
  input: CreateContentVideoInput
};


export type MutationPatchContentVideoByIdArgs = {
  id: Scalars['String'],
  input: PatchContentVideoInput
};


export type MutationAddContentVideoToTrainerArgs = {
  input: ContentVideoToTrainerInput
};


export type MutationCreateCustomEquipmentArgs = {
  input: CreateCustomEquipmentInput
};


export type MutationPatchCustomEquipmentByIdArgs = {
  id: Scalars['CustomEquipmentId'],
  input: PatchCustomEquipmentInput
};


export type MutationCreateDeepLinkAttemptInfoArgs = {
  input: CreateDeepLinkAttemptInfoInput
};


export type MutationPatchDeepLinkAttemptInfoByIdArgs = {
  id: Scalars['DeepLinkAttemptInfoId'],
  input: PatchDeepLinkAttemptInfoInput
};


export type MutationCreateEquipmentArgs = {
  input: CreateEquipmentInput
};


export type MutationDeleteEquipmentByIdArgs = {
  id: Scalars['EquipmentId']
};


export type MutationPatchEquipmentByIdArgs = {
  id: Scalars['EquipmentId'],
  input: PatchEquipmentInput
};


export type MutationCreateEquipmentLevelArgs = {
  input: CreateEquipmentLevelInput
};


export type MutationDeleteEquipmentLevelByIdArgs = {
  id: Scalars['EquipmentLevelId']
};


export type MutationPatchEquipmentLevelByIdArgs = {
  id: Scalars['EquipmentLevelId'],
  input: PatchEquipmentLevelInput
};


export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput
};


export type MutationPatchExerciseByIdArgs = {
  id: Scalars['Int'],
  input: PatchExerciseInput
};


export type MutationGenerateFreeSubCodesArgs = {
  input: GenerateFreeSubCodesInput
};


export type MutationGenerateAndEmailFreeSubCodeArgs = {
  input: GenerateAndEmailFreeSubCodeInput
};


export type MutationRedeemFreeSubCodeArgs = {
  input: RedeemFreeSubCodeInput
};


export type MutationPatchFreeSubCodeArgs = {
  id: Scalars['FreeSubCodeId'],
  input: PatchFreeSubCodeInput
};


export type MutationBulkPatchFreeSubCodesArgs = {
  partnerId?: Maybe<FreeSubCodePartnerId>,
  campaignId?: Maybe<Scalars['String']>,
  input: BulkPatchFreeSubCodeInput
};


export type MutationCreateImageArgs = {
  input: CreateImageInput
};


export type MutationUploadImageArgs = {
  input: UploadImageInput
};


export type MutationUploadImageSeparateInputsArgs = {
  src: Scalars['Upload'],
  input: UploadImageSeparateInputsInput
};


export type MutationPatchImageByIdArgs = {
  id: Scalars['Int'],
  input: PatchImageInput
};


export type MutationCreateInstallationArgs = {
  input: CreateInstallationInput
};


export type MutationSubscribeToCommunicationMessageTypesArgs = {
  input: SubscribeToCommunicationMessageTypesInput
};


export type MutationUnsubscribeFromCommunicationMessageTypesArgs = {
  input: UnsubscribeFromCommunicationMessageTypesInput
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput
};


export type MutationPatchLessonByIdArgs = {
  id: Scalars['Int'],
  input: PatchLessonInput
};


export type MutationFavouriteLessonArgs = {
  input: FavouriteLessonInput
};


export type MutationUnfavouriteLessonArgs = {
  input: FavouriteLessonInput
};


export type MutationReleaseLessonEditLockByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateLessonInstanceArgs = {
  input: CreateLessonInstanceInput
};


export type MutationPatchLessonInstanceByIdArgs = {
  id: Scalars['LessonInstanceId'],
  input: PatchLessonInstanceInput
};


export type MutationSignUpForLessonInstanceArgs = {
  input: LessonInstanceSignUpInput
};


export type MutationRemoveSignUpForLessonInstanceArgs = {
  input: LessonInstanceSignUpInput
};


export type MutationSendLessonInstanceInviteArgs = {
  input: SendLessonInstanceInviteInput
};


export type MutationPublishLessonInstanceCurrentTimeArgs = {
  id: Scalars['LessonInstanceId'],
  input: PublishLessonInstanceCurrentTimeInput
};


export type MutationCreateLessonInstanceScheduleArgs = {
  input: CreateLessonInstanceScheduleInput
};


export type MutationCancelLessonInstanceScheduleByIdArgs = {
  id: Scalars['LessonInstanceScheduleId']
};


export type MutationPatchLessonInstanceScheduleByIdArgs = {
  id: Scalars['LessonInstanceScheduleId'],
  input: PatchLessonInstanceScheduleInput
};


export type MutationCreateLessonMediaArgs = {
  input: CreateLessonMediaInput
};


export type MutationPatchLessonMediaByIdArgs = {
  id: Scalars['LessonMediaId'],
  input: PatchLessonMediaInput
};


export type MutationCreateLessonOutcomeArgs = {
  input: CreateLessonOutcomeInput
};


export type MutationPatchLessonOutcomeByIdArgs = {
  id: Scalars['Int'],
  input: PatchLessonOutcomeInput
};


export type MutationDeleteLessonOutcomeByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateLessonRecoverySportArgs = {
  input: CreateLessonRecoverySportInput
};


export type MutationPatchLessonRecoverySportByIdArgs = {
  id: Scalars['Int'],
  input: PatchLessonRecoverySportInput
};


export type MutationDeleteLessonRecoverySportByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateLessonTypeArgs = {
  input: CreateLessonTypeInput
};


export type MutationPatchLessonTypeByIdArgs = {
  id: Scalars['Int'],
  input: PatchLessonTypeInput
};


export type MutationDeleteLessonTypeByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateLessonVideoRevisionArgs = {
  input: CreateLessonVideoRevisionInput
};


export type MutationPatchLessonVideoRevisionArgs = {
  id: Scalars['LessonVideoRevisionId'],
  input: PatchLessonVideoRevisionInput
};


export type MutationJoinLobbyArgs = {
  input: JoinLobbyInput
};


export type MutationLeaveLobbyArgs = {
  input: LeaveLobbyInput
};


export type MutationPatchLobbyEntryArgs = {
  id: Scalars['LobbyEntryId'],
  input: PatchLobbyEntryInput
};


export type MutationCreateMovementStandardArgs = {
  input: CreateMovementStandardInput
};


export type MutationPatchMovementStandardByIdArgs = {
  id: Scalars['MovementStandardId'],
  input: PatchMovementStandardInput
};


export type MutationCreateMusicArgs = {
  input: CreateMusicInput
};


export type MutationPatchMusicArgs = {
  id: Scalars['MusicId'],
  input: PatchMusicInput
};


export type MutationCreateMusicGenreArgs = {
  input: CreateMusicGenreInput
};


export type MutationPatchMusicGenreByIdArgs = {
  id: Scalars['MusicGenreId'],
  input: PatchMusicGenreInput
};


export type MutationDeleteMusicGenreByIdArgs = {
  id: Scalars['MusicGenreId']
};


export type MutationPayOrderArgs = {
  input: PayOrderInput
};


export type MutationCreateTggOrderArgs = {
  input: CreateTggOrderInput
};


export type MutationGeneratePaidSubCodesArgs = {
  input: GeneratePaidSubCodesInput
};


export type MutationPatchPaidSubCodeArgs = {
  id: Scalars['PaidSubCodeId'],
  input: PatchPaidSubCodeInput
};


export type MutationBulkPatchPaidSubCodesArgs = {
  subscriptionProvider?: Maybe<PaymentProvider>,
  subscriptionInterval?: Maybe<SubscriptionInterval>,
  campaignId?: Maybe<Scalars['String']>,
  input: BulkPatchPaidSubCodeInput
};


export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput
};


export type MutationPatchPartnerByIdArgs = {
  id: Scalars['PartnerId'],
  input: PatchPartnerInput
};


export type MutationCreatePartyArgs = {
  input: CreatePartyInput
};


export type MutationPatchPartyByIdArgs = {
  id: Scalars['PartyId'],
  input: PatchPartyInput
};


export type MutationReopenPartyArgs = {
  id: Scalars['PartyId']
};


export type MutationCreatePerkArgs = {
  input: CreatePerkInput
};


export type MutationPatchPerkByIdArgs = {
  id: Scalars['PerkId'],
  input: PatchPerkInput
};


export type MutationCreatePlatformPartnerArgs = {
  input: CreatePlatformPartnerInput
};


export type MutationPatchPlatformPartnerByIdArgs = {
  id: Scalars['PlatformPartnerId'],
  input: PatchPlatformPartnerInput
};


export type MutationSetUserPrivacyGroupLevelArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  privacyGroupIds: Array<PrivacyGroupId>,
  privacyLevelId: PrivacyLevelId
};


export type MutationCreatePrizeArgs = {
  input: CreatePrizeInput
};


export type MutationPatchPrizeByIdArgs = {
  id: Scalars['PrizeId'],
  input: PatchPrizeInput
};


export type MutationCreatePushTokenArgs = {
  input: CreatePushTokenInput
};


export type MutationDeletePushTokenByTokenArgs = {
  token: Scalars['String']
};


export type MutationCreateRandomSpinPrizeArgs = {
  input: CreateRandomSpinPrizeInput
};


export type MutationPatchRandomSpinPrizeByIdArgs = {
  id: Scalars['RandomSpinPrizeId'],
  input: PatchRandomSpinPrizeInput
};


export type MutationSpinForRandomPrizeArgs = {
  input: SpinForRandomPrizeInput
};


export type MutationCreateRemoteAppPinArgs = {
  input: CreateRemoteAppPinInput
};


export type MutationAuthRemoteAppPinArgs = {
  input: AuthRemoteAppPinInput
};


export type MutationCreateRepCountingProfileArgs = {
  input: CreateRepCountingProfileInput
};


export type MutationPatchRepCountingProfileByIdArgs = {
  id: Scalars['RepCountingProfileId'],
  input: PatchRepCountingProfileInput
};


export type MutationCreateRepCountingProfileVersionsBulkArgs = {
  input?: Maybe<Array<CreateRepCountingProfileVersionInput>>
};


export type MutationPatchRepCountingProfileVersionByIdArgs = {
  id: Scalars['RepCountingProfileVersionId'],
  input: PatchRepCountingProfileVersionInput
};


export type MutationCreateSpotlightLessonArgs = {
  input: CreateSpotlightLessonInput
};


export type MutationPatchSpotlightLessonByIdArgs = {
  id: Scalars['Int'],
  input: PatchSpotlightLessonInput
};


export type MutationPatchStudioByIdArgs = {
  id: StudioType,
  input: PatchStudioInput
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput
};


export type MutationCreateProviderSubscriptionArgs = {
  input: CreateProviderSubscriptionInput
};


export type MutationCancelProviderSubscriptionArgs = {
  input: CancelProviderSubscriptionInput
};


export type MutationCreateGympassSubscriptionArgs = {
  input: CreateGympassSubscriptionInput
};


export type MutationCancelGympassSubscriptionArgs = {
  input: CancelGympassSubscriptionInput
};


export type MutationCreateMoveGbSubscriptionArgs = {
  input: CreateMoveGbSubscriptionInput
};


export type MutationCancelMoveGbSubscriptionArgs = {
  input: CancelMoveGbSubscriptionInput
};


export type MutationCreateHussleSubscriptionArgs = {
  input: CreateHussleSubscriptionInput
};


export type MutationCancelHussleSubscriptionArgs = {
  input: CancelHussleSubscriptionInput
};


export type MutationCheckCreateTggSubscriptionArgs = {
  input: CheckCreateTggSubscriptionInput
};


export type MutationCreateTggSubscriptionArgs = {
  input: CreateTggSubscriptionInput
};


export type MutationReactivateTggSubscriptionArgs = {
  input: ReactivateTggSubscriptionInput
};


export type MutationCancelTggSubscriptionArgs = {
  input: CancelTggSubscriptionInput
};


export type MutationCreateBetterSpaceSubscriptionArgs = {
  input: CreateBetterSpaceSubscriptionInput
};


export type MutationCancelBetterSpaceSubscriptionArgs = {
  input: CancelBetterSpaceSubscriptionInput
};


export type MutationCreateHekaSubscriptionArgs = {
  input: CreateHekaSubscriptionInput
};


export type MutationCancelHekaSubscriptionArgs = {
  input: CancelHekaSubscriptionInput
};


export type MutationCreateYuLifeSubscriptionArgs = {
  input: CreateYuLifeSubscriptionInput
};


export type MutationCancelYuLifeSubscriptionArgs = {
  input: CancelYuLifeSubscriptionInput
};


export type MutationCreatePingSubscriptionArgs = {
  input: CreatePingSubscriptionInput
};


export type MutationCancelPingSubscriptionArgs = {
  input: CancelPingSubscriptionInput
};


export type MutationCreateHearstSubscriptionArgs = {
  input: CreateHearstSubscriptionInput
};


export type MutationRedeemHearstSubscriptionArgs = {
  input: RedeemHearstSubscriptionInput
};


export type MutationCancelHearstSubscriptionArgs = {
  input: CancelHearstSubscriptionInput
};


export type MutationCreateProviderSubscriptionFromCodeArgs = {
  input: CreateProviderSubscriptionFromCodeInput
};


export type MutationBulkPatchUserSubscriptionArgs = {
  userIds: Array<Scalars['Int']>,
  input: BulkPatchUserSubscriptionInput
};


export type MutationCreatePartnerSubscriptionArgs = {
  input: CreatePartnerSubscriptionInput
};


export type MutationCancelPartnerSubscriptionArgs = {
  input: CancelPartnerSubscriptionInput
};


export type MutationCreateSubscriptionPlanArgs = {
  input: CreateSubscriptionPlanInput
};


export type MutationPatchSubscriptionPlanByIdArgs = {
  id: Scalars['Int'],
  input: PatchSubscriptionPlanInput
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput
};


export type MutationPatchTeamByIdArgs = {
  id: Scalars['TeamId'],
  input: PatchTeamInput
};


export type MutationDeleteTeamByIdArgs = {
  id: Scalars['TeamId']
};


export type MutationJoinTeamArgs = {
  input: JoinTeamInput
};


export type MutationLeaveTeamArgs = {
  input: LeaveTeamInput
};


export type MutationCreateTeamBehaviourReportArgs = {
  input: CreateTeamBehaviourReportInput
};


export type MutationPatchTeamBehaviourReportByIdArgs = {
  id: Scalars['TeamBehaviourReportId'],
  input: PatchTeamBehaviourReportInput
};


export type MutationCreateTrainerArgs = {
  input: CreateTrainerInput
};


export type MutationPatchTrainerByIdArgs = {
  id: Scalars['Int'],
  input: PatchTrainerInput
};


export type MutationFavouriteTrainerArgs = {
  input: FavouriteTrainerInput
};


export type MutationUnfavouriteTrainerArgs = {
  input: FavouriteTrainerInput
};


export type MutationCreateTrainingPlanArgs = {
  input: CreateTrainingPlanInput
};


export type MutationPatchTrainingPlanByIdArgs = {
  id: Scalars['Int'],
  input: PatchTrainingPlanInput
};


export type MutationStartTrainingPlanArgs = {
  input: StartUserTrainingPlanInput
};


export type MutationPauseTrainingPlanArgs = {
  input: PauseUserTrainingPlanInput
};


export type MutationResumeTrainingPlanArgs = {
  input: ResumeUserTrainingPlanInput
};


export type MutationCancelTrainingPlanByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateUserTrainingPlanReviewArgs = {
  input: CreateUserTrainingPlanReviewInput
};


export type MutationCreateUserTrainingPlanCompletedReviewArgs = {
  input: CreateUserTrainingPlanCompletedReviewInput
};


export type MutationCreateUserTrainingPlanCancelledReviewArgs = {
  input: CreateUserTrainingPlanCancelledReviewInput
};


export type MutationCreateUserTrainingPlanExpiredReviewArgs = {
  input: CreateUserTrainingPlanExpiredReviewInput
};


export type MutationPatchUserTrainingPlanReviewByIdArgs = {
  id: Scalars['Int'],
  input: PatchUserTrainingPlanReviewInput
};


export type MutationCreateTrainingPlanListArgs = {
  input: CreateTrainingPlanListInput
};


export type MutationPatchTrainingPlanListByIdArgs = {
  id: Scalars['TrainingPlanListId'],
  input: PatchTrainingPlanListInput
};


export type MutationCreateTrainingPlanRecommendationArgs = {
  input: CreateTrainingPlanRecommendationInput
};


export type MutationPatchTrainingPlanRecommendationByIdArgs = {
  id: Scalars['TrainingPlanRecommendationId'],
  input: PatchTrainingPlanRecommendationInput
};


export type MutationDeleteTrainingPlanRecommendationByIdArgs = {
  id: Scalars['TrainingPlanRecommendationId']
};


export type MutationCreateTrainingPlanTemplateArgs = {
  input: CreateTrainingPlanTemplateInput
};


export type MutationPatchTrainingPlanTemplateByIdArgs = {
  id: Scalars['Int'],
  input: PatchTrainingPlanTemplateInput
};


export type MutationCreateTutorialArgs = {
  input: CreateTutorialInput
};


export type MutationPatchTutorialByIdArgs = {
  id: Scalars['Int'],
  input: PatchTutorialInput
};


export type MutationReleaseTutorialEditLockByIdArgs = {
  id: Scalars['Int']
};


export type MutationCreateUserArgs = {
  input: CreateUserInput
};


export type MutationPatchUserByIdArgs = {
  id: Scalars['Int'],
  input: PatchUserInput
};


export type MutationPatchUserArgs = {
  user: UserInput,
  input: PatchUserInput
};


export type MutationPatchUserEmailByIdArgs = {
  id: Scalars['Int'],
  input: PatchUserEmailInput
};


export type MutationDeleteUserByIdArgs = {
  id: Scalars['Int'],
  gdpr?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteUserByEmailArgs = {
  email: Scalars['Email'],
  gdpr?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteUserByStripeSubAndEmailArgs = {
  subscriptionId: Scalars['String'],
  email: Scalars['String']
};


export type MutationFollowUserArgs = {
  follower: UserInput,
  user: UserInput
};


export type MutationUnfollowUserArgs = {
  follower: UserInput,
  user: UserInput
};


export type MutationRemoveFollowerArgs = {
  follower: UserInput,
  user: UserInput
};


export type MutationGrantAuthGroupsToUserArgs = {
  user: UserInput,
  groups: Array<Scalars['String']>
};


export type MutationRevokeAuthGroupsFromUserArgs = {
  user: UserInput,
  groups: Array<Scalars['String']>
};


export type MutationSignUpFreeUserWithEmailArgs = {
  input: SignUpFreeUserWithEmailInput
};


export type MutationCreateUserBehaviourReportArgs = {
  input: CreateUserBehaviourReportInput
};


export type MutationPatchUserBehaviourReportByIdArgs = {
  id: Scalars['UserBehaviourReportId'],
  input: PatchUserBehaviourReportInput
};


export type MutationStartChallengeArgs = {
  input: StartUserChallengeInput
};


export type MutationLeaveChallengeByIdArgs = {
  input: LeaveUserChallengeInput
};


export type MutationSetUserInteractionLevelArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  userInteractionIds: Array<UserInteractionId>,
  userInteractionLevelId: UserInteractionLevelId
};


export type MutationSendUserPushEventForLessonArgs = {
  fromUser: UserInput,
  toUser: UserInput,
  eventName: LessonPushEventName,
  message: Scalars['String'],
  context: LessonPushEventContext
};


export type MutationCreateUserRandomSpinPrizeSpinArgs = {
  input: CreateUserRandomSpinPrizeSpinInput
};


export type MutationClaimRandomSpinPrizeArgs = {
  input: ClaimRandomSpinPrizeInput
};


export type MutationCreateVideoArgs = {
  input: CreateVideoInput
};


export type MutationPatchVideoByIdArgs = {
  id: Scalars['VIDEO_ID'],
  input: PatchVideoInput
};


export type MutationStartWorkoutArgs = {
  input: StartWorkoutInput
};


export type MutationStartStreamableWorkoutArgs = {
  input: StartStreamableWorkoutInput
};


export type MutationUpdateWorkoutStatsByIdArgs = {
  id: Scalars['Int'],
  input: UpdateWorkoutStatsInput
};


export type MutationFinishWorkoutByIdArgs = {
  id: Scalars['Int'],
  chunks?: Maybe<Scalars['Int']>,
  input: FinishWorkoutInput
};


export type MutationExplainIncompleteWorkoutByIdArgs = {
  id: Scalars['Int'],
  input: WorkoutIncompleteReasonInput
};


export type MutationPrepareStreamArgs = {
  input: PrepareStreamInput
};


export type MutationFairPlayDrmKeyExchangeArgs = {
  input: FairPlayDrmKeyExchangeInput
};


export type MutationPatchWorkoutByIdArgs = {
  id: Scalars['Int'],
  input: PatchWorkoutInput
};


export type MutationPatchWorkoutStateByIdArgs = {
  id: Scalars['Int'],
  state: WorkoutState
};


export type MutationPatchWorkoutHeroValueByIdArgs = {
  id: Scalars['Int'],
  heroValue: Scalars['Float']
};


export type MutationCreateWorkoutReactionArgs = {
  input: CreateWorkoutReactionInput
};


export type MutationPatchWorkoutReactionByIdArgs = {
  id: Scalars['WorkoutReactionId'],
  input: PatchWorkoutReactionInput
};


export type MutationDeleteWorkoutReactionByIdArgs = {
  id: Scalars['WorkoutReactionId']
};


export type MutationCreateWorkoutReviewArgs = {
  input: CreateWorkoutReviewInput
};

export enum OptionsAmount {
  NOT_ENOUGH = 'NOT_ENOUGH',
  PERFECT = 'PERFECT',
  TOO_MANY = 'TOO_MANY'
}

export enum OptionsIntensity {
  TOO_EASY = 'TOO_EASY',
  FAIRLY_EASY = 'FAIRLY_EASY',
  JUST_RIGHT = 'JUST_RIGHT',
  CHALLENGING = 'CHALLENGING',
  EXTREMELY_TOUGH = 'EXTREMELY_TOUGH'
}

export enum OrderByDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderMetadataInput = {
  source?: Maybe<Scalars['String']>,
  reason?: Maybe<Scalars['String']>,
  shopifySku?: Maybe<Scalars['String']>,
  shopifyProductName?: Maybe<Scalars['String']>,
  shopifyProductTitle?: Maybe<Scalars['String']>,
};

export type PageInfo = {
   __typename?: 'PageInfo',
  hasPreviousPage: Scalars['Boolean'],
  hasNextPage: Scalars['Boolean'],
};

export type PaidSubCode = {
   __typename?: 'PaidSubCode',
  id: Scalars['PaidSubCodeId'],
  subscriptionProvider: PaymentProvider,
  subscriptionSku: Scalars['String'],
  expiresAt?: Maybe<Scalars['DateTime']>,
  durationValue?: Maybe<Scalars['Int']>,
  durationUnit?: Maybe<PaidSubCodeDurationUnit>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  campaignId: Scalars['String'],
  redeemedByUserId?: Maybe<Scalars['Int']>,
  team?: Maybe<Team>,
};

export enum PaidSubCodeDurationUnit {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}


export enum ParentalWarningType {
  EXPLICIT = 'EXPLICIT',
  EXPLICIT_CONTENT_EDITED = 'EXPLICIT_CONTENT_EDITED',
  NOT_EXPLICIT = 'NOT_EXPLICIT',
  UNKNOWN = 'UNKNOWN'
}

export type Partner = {
   __typename?: 'Partner',
  id: Scalars['PartnerId'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  logoImage: Image,
  type?: Maybe<PartnerType>,
  partnerUsers: Array<PartnerUser>,
  subscriptionLimit?: Maybe<Scalars['Int']>,
  subscriptions: UserSubscriptionConnection,
  attribution?: Maybe<Attribution>,
  perks: PerkConnection,
};


export type PartnerSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  direction?: Maybe<OrderByDirection>
};


export type PartnerPerksArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<PerkOrder>
};


export enum PartnerType {
  MOBILE = 'MOBILE',
  STUDIO = 'STUDIO'
}

export type PartnerUser = {
   __typename?: 'PartnerUser',
  user: User,
  type: PartnerUserType,
  partner: Partner,
};

export enum PartnerUserType {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export type Party = {
   __typename?: 'Party',
  id: Scalars['PartyId'],
  lessonMedia: LessonMedia,
  lessonInstance: LessonInstance,
  hostUser: User,
  closedAt?: Maybe<Scalars['DateTime']>,
  status: PartyStatus,
  hostInstallation?: Maybe<Installation>,
  hostScheduleEntryId?: Maybe<Scalars['Int']>,
  lobbyEntries: LobbyEntryConnection,
  openedAt: Scalars['DateTime'],
  expired: Scalars['Boolean'],
  permissions: PartyPermissions,
};


export type PartyLobbyEntriesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LobbyEntryOrder>,
  condition?: Maybe<LobbyEntryCondition>
};

export type PartyCondition = {
  status?: Maybe<Array<Maybe<PartyStatus>>>,
  expired?: Maybe<Array<Maybe<Scalars['Boolean']>>>,
};

export type PartyConnection = {
   __typename?: 'PartyConnection',
  edges: Array<PartyEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type PartyCreatedEvent = {
   __typename?: 'PartyCreatedEvent',
  party: Party,
};

export type PartyEdge = {
   __typename?: 'PartyEdge',
  node: Party,
};


export type PartyJoinPermission = {
   __typename?: 'PartyJoinPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<PartyJoinPermissionReason>>,
};

export enum PartyJoinPermissionReason {
  CANCELLED = 'CANCELLED',
  PRIVATE = 'PRIVATE',
  PREMIUM = 'PREMIUM',
  LOBBY_NOT_YET_OPEN = 'LOBBY_NOT_YET_OPEN',
  LOBBY_CLOSED = 'LOBBY_CLOSED',
  EXPIRED = 'EXPIRED',
  JOINED_ON_ANOTHER_DEVICE = 'JOINED_ON_ANOTHER_DEVICE'
}

export type PartyOpenedEvent = {
   __typename?: 'PartyOpenedEvent',
  party: Party,
};

export enum PartyOrder {
  CREATED_AT = 'CREATED_AT',
  OPENED_AT = 'OPENED_AT'
}

export type PartyPermissions = {
   __typename?: 'PartyPermissions',
  join: PartyJoinPermission,
};


export type PartyPermissionsJoinArgs = {
  installationId: Scalars['InstallationId']
};

export enum PartyStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export type PartyUpdatedEvent = {
   __typename?: 'PartyUpdatedEvent',
  party: Party,
  prev: PrevPartyEvent,
};

export type PatchAgreementInput = {
  type?: Maybe<AgreementType>,
  url?: Maybe<Scalars['URL']>,
  published?: Maybe<Scalars['Boolean']>,
};

export type PatchBlogInput = {
  title?: Maybe<Scalars['String']>,
  blurb?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['URL']>,
  imageId?: Maybe<Scalars['Int']>,
  authoredAt?: Maybe<Scalars['DateTime']>,
};

export type PatchBodyPartInput = {
  displayName?: Maybe<Scalars['String']>,
};

export type PatchBrandInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type PatchChallengeInput = {
  name?: Maybe<Scalars['String']>,
  status?: Maybe<ChallengeStatus>,
  slug?: Maybe<Scalars['Slug']>,
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  coverImageId?: Maybe<Scalars['Int']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  prizeId?: Maybe<Scalars['String']>,
  startsAt?: Maybe<Scalars['DateTime']>,
  endsAt?: Maybe<Scalars['DateTime']>,
  block?: Maybe<Array<ChallengeBlockInput>>,
  premium?: Maybe<Scalars['Boolean']>,
  partnerId?: Maybe<Scalars['String']>,
  badgeImageId?: Maybe<Scalars['Int']>,
  completionMessage?: Maybe<Scalars['String']>,
  xpReward?: Maybe<Scalars['Int']>,
  tier?: Maybe<Array<Scalars['TierId']>>,
  challengePrivacyLevel?: Maybe<ChallengePrivacyLevel>,
  promoted?: Maybe<Scalars['Boolean']>,
};

export type PatchChallengeListInput = {
  challenges?: Maybe<Array<Scalars['ChallengeId']>>,
};

export type PatchCollectionInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageId?: Maybe<Scalars['Int']>,
  lessonConditions?: Maybe<Scalars['JSONObject']>,
  relatedCollectionListId?: Maybe<Scalars['CollectionListId']>,
  trainingPlanListId?: Maybe<Scalars['TrainingPlanListId']>,
};

export type PatchCollectionListInput = {
  name?: Maybe<Scalars['String']>,
  collections?: Maybe<Array<Scalars['CollectionId']>>,
};

export type PatchCollectionUpdateInput = {
  collectionId?: Maybe<Scalars['CollectionId']>,
  updateDate?: Maybe<Scalars['Date']>,
  lessonConditions?: Maybe<Scalars['JSONObject']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  updated?: Maybe<Scalars['Boolean']>,
};

export type PatchCommunicationMessageGroupInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  messageTypeIds?: Maybe<Array<Scalars['Int']>>,
  displayOrder?: Maybe<Scalars['Int']>,
};

export type PatchCommunicationMessageGroupResponse = {
   __typename?: 'PatchCommunicationMessageGroupResponse',
  communicationMessageGroup: CommunicationMessageGroup,
};

export type PatchConnectedKitInput = {
  displayName?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type PatchContentVideoInput = {
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
};

export type PatchCustomEquipmentInput = {
  equipmentName?: Maybe<Scalars['String']>,
  show?: Maybe<Scalars['Boolean']>,
};

export type PatchDeepLinkAttemptInfoInput = {
  user?: Maybe<UserInput>,
  deepLinkPath?: Maybe<Scalars['String']>,
  fulfilled?: Maybe<Scalars['Boolean']>,
};

export type PatchEquipmentInput = {
  shortDisplay?: Maybe<Scalars['String']>,
  longDisplay?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageId?: Maybe<Scalars['Int']>,
};

export type PatchEquipmentLevelInput = {
  displayName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  equipmentId?: Maybe<Scalars['EquipmentId']>,
};

export type PatchExerciseInput = {
  name?: Maybe<Scalars['String']>,
  family?: Maybe<ExerciseFamily>,
  calibration?: Maybe<Calibration>,
  muscle?: Maybe<MuscleGroup>,
  doubleSided?: Maybe<Scalars['Boolean']>,
  supportedCircuitTypes?: Maybe<Array<ExerciseSupportedCircuitTypeInput>>,
  supportedStudios?: Maybe<Array<ExerciseSupportedStudioInput>>,
  difficulty?: Maybe<ExerciseDifficulty>,
  wahooCalibrationId?: Maybe<Scalars['Int']>,
  repCountingProfileId?: Maybe<Scalars['RepCountingProfileId']>,
  active?: Maybe<Scalars['Boolean']>,
  connectedKitId?: Maybe<Scalars['ConnectedKitId']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
};

export type PatchFreeSubCodeInput = {
  redemptionLimit?: Maybe<Scalars['Int']>,
  expiresAt?: Maybe<Scalars['DateTime']>,
  durationValue?: Maybe<Scalars['Int']>,
  durationUnit?: Maybe<FreeSubCodeDurationUnit>,
  partnerId?: Maybe<FreeSubCodePartnerId>,
  campaignId?: Maybe<Scalars['String']>,
};

export type PatchImageInput = {
  horizontalAlignment?: Maybe<ImageHorizontalAlignment>,
  verticalAlignment?: Maybe<ImageVerticalAlignment>,
  hexColour?: Maybe<Scalars['HexColour']>,
};

export type PatchLessonInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  studioId?: Maybe<StudioType>,
  difficulty?: Maybe<LessonDifficulty>,
  mainImageId?: Maybe<Scalars['Int']>,
  durationRange?: Maybe<LessonDurationRange>,
  muscle?: Maybe<Array<LessonMuscleInput>>,
  trainer?: Maybe<Array<LessonTrainerInput>>,
  countdownBeep?: Maybe<Scalars['Boolean']>,
  finishingBeep?: Maybe<Scalars['Boolean']>,
  plan?: Maybe<Array<PlanActivityInput>>,
  status?: Maybe<LessonStatus>,
  exerciseDisplay?: Maybe<ExerciseDisplay>,
  filmAt?: Maybe<Scalars['DateTime']>,
  releaseAt?: Maybe<Scalars['DateTime']>,
  editingUserId?: Maybe<Scalars['Int']>,
  editingUser?: Maybe<UserInput>,
  number?: Maybe<Scalars['Int']>,
  trainerMusic?: Maybe<Scalars['String']>,
  rxId?: Maybe<Scalars['VIDEO_ID']>,
  ledPage?: Maybe<Scalars['Int']>,
  premium?: Maybe<Scalars['Boolean']>,
  lessonType?: Maybe<Array<LessonTypeInput>>,
  bodyPart?: Maybe<Array<LessonBodyPartInput>>,
  lessonRecoverySport?: Maybe<Array<LessonRecoverySportInput>>,
  lessonOutcome?: Maybe<Array<LessonOutcomeInput>>,
  trainingGoal?: Maybe<Array<TrainingGoalInput>>,
  musicGenreId?: Maybe<Scalars['MusicGenreId']>,
  scheduledOnly?: Maybe<Scalars['Boolean']>,
  planOnly?: Maybe<Scalars['Boolean']>,
  ckSpecialAccess?: Maybe<Scalars['Boolean']>,
  concept?: Maybe<LessonConcept>,
  heroMetric?: Maybe<MetricTypes>,
  tier?: Maybe<Array<Scalars['TierId']>>,
  supportsLive?: Maybe<Scalars['Boolean']>,
};

export type PatchLessonInstanceInput = {
  lobbyDurationSeconds?: Maybe<Scalars['Seconds']>,
  workoutStartTime?: Maybe<Scalars['DateTime']>,
  lobbyStatus?: Maybe<LessonInstanceLobbyStatus>,
  cancelled?: Maybe<Scalars['Boolean']>,
  leaderboard?: Maybe<Array<LeaderboardEntry>>,
  prizeId?: Maybe<Scalars['String']>,
  playState?: Maybe<LessonInstancePlayState>,
  memberLimit?: Maybe<Scalars['Int']>,
  lessonMediaId?: Maybe<Scalars['LessonMediaId']>,
  playbackType?: Maybe<LessonInstancePlaybackType>,
};

export type PatchLessonInstanceScheduleInput = {
  lobbyDurationSeconds?: Maybe<Scalars['Seconds']>,
  workoutStartTime?: Maybe<Scalars['DateTime']>,
  memberLimit?: Maybe<Scalars['Int']>,
  lessonMediaId?: Maybe<Scalars['LessonMediaId']>,
  playbackType?: Maybe<LessonInstancePlaybackType>,
  endsAt?: Maybe<Scalars['DateTime']>,
};

export type PatchLessonMediaInput = {
  status?: Maybe<LessonMediaStatus>,
  commercial?: Maybe<Scalars['Boolean']>,
  hlsUuid?: Maybe<Scalars['UUID']>,
  dashUuid?: Maybe<Scalars['UUID']>,
  uploadedByUserId?: Maybe<Scalars['Int']>,
  uploadedByUser?: Maybe<UserInput>,
  brandId?: Maybe<Scalars['BrandId']>,
};

export type PatchLessonOutcomeInput = {
  displayName?: Maybe<Scalars['String']>,
};

export type PatchLessonRecoverySportInput = {
  displayName?: Maybe<Scalars['String']>,
};

export type PatchLessonTypeInput = {
  displayName?: Maybe<Scalars['String']>,
};

export type PatchLessonVideoRevisionInput = {
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  displayName?: Maybe<Scalars['String']>,
  uploaded?: Maybe<Scalars['Boolean']>,
};

export type PatchLobbyEntryInput = {
  trackerConnected?: Maybe<Scalars['Boolean']>,
  equipmentConnected?: Maybe<Scalars['Boolean']>,
};

export type PatchLobbyEntryResponse = {
   __typename?: 'PatchLobbyEntryResponse',
  lobbyEntry?: Maybe<LobbyEntry>,
};

export type PatchMovementStandardInput = {
  videoUuid: Scalars['UUID'],
};

export type PatchMusicGenreInput = {
  displayName?: Maybe<Scalars['String']>,
};

export type PatchMusicInput = {
  uploaded?: Maybe<Scalars['Boolean']>,
};

export type PatchPaidSubCodeInput = {
  expiresAt?: Maybe<Scalars['DateTime']>,
  campaignId?: Maybe<Scalars['String']>,
  subscriptionType?: Maybe<SubscriptionTypeId>,
  subscriptionInterval?: Maybe<SubscriptionInterval>,
  teamId?: Maybe<Scalars['TeamId']>,
};

export type PatchPartnerInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  logoImageId?: Maybe<Scalars['Int']>,
  type?: Maybe<PartnerType>,
  subscriptionLimit?: Maybe<Scalars['Int']>,
  attributionId?: Maybe<Scalars['String']>,
};

export type PatchPartyInput = {
  status?: Maybe<PartyStatus>,
};

export type PatchPartyResponse = {
   __typename?: 'PatchPartyResponse',
  party: Party,
};

export type PatchPerkInput = {
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  partnerId?: Maybe<Scalars['PartnerId']>,
  active?: Maybe<Scalars['Boolean']>,
  activeFrom?: Maybe<Scalars['DateTime']>,
  expiresAt?: Maybe<Scalars['DateTime']>,
  tier?: Maybe<Array<Scalars['TierId']>>,
};

export type PatchPlatformPartnerInput = {
  name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['URL']>,
  holdingVideoId?: Maybe<Scalars['VIDEO_ID']>,
  exerciseLibrary?: Maybe<Scalars['Boolean']>,
  classBuilder?: Maybe<Scalars['Boolean']>,
  liveStudioClass?: Maybe<Scalars['Boolean']>,
  scheduler?: Maybe<Scalars['Boolean']>,
};

export type PatchPrizeInput = {
  description?: Maybe<Scalars['String']>,
  imageId?: Maybe<Scalars['Int']>,
};

export type PatchRandomSpinPrizeInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  weight?: Maybe<Scalars['Int']>,
  spinVariant?: Maybe<SpinVariant>,
  active?: Maybe<Scalars['Boolean']>,
  startsAt?: Maybe<Scalars['DateTime']>,
  endsAt?: Maybe<Scalars['DateTime']>,
  initialQuantity?: Maybe<Scalars['Int']>,
  imageId?: Maybe<Scalars['Int']>,
  shopifyPrizeRule?: Maybe<Scalars['String']>,
  type?: Maybe<RandomSpinPrizeType>,
  partnerCoupon?: Maybe<Scalars['String']>,
  partnerUrl?: Maybe<Scalars['URL']>,
  percentageOff?: Maybe<Scalars['Float']>,
  freeTrialDays?: Maybe<Scalars['Int']>,
  maxWinsPerUser?: Maybe<Scalars['Int']>,
};

export type PatchRepCountingProfileInput = {
  displayName?: Maybe<Scalars['String']>,
};

export type PatchRepCountingProfileVersionInput = {
  schemaVersion?: Maybe<Scalars['Int']>,
  modelPayload: Scalars['JSONObject'],
};

export type PatchSpotlightLessonInput = {
  lessonId?: Maybe<Scalars['Int']>,
  activeFrom?: Maybe<Scalars['DateTime']>,
};

export type PatchStudioInput = {
  collectionListId?: Maybe<Scalars['CollectionListId']>,
  heroImg?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  prerollVideoId?: Maybe<Scalars['VIDEO_ID']>,
};

export type PatchSubscriptionPlanInput = {
  period?: Maybe<SubscriptionPlanPeriod>,
  cycle?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  popular?: Maybe<Scalars['Boolean']>,
  requiresTracker?: Maybe<Scalars['Boolean']>,
  trialLengthDays?: Maybe<Scalars['Days']>,
  appleSku?: Maybe<Scalars['String']>,
  appleActive?: Maybe<Scalars['Boolean']>,
  amazonSku?: Maybe<Scalars['String']>,
  amazonActive?: Maybe<Scalars['Boolean']>,
  androidSku?: Maybe<Scalars['String']>,
  androidActive?: Maybe<Scalars['Boolean']>,
  international?: Maybe<Scalars['Boolean']>,
  subscriptionType?: Maybe<SubscriptionTypeId>,
};

export type PatchTeamBehaviourReportInput = {
  status?: Maybe<TeamBehaviourReportStatus>,
};

export type PatchTeamInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  profileImageId?: Maybe<Scalars['Int']>,
  bannerImageId?: Maybe<Scalars['Int']>,
  primaryOwnerId?: Maybe<Scalars['Int']>,
  privacyLevel?: Maybe<PrivacyLevelId>,
  profileImageKey?: Maybe<Scalars['String']>,
  bannerImageKey?: Maybe<Scalars['String']>,
  teamPrivacyLevel?: Maybe<TeamPrivacyLevel>,
  promoted?: Maybe<Scalars['Boolean']>,
};

export type PatchTrainerInput = {
  firstname?: Maybe<Scalars['String']>,
  lastname?: Maybe<Scalars['String']>,
  instagramHandle?: Maybe<Scalars['String']>,
  instagramId?: Maybe<Scalars['Int']>,
  facebookHandle?: Maybe<Scalars['String']>,
  twitterHandle?: Maybe<Scalars['String']>,
  instaId?: Maybe<Scalars['String']>,
  longBio?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  focus?: Maybe<Scalars['String']>,
  shortFocus?: Maybe<Scalars['String']>,
  status?: Maybe<TrainerStatus>,
  quote?: Maybe<Array<TrainerQuoteInput>>,
  coverImageId?: Maybe<Scalars['Int']>,
  avatarImageId?: Maybe<Scalars['Int']>,
  profileImage?: Maybe<Array<TrainerProfileImageInput>>,
  blog?: Maybe<Array<TrainerBlogInput>>,
  premium?: Maybe<Scalars['Boolean']>,
};

export type PatchTrainingPlanInput = {
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['Slug']>,
  status?: Maybe<TrainingPlanStatus>,
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  durationWeeks?: Maybe<Scalars['Weeks']>,
  trainingGoalId?: Maybe<UserFitnessGoal>,
  coverImageId?: Maybe<Scalars['Int']>,
  displayOrder?: Maybe<Scalars['Int']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  tagline?: Maybe<Scalars['String']>,
  rewardTypeId?: Maybe<RewardTypeId>,
  premium?: Maybe<Scalars['Boolean']>,
  brandId?: Maybe<Scalars['BrandId']>,
  cardImageId?: Maybe<Scalars['Int']>,
  equipment?: Maybe<Scalars['Boolean']>,
  promoted?: Maybe<Scalars['Boolean']>,
  xpReward?: Maybe<Scalars['Int']>,
  tier?: Maybe<Array<Scalars['TierId']>>,
  refreshed?: Maybe<Scalars['Boolean']>,
};

export type PatchTrainingPlanListInput = {
  trainingPlans?: Maybe<Array<Scalars['Int']>>,
};

export type PatchTrainingPlanRecommendationInput = {
  trainingPlanId?: Maybe<Scalars['Int']>,
  trainingGoalId?: Maybe<UserFitnessGoal>,
  activityLevel?: Maybe<UserActivityLevel>,
  equipmentId?: Maybe<Scalars['CustomEquipmentId']>,
  subscriptionTypeId?: Maybe<SubscriptionTypeId>,
};

export type PatchTrainingPlanTemplateInput = {
  level?: Maybe<LessonDifficulty>,
  trainingPlanId?: Maybe<Scalars['Int']>,
  schedule?: Maybe<Array<CreateTrainingPlanScheduleInput>>,
  weeklyTutorials?: Maybe<Array<CreateTrainingPlanWeeklyTutorial>>,
  weeklyVideos?: Maybe<Array<CreateTrainingPlanWeeklyVideo>>,
  weeklyOverviews?: Maybe<Array<CreateTrainingPlanWeeklyOverview>>,
  weeklyBlogs?: Maybe<Array<CreateTrainingPlanWeeklyBlog>>,
};

export type PatchTutorialInput = {
  name?: Maybe<Scalars['String']>,
  trainerId?: Maybe<Scalars['Int']>,
  muscle?: Maybe<Array<TutorialMuscleInput>>,
  description?: Maybe<Scalars['String']>,
  mainImageId?: Maybe<Scalars['Int']>,
  releaseAt?: Maybe<Scalars['DateTime']>,
  videoId?: Maybe<Scalars['VIDEO_ID']>,
  ledPage?: Maybe<Scalars['Int']>,
  status?: Maybe<TutorialStatus>,
  plan?: Maybe<Array<PlanActivityInput>>,
  planOnly?: Maybe<Scalars['Boolean']>,
  exerciseDisplay?: Maybe<ExerciseDisplay>,
  studioId?: Maybe<StudioType>,
  heroMetric?: Maybe<MetricTypes>,
};

export type PatchUserBehaviourReportInput = {
  status?: Maybe<UserBehaviourReportStatus>,
};

export type PatchUserEmailInput = {
  email: Scalars['Email'],
};

export type PatchUserInput = {
  firstname?: Maybe<Scalars['String']>,
  lastname?: Maybe<Scalars['String']>,
  dob?: Maybe<Scalars['Date']>,
  height?: Maybe<Scalars['Int']>,
  activityLevel?: Maybe<UserActivityLevel>,
  weight?: Maybe<Scalars['Float']>,
  gender?: Maybe<Gender>,
  sex?: Maybe<Sex>,
  fitnessGoal?: Maybe<UserFitnessGoal>,
  trainingGoalId?: Maybe<UserFitnessGoal>,
  weeklyGoal?: Maybe<UserWeeklyGoalInput>,
  phoneNumber?: Maybe<Scalars['String']>,
  unitSystemPreference?: Maybe<UnitSystem>,
  shippingAddress?: Maybe<AddressInput>,
  tosAgreement?: Maybe<Scalars['Int']>,
  healthAgreement?: Maybe<Scalars['Int']>,
  postnatalAgreement?: Maybe<Scalars['Int']>,
  breathworkAgreement?: Maybe<Scalars['Int']>,
  privacyAgreement?: Maybe<Scalars['Int']>,
  attributionId?: Maybe<Scalars['String']>,
  lastUsedFiitDevice?: Maybe<Scalars['String']>,
  subscriptionAnalyticsBlacklisted?: Maybe<Scalars['Boolean']>,
  profileImageKey?: Maybe<Scalars['String']>,
  purchasedAirbikeAt?: Maybe<Scalars['DateTime']>,
  ownedEquipmentIds?: Maybe<Array<Scalars['CustomEquipmentId']>>,
  ownedEquipmentOnboarded?: Maybe<Scalars['Boolean']>,
  maxHr?: Maybe<Scalars['Int']>,
  challengeOnboarded?: Maybe<Scalars['Boolean']>,
};

export type PatchUserTrainingPlanReviewInput = {
  rating?: Maybe<Scalars['Int']>,
  general?: Maybe<Scalars['String']>,
  intensityStrength?: Maybe<OptionsIntensity>,
  intensityCardio?: Maybe<OptionsIntensity>,
  intensityRebalance?: Maybe<OptionsIntensity>,
  planLevel?: Maybe<OptionsAmount>,
  intensityExpectations?: Maybe<OptionsIntensity>,
  incompleteReason?: Maybe<UserTrainingPlanCancelReason>,
  achievementResponse?: Maybe<Array<TrainingPlanAchievement>>,
};

export type PatchVideoInput = {
  description?: Maybe<Scalars['String']>,
  uuid?: Maybe<Scalars['UUID']>,
  previewImg?: Maybe<Scalars['URL']>,
  status?: Maybe<VideoStatus>,
};

export type PatchWorkoutInput = {
  userNotes?: Maybe<Scalars['String']>,
  locationCategory?: Maybe<WorkoutLocationCategory>,
  locationDetail?: Maybe<Scalars['String']>,
  weights?: Maybe<Array<WorkoutWeightInput>>,
  healthPlatformSynced?: Maybe<Scalars['Boolean']>,
};

export type PatchWorkoutReactionInput = {
  reaction?: Maybe<Scalars['SingleEmoji']>,
};

export type PauseUserTrainingPlanInput = {
  userTrainingPlanId: Scalars['Int'],
  resumptionDate: Scalars['DateTime'],
  pausingReason?: Maybe<TrainingPlanPausingReason>,
};

export enum PaymentProvider {
  APPLE = 'APPLE',
  STRIPE = 'STRIPE',
  TGG = 'TGG',
  INTERNAL = 'INTERNAL',
  FREE_SUB = 'FREE_SUB',
  MOVE_GB = 'MOVE_GB',
  GYMPASS = 'GYMPASS',
  HUSSLE = 'HUSSLE',
  SKY = 'SKY',
  NO_CARD_TRIAL = 'NO_CARD_TRIAL',
  AMAZON = 'AMAZON',
  ANDROID = 'ANDROID',
  BETTER_SPACE = 'BETTER_SPACE',
  HEKA = 'HEKA',
  BS_ACCENTURE = 'BS_ACCENTURE',
  BARROWS_GLOBAL = 'BARROWS_GLOBAL',
  BLC = 'BLC',
  CH_ROB = 'CH_ROB',
  COOPERVISION = 'COOPERVISION',
  EY = 'EY',
  FLAGSTONE = 'FLAGSTONE',
  FORMULA_E = 'FORMULA_E',
  HERE_EAST = 'HERE_EAST',
  HEYCAR = 'HEYCAR',
  HSA = 'HSA',
  HSBC_GRADS = 'HSBC_GRADS',
  HUMANITY = 'HUMANITY',
  IFG = 'IFG',
  MINDCHEF_CHEF = 'MINDCHEF_CHEF',
  MONEYBOX = 'MONEYBOX',
  PREM_LEAGUE = 'PREM_LEAGUE',
  REV_TECH = 'REV_TECH',
  SONNEDIX = 'SONNEDIX',
  THORNTON_ROSS = 'THORNTON_ROSS',
  TIBRA = 'TIBRA',
  VCMT = 'VCMT',
  VTBA_UK = 'VTBA_UK',
  YULIFE = 'YULIFE',
  ZMAX = 'ZMAX',
  WINCH_DESIGNS = 'WINCH_DESIGNS',
  TWELVE_NYC = 'TWELVE_NYC',
  KENTPLC = 'KENTPLC',
  EE = 'EE',
  MAIN_ST_ARTS = 'MAIN_ST_ARTS',
  OFFSHORELY = 'OFFSHORELY',
  CAE_UK = 'CAE_UK',
  MINDFUL_CHEF = 'MINDFUL_CHEF',
  LIFETIME_MEMBERSHIP = 'LIFETIME_MEMBERSHIP',
  GIFFGAFF = 'GIFFGAFF',
  MONARCHS = 'MONARCHS',
  SPAR = 'SPAR',
  PRI = 'PRI',
  KMK = 'KMK',
  VITALITY = 'VITALITY',
  CORTLAND = 'CORTLAND',
  WE_R_BLIGHTY = 'WE_R_BLIGHTY',
  FIIT_FITNESS = 'FIIT_FITNESS',
  GOODLIFE = 'GOODLIFE',
  DEUTSCHE_BANK = 'DEUTSCHE_BANK',
  VERTUS = 'VERTUS',
  MARKS_SPENCER = 'MARKS_SPENCER',
  TRUTH_NONSURGICAL = 'TRUTH_NONSURGICAL',
  SERCO = 'SERCO',
  MYTIME_STUDIO = 'MYTIME_STUDIO',
  REVWISE = 'REVWISE',
  RS_FITNESS = 'RS_FITNESS',
  PARTNER = 'PARTNER',
  SIGMA_CAPITAL = 'SIGMA_CAPITAL',
  JP_MORGAN = 'JP_MORGAN',
  AZ_FITNESS = 'AZ_FITNESS',
  EGYM_WELLPASS = 'EGYM_WELLPASS',
  HEARST = 'HEARST',
  PING = 'PING',
  IMATTA = 'IMATTA',
  THEWILD_CO = 'THEWILD_CO',
  THE_MINISTRY = 'THE_MINISTRY',
  VIDEOTRON = 'VIDEOTRON',
  PERKBOX_FLEXI = 'PERKBOX_FLEXI'
}

export type PaymentProviderInfo = {
   __typename?: 'PaymentProviderInfo',
  id: PaymentProvider,
  displayName?: Maybe<Scalars['String']>,
  helperText?: Maybe<Scalars['String']>,
  showEndDate: Scalars['Boolean'],
  subscriptionTypesAvailable: Array<SubscriptionInfo>,
};

export type PayOrderInput = {
  customerId: Scalars['String'],
  paymentSource?: Maybe<Scalars['String']>,
  currency: CurrencyCode,
  total: Scalars['Int'],
  skuItems: Array<SkuItemInput>,
  metadata?: Maybe<OrderMetadataInput>,
};

export type PayOrderResponse = {
   __typename?: 'PayOrderResponse',
  paymentSucceeded: Scalars['Boolean'],
  paymentIntentSecret?: Maybe<Scalars['String']>,
  order: StripeOrder,
};

export type Perk = {
   __typename?: 'Perk',
  id: Scalars['PerkId'],
  title: Scalars['String'],
  description: Scalars['String'],
  partner: Partner,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  active: Scalars['Boolean'],
  activeFrom?: Maybe<Scalars['DateTime']>,
  expiresAt?: Maybe<Scalars['DateTime']>,
  tier?: Maybe<Array<Tier>>,
  permissionsWithReasons: PerkPermissionsWithReasons,
};

export type PerkAccessPermission = {
   __typename?: 'PerkAccessPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<PerkAccessPermissionReason>>,
};

export enum PerkAccessPermissionReason {
  NO_USER = 'NO_USER',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

export type PerkCondition = {
  isLive?: Maybe<Scalars['Boolean']>,
};

export type PerkConnection = {
   __typename?: 'PerkConnection',
  edges?: Maybe<Array<PerkEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type PerkEdge = {
   __typename?: 'PerkEdge',
  node?: Maybe<Perk>,
};


export enum PerkOrder {
  CREATED_AT = 'CREATED_AT'
}

export type PerkPermissionsWithReasons = {
   __typename?: 'PerkPermissionsWithReasons',
  access: PerkAccessPermission,
};

export type PersonalBestMetric = {
   __typename?: 'PersonalBestMetric',
  current?: Maybe<Scalars['Float']>,
  previous?: Maybe<Scalars['Float']>,
};

export type PipelineVersionCondition = {
  uploaded: Scalars['Boolean'],
};

export type PlanActivity = Set | Transition | PlanCircuit;

export type PlanActivityInput = {
  activityType: ActivityTypeInput,
  autocue?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Int']>,
  exerciseId?: Maybe<Scalars['Int']>,
  exercises?: Maybe<Array<SetExerciseInput>>,
  equipmentLevelId?: Maybe<Scalars['EquipmentLevelId']>,
  enableTracking?: Maybe<Scalars['Boolean']>,
  onBike?: Maybe<Scalars['Boolean']>,
  onCkEquipment?: Maybe<Scalars['Boolean']>,
  animationVideos?: Maybe<Array<AnimationVideoInput>>,
  ledPresetActive?: Maybe<Scalars['Int']>,
  ledPresetInactive?: Maybe<Scalars['Int']>,
  type?: Maybe<CircuitType>,
  name?: Maybe<Scalars['String']>,
  repeatCount?: Maybe<Scalars['Int']>,
  skipLastActivity?: Maybe<Scalars['Boolean']>,
  activities?: Maybe<Array<PlanActivityInput>>,
  setsTrackReps?: Maybe<Scalars['Boolean']>,
};

export type PlanCircuit = {
   __typename?: 'PlanCircuit',
  activityId: Scalars['Int'],
  type?: Maybe<CircuitType>,
  repeatCount: Scalars['Int'],
  skipLastActivity: Scalars['Boolean'],
  animationVideos?: Maybe<Array<AnimationVideo>>,
  ledPresetActive?: Maybe<Scalars['Int']>,
  ledPresetInactive?: Maybe<Scalars['Int']>,
  activities: Array<PlanActivity>,
  name?: Maybe<Scalars['String']>,
  setsTrackReps?: Maybe<Scalars['Boolean']>,
};

export type PlatformPartner = {
   __typename?: 'PlatformPartner',
  id: Scalars['PlatformPartnerId'],
  name: Scalars['String'],
  url?: Maybe<Scalars['URL']>,
  holdingVideo?: Maybe<Video>,
  permissions: PlatformPartnerPermissions,
  lessonInstances: LessonInstanceConnection,
};


export type PlatformPartnerLessonInstancesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonInstanceOrder>,
  condition?: Maybe<LessonInstanceCondition>
};

export type PlatformPartnerConnection = {
   __typename?: 'PlatformPartnerConnection',
  edges?: Maybe<Array<PlatformPartnerEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type PlatformPartnerEdge = {
   __typename?: 'PlatformPartnerEdge',
  node: PlatformPartner,
};


export enum PlatformPartnerOrder {
  CREATED_AT = 'CREATED_AT'
}

export type PlatformPartnerPermissions = {
   __typename?: 'PlatformPartnerPermissions',
  exerciseLibrary?: Maybe<Scalars['Boolean']>,
  classBuilder?: Maybe<Scalars['Boolean']>,
  liveStudioClass?: Maybe<Scalars['Boolean']>,
  scheduler?: Maybe<Scalars['Boolean']>,
};

export type PrepareStreamInput = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  lessonMediaId: Scalars['LessonMediaId'],
  resourceType: StreamResourceType,
};

export type PrepareStreamResponse = {
   __typename?: 'PrepareStreamResponse',
  signedCookie: Scalars['String'],
  signedParameters: Array<UrlParameter>,
};

export type PrevLessonInstanceEvent = {
   __typename?: 'PrevLessonInstanceEvent',
  lessonInstance: PrevLessonInstanceState,
};

export type PrevLessonInstanceState = {
   __typename?: 'PrevLessonInstanceState',
  playState?: Maybe<LessonInstancePlayState>,
  lobbyStatus: LessonInstanceLobbyStatus,
};

export type PrevLobbyEntryEvent = {
   __typename?: 'PrevLobbyEntryEvent',
  lobbyEntry: PrevLobbyEntryState,
};

export type PrevLobbyEntryState = {
   __typename?: 'PrevLobbyEntryState',
  trackerConnected?: Maybe<Scalars['Boolean']>,
  equipmentConnected?: Maybe<Scalars['Boolean']>,
  leftAt?: Maybe<Scalars['DateTime']>,
};

export type PrevPartyEvent = {
   __typename?: 'PrevPartyEvent',
  party: PrevPartyState,
};

export type PrevPartyState = {
   __typename?: 'PrevPartyState',
  status: PartyStatus,
};

export type PrevWorkoutEvent = {
   __typename?: 'PrevWorkoutEvent',
  workout: PrevWorkoutState,
};

export type PrevWorkoutState = {
   __typename?: 'PrevWorkoutState',
  state: WorkoutState,
};

export type PrivacyGroup = {
   __typename?: 'PrivacyGroup',
  id: PrivacyGroupId,
  description: Scalars['String'],
  displayName: Scalars['String'],
  levels: Array<PrivacyLevel>,
  userLevel: PrivacyGroupUserLevel,
};


export type PrivacyGroupUserLevelArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};

export enum PrivacyGroupId {
  PROFILE = 'PROFILE',
  ACTIVITY = 'ACTIVITY',
  FAVOURITES = 'FAVOURITES'
}

export type PrivacyGroupUserLevel = {
   __typename?: 'PrivacyGroupUserLevel',
  user: User,
  selectedLevel?: Maybe<PrivacyLevel>,
};

export type PrivacyLevel = {
   __typename?: 'PrivacyLevel',
  id: PrivacyLevelId,
  displayName: Scalars['String'],
  description: Scalars['String'],
};

export enum PrivacyLevelId {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type Prize = {
   __typename?: 'Prize',
  id: Scalars['PrizeId'],
  description: Scalars['String'],
  image: Image,
};


export type Product = {
   __typename?: 'Product',
  id: Scalars['String'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  images: Array<Scalars['URL']>,
  skus: Array<Sku>,
  attributes: Array<AttributeName>,
  metadata: ProductMetadata,
};


export type ProductSkusArgs = {
  attributeConditions?: Maybe<SkuAttributeConditions>
};

export type ProductCondition = {
  ids?: Maybe<Array<Scalars['String']>>,
  country?: Maybe<Array<CountryCode>>,
};

export type ProductConnection = {
   __typename?: 'ProductConnection',
  edges: Array<ProductEdge>,
};

export type ProductEdge = {
   __typename?: 'ProductEdge',
  node: Product,
};

export type ProductMetadata = {
   __typename?: 'ProductMetadata',
  hidden: Scalars['Boolean'],
  hero: Scalars['Boolean'],
  ukShipping?: Maybe<Scalars['Int']>,
  niShipping?: Maybe<Scalars['Int']>,
  ieShipping?: Maybe<Scalars['Int']>,
  shopifySku?: Maybe<Scalars['String']>,
  shopifyProductName?: Maybe<Scalars['String']>,
  shopifyProductTitle?: Maybe<Scalars['String']>,
  websiteName?: Maybe<Scalars['String']>,
  websiteStrapline?: Maybe<Scalars['String']>,
  websiteBullets?: Maybe<Array<Scalars['String']>>,
  websiteImageUrl?: Maybe<Scalars['String']>,
  websiteDescriptionLineOne?: Maybe<Scalars['String']>,
  websiteDescriptionLineTwo?: Maybe<Scalars['String']>,
  websiteBannerText?: Maybe<Scalars['String']>,
  isYearlyBundle: Scalars['Boolean'],
};

export enum ProductOrder {
  NAME = 'NAME'
}

export type ProfileImageUploadInfo = {
   __typename?: 'ProfileImageUploadInfo',
  url: Scalars['URL'],
  imageKey: Scalars['String'],
};

export type Progress = {
   __typename?: 'Progress',
  done: Scalars['Float'],
  target: Scalars['Float'],
  metricType: UserChallengeMetricType,
};

export type PublishLessonInstanceCurrentTimeInput = {
  clientTime: Scalars['DateTime'],
  videoTime: Scalars['Seconds'],
};

export type PublishLessonInstanceCurrentTimeResponse = {
   __typename?: 'PublishLessonInstanceCurrentTimeResponse',
  success: Scalars['Boolean'],
};

export type PushToken = {
   __typename?: 'PushToken',
  token: Scalars['String'],
  deviceId: Scalars['String'],
  user: User,
  platform: PushTokenPlatform,
};

export enum PushTokenPlatform {
  APNS = 'APNS',
  APNS_SANDBOX = 'APNS_SANDBOX',
  FCM = 'FCM'
}

export type Query = {
   __typename?: 'Query',
  exerciseById?: Maybe<Exercise>,
  repCountingProfileById?: Maybe<RepCountingProfile>,
  repCountingProfileVersions: Array<RepCountingProfileVersion>,
  allRepCountingProfiles: Array<RepCountingProfile>,
  allExercises: ExerciseConnection,
  allExercisesList: Array<Exercise>,
  userById?: Maybe<User>,
  user?: Maybe<User>,
  userByToken?: Maybe<User>,
  userByHandle?: Maybe<User>,
  auth: Auth,
  userByEmail?: Maybe<User>,
  lessonById?: Maybe<Lesson>,
  allLessons: LessonConnection,
  studioById?: Maybe<Studio>,
  allStudios: StudioConnection,
  trainerById?: Maybe<Trainer>,
  trainerBySlug?: Maybe<Trainer>,
  allTrainers: TrainerConnection,
  teamById?: Maybe<Team>,
  allTeams: TeamConnection,
  randomSpinPrizeById?: Maybe<RandomSpinPrize>,
  allRandomSpinPrizes: RandomSpinPrizeConnection,
  userRandomSpinPrizeSpinById?: Maybe<UserRandomSpinPrizeSpin>,
  allUserRandomSpinPrizeSpins: UserRandomSpinPrizeSpinConnection,
  deepLinkAttemptInfoById?: Maybe<DeepLinkAttemptInfo>,
  allDeepLinkAttemptInfo: DeepLinkAttemptInfoConnection,
  teamBehaviourReportById?: Maybe<TeamBehaviourReport>,
  allTeamBehaviourReports: TeamBehaviourReportConnection,
  trainingPlanListById?: Maybe<TrainingPlanList>,
  allTrainingPlanLists: TrainingPlanListConnection,
  allProducts: ProductConnection,
  productById?: Maybe<Product>,
  agreementById?: Maybe<Agreement>,
  allAgreements: AgreementConnection,
  induction: Induction,
  workoutById?: Maybe<Workout>,
  allMuscles: Array<Muscle>,
  wahooCalibrationById?: Maybe<WahooCalibration>,
  allWahooCalibrations: Array<WahooCalibration>,
  allSpotlightLessons: SpotlightLessonConnection,
  spotlightLessonById?: Maybe<SpotlightLesson>,
  spotlightLessons: Array<Lesson>,
  allWorkoutReviews: WorkoutReviewConnection,
  subscriptionPlanById?: Maybe<SubscriptionPlan>,
  allSubscriptionPlans: SubscriptionPlanConnection,
  userEvents?: Maybe<UserEvents>,
  contentVideoById: ContentVideo,
  contentVideoDetailsById?: Maybe<ContentVideoDetails>,
  allImages: ImageConnection,
  allBlogs: BlogConnection,
  attributionById?: Maybe<Attribution>,
  publicAttributions: Array<Attribution>,
  allTrainingGoals: Array<TrainingGoal>,
  trainingGoalById?: Maybe<TrainingGoal>,
  trainingPlanById?: Maybe<TrainingPlan>,
  trainingPlanBySlug?: Maybe<TrainingPlan>,
  allTrainingPlans: TrainingPlanConnection,
  userTrainingPlanById?: Maybe<UserTrainingPlan>,
  tutorialById?: Maybe<Tutorial>,
  allTutorials: TutorialConnection,
  allLessonTypes: Array<LessonType>,
  allBodyParts: Array<BodyPart>,
  allLessonRecoverySports: Array<LessonRecoverySport>,
  allLessonOutcomes: Array<LessonOutcome>,
  equipmentById?: Maybe<Equipment>,
  allEquipment: EquipmentConnection,
  equipmentLevelById?: Maybe<EquipmentLevel>,
  allCollections: CollectionConnection,
  collectionBySlug?: Maybe<Collection>,
  collectionListBySlug?: Maybe<CollectionList>,
  allMusicGenres: Array<MusicGenre>,
  videoById?: Maybe<Video>,
  allVideos: VideoConnection,
  lessonVideoRevisionById?: Maybe<LessonVideoRevision>,
  allLessonVideoRevisions: LessonVideoRevisionConnection,
  musicById?: Maybe<MusicInfo>,
  lessonInstanceById: LessonInstance,
  lessonInstance?: Maybe<LessonInstance>,
  allLessonInstances: LessonInstanceConnection,
  listLessonInstances?: Maybe<Array<LessonInstance>>,
  lessonInstanceScheduleById?: Maybe<LessonInstanceSchedule>,
  allLessonInstanceSchedules: LessonInstanceScheduleConnection,
  prizeById?: Maybe<Prize>,
  allPrizes: Array<Prize>,
  tierById: Tier,
  allTiers: Array<Tier>,
  trackInfoByKey: Array<Maybe<TrackInfo>>,
  shippingMethods: Array<Maybe<ShippingMethod>>,
  listCommunicationMessageGroups: Array<CommunicationMessageGroup>,
  allBrands: Array<Maybe<Brand>>,
  allPrivacyGroups: Array<PrivacyGroup>,
  allUserInteractions: Array<UserInteraction>,
  allUserBehaviourReports: UserBehaviourReportConnection,
  challengeById?: Maybe<Challenge>,
  challengeBySlug?: Maybe<Challenge>,
  allChallenges: ChallengeConnection,
  userChallengeById?: Maybe<UserChallenge>,
  challengeListById?: Maybe<ChallengeList>,
  partyById?: Maybe<Party>,
  lobbyEntryById?: Maybe<LobbyEntry>,
  isEligibleForFreeTrial: Scalars['Boolean'],
  allConnectedKit: Array<ConnectedKit>,
  allCustomEquipment: CustomEquipmentConnection,
  platformPartnerById?: Maybe<PlatformPartner>,
  platformPartnerByUrl?: Maybe<PlatformPartner>,
  allPlatformPartners: PlatformPartnerConnection,
  allMovementStandards: MovementStandardConnection,
  movementStandardByExerciseIdAndPlatformPartnerId?: Maybe<MovementStandard>,
  movementStandardByExerciseId?: Maybe<MovementStandard>,
  workoutReactionById?: Maybe<WorkoutReaction>,
  partnerById?: Maybe<Partner>,
  allPartners: Array<Partner>,
  allCollectionUpdates: CollectionUpdateConnection,
  collectionUpdateById?: Maybe<CollectionUpdate>,
  allPerks: PerkConnection,
  perkById?: Maybe<Perk>,
};


export type QueryExerciseByIdArgs = {
  id: Scalars['Int']
};


export type QueryRepCountingProfileByIdArgs = {
  id: Scalars['RepCountingProfileId']
};


export type QueryRepCountingProfileVersionsArgs = {
  condition: RepCountingProfileVersionCondition
};


export type QueryAllRepCountingProfilesArgs = {
  orderBy: RepCountingProfileOrder
};


export type QueryAllExercisesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ExerciseOrder>,
  condition?: Maybe<ExerciseCondition>
};


export type QueryAllExercisesListArgs = {
  condition?: Maybe<ExerciseCondition>
};


export type QueryUserByIdArgs = {
  id: Scalars['Int']
};


export type QueryUserArgs = {
  input: UserInput
};


export type QueryUserByHandleArgs = {
  handle: Scalars['UserHandle']
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']
};


export type QueryLessonByIdArgs = {
  id: Scalars['Int']
};


export type QueryAllLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonOrder>,
  condition?: Maybe<LessonCondition>
};


export type QueryStudioByIdArgs = {
  id: StudioType
};


export type QueryAllStudiosArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<StudioOrder>,
  condition?: Maybe<StudioCondition>
};


export type QueryTrainerByIdArgs = {
  id: Scalars['Int']
};


export type QueryTrainerBySlugArgs = {
  slug: Scalars['String']
};


export type QueryAllTrainersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TrainerOrder>,
  condition?: Maybe<TrainerCondition>
};


export type QueryTeamByIdArgs = {
  id: Scalars['TeamId']
};


export type QueryAllTeamsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TeamOrder>,
  condition?: Maybe<TeamCondition>
};


export type QueryRandomSpinPrizeByIdArgs = {
  id: Scalars['RandomSpinPrizeId']
};


export type QueryAllRandomSpinPrizesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<RandomSpinPrizeOrder>,
  condition?: Maybe<RandomSpinPrizeCondition>
};


export type QueryUserRandomSpinPrizeSpinByIdArgs = {
  id: Scalars['UserRandomSpinPrizeSpinId']
};


export type QueryAllUserRandomSpinPrizeSpinsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<UserRandomSpinPrizeSpinOrder>,
  condition?: Maybe<UserRandomSpinPrizeSpinCondition>
};


export type QueryDeepLinkAttemptInfoByIdArgs = {
  id: Scalars['DeepLinkAttemptInfoId']
};


export type QueryAllDeepLinkAttemptInfoArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<DeepLinkAttemptInfoOrder>,
  condition?: Maybe<DeepLinkAttemptInfoCondition>
};


export type QueryTeamBehaviourReportByIdArgs = {
  id: Scalars['TeamBehaviourReportId']
};


export type QueryAllTeamBehaviourReportsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TeamBehaviourReportOrder>,
  condition?: Maybe<TeamBehaviourReportCondition>
};


export type QueryTrainingPlanListByIdArgs = {
  id: Scalars['TrainingPlanListId']
};


export type QueryAllTrainingPlanListsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TrainingPlanListOrder>
};


export type QueryAllProductsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ProductOrder>,
  condition?: Maybe<ProductCondition>
};


export type QueryProductByIdArgs = {
  id: Scalars['String']
};


export type QueryAgreementByIdArgs = {
  id: Scalars['Int']
};


export type QueryAllAgreementsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<AgreementOrder>,
  condition?: Maybe<AgreementCondition>
};


export type QueryWorkoutByIdArgs = {
  id: Scalars['Int']
};


export type QueryWahooCalibrationByIdArgs = {
  id: Scalars['Int']
};


export type QueryAllWahooCalibrationsArgs = {
  orderBy?: Maybe<WahooCalibrationOrder>
};


export type QueryAllSpotlightLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SpotlightLessonOrder>
};


export type QuerySpotlightLessonByIdArgs = {
  id: Scalars['Int']
};


export type QueryAllWorkoutReviewsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutReviewOrder>,
  condition?: Maybe<WorkoutReviewCondition>
};


export type QuerySubscriptionPlanByIdArgs = {
  id: Scalars['Int']
};


export type QueryAllSubscriptionPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SubscriptionPlanOrder>,
  condition?: Maybe<SubscriptionPlanCondition>
};


export type QueryUserEventsArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};


export type QueryContentVideoByIdArgs = {
  id: ContentVideoId
};


export type QueryContentVideoDetailsByIdArgs = {
  id: Scalars['String']
};


export type QueryAllImagesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ImageOrder>
};


export type QueryAllBlogsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<BlogOrder>
};


export type QueryAttributionByIdArgs = {
  id: Scalars['String']
};


export type QueryAllTrainingGoalsArgs = {
  orderBy?: Maybe<TrainingGoalOrder>,
  direction?: Maybe<OrderByDirection>,
  condition?: Maybe<TrainingGoalCondition>
};


export type QueryTrainingGoalByIdArgs = {
  id: UserFitnessGoal
};


export type QueryTrainingPlanByIdArgs = {
  id: Scalars['Int']
};


export type QueryTrainingPlanBySlugArgs = {
  slug: Scalars['String']
};


export type QueryAllTrainingPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TrainingPlanOrder>,
  condition?: Maybe<TrainingPlanCondition>
};


export type QueryUserTrainingPlanByIdArgs = {
  id: Scalars['Int']
};


export type QueryTutorialByIdArgs = {
  id: Scalars['Int']
};


export type QueryAllTutorialsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TutorialOrder>,
  condition?: Maybe<TutorialCondition>
};


export type QueryEquipmentByIdArgs = {
  id: Scalars['EquipmentId']
};


export type QueryAllEquipmentArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<EquipmentOrder>
};


export type QueryEquipmentLevelByIdArgs = {
  id: Scalars['EquipmentLevelId']
};


export type QueryAllCollectionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<CollectionOrder>
};


export type QueryCollectionBySlugArgs = {
  slug: Scalars['String']
};


export type QueryCollectionListBySlugArgs = {
  slug: Scalars['String']
};


export type QueryVideoByIdArgs = {
  id: Scalars['VIDEO_ID']
};


export type QueryAllVideosArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<VideoCondition>,
  orderBy?: Maybe<VideoOrder>
};


export type QueryLessonVideoRevisionByIdArgs = {
  id?: Maybe<Scalars['LessonVideoRevisionId']>
};


export type QueryAllLessonVideoRevisionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>
};


export type QueryMusicByIdArgs = {
  id: Scalars['MusicId']
};


export type QueryLessonInstanceByIdArgs = {
  id: Scalars['LessonInstanceId']
};


export type QueryLessonInstanceArgs = {
  id: Scalars['LessonInstanceId']
};


export type QueryAllLessonInstancesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<LessonInstanceCondition>,
  orderBy?: Maybe<LessonInstanceOrder>
};


export type QueryListLessonInstancesArgs = {
  condition?: Maybe<LessonInstanceCondition>,
  orderBy?: Maybe<LessonInstanceOrder>
};


export type QueryLessonInstanceScheduleByIdArgs = {
  id: Scalars['LessonInstanceScheduleId']
};


export type QueryAllLessonInstanceSchedulesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonInstanceScheduleOrder>,
  condition?: Maybe<LessonInstanceScheduleCondition>
};


export type QueryPrizeByIdArgs = {
  id: Scalars['PrizeId']
};


export type QueryTierByIdArgs = {
  id: Scalars['String']
};


export type QueryTrackInfoByKeyArgs = {
  key: Scalars['String'],
  condition?: Maybe<TrackInfoCondition>
};


export type QueryShippingMethodsArgs = {
  input: ShippingRequest
};


export type QueryListCommunicationMessageGroupsArgs = {
  orderBy?: Maybe<CommunicationMessageGroupOrder>
};


export type QueryAllUserBehaviourReportsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<UserBehaviourReportCondition>,
  orderBy: UserBehaviourReportOrder
};


export type QueryChallengeByIdArgs = {
  id: Scalars['ChallengeId']
};


export type QueryChallengeBySlugArgs = {
  slug: Scalars['String']
};


export type QueryAllChallengesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<ChallengeCondition>,
  orderBy: ChallengeOrder
};


export type QueryUserChallengeByIdArgs = {
  id: Scalars['UserChallengeId']
};


export type QueryChallengeListByIdArgs = {
  id: Scalars['ChallengeListId']
};


export type QueryPartyByIdArgs = {
  id: Scalars['PartyId']
};


export type QueryLobbyEntryByIdArgs = {
  id: Scalars['LobbyEntryId']
};


export type QueryIsEligibleForFreeTrialArgs = {
  email: Scalars['String']
};


export type QueryAllConnectedKitArgs = {
  orderBy: ConnectedKitOrder
};


export type QueryAllCustomEquipmentArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<CustomEquipmentCondition>,
  orderBy?: Maybe<CustomEquipmentOrder>
};


export type QueryPlatformPartnerByIdArgs = {
  id: Scalars['PlatformPartnerId']
};


export type QueryPlatformPartnerByUrlArgs = {
  url: Scalars['String']
};


export type QueryAllPlatformPartnersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<PlatformPartnerOrder>
};


export type QueryAllMovementStandardsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<MovementStandardCondition>,
  orderBy?: Maybe<MovementStandardOrder>
};


export type QueryMovementStandardByExerciseIdAndPlatformPartnerIdArgs = {
  exerciseId: Scalars['Int'],
  platformPartnerId: Scalars['PlatformPartnerId'],
  fallbackToGeneric?: Maybe<Scalars['Boolean']>
};


export type QueryMovementStandardByExerciseIdArgs = {
  exerciseId: Scalars['Int']
};


export type QueryWorkoutReactionByIdArgs = {
  id: Scalars['WorkoutReactionId']
};


export type QueryPartnerByIdArgs = {
  id: Scalars['PartnerId']
};


export type QueryAllCollectionUpdatesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<CollectionUpdateOrder>,
  condition?: Maybe<CollectionUpdateCondition>
};


export type QueryCollectionUpdateByIdArgs = {
  id: Scalars['CollectionUpdateId']
};


export type QueryAllPerksArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<PerkOrder>,
  condition?: Maybe<PerkCondition>
};


export type QueryPerkByIdArgs = {
  id: Scalars['PerkId']
};

export type RandomSpinPrize = {
   __typename?: 'RandomSpinPrize',
  id: Scalars['RandomSpinPrizeId'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  weight: Scalars['Int'],
  spinVariant: SpinVariant,
  active: Scalars['Boolean'],
  startsAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
  initialQuantity?: Maybe<Scalars['Int']>,
  currentQuantity?: Maybe<Scalars['Int']>,
  image?: Maybe<Image>,
  winners: UserConnection,
  shopifyPrizeRule?: Maybe<Scalars['String']>,
  type: RandomSpinPrizeType,
  partnerCoupon?: Maybe<Scalars['String']>,
  partnerUrl?: Maybe<Scalars['URL']>,
  percentageOff?: Maybe<Scalars['Float']>,
  freeTrialDays?: Maybe<Scalars['Int']>,
  maxWinsPerUser?: Maybe<Scalars['Int']>,
};


export type RandomSpinPrizeWinnersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<UserOrder>,
  condition?: Maybe<UserCondition>
};

export type RandomSpinPrizeCondition = {
  active?: Maybe<Scalars['Boolean']>,
  spinVariant?: Maybe<Array<SpinVariant>>,
  startsBefore?: Maybe<Scalars['DateTime']>,
  endsAfter?: Maybe<Scalars['DateTime']>,
  initialQuantity?: Maybe<Scalars['Int']>,
};

export type RandomSpinPrizeConnection = {
   __typename?: 'RandomSpinPrizeConnection',
  edges?: Maybe<Array<RandomSpinPrizeEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type RandomSpinPrizeEdge = {
   __typename?: 'RandomSpinPrizeEdge',
  node: RandomSpinPrize,
};


export enum RandomSpinPrizeOrder {
  CREATED_AT = 'CREATED_AT',
  STARTS_AT = 'STARTS_AT'
}

export enum RandomSpinPrizeType {
  SHOP = 'SHOP',
  SUBSCRIPTION = 'SUBSCRIPTION',
  PHYSICAL = 'PHYSICAL',
  PARTNER = 'PARTNER'
}

export type ReactivateTggSubscriptionInput = {
  tggMembershipId: Scalars['String'],
};

export type ReactivateTggSubscriptionResponse = {
   __typename?: 'ReactivateTggSubscriptionResponse',
  tggMembershipId: Scalars['String'],
};

export type RedeemFreeSubCodeInput = {
  code: Scalars['String'],
  email: Scalars['String'],
  marketingOptIn: Scalars['Boolean'],
  attributionId?: Maybe<Scalars['String']>,
  userTestEvent?: Maybe<UserTestEvent>,
};

export type RedeemFreeSubCodeResponse = {
   __typename?: 'RedeemFreeSubCodeResponse',
  redeemed: Scalars['Boolean'],
  code: Scalars['String'],
};

export type RedeemHearstSubscriptionInput = {
  email: Scalars['Email'],
  customerId: Scalars['String'],
  subscriptionType: Scalars['String'],
  marketingOptIn?: Maybe<Scalars['Boolean']>,
};

export type RedeemHearstSubscriptionResponse = {
   __typename?: 'RedeemHearstSubscriptionResponse',
  providerSubscriptionId: Scalars['String'],
};

export type ReferAFriendRewards = {
   __typename?: 'ReferAFriendRewards',
  referrer: Money,
  referee: Money,
  refereeReward: Reward,
};

export type ReferralGroup = {
   __typename?: 'ReferralGroup',
  credit: Money,
  count: Scalars['Int'],
};

export type Referrals = {
   __typename?: 'Referrals',
  pendingCount: Scalars['Int'],
  paidCount: Scalars['Int'],
  paid: Array<ReferralGroup>,
  pending: Array<ReferralGroup>,
};

export type RemoveFollowerResponse = {
   __typename?: 'RemoveFollowerResponse',
  user: User,
  follower: User,
};

export type ReopenPartyResponse = {
   __typename?: 'ReopenPartyResponse',
  party: Party,
};

export enum RepCalculationMethod {
  WAHOO = 'WAHOO',
  INTERNAL = 'INTERNAL'
}

export type RepCountingProfile = {
   __typename?: 'RepCountingProfile',
  id: Scalars['RepCountingProfileId'],
  displayName: Scalars['String'],
  versions?: Maybe<Array<RepCountingProfileVersion>>,
};


export enum RepCountingProfileOrder {
  DISPLAY_NAME = 'DISPLAY_NAME'
}

export type RepCountingProfileVersion = {
   __typename?: 'RepCountingProfileVersion',
  id: Scalars['RepCountingProfileVersionId'],
  profile: RepCountingProfile,
  schemaVersion: Scalars['Int'],
  modelPayload: Scalars['String'],
};

export type RepCountingProfileVersionCondition = {
  schemaVersion: Scalars['Int'],
};


export type RepsMetricConfig = {
   __typename?: 'RepsMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type ResumeUserTrainingPlanInput = {
  userTrainingPlanId: Scalars['Int'],
};

export type RevokeAuthGroupsResponse = {
   __typename?: 'RevokeAuthGroupsResponse',
  user: User,
};

export type Reward = {
   __typename?: 'Reward',
  percentageOff: Scalars['Int'],
};

export type RewardInfos = {
   __typename?: 'RewardInfos',
  completionMessage?: Maybe<Scalars['String']>,
  preCompletionMessage?: Maybe<Scalars['String']>,
};

export type RewardType = {
   __typename?: 'RewardType',
  id: RewardTypeId,
  message?: Maybe<Scalars['String']>,
  completionMessage: Scalars['String'],
  eligibleMessage: Scalars['String'],
  ineligibleMessage: Scalars['String'],
};

export enum RewardTypeId {
  MONTH_CREDIT = 'MONTH_CREDIT'
}

export type RowDistanceMetricConfig = {
   __typename?: 'RowDistanceMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type RowKcalMetricConfig = {
   __typename?: 'RowKcalMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type RowResistanceMetricConfig = {
   __typename?: 'RowResistanceMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type RowSpeedMetricConfig = {
   __typename?: 'RowSpeedMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type RowSpmMetricConfig = {
   __typename?: 'RowSpmMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type RowTotalScoreMetricConfig = {
   __typename?: 'RowTotalScoreMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};

export type RowWattsMetricConfig = {
   __typename?: 'RowWattsMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export enum SchedulePeriod {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_FORTNIGHT = 'EVERY_FORTNIGHT',
  EVERY_MONTH = 'EVERY_MONTH'
}

export type ScoreMetricConfig = {
   __typename?: 'ScoreMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};


export type SendChallengeInviteInput = {
  user: UserInput,
  recipients: Array<UserInput>,
  challengeId: Scalars['ChallengeId'],
};

export type SendChallengeInviteResponse = {
   __typename?: 'SendChallengeInviteResponse',
  success: Scalars['Boolean'],
};

export type SendLessonInstanceInviteInput = {
  user: UserInput,
  recipients: Array<UserInput>,
  lessonInstanceId: Scalars['LessonInstanceId'],
};

export type SendLessonInstanceInviteResponse = {
   __typename?: 'SendLessonInstanceInviteResponse',
  success: Scalars['Boolean'],
};

export type SendUserPushEventResponse = {
   __typename?: 'SendUserPushEventResponse',
  success: Scalars['Boolean'],
};

export type Set = {
   __typename?: 'Set',
  activityId: Scalars['Int'],
  zoneConfig?: Maybe<ZoneConfig>,
  trackingConfig: TrackingConfig,
  key: Scalars['String'],
  metrics: ActivityMetrics,
  displayName: Scalars['String'],
  duration: Scalars['Int'],
  exercise: Exercise,
  exercises: Array<SetExercise>,
  activityHistoryByUser?: Maybe<InstantaneousActivityHistory>,
  equipmentLevel?: Maybe<EquipmentLevel>,
  enableTracking?: Maybe<Scalars['Boolean']>,
  onBike?: Maybe<Scalars['Boolean']>,
  onCkEquipment?: Maybe<Scalars['Boolean']>,
};


export type SetActivityHistoryByUserArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};

export type SetExercise = {
   __typename?: 'SetExercise',
  exercise: Exercise,
  count: Scalars['Int'],
};

export type SetExerciseInput = {
  id: Scalars['Int'],
  count: Scalars['Int'],
};

export type SetUserInteractionLevelResponse = {
   __typename?: 'SetUserInteractionLevelResponse',
  userInteractions: Array<UserInteraction>,
  allUserInteractions: Array<UserInteraction>,
};

export type SetUserPrivacyGroupLevelResponse = {
   __typename?: 'SetUserPrivacyGroupLevelResponse',
  user: User,
  setPrivacyGroups: Array<PrivacyGroup>,
  allPrivacyGroups: Array<PrivacyGroup>,
};

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export type ShippingAddress = {
   __typename?: 'ShippingAddress',
  addressOne?: Maybe<Scalars['String']>,
  addressTwo?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  country?: Maybe<CountryCode>,
  postalCode?: Maybe<Scalars['String']>,
};

export type ShippingAddressInput = {
  country: CountryCode,
  postalCode: Scalars['String'],
};

export type ShippingItemInput = {
  quantity: Scalars['Int'],
  parent: ShippingParentInput,
};

export type ShippingMethod = {
   __typename?: 'ShippingMethod',
  id: Scalars['String'],
  description: Scalars['String'],
  currency: Scalars['String'],
  amount: Scalars['Int'],
};

export type ShippingParentInput = {
  id: Scalars['String'],
};

export type ShippingRequest = {
  address?: Maybe<ShippingAddressInput>,
  items: Array<ShippingItemInput>,
  isSubscription: Scalars['Boolean'],
};

export type SignUpFreeUserWithEmailInput = {
  email: Scalars['Email'],
  attributionId?: Maybe<Scalars['String']>,
  marketingOptIn: Scalars['Boolean'],
};


export type SingleMetricSummary = {
   __typename?: 'SingleMetricSummary',
  corrupted: Scalars['Boolean'],
  count?: Maybe<Scalars['Int']>,
  sum?: Maybe<Scalars['Float']>,
  avg?: Maybe<Scalars['Float']>,
  min?: Maybe<Scalars['Float']>,
  max?: Maybe<Scalars['Float']>,
};

export enum SingleMetricSummaryTypes {
  COUNT = 'COUNT',
  SUM = 'SUM',
  AVG = 'AVG',
  MIN = 'MIN',
  MAX = 'MAX'
}

export type Sku = {
   __typename?: 'SKU',
  id: Scalars['String'],
  currency: CurrencyCode,
  price: Scalars['Int'],
  image?: Maybe<Scalars['URL']>,
  attributes: SkuAttributes,
};

export type SkuAttributeConditions = {
  hidden?: Maybe<Array<Scalars['Boolean']>>,
};

export type SkuAttributes = {
   __typename?: 'SkuAttributes',
  color?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  rrp?: Maybe<Scalars['Int']>,
};

export type SkuItemInput = {
  type: SkuItemType,
  parent: Scalars['String'],
  quantity: Scalars['Int'],
};

export enum SkuItemType {
  sku = 'sku'
}


export type SpinDistanceMetricConfig = {
   __typename?: 'SpinDistanceMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type SpinForRandomPrizeInput = {
  userRandomSpinPrizeSpinId: Scalars['UserRandomSpinPrizeSpinId'],
};

export type SpinKcalMetricConfig = {
   __typename?: 'SpinKcalMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type SpinResistanceMetricConfig = {
   __typename?: 'SpinResistanceMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type SpinRpmMetricConfig = {
   __typename?: 'SpinRPMMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type SpinSpeedMetricConfig = {
   __typename?: 'SpinSpeedMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export enum SpinStatus {
  NOT_SPUN = 'NOT_SPUN',
  SPUN = 'SPUN',
  CLAIMED = 'CLAIMED'
}

export type SpinTotalScoreMetricConfig = {
   __typename?: 'SpinTotalScoreMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};

export enum SpinVariant {
  COMPLETE_WORKOUT_FREE = 'COMPLETE_WORKOUT_FREE',
  COMPLETE_WORKOUT_PAID = 'COMPLETE_WORKOUT_PAID',
  INITIAL_SPIN = 'INITIAL_SPIN'
}

export type SpinWattsMetricConfig = {
   __typename?: 'SpinWattsMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type SpotlightLesson = {
   __typename?: 'SpotlightLesson',
  id: Scalars['Int'],
  activeFrom: Scalars['DateTime'],
  lesson: Lesson,
};

export type SpotlightLessonConnection = {
   __typename?: 'SpotlightLessonConnection',
  edges?: Maybe<Array<SpotlightLessonEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type SpotlightLessonEdge = {
   __typename?: 'SpotlightLessonEdge',
  node: SpotlightLesson,
};

export enum SpotlightLessonOrder {
  ACTIVE_FROM = 'ACTIVE_FROM'
}

export type StartStreamableWorkoutInput = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  scheduleEntryId?: Maybe<Scalars['Int']>,
  lessonMediaId: Scalars['LessonMediaId'],
  lessonInstanceId?: Maybe<Scalars['LessonInstanceId']>,
  leaderboardUserNameAnon?: Maybe<Scalars['Boolean']>,
  metadata?: Maybe<WorkoutMetadataInput>,
  device?: Maybe<DeviceInput>,
  devices?: Maybe<Array<DeviceInput>>,
  weights?: Maybe<Array<WorkoutWeightInput>>,
};

export type StartUserChallengeInput = {
  user: UserInput,
  challengeId: Scalars['ChallengeId'],
};

export type StartUserTrainingPlanInput = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  trainingPlanTemplateId: Scalars['Int'],
};

export type StartWorkoutInput = {
  userId: Scalars['Int'],
  lessonId: Scalars['Int'],
  lessonInstanceId?: Maybe<Scalars['LessonInstanceId']>,
  leaderboardUserNameAnon?: Maybe<Scalars['Boolean']>,
  metadata?: Maybe<WorkoutMetadataInput>,
};

export type StartWorkoutResponse = {
   __typename?: 'StartWorkoutResponse',
  workout: Workout,
};

export type Streak = {
   __typename?: 'Streak',
  current: Scalars['Int'],
  max: Scalars['Int'],
};

export enum StreamResourceType {
  HLS = 'HLS',
  DASH = 'DASH'
}

export type StripeOrder = {
   __typename?: 'StripeOrder',
  id: Scalars['String'],
  status: Scalars['String'],
};

export type StripeSubscription = {
   __typename?: 'StripeSubscription',
  id: Scalars['String'],
  status: Scalars['String'],
  billingCycleAnchor: Scalars['DateTime'],
  currentPeriodStart: Scalars['DateTime'],
};

export type Studio = {
   __typename?: 'Studio',
  id: StudioType,
  description: Scalars['String'],
  heroImg: Scalars['URL'],
  lessons: LessonConnection,
  trainers: TrainerConnection,
  collectionList?: Maybe<CollectionList>,
  family: StudioFamily,
  prerollVideo?: Maybe<Video>,
};


export type StudioLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonOrder>,
  condition?: Maybe<StudioLessonCondition>
};


export type StudioTrainersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TrainerOrder>,
  condition?: Maybe<StudioTrainerCondition>
};

export type StudioCondition = {
  id?: Maybe<Array<Scalars['String']>>,
  family?: Maybe<Array<StudioFamily>>,
};

export type StudioConnection = {
   __typename?: 'StudioConnection',
  edges?: Maybe<Array<StudioEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type StudioEdge = {
   __typename?: 'StudioEdge',
  node: Studio,
};

export enum StudioFamily {
  MAT = 'MAT',
  CONNECTED_KIT = 'CONNECTED_KIT'
}

export type StudioLessonCondition = {
  trainerId?: Maybe<Array<Scalars['Int']>>,
  difficulty?: Maybe<Array<LessonDifficulty>>,
  status?: Maybe<Array<LessonStatus>>,
  mediaType?: Maybe<Array<LessonMediaType>>,
};

export enum StudioOrder {
  ID = 'ID',
  NATURAL = 'NATURAL'
}

export type StudioTrainerCondition = {
  status?: Maybe<Array<TrainerStatus>>,
};

export enum StudioType {
  CARDIO = 'CARDIO',
  STRENGTH = 'STRENGTH',
  REBALANCE = 'REBALANCE',
  AIRBIKE = 'AIRBIKE',
  TREAD = 'TREAD',
  SPIN = 'SPIN',
  ROW = 'ROW'
}

export type SubscribeToCommunicationMessageTypesInput = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  messageTypeIds: Array<Scalars['Int']>,
};

export type SubscribeToCommunicationMessageTypesResponse = {
   __typename?: 'SubscribeToCommunicationMessageTypesResponse',
  communicationMessageGroups: Array<CommunicationMessageGroup>,
};


export type SubscribeToCommunicationMessageTypesResponseCommunicationMessageGroupsArgs = {
  orderBy?: Maybe<CommunicationMessageGroupOrder>
};

export type Subscription = {
   __typename?: 'Subscription',
  dummy?: Maybe<Scalars['String']>,
  lessonInstanceUpdated: LessonInstanceUpdatedEvent,
  lessonInstanceCurrentTime: LessonInstanceCurrentTimeEvent,
  lobbyEntryCreated: LobbyEntryCreatedEvent,
  lobbyEntryUpdated: LobbyEntryUpdatedEvent,
  partyCreated: PartyCreatedEvent,
  partyUpdated: PartyUpdatedEvent,
  partyOpened: PartyOpenedEvent,
  workoutStatsEvent: WorkoutStatsEvent,
  workoutCreated: WorkoutCreatedEvent,
  workoutUpdated: WorkoutUpdatedEvent,
};


export type SubscriptionLessonInstanceUpdatedArgs = {
  lessonInstanceId: Scalars['LessonInstanceId']
};


export type SubscriptionLessonInstanceCurrentTimeArgs = {
  lessonInstanceId: Scalars['LessonInstanceId']
};


export type SubscriptionLobbyEntryCreatedArgs = {
  partyId: Scalars['String']
};


export type SubscriptionLobbyEntryUpdatedArgs = {
  partyId: Scalars['String']
};


export type SubscriptionPartyCreatedArgs = {
  hostUserId: Scalars['Int']
};


export type SubscriptionPartyUpdatedArgs = {
  hostUserId: Scalars['Int'],
  partyId: Scalars['String']
};


export type SubscriptionPartyOpenedArgs = {
  hostUserId: Scalars['Int']
};


export type SubscriptionWorkoutStatsEventArgs = {
  partyId: Scalars['PartyId']
};


export type SubscriptionWorkoutCreatedArgs = {
  partyId: Scalars['PartyId']
};


export type SubscriptionWorkoutUpdatedArgs = {
  workoutId: Scalars['Int']
};

export type SubscriptionCustomer = {
   __typename?: 'SubscriptionCustomer',
  id: Scalars['String'],
};

export type SubscriptionInfo = {
   __typename?: 'SubscriptionInfo',
  id: SubscriptionTypeId,
};

export enum SubscriptionInterval {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export type SubscriptionPlan = {
   __typename?: 'SubscriptionPlan',
  id: Scalars['Int'],
  sku: Scalars['String'],
  period: SubscriptionPlanPeriod,
  cycle: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  popular: Scalars['Boolean'],
  active: Scalars['Boolean'],
  requiresTracker: Scalars['Boolean'],
  trialLengthDays?: Maybe<Scalars['Days']>,
  appleSku?: Maybe<Scalars['String']>,
  appleActive: Scalars['Boolean'],
  amazonSku?: Maybe<Scalars['String']>,
  amazonActive: Scalars['Boolean'],
  androidSku?: Maybe<Scalars['String']>,
  androidActive: Scalars['Boolean'],
  subscriptionType: SubscriptionTypeId,
};

export type SubscriptionPlanCondition = {
  active?: Maybe<Array<Scalars['Boolean']>>,
  appleActive?: Maybe<Array<Scalars['Boolean']>>,
  amazonActive?: Maybe<Array<Scalars['Boolean']>>,
  androidActive?: Maybe<Array<Scalars['Boolean']>>,
  requiresTracker?: Maybe<Array<Scalars['Boolean']>>,
  trialLengthDays?: Maybe<Array<Scalars['Int']>>,
  paymentProvider?: Maybe<PaymentProvider>,
  subscriptionType?: Maybe<Array<SubscriptionTypeId>>,
};

export type SubscriptionPlanConnection = {
   __typename?: 'SubscriptionPlanConnection',
  edges?: Maybe<Array<SubscriptionPlanEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type SubscriptionPlanEdge = {
   __typename?: 'SubscriptionPlanEdge',
  node: SubscriptionPlan,
};

export enum SubscriptionPlanOrder {
  CYCLE = 'CYCLE'
}

export enum SubscriptionPlanPeriod {
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}

export enum SubscriptionTypeId {
  STANDARD = 'STANDARD',
  BASIC = 'BASIC',
  ONLY_PLANS = 'ONLY_PLANS',
  ESSENTIAL = 'ESSENTIAL',
  ESSENTIAL_PLUS = 'ESSENTIAL_PLUS'
}

export enum SupportedCircuitType {
  WARMUP_SECTION = 'WARMUP_SECTION',
  WORKOUT_SECTION = 'WORKOUT_SECTION',
  COOLDOWN_SECTION = 'COOLDOWN_SECTION'
}

export type Team = {
   __typename?: 'Team',
  id: Scalars['TeamId'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  profileImage?: Maybe<Image>,
  bannerImage?: Maybe<Image>,
  members: UserConnection,
  lessonInstances: LessonInstanceConnection,
  workouts: WorkoutConnection,
  primaryOwner?: Maybe<User>,
  isUserMember: Scalars['Boolean'],
  isUserOwner: Scalars['Boolean'],
  metrics?: Maybe<TeamMetrics>,
  privacyLevel: PrivacyLevelId,
  teamPrivacyLevel: TeamPrivacyLevel,
  profileImageKey?: Maybe<Scalars['String']>,
  bannerImageKey?: Maybe<Scalars['String']>,
  profileImageUrl?: Maybe<Scalars['URL']>,
  bannerImageUrl?: Maybe<Scalars['URL']>,
  teamImageUpload: TeamImageUploadInfo,
  promoted: Scalars['Boolean'],
};


export type TeamMembersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<UserCondition>,
  orderBy?: Maybe<UserOrder>
};


export type TeamLessonInstancesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonInstanceOrder>,
  condition?: Maybe<LessonInstanceCondition>
};


export type TeamWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>,
  condition?: Maybe<UserWorkoutCondition>
};


export type TeamMetricsArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  first?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  metricType?: Maybe<WorkoutSummaryMetricTypes>,
  metricValue?: Maybe<SingleMetricSummaryTypes>
};


export type TeamTeamImageUploadArgs = {
  imageType: TeamImageType
};

export type TeamBehaviourReport = {
   __typename?: 'TeamBehaviourReport',
  id: Scalars['TeamBehaviourReportId'],
  reporter: User,
  reportedTeam: Team,
  status: TeamBehaviourReportStatus,
};

export type TeamBehaviourReportCondition = {
  reporterId?: Maybe<Array<Scalars['Int']>>,
  reportedTeamId?: Maybe<Array<Scalars['TeamId']>>,
  status?: Maybe<Array<TeamBehaviourReportStatus>>,
};

export type TeamBehaviourReportConnection = {
   __typename?: 'TeamBehaviourReportConnection',
  edges: Array<TeamBehaviourReportEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TeamBehaviourReportEdge = {
   __typename?: 'TeamBehaviourReportEdge',
  node: TeamBehaviourReport,
};


export enum TeamBehaviourReportOrder {
  CREATED_AT = 'CREATED_AT'
}

export enum TeamBehaviourReportStatus {
  REPORTED = 'REPORTED',
  WITHDRAWN = 'WITHDRAWN',
  HANDLED = 'HANDLED'
}

export type TeamClassesSummary = {
   __typename?: 'TeamClassesSummary',
  total: Scalars['Int'],
  byStudios?: Maybe<Array<TeamLessonsByStudiosSummary>>,
  byDays?: Maybe<Array<TeamLessonsByDaysSummary>>,
  byConcepts?: Maybe<Array<TeamLessonsByConceptsSummary>>,
};

export type TeamCondition = {
  privacyLevel?: Maybe<Array<PrivacyLevelId>>,
  teamPrivacyLevel?: Maybe<Array<TeamPrivacyLevel>>,
};

export type TeamConnection = {
   __typename?: 'TeamConnection',
  edges?: Maybe<Array<TeamEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TeamContributorsSummary = {
   __typename?: 'TeamContributorsSummary',
  user: User,
  total: Scalars['Int'],
  metricTotal?: Maybe<Scalars['Float']>,
};

export type TeamEdge = {
   __typename?: 'TeamEdge',
  node: Team,
};


export enum TeamImageType {
  PROFILE = 'PROFILE',
  BANNER = 'BANNER'
}

export type TeamImageUploadInfo = {
   __typename?: 'TeamImageUploadInfo',
  url: Scalars['URL'],
  imageKey: Scalars['String'],
};

export type TeamLessonsByConceptsSummary = {
   __typename?: 'TeamLessonsByConceptsSummary',
  concept: Scalars['String'],
  total: Scalars['Int'],
  percentage: Scalars['Float'],
};

export type TeamLessonsByDaysSummary = {
   __typename?: 'TeamLessonsByDaysSummary',
  date: Scalars['Date'],
  dayOfWeek: Scalars['Int'],
  total: Scalars['Int'],
};

export type TeamLessonsByStudiosSummary = {
   __typename?: 'TeamLessonsByStudiosSummary',
  studio: Studio,
  total: Scalars['Int'],
};

export type TeamMetrics = {
   __typename?: 'TeamMetrics',
  activeTimePerMember: TeamMetricSummary,
  classesCompleted: TeamClassesSummary,
  topContributors?: Maybe<Array<TeamContributorsSummary>>,
};

export type TeamMetricSummary = {
   __typename?: 'TeamMetricSummary',
  avg: Scalars['Float'],
  sum: Scalars['Float'],
};

export enum TeamOrder {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  PROMOTED = 'PROMOTED',
  POPULAR = 'POPULAR'
}

export enum TeamPrivacyLevel {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  QUARANTINE = 'QUARANTINE'
}

export enum TestEvent {
  TRIAL_STARTED = 'TRIAL_STARTED'
}

export enum TestName {
  CARDLESS_VS_CARDED = 'CARDLESS_VS_CARDED'
}

export enum TestVariant {
  HOME_CARDLESS = 'HOME_CARDLESS',
  HOME_CARDED = 'HOME_CARDED',
  PRICING_CARDLESS = 'PRICING_CARDLESS',
  PRICING_CARDED = 'PRICING_CARDED'
}

export type TggCustomerDetailsInput = {
  email: Scalars['Email'],
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  address?: Maybe<AddressInput>,
  billingAddress?: Maybe<AddressInput>,
  shippingAddress?: Maybe<AddressInput>,
  tggMembershipId: Scalars['String'],
  gender?: Maybe<Gender>,
  billingAnchorDate: Scalars['DateTime'],
};

export type TggOrderItemInput = {
  sku: TggOrderSku,
  quantity: Scalars['Int'],
};

export enum TggOrderSku {
  TV_CABLE_IOS = 'TV_CABLE_IOS',
  FIIT_LITE_DEVICE = 'FIIT_LITE_DEVICE',
  FIIT_DEVICE = 'FIIT_DEVICE'
}

export type ThirdPartyMembership = {
   __typename?: 'ThirdPartyMembership',
  thirdPartyId: Scalars['String'],
  thirdPartyName: ThirdPartyName,
};

export enum ThirdPartyName {
  TGG = 'TGG',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE'
}

export type Tier = {
   __typename?: 'Tier',
  id: Scalars['TierId'],
  name: Scalars['String'],
};

export type TierConnection = {
   __typename?: 'TierConnection',
  edges?: Maybe<Array<TierEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TierEdge = {
   __typename?: 'TierEdge',
  node?: Maybe<Tier>,
};



export type TimelineActivity = Set | Transition | TimelineCircuit;

export type TimelineCircuit = {
   __typename?: 'TimelineCircuit',
  activityId: Scalars['Int'],
  type?: Maybe<CircuitType>,
  activities: Array<TimelineActivity>,
  name?: Maybe<Scalars['String']>,
  ledPresetActive?: Maybe<Scalars['Int']>,
  ledPresetInactive?: Maybe<Scalars['Int']>,
};

export type TotalScoreMetricConfig = {
   __typename?: 'TotalScoreMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};

export type TrackInfo = {
   __typename?: 'TrackInfo',
  id: Scalars['TrackInfoId'],
  commercial: Scalars['Boolean'],
  premium: Scalars['Boolean'],
  providerCode: Scalars['String'],
  trackKey: Scalars['String'],
  imageKey?: Maybe<Scalars['String']>,
  imageUrl?: Maybe<Scalars['URL']>,
  trackDisplayName?: Maybe<Scalars['String']>,
  artistDisplayName?: Maybe<Scalars['String']>,
  parentalWarningType: ParentalWarningType,
  tracks: Array<Maybe<MusicTrack>>,
};

export type TrackInfoCondition = {
  lessonStatus: Array<LessonStatus>,
};


export enum TrackInfoOrder {
  MIX_START_SECONDS = 'MIX_START_SECONDS'
}

export type TrackingConfig = {
   __typename?: 'TrackingConfig',
  scoreZones?: Maybe<ZoneConfig>,
  trackReps: Scalars['Boolean'],
};

export type Trainer = {
   __typename?: 'Trainer',
  id: Scalars['Int'],
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  instagramHandle: Scalars['String'],
  instagramId?: Maybe<Scalars['Int']>,
  twitterHandle?: Maybe<Scalars['String']>,
  facebookHandle?: Maybe<Scalars['String']>,
  instaId?: Maybe<Scalars['String']>,
  longBio?: Maybe<Scalars['String']>,
  bio: Scalars['String'],
  focus?: Maybe<Scalars['String']>,
  shortFocus?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  referralCode: Scalars['String'],
  lessons: LessonConnection,
  profileVideos: VideoConnection,
  inductionVideos: VideoConnection,
  contentVideos: ContentVideoConnection,
  heroImg: Scalars['URL'],
  avatarImg: Scalars['URL'],
  slug: Scalars['String'],
  status: TrainerStatus,
  publishedAt?: Maybe<Scalars['DateTime']>,
  favouritedByUser: Scalars['Boolean'],
  quotes: TrainerQuoteConnection,
  coverImage: Image,
  avatarImage: Image,
  profileImages: ImageConnection,
  blogs: BlogConnection,
  premium: Scalars['Boolean'],
  permissions: TrainerPermission,
};


export type TrainerLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<LessonOrder>,
  condition?: Maybe<TrainerLessonCondition>
};


export type TrainerProfileVideosArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<VideoOrder>
};


export type TrainerInductionVideosArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<VideoOrder>
};


export type TrainerContentVideosArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ContentVideoOrder>
};


export type TrainerFavouritedByUserArgs = {
  id?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};


export type TrainerQuotesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TrainerQuoteOrder>,
  condition?: Maybe<TrainerQuoteCondition>
};


export type TrainerProfileImagesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ImageOrder>
};


export type TrainerBlogsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<BlogOrder>
};

export type TrainerBlogInput = {
  id: Scalars['Int'],
};

export type TrainerCondition = {
  id?: Maybe<Array<Scalars['Int']>>,
  lessonId?: Maybe<Array<Scalars['Int']>>,
  status?: Maybe<Array<TrainerStatus>>,
  studioId?: Maybe<Array<StudioType>>,
  studioFamily?: Maybe<Array<StudioFamily>>,
};

export type TrainerConnection = {
   __typename?: 'TrainerConnection',
  edges?: Maybe<Array<TrainerEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TrainerEdge = {
   __typename?: 'TrainerEdge',
  node: Trainer,
};

export enum TrainerImageOrder {
  PUBLISHED_AT = 'PUBLISHED_AT'
}

export type TrainerLessonCondition = {
  studioId?: Maybe<Array<StudioType>>,
  difficulty?: Maybe<Array<LessonDifficulty>>,
  status?: Maybe<Array<LessonStatus>>,
  mediaType?: Maybe<Array<LessonMediaType>>,
  durationRange?: Maybe<Array<LessonDurationRange>>,
};

export enum TrainerOrder {
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
  PUBLISHED_AT = 'PUBLISHED_AT',
  RECOMMENDED = 'RECOMMENDED'
}

export type TrainerPermission = {
   __typename?: 'TrainerPermission',
  startLesson: Scalars['Boolean'],
};

export type TrainerProfileImageInput = {
  id: Scalars['Int'],
};

export type TrainerQuote = {
   __typename?: 'TrainerQuote',
  id: Scalars['Int'],
  text: Scalars['String'],
  type: TrainerQuoteType,
  updatedAt: Scalars['DateTime'],
  published: Scalars['Boolean'],
  trainer: Trainer,
};

export type TrainerQuoteCondition = {
  type?: Maybe<Array<TrainerQuoteType>>,
};

export type TrainerQuoteConnection = {
   __typename?: 'TrainerQuoteConnection',
  edges: Array<TrainerQuoteEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TrainerQuoteEdge = {
   __typename?: 'TrainerQuoteEdge',
  node: TrainerQuote,
};

export type TrainerQuoteInput = {
  id?: Maybe<Scalars['Int']>,
  text: Scalars['String'],
  type: TrainerQuoteType,
  published?: Maybe<Scalars['Boolean']>,
};

export enum TrainerQuoteOrder {
  UPDATED_AT = 'UPDATED_AT'
}

export enum TrainerQuoteType {
  PROFILE = 'PROFILE',
  INSPIRATIONAL = 'INSPIRATIONAL',
  MARKETING = 'MARKETING'
}

export enum TrainerStatus {
  DRAFT = 'DRAFT',
  TESTING = 'TESTING',
  PUBLISHED = 'PUBLISHED',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED'
}

export type TrainingGoal = {
   __typename?: 'TrainingGoal',
  id: UserFitnessGoal,
  value: Scalars['String'],
  description: Scalars['String'],
  displayName: Scalars['String'],
  displayOrder: Scalars['Int'],
  trainers: TrainerConnection,
};


export type TrainingGoalTrainersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TrainerOrder>,
  condition?: Maybe<StudioTrainerCondition>
};

export type TrainingGoalCondition = {
  gender?: Maybe<Array<Gender>>,
};

export type TrainingGoalInput = {
  id: UserFitnessGoal,
};

export enum TrainingGoalOrder {
  DISPLAY_ORDER = 'DISPLAY_ORDER'
}

export type TrainingPlan = {
   __typename?: 'TrainingPlan',
  id: Scalars['Int'],
  slug: Scalars['String'],
  name: Scalars['String'],
  status: TrainingPlanStatus,
  description?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  tagline?: Maybe<Scalars['String']>,
  durationWeeks: Scalars['Weeks'],
  trainingGoal: TrainingGoal,
  coverImage?: Maybe<Image>,
  templates?: Maybe<Array<TrainingPlanTemplate>>,
  templateByLevel?: Maybe<TrainingPlanTemplate>,
  video?: Maybe<Video>,
  permissions: TrainingPlanPermission,
  permissionsWithReasons: TrainingPlanPermissionsWithReasons,
  premium: Scalars['Boolean'],
  rewardType?: Maybe<RewardType>,
  displayOrder: Scalars['Int'],
  branding?: Maybe<Brand>,
  userTrainingPlans: UserTrainingPlanConnection,
  images?: Maybe<TrainingPlanImages>,
  tier?: Maybe<Array<Tier>>,
  equipment: Scalars['Boolean'],
  promoted: Scalars['Boolean'],
  xpReward?: Maybe<Scalars['Int']>,
  labels?: Maybe<Array<TrainingPlanLabel>>,
  publishedAt?: Maybe<Scalars['DateTime']>,
  refreshed: Scalars['Boolean'],
};


export type TrainingPlanTemplateByLevelArgs = {
  level: LessonDifficulty
};


export type TrainingPlanUserTrainingPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<UserTrainingPlanOrder>,
  condition?: Maybe<UserTrainingPlanCondition>
};

export enum TrainingPlanAchievement {
  BETTER_EATING_HABITS = 'BETTER_EATING_HABITS',
  REDUCED_STRESS = 'REDUCED_STRESS',
  IMPROVED_MOOD = 'IMPROVED_MOOD',
  INCREASED_ENERGY_LEVELS = 'INCREASED_ENERGY_LEVELS',
  MUSCLE_GAIN = 'MUSCLE_GAIN',
  WEIGHT_LOSS = 'WEIGHT_LOSS',
  LOST_INCHES = 'LOST_INCHES',
  INCREASED_BODY_CONFIDENCE = 'INCREASED_BODY_CONFIDENCE',
  BETTER_SLEEP = 'BETTER_SLEEP',
  REDUCED_ALCOHOL_INTAKE = 'REDUCED_ALCOHOL_INTAKE',
  NONE_OF_THE_ABOVE = 'NONE_OF_THE_ABOVE'
}

export type TrainingPlanCondition = {
  trainingGoalId?: Maybe<Array<UserFitnessGoal>>,
  useEquipmentForRecommending?: Maybe<Scalars['Boolean']>,
  status?: Maybe<Array<TrainingPlanStatus>>,
};

export type TrainingPlanConnection = {
   __typename?: 'TrainingPlanConnection',
  edges?: Maybe<Array<TrainingPlanEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TrainingPlanEdge = {
   __typename?: 'TrainingPlanEdge',
  node: TrainingPlan,
};

export type TrainingPlanImages = {
   __typename?: 'TrainingPlanImages',
  cover?: Maybe<Image>,
  card?: Maybe<Image>,
};

export enum TrainingPlanLabel {
  NEW = 'NEW',
  REFRESHED = 'REFRESHED'
}

export type TrainingPlanList = {
   __typename?: 'TrainingPlanList',
  id: Scalars['TrainingPlanListId'],
  trainingPlans: TrainingPlanConnection,
};


export type TrainingPlanListTrainingPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ListedTrainingPlanOrder>,
  condition?: Maybe<TrainingPlanCondition>
};

export type TrainingPlanListConnection = {
   __typename?: 'TrainingPlanListConnection',
  edges?: Maybe<Array<TrainingPlanListEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TrainingPlanListEdge = {
   __typename?: 'TrainingPlanListEdge',
  node: TrainingPlanList,
};


export enum TrainingPlanListOrder {
  CREATED_AT = 'CREATED_AT'
}

export enum TrainingPlanOrder {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_ORDER = 'DISPLAY_ORDER',
  RECOMMENDED = 'RECOMMENDED'
}

export enum TrainingPlanPausingReason {
  ILLNESS_OR_INJURY = 'ILLNESS_OR_INJURY',
  NOT_ENOUGH_TIME = 'NOT_ENOUGH_TIME',
  WOMENS_HEALTH = 'WOMENS_HEALTH',
  TOO_INTENSE = 'TOO_INTENSE',
  HOLIDAY = 'HOLIDAY',
  OTHER = 'OTHER'
}

export type TrainingPlanPermission = {
   __typename?: 'TrainingPlanPermission',
  start: Scalars['Boolean'],
};

export type TrainingPlanPermissionsWithReasons = {
   __typename?: 'TrainingPlanPermissionsWithReasons',
  start: TrainingPlanStartPermission,
  pause: Scalars['Boolean'],
};

export type TrainingPlanRecommendation = {
   __typename?: 'TrainingPlanRecommendation',
  id?: Maybe<Scalars['TrainingPlanRecommendationId']>,
  trainingPlan: TrainingPlan,
  trainingGoal: TrainingGoal,
  activityLevel: UserActivityLevel,
  equipment?: Maybe<CustomEquipment>,
  subscriptionTypeId: SubscriptionTypeId,
};

export type TrainingPlanRecommendationConnection = {
   __typename?: 'TrainingPlanRecommendationConnection',
  edges: Array<TrainingPlanRecommendationEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TrainingPlanRecommendationEdge = {
   __typename?: 'TrainingPlanRecommendationEdge',
  node: Video,
};


export type TrainingPlanReviewAchievement = {
   __typename?: 'TrainingPlanReviewAchievement',
  id: Scalars['TrainingPlanReviewAchievementId'],
  response: TrainingPlanAchievement,
};


export type TrainingPlanSchedule = {
   __typename?: 'TrainingPlanSchedule',
  id: Scalars['Int'],
  trainingPlanTemplate: TrainingPlanTemplate,
  lesson: Lesson,
  weekNumber: Scalars['Int'],
  position: Scalars['Int'],
};

export type TrainingPlanStartPermission = {
   __typename?: 'TrainingPlanStartPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<TrainingPlanStartPermissionReason>>,
};

export enum TrainingPlanStartPermissionReason {
  PREMIUM = 'PREMIUM',
  STATUS = 'STATUS',
  NO_USER = 'NO_USER',
  CANT_START_FREE = 'CANT_START_FREE',
  USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  ALREADY_ACTIVE = 'ALREADY_ACTIVE'
}

export enum TrainingPlanStatus {
  DRAFT = 'DRAFT',
  TESTING = 'TESTING',
  PUBLISHED = 'PUBLISHED',
  LOCKED = 'LOCKED'
}

export type TrainingPlanTemplate = {
   __typename?: 'TrainingPlanTemplate',
  id: Scalars['Int'],
  level: LessonDifficulty,
  trainingPlan: TrainingPlan,
  schedule: Array<TrainingPlanSchedule>,
  lessonCount: Scalars['Int'],
  byWeek: Array<Maybe<TrainingPlanTemplateByWeek>>,
  userTrainingPlans: UserTrainingPlanConnection,
};


export type TrainingPlanTemplateUserTrainingPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy: UserTrainingPlanOrder,
  condition?: Maybe<UserTrainingPlanCondition>
};

export type TrainingPlanTemplateByWeek = {
   __typename?: 'TrainingPlanTemplateByWeek',
  weekNumber: Scalars['Int'],
  overview?: Maybe<Scalars['String']>,
  schedule: Array<TrainingPlanSchedule>,
  tutorials: Array<Tutorial>,
  video?: Maybe<Video>,
  blog?: Maybe<Blog>,
};

export enum TrainingPlanTemplateTutorialOrder {
  CREATED_AT = 'CREATED_AT'
}

export type TrainingPlanTiers = {
   __typename?: 'TrainingPlanTiers',
  tier?: Maybe<Tier>,
};

export type Transition = {
   __typename?: 'Transition',
  activityId: Scalars['Int'],
  zoneConfig?: Maybe<ZoneConfig>,
  trackingConfig: TrackingConfig,
  metrics: ActivityMetrics,
  duration: Scalars['Int'],
  autocue?: Maybe<Scalars['String']>,
  movementStandardUrl: Scalars['String'],
};

export type TreadDistanceMetricConfig = {
   __typename?: 'TreadDistanceMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type TreadKcalMetricConfig = {
   __typename?: 'TreadKcalMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type TreadRpmMetricConfig = {
   __typename?: 'TreadRPMMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type TreadSpeedMetricConfig = {
   __typename?: 'TreadSpeedMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type TreadTotalScoreMetricConfig = {
   __typename?: 'TreadTotalScoreMetricConfig',
  track: Scalars['Boolean'],
  totalWorkout: CumulativeMetricShowState,
};

export type TreadWattsMetricConfig = {
   __typename?: 'TreadWattsMetricConfig',
  track: Scalars['Boolean'],
  showInstantaneous: Scalars['Boolean'],
};

export type Tutorial = {
   __typename?: 'Tutorial',
  id: Scalars['Int'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  muscles: Array<Muscle>,
  trainer?: Maybe<Trainer>,
  video?: Maybe<Video>,
  ledPage?: Maybe<Scalars['Int']>,
  status: TutorialStatus,
  duration: Scalars['Int'],
  mainImage?: Maybe<Image>,
  plan: Array<PlanCircuit>,
  timeline: Array<TimelineCircuit>,
  releaseAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  editingUser?: Maybe<User>,
  editingFrom?: Maybe<Scalars['DateTime']>,
  planOnly: Scalars['Boolean'],
  exerciseDisplay: ExerciseDisplay,
  uiConfig: TutorialUiConfig,
  studio?: Maybe<Studio>,
  heroMetric?: Maybe<MetricTypes>,
};

export type TutorialCondition = {
  id?: Maybe<Array<Scalars['Int']>>,
  trainerId?: Maybe<Array<Scalars['Int']>>,
  status?: Maybe<Array<TutorialStatus>>,
  muscleId?: Maybe<Array<Scalars['Int']>>,
  planOnly?: Maybe<Array<Scalars['Boolean']>>,
};

export type TutorialConnection = {
   __typename?: 'TutorialConnection',
  edges?: Maybe<Array<TutorialEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TutorialEdge = {
   __typename?: 'TutorialEdge',
  node: Tutorial,
};

export type TutorialMuscleInput = {
  id: Scalars['Int'],
};

export enum TutorialOrder {
  CREATED_AT = 'CREATED_AT',
  PUBLISHED_AT = 'PUBLISHED_AT'
}

export enum TutorialStatus {
  DRAFT = 'DRAFT',
  TESTING = 'TESTING',
  PUBLISHED = 'PUBLISHED',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED'
}

export type TutorialUiConfig = {
   __typename?: 'TutorialUiConfig',
  showBpmWithZone: Scalars['Boolean'],
  showExerciseTitle: Scalars['Boolean'],
  showPBForActivityTotal: Scalars['Boolean'],
};

export enum UiMode {
  TV = 'TV',
  MOBILE = 'MOBILE'
}

export type UnfollowUserResponse = {
   __typename?: 'UnfollowUserResponse',
  user: User,
  follower: User,
};

export enum UnitSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC'
}

export type UnsubscribeFromCommunicationMessageTypesInput = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>,
  messageTypeIds: Array<Scalars['Int']>,
};

export type UnsubscribeFromCommunicationMessageTypesResponse = {
   __typename?: 'UnsubscribeFromCommunicationMessageTypesResponse',
  communicationMessageGroups: Array<CommunicationMessageGroup>,
};


export type UnsubscribeFromCommunicationMessageTypesResponseCommunicationMessageGroupsArgs = {
  orderBy?: Maybe<CommunicationMessageGroupOrder>
};

export type UpdateWorkoutStatsInput = {
  chunks: Array<WorkoutStatsChunkInput>,
};


export type UploadImageInput = {
  src: Scalars['Upload'],
  horizontalAlignment?: Maybe<ImageHorizontalAlignment>,
  verticalAlignment?: Maybe<ImageVerticalAlignment>,
  hexColour?: Maybe<Scalars['HexColour']>,
};

export type UploadImageSeparateInputsInput = {
  horizontalAlignment?: Maybe<ImageHorizontalAlignment>,
  verticalAlignment?: Maybe<ImageVerticalAlignment>,
  hexColour?: Maybe<Scalars['HexColour']>,
};


export type UrlParameter = {
   __typename?: 'URLParameter',
  key: Scalars['String'],
  value: Scalars['String'],
};

export type User = {
   __typename?: 'User',
  id: Scalars['Int'],
  userId?: Maybe<Scalars['Int']>,
  handle: Scalars['UserHandle'],
  alias: UserAlias,
  email: Scalars['Email'],
  stripeCustomerId: Scalars['String'],
  firstname?: Maybe<Scalars['String']>,
  lastname?: Maybe<Scalars['String']>,
  displayNames: UserDisplayNames,
  displayImageUrl?: Maybe<Scalars['URL']>,
  tracker: Scalars['Boolean'],
  dob?: Maybe<Scalars['Date']>,
  height?: Maybe<Scalars['Int']>,
  activityLevel?: Maybe<UserActivityLevel>,
  acceptedTos: Scalars['Boolean'],
  acceptedPrivacyPolicy: Scalars['Boolean'],
  acceptedHealthDisclaimer: Scalars['Boolean'],
  acceptedAgreements: Array<AgreementType>,
  hasSetAllPrivacyGroups: Scalars['Boolean'],
  hasSetAllUserInteractions: Scalars['Boolean'],
  groups: Array<Scalars['String']>,
  purchasedAirbikeAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  isWhitelisted: Scalars['Boolean'],
  subscribed: Scalars['Boolean'],
  type: UserType,
  fiitSubscription?: Maybe<UserSubscription>,
  sendProductEmails: Scalars['Boolean'],
  sendProductNotifications: Scalars['Boolean'],
  sendNewsEmails: Scalars['Boolean'],
  sendNewsNotifications: Scalars['Boolean'],
  sendActivityEmails: Scalars['Boolean'],
  sendActivityNotifications: Scalars['Boolean'],
  phoneNumber?: Maybe<Scalars['String']>,
  unitSystemPreference: UnitSystem,
  shippingAddress?: Maybe<Address>,
  shipAddress?: Maybe<ShippingAddress>,
  referringTrainer?: Maybe<Trainer>,
  weight?: Maybe<Scalars['Float']>,
  fitnessGoal?: Maybe<UserFitnessGoal>,
  trainingGoal?: Maybe<TrainingGoal>,
  gender?: Maybe<Gender>,
  sex?: Maybe<Sex>,
  attributionId?: Maybe<Scalars['String']>,
  referralCode: Scalars['String'],
  referrals: Referrals,
  dirtyPassword: Scalars['Boolean'],
  profileImageUrl?: Maybe<Scalars['URL']>,
  profileImageUploadUrl: Scalars['URL'],
  profileImageUpload: ProfileImageUploadInfo,
  workouts: WorkoutConnection,
  lessonInstanceSignUps: LessonInstanceConnection,
  streaks: UserStreaks,
  summaryByTimePeriod?: Maybe<AggregationSummary>,
  summaryByStudio?: Maybe<AggregationSummary>,
  workoutSummary?: Maybe<AggregationSummary>,
  workoutProgress?: Maybe<MetricsSummary>,
  workoutCount?: Maybe<Scalars['Int']>,
  workoutActivities?: Maybe<ActivityHistoryConnection>,
  workoutHighlights?: Maybe<Array<ActivityHistory>>,
  weeklyGoal?: Maybe<UserWeeklyGoal>,
  favouriteLessons: LessonConnection,
  favouriteTrainers: TrainerConnection,
  trainingPlans: UserTrainingPlanConnection,
  activeTrainingPlan?: Maybe<UserTrainingPlan>,
  challenges: UserChallengeConnection,
  maxHeartRate?: Maybe<Scalars['Float']>,
  heartRate?: Maybe<UserHeartRate>,
  referAFriendRewards: ReferAFriendRewards,
  recommendedLessons: LessonConnection,
  recommendedTrainingPlans: TrainingPlanConnection,
  lastUsedFiitDevice?: Maybe<Scalars['String']>,
  lastUsedDevices?: Maybe<Array<Device>>,
  lastUsedEquipment?: Maybe<Array<Device>>,
  lastUsedConnectedKit?: Maybe<Array<Device>>,
  permissions: UserInteractionPermissions,
  userBehaviourReports: UserBehaviourReportConnection,
  followers: UserConnection,
  allFollowers: Array<User>,
  following: UserConnection,
  hostedParties?: Maybe<PartyConnection>,
  freeSubCode?: Maybe<FreeSubCode>,
  stripeCurrency?: Maybe<Scalars['String']>,
  teams: TeamConnection,
  rootCollectionList?: Maybe<CollectionList>,
  recommendedOnboardingChallenge?: Maybe<Challenge>,
  thirdPartyMembership?: Maybe<Array<Maybe<ThirdPartyMembership>>>,
  ownedEquipmentOnboarded?: Maybe<Scalars['Boolean']>,
  ownedEquipment: CustomEquipmentConnection,
  maxHr?: Maybe<Scalars['Int']>,
  experience: UserExperience,
  randomSpinPrizeSpins: UserRandomSpinPrizeSpinConnection,
  randomSpinPrizeInfo: UserRandomSpinPrizeInfo,
  deepLinkAttemptInfo?: Maybe<DeepLinkAttemptInfo>,
  challengeOnboarded?: Maybe<Scalars['Boolean']>,
  partnerUsers: Array<PartnerUser>,
};


export type UserReferralsArgs = {
  currency?: Maybe<Array<CurrencyCode>>
};


export type UserProfileImageUploadUrlArgs = {
  fileMetadata: UserFileMetadata
};


export type UserWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>,
  condition?: Maybe<UserWorkoutCondition>
};


export type UserLessonInstanceSignUpsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<LessonInstanceCondition>,
  orderBy?: Maybe<LessonInstanceOrder>
};


export type UserSummaryByTimePeriodArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>
};


export type UserSummaryByStudioArgs = {
  studioId: Array<StudioType>
};


export type UserWorkoutSummaryArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>,
  userTrainingPlanId?: Maybe<Scalars['Int']>
};


export type UserWorkoutProgressArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>,
  userTrainingPlanId?: Maybe<Scalars['Int']>
};


export type UserWorkoutCountArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>,
  userTrainingPlanId?: Maybe<Scalars['Int']>
};


export type UserWorkoutActivitiesArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>,
  userTrainingPlanId?: Maybe<Scalars['Int']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ActivityHistoryOrder>
};


export type UserWorkoutHighlightsArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>,
  userTrainingPlanId?: Maybe<Scalars['Int']>
};


export type UserWeeklyGoalArgs = {
  date?: Maybe<Scalars['DateTime']>
};


export type UserFavouriteLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FavouriteLessonOrder>,
  condition?: Maybe<LessonCondition>
};


export type UserFavouriteTrainersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FavouriteTrainerOrder>
};


export type UserTrainingPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<UserTrainingPlanOrder>,
  condition?: Maybe<UserTrainingPlanCondition>
};


export type UserChallengesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy: UserChallengeOrder,
  condition?: Maybe<UserChallengeCondition>
};


export type UserRecommendedLessonsArgs = {
  first?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<LessonCondition>
};


export type UserRecommendedTrainingPlansArgs = {
  first?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>
};


export type UserUserBehaviourReportsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<UserBehaviourReportCondition>,
  orderBy: UserBehaviourReportOrder
};


export type UserFollowersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FollowerOrder>,
  condition?: Maybe<FollowerCondition>
};


export type UserFollowingArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FollowerOrder>,
  condition?: Maybe<FollowerCondition>
};


export type UserHostedPartiesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<PartyOrder>,
  condition?: Maybe<PartyCondition>
};


export type UserTeamsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<TeamOrder>
};


export type UserOwnedEquipmentArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<CustomEquipmentCondition>,
  orderBy?: Maybe<CustomEquipmentOrder>
};


export type UserRandomSpinPrizeSpinsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<UserRandomSpinPrizeSpinCondition>,
  orderBy?: Maybe<UserRandomSpinPrizeSpinOrder>
};

export enum UserActivityLevel {
  VERY_LOW = 'VERY_LOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH'
}

export type UserAlias = {
   __typename?: 'UserAlias',
  id?: Maybe<Scalars['Int']>,
  handle?: Maybe<Scalars['UserHandle']>,
};

export type UserBehaviourReport = {
   __typename?: 'UserBehaviourReport',
  id: Scalars['UserBehaviourReportId'],
  reporter: User,
  reported: User,
  status: UserBehaviourReportStatus,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type UserBehaviourReportCondition = {
  reporterId?: Maybe<Array<Scalars['Int']>>,
  reportedId?: Maybe<Array<Scalars['Int']>>,
  status?: Maybe<Array<UserBehaviourReportStatus>>,
};

export type UserBehaviourReportConnection = {
   __typename?: 'UserBehaviourReportConnection',
  edges: Array<UserBehaviourReportEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserBehaviourReportEdge = {
   __typename?: 'UserBehaviourReportEdge',
  node: UserBehaviourReport,
};


export enum UserBehaviourReportOrder {
  CREATED_AT = 'CREATED_AT'
}

export enum UserBehaviourReportStatus {
  REPORTED = 'REPORTED',
  WITHDRAWN = 'WITHDRAWN',
  HANDLED = 'HANDLED'
}

export type UserChallenge = {
   __typename?: 'UserChallenge',
  id: Scalars['UserChallengeId'],
  user?: Maybe<User>,
  challenge: Challenge,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  completedAt?: Maybe<Scalars['DateTime']>,
  abandonedAt?: Maybe<Scalars['DateTime']>,
  cancelledAt?: Maybe<Scalars['DateTime']>,
  state: UserChallengeState,
  blocks: Array<UserChallengeBlock>,
  privacyLevel: PrivacyLevelId,
  workoutSummary?: Maybe<AggregationSummary>,
  startsAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
};


export type UserChallengeBlocksArgs = {
  orderBy?: Maybe<ChallengeBlockOrder>,
  condition?: Maybe<ChallengeBlockCondition>
};


export type UserChallengeWorkoutSummaryArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>
};

export type UserChallengeBlock = {
   __typename?: 'UserChallengeBlock',
  id: Scalars['UserChallengeBlockId'],
  userChallenge: UserChallenge,
  challengeBlock: ChallengeBlock,
  completedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  goals: Array<UserChallengeBlockGoal>,
  progress: Progress,
  workouts: WorkoutConnection,
  startsAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
};


export type UserChallengeBlockWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>,
  condition?: Maybe<UserChallengeBlockWorkoutCondition>
};

export type UserChallengeBlockGoal = {
   __typename?: 'UserChallengeBlockGoal',
  id: Scalars['UserChallengeBlockGoalId'],
  userChallengeBlock: UserChallengeBlock,
  challengeBlockGoal: ChallengeBlockGoal,
  workouts: WorkoutConnection,
  progress: Progress,
  completedAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type UserChallengeBlockGoalWorkoutsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<WorkoutOrder>,
  condition?: Maybe<UserChallengeBlockGoalWorkoutCondition>
};


export type UserChallengeBlockGoalWorkoutCondition = {
  state?: Maybe<Array<WorkoutState>>,
  includeAnonymous?: Maybe<Array<Scalars['Boolean']>>,
};



export type UserChallengeBlockWorkoutCondition = {
  state?: Maybe<Array<WorkoutState>>,
  includeAnonymous?: Maybe<Array<Scalars['Boolean']>>,
};

export type UserChallengeCondition = {
  state?: Maybe<Array<UserChallengeState>>,
  user?: Maybe<UserInput>,
  includeAnonymous?: Maybe<Scalars['Boolean']>,
  completedAfter?: Maybe<Scalars['DateTime']>,
  endsAfter?: Maybe<Scalars['DateTime']>,
};

export type UserChallengeConnection = {
   __typename?: 'UserChallengeConnection',
  edges: Array<UserChallengeEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserChallengeEdge = {
   __typename?: 'UserChallengeEdge',
  node: UserChallenge,
};


export enum UserChallengeMetricType {
  WORKOUTS_COMPLETED = 'WORKOUTS_COMPLETED',
  FIIT_POINTS = 'FIIT_POINTS',
  ACTIVE_MINUTES = 'ACTIVE_MINUTES'
}

export enum UserChallengeOrder {
  STARTS_AT = 'STARTS_AT',
  ENDS_AT = 'ENDS_AT'
}

export enum UserChallengeState {
  SIGNED_UP = 'SIGNED_UP',
  SIGN_UP_CANCELLED = 'SIGN_UP_CANCELLED',
  STARTED = 'STARTED',
  ABANDONED = 'ABANDONED',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED'
}

export type UserCondition = {
  id?: Maybe<Array<Scalars['Int']>>,
  input?: Maybe<Array<UserInput>>,
};

export type UserConnection = {
   __typename?: 'UserConnection',
  edges?: Maybe<Array<UserEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserDisplayNames = {
   __typename?: 'UserDisplayNames',
  full: Scalars['String'],
  short: Scalars['String'],
};

export type UserEdge = {
   __typename?: 'UserEdge',
  node: User,
};

export type UserEvents = {
   __typename?: 'UserEvents',
  url: Scalars['URL'],
  secret: Scalars['String'],
};

export type UserExperience = {
   __typename?: 'UserExperience',
  currentExperience: Scalars['Int'],
};

export type UserFileMetadata = {
  contentType: Scalars['String'],
  sizeInKB: Scalars['Int'],
};

export enum UserFitnessGoal {
  LOSE_WEIGHT = 'LOSE_WEIGHT',
  BUILD_MUSCLE = 'BUILD_MUSCLE',
  FLEXIBILITY = 'FLEXIBILITY',
  GENERAL = 'GENERAL',
  POSTNATAL = 'POSTNATAL'
}


export type UserHeartRate = {
   __typename?: 'UserHeartRate',
  max: Scalars['GraphQLHeartRate'],
  min: Scalars['GraphQLHeartRate'],
};

export type UserInput = {
  id?: Maybe<Scalars['Int']>,
  handle?: Maybe<Scalars['UserHandle']>,
};

export type UserInteraction = {
   __typename?: 'UserInteraction',
  id: UserInteractionId,
  description: Scalars['String'],
  displayName: Scalars['String'],
  levels: Array<UserInteractionLevel>,
  userLevel: UserInteractionUserLevel,
};


export type UserInteractionUserLevelArgs = {
  userId?: Maybe<Scalars['Int']>,
  user?: Maybe<UserInput>
};

export type UserInteractionDebugPermission = {
   __typename?: 'UserInteractionDebugPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<UserInteractionDebugReason>>,
};

export enum UserInteractionDebugReason {
  INSUFFICIENT_SCOPES = 'INSUFFICIENT_SCOPES'
}

export type UserInteractionFollowPermission = {
   __typename?: 'UserInteractionFollowPermission',
  value: Scalars['Boolean'],
  blockedReasons?: Maybe<Array<UserInteractionFollowReason>>,
};

export enum UserInteractionFollowReason {
  SELF = 'SELF',
  USER_SETTINGS = 'USER_SETTINGS'
}

export enum UserInteractionId {
  FOLLOW = 'FOLLOW'
}

export type UserInteractionLevel = {
   __typename?: 'UserInteractionLevel',
  id: UserInteractionLevelId,
  displayName: Scalars['String'],
  description: Scalars['String'],
};

export enum UserInteractionLevelId {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type UserInteractionPermissions = {
   __typename?: 'UserInteractionPermissions',
  follow: UserInteractionFollowPermission,
  debug: UserInteractionDebugPermission,
};

export type UserInteractionUserLevel = {
   __typename?: 'UserInteractionUserLevel',
  user: User,
  selectedLevel?: Maybe<UserInteractionLevel>,
};

export type UserLeaderboard = {
   __typename?: 'UserLeaderboard',
  rank: Scalars['Int'],
  value?: Maybe<Scalars['Float']>,
  user: User,
  valueType?: Maybe<MetricTypes>,
  metricType?: Maybe<WorkoutSummaryMetricTypes>,
};

export type UserLeaderboardCondition = {
  includeAnonymous?: Maybe<Array<Scalars['Boolean']>>,
  sex?: Maybe<Sex>,
  ageRange?: Maybe<AgeRange>,
  from?: Maybe<Scalars['Date']>,
  to?: Maybe<Scalars['Date']>,
  metric?: Maybe<WorkoutSummaryMetricTypes>,
};

export type UserLeaderboardConnection = {
   __typename?: 'UserLeaderboardConnection',
  edges?: Maybe<Array<UserLeaderboardEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserLeaderboardEdge = {
   __typename?: 'UserLeaderboardEdge',
  node: UserLeaderboard,
};

export enum UserOrder {
  FIRSTNAME = 'FIRSTNAME',
  CREATED_AT = 'CREATED_AT'
}

export type UserOwnedEquipmentResponse = {
   __typename?: 'UserOwnedEquipmentResponse',
  success: Scalars['Boolean'],
};

export type UserRandomSpinPrizeInfo = {
   __typename?: 'UserRandomSpinPrizeInfo',
  canSpin: Scalars['Boolean'],
  spinsToday: Scalars['Int'],
  canEarnSpin: Scalars['Boolean'],
  spinsEarnedToday: Scalars['Int'],
};

export type UserRandomSpinPrizeSpin = {
   __typename?: 'UserRandomSpinPrizeSpin',
  id: Scalars['UserRandomSpinPrizeSpinId'],
  user: User,
  randomSpinPrize?: Maybe<RandomSpinPrize>,
  spinVariant: SpinVariant,
  status: SpinStatus,
  expiresAt: Scalars['DateTime'],
  expired: Scalars['Boolean'],
  spunAt?: Maybe<Scalars['DateTime']>,
  claimedAt?: Maybe<Scalars['DateTime']>,
  coupon?: Maybe<Scalars['String']>,
};

export type UserRandomSpinPrizeSpinCondition = {
  randomSpinPrizeId?: Maybe<Scalars['RandomSpinPrizeId']>,
  status?: Maybe<Array<SpinStatus>>,
  spinVariant?: Maybe<Array<SpinVariant>>,
  expiresAfter?: Maybe<Scalars['DateTime']>,
};

export type UserRandomSpinPrizeSpinConnection = {
   __typename?: 'UserRandomSpinPrizeSpinConnection',
  edges?: Maybe<Array<UserRandomSpinPrizeSpinEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserRandomSpinPrizeSpinEdge = {
   __typename?: 'UserRandomSpinPrizeSpinEdge',
  node: UserRandomSpinPrizeSpin,
};


export enum UserRandomSpinPrizeSpinOrder {
  CREATED_AT = 'CREATED_AT'
}

export type UserStreaks = {
   __typename?: 'UserStreaks',
  daily?: Maybe<Streak>,
  weekly?: Maybe<Streak>,
};

export type UserStreakSummary = {
   __typename?: 'UserStreakSummary',
  daily: Scalars['Int'],
  weekly: Scalars['Int'],
};

export type UserSubscription = {
   __typename?: 'UserSubscription',
  paymentProvider: PaymentProvider,
  paymentProviderInfo: PaymentProviderInfo,
  sku?: Maybe<Scalars['String']>,
  start?: Maybe<Scalars['DateTime']>,
  end?: Maybe<Scalars['DateTime']>,
  trialEnd?: Maybe<Scalars['DateTime']>,
  skuInterval?: Maybe<Scalars['String']>,
  skuIntervalCount?: Maybe<Scalars['Int']>,
  attributionId?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type UserSubscriptionConnection = {
   __typename?: 'UserSubscriptionConnection',
  edges?: Maybe<Array<UserSubscriptionEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserSubscriptionEdge = {
   __typename?: 'UserSubscriptionEdge',
  node: UserSubscription,
};

export type UserTestEvent = {
  event: TestEvent,
  test: TestName,
  variant: TestVariant,
};

export type UserTrainingPlan = {
   __typename?: 'UserTrainingPlan',
  id: Scalars['Int'],
  user: User,
  trainingPlanTemplate: TrainingPlanTemplate,
  startedAt: Scalars['DateTime'],
  endsAt: Scalars['DateTime'],
  cancelledAt?: Maybe<Scalars['DateTime']>,
  isActive: Scalars['Boolean'],
  schedule: Array<UserTrainingPlanSchedule>,
  rewardInfo?: Maybe<Scalars['String']>,
  rewardInfos?: Maybe<RewardInfos>,
  workoutSummary?: Maybe<AggregationSummary>,
  isComplete: Scalars['Boolean'],
  state: UserTrainingPlanState,
  review?: Maybe<UserTrainingPlanReview>,
  freeCreditAppliedAt?: Maybe<Scalars['DateTime']>,
  byWeek: Array<UserTrainingPlanWeek>,
  currentWeek?: Maybe<Scalars['Int']>,
  xpReward?: Maybe<Scalars['Int']>,
  pausedAt?: Maybe<Scalars['DateTime']>,
  pausedFrom?: Maybe<Scalars['DateTime']>,
  pausedTo?: Maybe<Scalars['DateTime']>,
  pausingReason?: Maybe<TrainingPlanPausingReason>,
};


export type UserTrainingPlanWorkoutSummaryArgs = {
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  studioId?: Maybe<Array<StudioType>>
};

export enum UserTrainingPlanCancelReason {
  NOT_RIGHT = 'NOT_RIGHT',
  RESTART = 'RESTART',
  NO_DEVICE = 'NO_DEVICE',
  TOO_DIFFICULT = 'TOO_DIFFICULT',
  TOO_EASY = 'TOO_EASY',
  NO_TIME = 'NO_TIME',
  ILLNESS_INJURY = 'ILLNESS_INJURY',
  HOLIDAYS = 'HOLIDAYS',
  NO_JOY = 'NO_JOY',
  OTHER = 'OTHER'
}

export type UserTrainingPlanCondition = {
  state?: Maybe<Array<UserTrainingPlanState>>,
};

export type UserTrainingPlanConnection = {
   __typename?: 'UserTrainingPlanConnection',
  edges: Array<UserTrainingPlanEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type UserTrainingPlanEdge = {
   __typename?: 'UserTrainingPlanEdge',
  node: UserTrainingPlan,
};

export enum UserTrainingPlanOrder {
  STARTED_AT = 'STARTED_AT',
  ENDS_AT = 'ENDS_AT'
}

export type UserTrainingPlanReview = {
   __typename?: 'UserTrainingPlanReview',
  id: Scalars['Int'],
  userTrainingPlan: UserTrainingPlan,
  rating?: Maybe<Scalars['Int']>,
  general?: Maybe<Scalars['String']>,
  intensityStrength?: Maybe<OptionsIntensity>,
  intensityCardio?: Maybe<OptionsIntensity>,
  intensityRebalance?: Maybe<OptionsIntensity>,
  planLevel?: Maybe<OptionsAmount>,
  intensityExpectations?: Maybe<OptionsIntensity>,
  cancelReason?: Maybe<UserTrainingPlanCancelReason>,
  incompleteReason?: Maybe<UserTrainingPlanCancelReason>,
  achievementResponses?: Maybe<Array<TrainingPlanReviewAchievement>>,
};

export type UserTrainingPlanSchedule = {
   __typename?: 'UserTrainingPlanSchedule',
  id: Scalars['Int'],
  lesson: Lesson,
  userTrainingPlan: UserTrainingPlan,
  weekNumber: Scalars['Int'],
  position: Scalars['Int'],
  completeAfter: Scalars['DateTime'],
  completeBefore: Scalars['DateTime'],
  workout?: Maybe<Workout>,
};

export enum UserTrainingPlanState {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  CANCELLED_EARLY = 'CANCELLED_EARLY',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  PAUSED = 'PAUSED'
}

export type UserTrainingPlanWeek = {
   __typename?: 'UserTrainingPlanWeek',
  weekNumber: Scalars['Int'],
  periodStart: Scalars['DateTime'],
  periodEnd: Scalars['DateTime'],
  schedule: Array<UserTrainingPlanSchedule>,
  workoutSummary: AggregationSummary,
};


export type UserTrainingPlanWeekWorkoutSummaryArgs = {
  studioId?: Maybe<Array<StudioType>>
};

export enum UserType {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  ONLY_PLANS = 'ONLY_PLANS',
  ESSENTIAL = 'ESSENTIAL',
  ESSENTIAL_PLUS = 'ESSENTIAL_PLUS'
}

export type UserWeeklyGoal = {
   __typename?: 'UserWeeklyGoal',
  goal: Scalars['Int'],
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type UserWeeklyGoalInput = {
  goal: Scalars['Int'],
};

export type UserWorkoutCondition = {
  lessonId?: Maybe<Scalars['Int']>,
  studioId?: Maybe<StudioType>,
  lessonDurationRange?: Maybe<Array<LessonDurationRange>>,
  state?: Maybe<Array<WorkoutState>>,
  lessonStatus?: Maybe<Array<LessonStatus>>,
  from?: Maybe<Scalars['DateTime']>,
  to?: Maybe<Scalars['DateTime']>,
  healthPlatformSynced?: Maybe<Array<Scalars['Boolean']>>,
};


export type Video = {
   __typename?: 'Video',
  id: Scalars['VIDEO_ID'],
  type: VideoType,
  uuid?: Maybe<Scalars['UUID']>,
  url?: Maybe<Scalars['URL']>,
  description: Scalars['String'],
  previewImg?: Maybe<Scalars['URL']>,
  status: VideoStatus,
  publishedAt?: Maybe<Scalars['DateTime']>,
  versions: VideoVersionConnection,
};


export type VideoVersionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<VideoVersionOrder>
};


export type VideoCondition = {
  status?: Maybe<Array<VideoStatus>>,
};

export type VideoConnection = {
   __typename?: 'VideoConnection',
  edges: Array<VideoEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type VideoEdge = {
   __typename?: 'VideoEdge',
  node: Video,
};

export enum VideoOrder {
  PUBLISHED_AT = 'PUBLISHED_AT'
}

export enum VideoStatus {
  DRAFT = 'DRAFT',
  TESTING = 'TESTING',
  PUBLISHED = 'PUBLISHED',
  REMOVED = 'REMOVED'
}

export enum VideoType {
  TUTORIAL = 'TUTORIAL',
  PROMO = 'PROMO',
  GENERIC_INDUCTION = 'GENERIC_INDUCTION',
  TRAINER_PROFILE = 'TRAINER_PROFILE',
  TRAINER_INDUCTION = 'TRAINER_INDUCTION',
  LESSON = 'LESSON',
  TRAINING_PLAN = 'TRAINING_PLAN',
  CHALLENGE = 'CHALLENGE',
  PREROLL_STUDIO = 'PREROLL_STUDIO',
  EXERCISE = 'EXERCISE',
  HOLDING_VIDEO = 'HOLDING_VIDEO',
  TRAINING_PLAN_TEMPLATE = 'TRAINING_PLAN_TEMPLATE'
}

export type VideoVersion = {
   __typename?: 'VideoVersion',
  id: Scalars['UUID'],
  createdAt: Scalars['DateTime'],
  video: Video,
};

export type VideoVersionConnection = {
   __typename?: 'VideoVersionConnection',
  edges: Array<VideoVersionEdge>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type VideoVersionEdge = {
   __typename?: 'VideoVersionEdge',
  node: VideoVersion,
};

export enum VideoVersionOrder {
  CREATED_AT = 'CREATED_AT'
}

export type WahooCalibration = {
   __typename?: 'WahooCalibration',
  id: Scalars['Int'],
  name: Scalars['String'],
  ios?: Maybe<Scalars['String']>,
  android?: Maybe<Scalars['String']>,
};

export type WahooCalibrationConnection = {
   __typename?: 'WahooCalibrationConnection',
  edges?: Maybe<Array<WahooCalibrationEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type WahooCalibrationEdge = {
   __typename?: 'WahooCalibrationEdge',
  node: WahooCalibration,
};

export enum WahooCalibrationOrder {
  NAME = 'NAME'
}


export enum WeightDivision {
  RX = 'RX',
  SCALED = 'SCALED',
  CUSTOM = 'CUSTOM'
}

export enum WeightUnit {
  KG = 'KG',
  LB = 'LB'
}

export type Workout = {
   __typename?: 'Workout',
  id: Scalars['Int'],
  lesson: Lesson,
  lessonInstance: LessonInstance,
  user: User,
  owner?: Maybe<User>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  state: WorkoutState,
  summary?: Maybe<MetricsSummary>,
  highlights?: Maybe<Array<ActivityHistory>>,
  insights?: Maybe<Insights>,
  activities: ActivityHistoryConnection,
  position?: Maybe<Scalars['Int']>,
  heroMetric?: Maybe<MetricTypes>,
  quote?: Maybe<TrainerQuote>,
  userAge?: Maybe<Scalars['Int']>,
  userType: UserType,
  metadata?: Maybe<WorkoutMetadata>,
  review?: Maybe<WorkoutReview>,
  userTrainingPlanSchedule?: Maybe<UserTrainingPlanSchedule>,
  lessonMedia?: Maybe<LessonMedia>,
  leaderboard?: Maybe<WorkoutLeaderboardEntry>,
  leaderboardEntry?: Maybe<WorkoutLeaderboardEntry>,
  leaderboardUserNameAnon?: Maybe<Scalars['Boolean']>,
  userStreaks: UserStreakSummary,
  streaks: WorkoutStreaks,
  incompleteReason?: Maybe<WorkoutIncompleteReason>,
  incompleteExplanation?: Maybe<Scalars['String']>,
  device?: Maybe<Device>,
  deviceMetadata?: Maybe<DeviceWorkoutMetadata>,
  devices?: Maybe<Array<Device>>,
  weights?: Maybe<Array<WorkoutWeight>>,
  allDeviceMetadata?: Maybe<Array<DeviceWorkoutMetadata>>,
  privacyLevel: PrivacyLevelId,
  anonymous: Scalars['Boolean'],
  userNotes?: Maybe<Scalars['String']>,
  userChallengeBlockGoals?: Maybe<Array<UserChallengeBlockGoal>>,
  repCountingProfileVersions?: Maybe<Array<RepCountingProfileVersion>>,
  realTimeStatsUrl?: Maybe<Scalars['String']>,
  updatedByUserId?: Maybe<Scalars['Int']>,
  finishedAt?: Maybe<Scalars['DateTime']>,
  metrics?: Maybe<WorkoutMetrics>,
  rawMetrics?: Maybe<WorkoutMetrics>,
  locationCategory?: Maybe<WorkoutLocationCategory>,
  locationDetail?: Maybe<Scalars['String']>,
  heartRatePreWorkout?: Maybe<WorkoutUserHeartRate>,
  allReactions?: Maybe<Array<WorkoutReaction>>,
  reactionsSummary?: Maybe<Array<WorkoutReactionSummary>>,
  healthPlatformSynced?: Maybe<Scalars['Boolean']>,
};


export type WorkoutActivitiesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ActivityHistoryOrder>
};


export type WorkoutAllReactionsArgs = {
  first?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  condition?: Maybe<AllReactionsCondition>
};

export enum WorkoutAchievementType {
  WORKOUTS_COMPLETED = 'WORKOUTS_COMPLETED',
  FIIT_POINTS_TOTAL = 'FIIT_POINTS_TOTAL',
  FIIT_POINTS_ONCE = 'FIIT_POINTS_ONCE',
  ACTIVE_MINUTES = 'ACTIVE_MINUTES'
}

export type WorkoutConnection = {
   __typename?: 'WorkoutConnection',
  edges?: Maybe<Array<WorkoutEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type WorkoutCreatedEvent = {
   __typename?: 'WorkoutCreatedEvent',
  workout: Workout,
};

export type WorkoutCumulativeMetricSummary = {
  sum?: Maybe<Scalars['Float']>,
};

export type WorkoutEdge = {
   __typename?: 'WorkoutEdge',
  node: Workout,
};

export enum WorkoutIncompleteReason {
  TOO_HARD = 'TOO_HARD',
  TOO_EASY = 'TOO_EASY',
  JUST_BROWSING = 'JUST_BROWSING',
  NO_TIME = 'NO_TIME',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  OTHER = 'OTHER'
}

export type WorkoutIncompleteReasonInput = {
  reason: WorkoutIncompleteReason,
  explanation?: Maybe<Scalars['String']>,
};

export type WorkoutInstantaneousMetricSummary = {
  avg?: Maybe<Scalars['Float']>,
  max?: Maybe<Scalars['Float']>,
};

export type WorkoutLeaderboardEntry = {
   __typename?: 'WorkoutLeaderboardEntry',
  position: Scalars['Int'],
  value: Scalars['Int'],
  total: Scalars['Int'],
};

export enum WorkoutLocationCategory {
  HOME = 'HOME',
  GYM = 'GYM',
  OUTDOOR = 'OUTDOOR',
  OTHER = 'OTHER'
}

export type WorkoutMetadata = {
   __typename?: 'WorkoutMetadata',
  id: Scalars['Int'],
  version?: Maybe<Scalars['String']>,
  uiMode?: Maybe<UiMode>,
  tracker?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  locationContext?: Maybe<Scalars['String']>,
};

export type WorkoutMetadataInput = {
  uiMode?: Maybe<UiMode>,
  tracker?: Maybe<Scalars['String']>,
  version?: Maybe<Scalars['String']>,
  locationContext?: Maybe<Scalars['String']>,
  repCountingSchemaVersion?: Maybe<Scalars['Int']>,
  repCalculationMethod?: Maybe<RepCalculationMethod>,
};

export type WorkoutMetrics = {
   __typename?: 'WorkoutMetrics',
  bpm?: Maybe<Array<InstantaneousWorkoutMetric>>,
  score?: Maybe<Array<CumulativeWorkoutMetric>>,
  totalScore?: Maybe<Array<CumulativeWorkoutMetric>>,
  kcal?: Maybe<Array<CumulativeWorkoutMetric>>,
  reps?: Maybe<Array<CumulativeWorkoutMetric>>,
  workRate?: Maybe<Array<CumulativeWorkoutMetric>>,
  bikeKcal?: Maybe<Array<CumulativeWorkoutMetric>>,
  bikeTotalScore?: Maybe<Array<CumulativeWorkoutMetric>>,
  bikeDistance?: Maybe<Array<CumulativeWorkoutMetric>>,
  bikeRpm?: Maybe<Array<InstantaneousWorkoutMetric>>,
  bikeSpeed?: Maybe<Array<InstantaneousWorkoutMetric>>,
  bikeWatts?: Maybe<Array<InstantaneousWorkoutMetric>>,
  treadKcal?: Maybe<Array<CumulativeWorkoutMetric>>,
  treadTotalScore?: Maybe<Array<CumulativeWorkoutMetric>>,
  treadSpeed?: Maybe<Array<InstantaneousWorkoutMetric>>,
  treadRpm?: Maybe<Array<InstantaneousWorkoutMetric>>,
  treadWatts?: Maybe<Array<InstantaneousWorkoutMetric>>,
  treadDistance?: Maybe<Array<CumulativeWorkoutMetric>>,
  rowKcal?: Maybe<Array<CumulativeWorkoutMetric>>,
  rowTotalScore?: Maybe<Array<CumulativeWorkoutMetric>>,
  rowDistance?: Maybe<Array<CumulativeWorkoutMetric>>,
  rowSpeed?: Maybe<Array<InstantaneousWorkoutMetric>>,
  rowWatts?: Maybe<Array<InstantaneousWorkoutMetric>>,
  rowResistance?: Maybe<Array<InstantaneousWorkoutMetric>>,
  rowSpm?: Maybe<Array<InstantaneousWorkoutMetric>>,
  spinKcal?: Maybe<Array<CumulativeWorkoutMetric>>,
  spinTotalScore?: Maybe<Array<CumulativeWorkoutMetric>>,
  spinDistance?: Maybe<Array<CumulativeWorkoutMetric>>,
  spinRpm?: Maybe<Array<InstantaneousWorkoutMetric>>,
  spinSpeed?: Maybe<Array<InstantaneousWorkoutMetric>>,
  spinWatts?: Maybe<Array<InstantaneousWorkoutMetric>>,
  spinResistance?: Maybe<Array<InstantaneousWorkoutMetric>>,
};

export enum WorkoutOrder {
  CREATED_AT = 'CREATED_AT',
  HERO_VALUE = 'HERO_VALUE',
  DAILY_STREAK = 'DAILY_STREAK',
  WEEKLY_STREAK = 'WEEKLY_STREAK',
  LEADERBOARD_POSITION = 'LEADERBOARD_POSITION'
}

export enum WorkoutOutcome {
  CANCELLED = 'CANCELLED',
  QUIT = 'QUIT',
  COMPLETED = 'COMPLETED'
}

export type WorkoutReaction = {
   __typename?: 'WorkoutReaction',
  id: Scalars['WorkoutReactionId'],
  reaction: Scalars['SingleEmoji'],
  workout: Workout,
  user: User,
};

export type WorkoutReactionConnection = {
   __typename?: 'WorkoutReactionConnection',
  edges?: Maybe<Array<WorkoutReactionEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type WorkoutReactionEdge = {
   __typename?: 'WorkoutReactionEdge',
  node: WorkoutReaction,
};


export enum WorkoutReactionOrder {
  CREATED_AT = 'CREATED_AT'
}

export type WorkoutReactionSummary = {
   __typename?: 'WorkoutReactionSummary',
  reaction: Scalars['SingleEmoji'],
  count: Scalars['Int'],
  users?: Maybe<Array<User>>,
};

export type WorkoutReview = {
   __typename?: 'WorkoutReview',
  workout: Workout,
  overall: Scalars['Int'],
  trainer?: Maybe<Scalars['Int']>,
  difficulty?: Maybe<Scalars['Int']>,
  music?: Maybe<Scalars['Int']>,
  trackingAccuracy?: Maybe<Scalars['Int']>,
  general?: Maybe<Scalars['String']>,
  trainerAudioMusic?: Maybe<Scalars['Int']>,
};

export type WorkoutReviewCondition = {
  lessonId?: Maybe<Array<Scalars['Int']>>,
  trainerId?: Maybe<Array<Scalars['Int']>>,
};

export type WorkoutReviewConnection = {
   __typename?: 'WorkoutReviewConnection',
  edges?: Maybe<Array<WorkoutReviewEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type WorkoutReviewEdge = {
   __typename?: 'WorkoutReviewEdge',
  node: WorkoutReview,
};

export enum WorkoutReviewOrder {
  CREATED_AT = 'CREATED_AT'
}

export enum WorkoutState {
  STARTED = 'STARTED',
  CANCELLED = 'CANCELLED',
  QUIT = 'QUIT',
  COMPLETED = 'COMPLETED'
}

export type WorkoutStatsChunkInput = {
  chunk: Scalars['Int'],
  timestamp: Scalars['DateTime'],
  videoElapsedSeconds: Scalars['Seconds'],
  metrics: WorkoutStatsMetrics,
};

export type WorkoutStatsEvent = {
   __typename?: 'WorkoutStatsEvent',
  usr: Scalars['Int'],
  wrk: Scalars['Int'],
  lsi: Scalars['String'],
  prt?: Maybe<Scalars['String']>,
  ct?: Maybe<Scalars['String']>,
  vt: Scalars['Float'],
  bpm?: Maybe<Scalars['Float']>,
  kc?: Maybe<Scalars['Float']>,
  pt?: Maybe<Scalars['Float']>,
  ar?: Maybe<Scalars['Float']>,
  tr?: Maybe<Scalars['Float']>,
  iar?: Maybe<Scalars['Float']>,
  itr?: Maybe<Scalars['Float']>,
  brp?: Maybe<Scalars['Float']>,
  bwa?: Maybe<Scalars['Float']>,
  bkc?: Maybe<Scalars['Float']>,
  bts?: Maybe<Scalars['Float']>,
  bsp?: Maybe<Scalars['Float']>,
  trp?: Maybe<Scalars['Float']>,
  twa?: Maybe<Scalars['Float']>,
  tkc?: Maybe<Scalars['Float']>,
  tts?: Maybe<Scalars['Float']>,
  tsp?: Maybe<Scalars['Float']>,
};

export type WorkoutStatsMetrics = {
  time: Array<Maybe<Array<Scalars['Seconds']>>>,
  bpm?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  kcal?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  reps?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  internalReps?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  score?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  totalScore?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rawBpm?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  workRate?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  motion?: Maybe<Array<MotionMetricByTime>>,
  bikeKcal?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  bikeRpm?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  bikeSpeed?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  bikeWatts?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  bikeTotalScore?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  bikeDistance?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  treadKcal?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  treadSpeed?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  treadTotalScore?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  treadWatts?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  treadRpm?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  treadDistance?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowKcal?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowSpeed?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowWatts?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowResistance?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowSpm?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowTotalScore?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  rowDistance?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinKcal?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinRpm?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinSpeed?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinWatts?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinTotalScore?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinDistance?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
  spinResistance?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>,
};

export type WorkoutStreaks = {
   __typename?: 'WorkoutStreaks',
  daily?: Maybe<Streak>,
  weekly?: Maybe<Streak>,
};

export enum WorkoutSummaryMetricTypes {
  BPM = 'BPM',
  KCAL = 'KCAL',
  SCORE = 'SCORE',
  TOTAL_SCORE = 'TOTAL_SCORE',
  TIME = 'TIME',
  REPS = 'REPS',
  WORK_RATE = 'WORK_RATE',
  BIKE_KCAL = 'BIKE_KCAL',
  BIKE_RPM = 'BIKE_RPM',
  BIKE_TOTAL_SCORE = 'BIKE_TOTAL_SCORE',
  BIKE_DISTANCE = 'BIKE_DISTANCE',
  TREAD_KCAL = 'TREAD_KCAL',
  TREAD_SPEED = 'TREAD_SPEED',
  TREAD_TOTAL_SCORE = 'TREAD_TOTAL_SCORE',
  TREAD_DISTANCE = 'TREAD_DISTANCE',
  ROW_KCAL = 'ROW_KCAL',
  ROW_TOTAL_SCORE = 'ROW_TOTAL_SCORE',
  ROW_DISTANCE = 'ROW_DISTANCE',
  ROW_SPEED = 'ROW_SPEED',
  SPIN_KCAL = 'SPIN_KCAL',
  SPIN_RPM = 'SPIN_RPM',
  SPIN_TOTAL_SCORE = 'SPIN_TOTAL_SCORE',
  SPIN_DISTANCE = 'SPIN_DISTANCE',
  SPIN_SPEED = 'SPIN_SPEED'
}

export type WorkoutUpdatedEvent = {
   __typename?: 'WorkoutUpdatedEvent',
  workout: Workout,
  prev: PrevWorkoutEvent,
};

export type WorkoutUserHeartRate = {
   __typename?: 'WorkoutUserHeartRate',
  max?: Maybe<Scalars['GraphQLHeartRate']>,
  min?: Maybe<Scalars['GraphQLHeartRate']>,
  zones?: Maybe<Array<WorkoutUserHeartRateZones>>,
};

export type WorkoutUserHeartRateZones = {
   __typename?: 'WorkoutUserHeartRateZones',
  startHr: Scalars['Int'],
  percentile: Scalars['Int'],
};

export type WorkoutWeight = {
   __typename?: 'WorkoutWeight',
  workout: Workout,
  usedWeight: Scalars['Float'],
  weightUnit: WeightUnit,
  weightDivision: WeightDivision,
  equipmentLevel: EquipmentLevel,
};

export type WorkoutWeightConnection = {
   __typename?: 'WorkoutWeightConnection',
  edges?: Maybe<Array<WorkoutWeightEdge>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type WorkoutWeightEdge = {
   __typename?: 'WorkoutWeightEdge',
  node?: Maybe<WorkoutWeight>,
};

export type WorkoutWeightInput = {
  usedWeight?: Maybe<Scalars['Float']>,
  weightUnit?: Maybe<WeightUnit>,
  weightDivision?: Maybe<WeightDivision>,
  equipmentLevelId?: Maybe<Scalars['EquipmentLevelId']>,
};

export type WorkRateMetricConfig = {
   __typename?: 'WorkRateMetricConfig',
  track: Scalars['Boolean'],
  totalActivity: CumulativeMetricShowState,
  totalWorkout: CumulativeMetricShowState,
};

export type ZoneBoundary = {
   __typename?: 'ZoneBoundary',
  start: Scalars['Int'],
  value: Scalars['Float'],
};

export type ZoneConfig = {
   __typename?: 'ZoneConfig',
  periodSeconds: Scalars['Seconds'],
  boundaries: Array<Maybe<ZoneBoundary>>,
};

export type CreateInstallationMutationVariables = {
  platform: InstallationPlatform,
  userAgent?: Maybe<Scalars['String']>
};


export type CreateInstallationMutation = (
  { __typename?: 'Mutation' }
  & { createInstallation: (
    { __typename?: 'CreateInstallationResponse' }
    & { installation: (
      { __typename?: 'Installation' }
      & Pick<Installation, 'id' | 'platform' | 'userAgent'>
    ) }
  ) }
);

export type PrepareStreamMutationVariables = {
  input: PrepareStreamInput
};


export type PrepareStreamMutation = (
  { __typename?: 'Mutation' }
  & { prepareStream: (
    { __typename?: 'PrepareStreamResponse' }
    & Pick<PrepareStreamResponse, 'signedCookie'>
  ) }
);

export type StartWorkoutMutationVariables = {
  userId: Scalars['Int'],
  lessonMediaId: Scalars['LessonMediaId'],
  lessonInstanceId?: Maybe<Scalars['LessonInstanceId']>,
  scheduleId?: Maybe<Scalars['Int']>
};


export type StartWorkoutMutation = (
  { __typename?: 'Mutation' }
  & { startStreamableWorkout: (
    { __typename?: 'StartWorkoutResponse' }
    & { workout: (
      { __typename?: 'Workout' }
      & Pick<Workout, 'id'>
      & { lessonInstance: (
        { __typename?: 'LessonInstance' }
        & Pick<LessonInstance, 'id'>
      ) }
    ) }
  ) }
);

export type UpdatePlayStateMutationVariables = {
  lessonInstanceId: Scalars['LessonInstanceId'],
  playState?: Maybe<LessonInstancePlayState>
};


export type UpdatePlayStateMutation = (
  { __typename?: 'Mutation' }
  & { patchLessonInstanceById: (
    { __typename?: 'LessonInstance' }
    & Pick<LessonInstance, 'id'>
  ) }
);


      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "PlanActivity",
        "possibleTypes": [
          {
            "name": "Set"
          },
          {
            "name": "Transition"
          },
          {
            "name": "PlanCircuit"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "TimelineActivity",
        "possibleTypes": [
          {
            "name": "Set"
          },
          {
            "name": "Transition"
          },
          {
            "name": "TimelineCircuit"
          }
        ]
      }
    ]
  }
};
      export default result;
    