const FONT_URL = 'https://fiit.tv/static/fonts/';

// TODO: remove unused fonts cause we deffo don't need all of these
export default `
  @font-face {
    font-family: "Gilroy";
    font-weight: 100;
    src: url("${FONT_URL}Gilroy-Thin.eot");
    src: url("${FONT_URL}Gilroy-Thin.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-Thin.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-Thin.woff") format("woff"),
         url("${FONT_URL}Gilroy-Thin.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 100;
    src: url("${FONT_URL}Gilroy-ThinItalic.eot");
    src: url("${FONT_URL}Gilroy-ThinItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-ThinItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-ThinItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-ThinItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 200;
    src: url("${FONT_URL}Gilroy-UltraLight.eot");
    src: url("${FONT_URL}Gilroy-UltraLight.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-UltraLight.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-UltraLight.woff") format("woff"),
         url("${FONT_URL}Gilroy-UltraLight.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 200;
    src: url("${FONT_URL}Gilroy-UltraLightItalic.eot");
    src: url("${FONT_URL}Gilroy-UltraLightItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-UltraLightItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-UltraLightItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-UltraLightItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 300;
    src: url("${FONT_URL}Gilroy-Light.eot");
    src: url("${FONT_URL}Gilroy-Light.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-Light.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-Light.woff") format("woff"),
         url("${FONT_URL}Gilroy-Light.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 300;
    src: url("${FONT_URL}Gilroy-LightItalic.eot");
    src: url("${FONT_URL}Gilroy-LightItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-LightItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-LightItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-LightItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 400;
    src: url("${FONT_URL}Gilroy-Regular.eot");
    src: url("${FONT_URL}Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-Regular.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-Regular.woff") format("woff"),
         url("${FONT_URL}Gilroy-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 400;
    src: url("${FONT_URL}Gilroy-RegularItalic.eot");
    src: url("${FONT_URL}Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-RegularItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-RegularItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-RegularItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 500;
    src: url("${FONT_URL}Gilroy-Medium.eot");
    src: url("${FONT_URL}Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-Medium.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-Medium.woff") format("woff"),
         url("${FONT_URL}Gilroy-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 500;
    src: url("${FONT_URL}Gilroy-MediumItalic.eot");
    src: url("${FONT_URL}Gilroy-MediumItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-MediumItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-MediumItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-MediumItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 600;
    src: url("${FONT_URL}Gilroy-SemiBold.eot");
    src: url("${FONT_URL}Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-SemiBold.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-SemiBold.woff") format("woff"),
         url("${FONT_URL}Gilroy-SemiBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 600;
    src: url("${FONT_URL}Gilroy-SemiBoldItalic.eot");
    src: url("${FONT_URL}Gilroy-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-SemiBoldItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-SemiBoldItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-SemiBoldItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 700;
    src: url("${FONT_URL}Gilroy-Bold.eot");
    src: url("${FONT_URL}Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-Bold.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-Bold.woff") format("woff"),
         url("${FONT_URL}Gilroy-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 700;
    src: url("${FONT_URL}Gilroy-BoldItalic.eot");
    src: url("${FONT_URL}Gilroy-BoldItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-BoldItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-BoldItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-BoldItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 800;
    src: url("${FONT_URL}Gilroy-ExtraBold.eot");
    src: url("${FONT_URL}Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-ExtraBold.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-ExtraBold.woff") format("woff"),
         url("${FONT_URL}Gilroy-ExtraBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 800;
    src: url("${FONT_URL}Gilroy-ExtraBoldItalic.eot");
    src: url("${FONT_URL}Gilroy-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-ExtraBoldItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-ExtraBoldItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-ExtraBoldItalic.ttf") format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 900;
    src: url("${FONT_URL}Gilroy-Heavy.eot");
    src: url("${FONT_URL}Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-Heavy.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-Heavy.woff") format("woff"),
         url("${FONT_URL}Gilroy-Heavy.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: 900;
    src: url("${FONT_URL}Gilroy-HeavyItalic.eot");
    src: url("${FONT_URL}Gilroy-HeavyItalic.eot?#iefix") format("embedded-opentype"),
         url("${FONT_URL}Gilroy-HeavyItalic.woff2") format("woff2"),
         url("${FONT_URL}Gilroy-HeavyItalic.woff") format("woff"),
         url("${FONT_URL}Gilroy-HeavyItalic.ttf") format("truetype");
    font-style: italic;
  }
`;
