import React, { ReactChild } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import defaultTheme from 'ui/theme';

type Props = {
  children: ReactChild,
};

// To allow a consumer of component library to extend the default theme, accept an argument of theme,
// and merge with default theme

const ThemeProvider = ({ children }: Props) => (
  <StyledThemeProvider theme={defaultTheme}>
    { children }
  </StyledThemeProvider>
);

export default ThemeProvider;
