import React from 'react';
import styled, { useTheme } from 'styled-components';
import useConfig from 'app/on-tv/config-provider';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 0.6rem;
  height: 0.6rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgBpm = ({ className = '', color = 'red' }: IconProps) => {
  const { config } = useConfig();
  const isFireTV = config.APP_TYPE === 'amazon';
  const theme = useTheme();
  const fillColor = theme.colors[color];

  // Amazon TV Fire OS 7.2.7.3 (PS7273/2625) caused SVG icons to render black video screen. PNG works
  if (isFireTV) {
    return (
      <Svg className={className} viewBox="0 0 16 16">
        <image href="https://images-bucket.prod.fiit-static.net/app/uploads/2022/04/14140658/HR.png" height="16" width="16" />
      </Svg>
    );
  }

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <defs>
        <path
          id="bpm_svg__a"
          d="M7.986 14.667l-6.83-6.699A3.876 3.876 0 010 5.218c0-1.043.41-2.02 1.156-2.751A4.032 4.032 0 013.96 1.333c1.063 0 2.06.403 2.805 1.134l1.223 1.221 1.245-1.221a3.977 3.977 0 012.805-1.134c1.064 0 2.06.403 2.805 1.134A3.876 3.876 0 0116 5.217c0 1.026-.42 2.002-1.185 2.751l-6.83 6.699z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={theme.colors.white}
          fillOpacity={0.005}
          fillRule="nonzero"
          d="M0 0h16v16H0z"
          style={{
            mixBlendMode: 'screen',
          }}
        />
        <mask id="bpm_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#bpm_svg__a" />
        </mask>
        <use fill={theme.colors.black} xlinkHref="#bpm_svg__a" />
        <g fill={fillColor} mask="url(#bpm_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgBpm;
