import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { rem } from 'ui/helpers';

import Logo from 'ui/components/atoms/logo';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
`;

const Column = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.xs} 0.4rem;
  position: relative;
`;

const LogoContainer = styled.div`
  background-color: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const BackButton = styled(Button)`
  position: absolute;
  left: ${rem(50)};
  bottom: ${rem(50)};
  z-index: 1;
`;

const Hold = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <Column>
        <LogoContainer>
          <Logo color="white" height="10rem" />
        </LogoContainer>
        <BackButton
          onClick={() => history.goBack()}
          labelPosition="right"
          icon={<ChevronLeft />}
          iconOnly
        />
      </Column>
    </Wrapper>
  );
};

export default Hold;
