import React, { useState, ChangeEvent, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { endOfDay, format } from 'date-fns';

import { useMutation } from '@apollo/react-hooks';
import NotificationPopup, { NotificationPopupType } from 'ui/components/atoms/notification-popup';
import { useNotificationPopup } from 'ui/components/atoms/notification-popup/hooks';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import FullPageModal from 'ui/components/layouts/full-page-modal';
import Typography from 'ui/components/atoms/typography';
import RadioField from 'ui/components/atoms/radio-field';
import LiveIcon from 'ui/components/atoms/icons/live';
import VirtualIcon from 'ui/components/atoms/icons/virtual';
import CrossIcon from 'ui/components/atoms/icons/cross';
import Button from 'ui/components/atoms/button';
import Spinner from 'ui/components/atoms/animated-spinner';
import DateTimePicker from 'ui/components/molecules/date-time-picker';
import CheckboxField from 'ui/components/atoms/checkbox-field';

import {
  GroupClassType,
  LessonInstancePlaybackType,
  LeaderboardDisplayType,
  SchedulePeriod,
  CancelScheduleMutation,
  CancelScheduleMutationVariables,
  PatchLessonInstanceMutation,
  PatchLessonInstanceMutationVariables,
  CancelLessonInstanceMutation,
  CancelLessonInstanceMutationVariables,
  UpdateScheduleMutation,
  UpdateScheduleMutationVariables,
} from 'app/in-studio/types/graphql';

import { UpdateSchedule as UPDATE_SCHEDULE_MUTATION } from 'app/in-studio/pages/lesson/graphql/update-schedule.gql';
import { CancelSchedule as CANCEL_SCHEDULE_MUTATION } from 'app/in-studio/pages/lesson/graphql/cancel-schedule.gql';
import { PatchLessonInstance as UPDATE_INSTANCE_MUTATION } from 'app/in-studio/pages/lesson/graphql/update-lesson.gql';
import { CancelLessonInstance as CANCEL_INSTANCE_MUTATION } from 'app/in-studio/pages/lesson/graphql/cancel-lesson.gql';

import {
  ExitButtonWrapper,
  ContentWrapper,
  RadioWrapper,
  ActionButton,
  Title,
  ToggleOptionsArea,
  RepeatScheduleArea,
  RepeatScheduleRow,
  DisabledSchedulePeriodSelector,
  ScheduleInformationArea,
  GreyedOutInformationArea,
  ButtonArea,
} from 'app/in-studio/pages/lesson/schedule-modals/styled-components';

type Props = {
  onDismiss: () => void,
  lessonInstance: {
    id: string,
    leaderboardDisplayType?: LeaderboardDisplayType | null,
    playbackType: LessonInstancePlaybackType,
    groupClassType: GroupClassType,
    workoutStartTime?: string | null,
  },
  schedule?: {
    id: string,
    schedulePeriod: SchedulePeriod,
    startsAt: string,
    endsAt: string,
  } | null,
  supportsLive: boolean,
};

type SchedulePeriodOption = {
  value: string,
  label: string,
};

type OnCompletedOnError = {
  onCompleted: () => void,
  onError: () => void,
};

const UpdateClassesModal = ({
  onDismiss,
  supportsLive,
  lessonInstance: {
    id: lessonInstanceId,
    playbackType,
    groupClassType,
    workoutStartTime,
    leaderboardDisplayType,
  },
  schedule,
}: Props) => {
  const hasSchedule = !!schedule;
  const { id: scheduleId, startsAt: currentStartsAt, endsAt: currentEndsAt, schedulePeriod } = schedule || {};

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isUpdateTriggered, setIsUpdateTriggered] = useState(false);
  const [isUpdateConfirmed, setIsUpdateConfirmed] = useState(false);

  const [updateWholeSchedule, setUpdateWholeSchedule] = useState<boolean>(hasSchedule);
  const [isCancelTriggered, setIsCancelTriggered] = useState(false);
  const [isCancelConfirmed, setIsCancelConfirmed] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const [isPublic, setIsPublic] = useState(groupClassType === GroupClassType.PUBLIC_USER_OWNED);
  const [isLeaderboardOn, setIsLeaderboardOn] = useState(leaderboardDisplayType !== LeaderboardDisplayType.NONE);
  const [selectedPlaybackType, setPlaybackType] = useState(
    supportsLive ? LessonInstancePlaybackType[playbackType] : LessonInstancePlaybackType.VIRTUAL,
  );

  const [startsAt, setStartsAt] = useState<Date>(
    hasSchedule && currentStartsAt
      ? new Date(currentStartsAt)
      : new Date(workoutStartTime || Date.now()),
  );
  const [endsAt, setEndsAt] = useState<Date>(
    hasSchedule && currentEndsAt
      ? moment(currentEndsAt).toDate()
      : new Date(),
  );
  const [repeatingPeriod] = useState<SchedulePeriodOption>(
    hasSchedule && schedulePeriod
      ? { value: schedulePeriod, label: schedulePeriod.toLowerCase().replace('_', ' ') }
      : { value: 'never', label: 'never' },
  );

  const [notificationMessage, setNotificationMessage] = useState('');
  const { showNotification, promptNotification } = useNotificationPopup();

  useDismissEvent(useCallback(() => {
    onDismiss();
  }, [onDismiss]), true);

  const getCancelOnCompletedOnError = (): OnCompletedOnError => ({
    onCompleted: () => {
      setIsCancelLoading(false);
      setIsCancelTriggered(false);
      setIsCancelConfirmed(true);
    },
    onError: () => {
      setIsCancelLoading(false);
      setNotificationMessage('There has been a problem while cancelling the classes.');
      promptNotification();
    },
  });

  const [cancelSchedule] = useMutation<CancelScheduleMutation, CancelScheduleMutationVariables>(
    CANCEL_SCHEDULE_MUTATION,
    getCancelOnCompletedOnError(),
  );

  const [cancelLessonInstance] = useMutation<CancelLessonInstanceMutation, CancelLessonInstanceMutationVariables>(
    CANCEL_INSTANCE_MUTATION,
    getCancelOnCompletedOnError(),
  );

  const getUpdateOnCompletedOnError = (): OnCompletedOnError => ({
    onCompleted: () => {
      setIsUpdateLoading(false);
      setIsUpdateTriggered(false);
      setIsUpdateConfirmed(true);
    },
    onError: () => {
      setIsUpdateLoading(false);
      setNotificationMessage('There has been a problem while updating the classes.');
      promptNotification();
    },
  });

  const [updateLessonInstance] = useMutation<PatchLessonInstanceMutation, PatchLessonInstanceMutationVariables>(
    UPDATE_INSTANCE_MUTATION,
    getUpdateOnCompletedOnError(),
  );

  const [updateSchedule] = useMutation<UpdateScheduleMutation, UpdateScheduleMutationVariables>(
    UPDATE_SCHEDULE_MUTATION,
    getUpdateOnCompletedOnError(),
  );

  const onCancel = async () => {
    setIsCancelTriggered(true);
  };

  const onCancelConfirmed = async () => {
    setIsCancelLoading(true);

    if (updateWholeSchedule) {
      cancelSchedule({
        variables: {
          lessonInstanceScheduleId: scheduleId,
        },
      });
    } else {
      cancelLessonInstance({
        variables: { id: lessonInstanceId },
      });
    }
  };

  const onUpdate = async () => {
    setIsUpdateTriggered(true);
  };

  const onUpdateConfirmed = async () => {
    setIsUpdateLoading(true);

    if (updateWholeSchedule) {
      updateSchedule({
        variables: {
          id: scheduleId,
          input: {
            ...(
              moment.utc(currentStartsAt).isSame(moment.utc(startsAt))
                ? {} : { workoutStartTime: moment.utc(startsAt).toISOString() }
            ),
            ...(
              moment.utc(currentEndsAt).isSame(moment.utc(endsAt))
                ? {} : { endsAt: moment.utc(endsAt).toISOString() }
            ),
            ...(
              selectedPlaybackType === playbackType
                ? {} : { playbackType: selectedPlaybackType }
            ),
          },
        },
      });
    } else {
      updateLessonInstance({
        variables: {
          id: lessonInstanceId,
          input: {
            workoutStartTime: moment.utc(startsAt).toISOString(),
            ...(
              selectedPlaybackType === playbackType
                ? {} : { playbackType: selectedPlaybackType }
            ),
          },
        },
      });
    }
  };

  const onRadioSelected = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setPlaybackType(value === 'live' ? LessonInstancePlaybackType.LIVE : LessonInstancePlaybackType.VIRTUAL);
  };

  const onScheduleRadioSelected = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setUpdateWholeSchedule(value === 'cancel-schedule');
  };

  const reloadTimetable = () => {
    window.location.reload();
  };

  const getModalContent = () => {
    if (isCancelConfirmed) {
      return (
        <>
          <Title weight="bold" variant="double-pica">Success!</Title>
          <Typography>
            { updateWholeSchedule
              ? 'The schedule of classes has been deleted'
              : 'The class has been deleted' }
          </Typography>
          <ButtonArea>
            <ActionButton
              onClick={() => reloadTimetable()}
              label="See updated classes"
              variant="cta"
              autofocus
            />
          </ButtonArea>
        </>
      );
    }

    if (isCancelTriggered) {
      return (
        <>
          <Title weight="bold" variant="double-pica">Delete classes</Title>
          <RadioWrapper>
            <RadioField
              name="cancel-class"
              label="This class"
              value="cancel-class"
              onChange={(event: ChangeEvent<HTMLInputElement>) => onScheduleRadioSelected(event)}
              bold={false}
              checked={!updateWholeSchedule}
            />
            {
              hasSchedule && (
                <RadioField
                  name="cancel-schedule"
                  label="This and all recurring classes"
                  value="cancel-schedule"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onScheduleRadioSelected(event)}
                  bold={false}
                  checked={updateWholeSchedule}
                />
              )
            }
          </RadioWrapper>
          <ButtonArea>
            <ActionButton
              onClick={() => setIsCancelTriggered(false)}
              label="Cancel"
              variant="cta"
            />
            <ActionButton
              onClick={() => onCancelConfirmed()}
              label="OK"
              variant="cta"
              icon={isCancelLoading && <Spinner />}
            />
          </ButtonArea>
        </>
      );
    }

    if (isUpdateConfirmed) {
      return (
        <>
          <Title weight="bold" variant="double-pica">Success!</Title>
          { hasSchedule ? (
            <>
              <Typography>Schedule of classes has been updated</Typography>
              <ScheduleInformationArea>
                <Typography weight="bold">{format(startsAt, 'dd MMM yyyy, HH:mm')}</Typography>
                <Typography weight="bold">{selectedPlaybackType}</Typography>
              </ScheduleInformationArea>
              <GreyedOutInformationArea>
                <Typography>Repeats {repeatingPeriod.label}</Typography>
                <Typography>Ends {format(endsAt, 'dd MMM yyyy, HH:mm')}</Typography>
              </GreyedOutInformationArea>
            </>
          ) : (
            <Typography>Classes has been updated</Typography>
          )}
          <ButtonArea>
            <ActionButton
              onClick={() => reloadTimetable()}
              label="See updated classes"
              variant="cta"
              autofocus
            />
          </ButtonArea>
        </>
      );
    }

    if (isUpdateTriggered) {
      return (
        <>
          <Title weight="bold" variant="double-pica">Update classes</Title>
          <RadioWrapper>
            <RadioField
              name="cancel-class"
              label="This class"
              value="cancel-class"
              onChange={(event: ChangeEvent<HTMLInputElement>) => onScheduleRadioSelected(event)}
              bold={false}
              checked={!updateWholeSchedule}
            />
            {
              hasSchedule && (
                <RadioField
                  name="cancel-schedule"
                  label="This and all recurring classes"
                  value="cancel-schedule"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onScheduleRadioSelected(event)}
                  bold={false}
                  checked={updateWholeSchedule}
                />
              )
            }
          </RadioWrapper>
          <ButtonArea>
            <ActionButton
              onClick={() => setIsUpdateTriggered(false)}
              label="Cancel"
              variant="cta"
            />
            <ActionButton
              onClick={() => onUpdateConfirmed()}
              label="OK"
              variant="cta"
              icon={isUpdateLoading && <Spinner />}
            />
          </ButtonArea>
        </>
      );
    }

    return (
      <>
        <Title weight="bold" variant="double-pica">{hasSchedule ? 'Update a class schedule' : 'Update a class'}</Title>
        <RepeatScheduleArea>
          <RepeatScheduleRow>
            <DateTimePicker
              value={startsAt}
              initialValue={startsAt}
              onChange={(date: Moment): void => setStartsAt(date.toDate() || new Date())}
            />
            { hasSchedule && (
              <DisabledSchedulePeriodSelector
                classNamePrefix="Select"
                value={repeatingPeriod || ''}
                placeholder={repeatingPeriod}
                isSearchable={false}
                options={[repeatingPeriod]}
                isDisabled
              />
            )}
          </RepeatScheduleRow>
          { hasSchedule && (
            <RepeatScheduleRow>
              <Typography>Repeat until:&nbsp;</Typography>
              <DateTimePicker
                value={endsAt}
                initialValue={endsAt}
                onChange={(date: Moment): void => setEndsAt(endOfDay(date.toDate()) || endOfDay(new Date()))}
              />
            </RepeatScheduleRow>
          )}
        </RepeatScheduleArea>
        <RadioWrapper>
          { supportsLive && (
            <RadioField
              name="playback-type"
              label="Live"
              value="live"
              description="In-person class, lead by trainer at the gym"
              checked={selectedPlaybackType === LessonInstancePlaybackType.LIVE}
              icon={<LiveIcon />}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onRadioSelected(event)}
            />
          )}
          <RadioField
            name="playback-type"
            label="Virtual"
            value="virtual"
            description="Show pre-recorded class on screen"
            checked={selectedPlaybackType === LessonInstancePlaybackType.VIRTUAL}
            icon={<VirtualIcon />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onRadioSelected(event)}
          />
        </RadioWrapper>
        <ToggleOptionsArea>
          <CheckboxField
            label="Make class public"
            onChange={(checked) => setIsPublic(checked)}
            checked={isPublic}
            disabled
          />
          <CheckboxField
            label="Show the leaderboard"
            onChange={(checked) => setIsLeaderboardOn(checked)}
            checked={isLeaderboardOn}
            disabled
          />
        </ToggleOptionsArea>
        <ButtonArea>
          <ActionButton
            onClick={() => onCancel()}
            label="Delete"
            variant="cta"
          />
          <ActionButton
            onClick={() => onUpdate()}
            label="Update"
            variant="cta"
          />
        </ButtonArea>
      </>
    );
  };

  return (
    <FullPageModal backgroundColour="fordDarkCharcoal">
      { showNotification && (
        <NotificationPopup
          type={NotificationPopupType.ERROR}
          message={notificationMessage}
        />
      )}
      <ContentWrapper>
        <ExitButtonWrapper>
          <Button iconOnly icon={<CrossIcon />} onClick={() => onDismiss()} />
        </ExitButtonWrapper>
        {
          getModalContent()
        }
      </ContentWrapper>
    </FullPageModal>
  );
};

export default UpdateClassesModal;
