import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 0.8rem;
  height: 0.8rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgScale = ({ className = '', color = 'beckersKnop' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} viewBox="0 0 24 24">
      <defs>
        <path
          id="scale_svg__a"
          d="M7.186 2.042l7.686 16.188-.007.047a2 2 0 11-3.847.993L11 19.259 7.913 5.327l-.727-3.285zM5.812 5.716l.673 1.941A9.99 9.99 0 002 16H0c0-4.364 2.33-8.183 5.812-10.284zM12 4c6.627 0 12 5.373 12 12h-2c0-5.523-4.477-10-10-10-.266 0-.529.01-.789.03l-.672-1.942C11.018 4.03 11.505 4 12 4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="scale_svg__b" fill={fill}>
          <use xlinkHref="#scale_svg__a" />
        </mask>
        <use fill={fill} opacity={0.649} xlinkHref="#scale_svg__a" />
        <g fill={fill} mask="url(#scale_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgScale;
