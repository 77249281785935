import { ComponentClass } from 'react';

import LandingPage from 'app/in-studio/pages/landing';
import LessonInstancePage from 'app/in-studio/pages/lesson-instance';
import LoginPage from 'app/pages/login-password';
import SchedulePage from 'app/in-studio/pages/schedule';
import UpcomingPage from 'app/in-studio/pages/upcoming';
import FilterPage from 'app/in-studio/pages/filter';
import LessonsPage from 'app/in-studio/pages/lessons';
import LessonPage from 'app/in-studio/pages/lesson';
import UnrecognisedPartnerPage from 'app/in-studio/pages/unrecognised-partner';
import ProfilePage from 'app/in-studio/pages/profile';
import LegalPage from 'app/in-studio/pages/legal';
import StatsPage from 'app/in-studio/pages/stats';
import PreLobbyPage from 'app/in-studio/pages/pre-lobby';

import { RoutePermissions } from 'app/in-studio/utils/route-permissions';

const { LIVE_STUDIO_CLASS, SCHEDULER, EXERCISE_LIBRARY } = RoutePermissions;

export type RouteConfig = {
  acceptedPaths: string[],
  component: ((args: any) => (JSX.Element) | null) | ComponentClass<any>;
  exact: boolean,
  // Does it show the menu?
  menu?: boolean,
  // Is the route accessible without authentication?
  unauthenticated: boolean[],
  // What permissions are required for this route?
  requiredPermissions: RoutePermissions[],
  title?: string,
};

const routes = {
  LOGIN: {
    path: 'LOGIN',
    acceptedPaths: ['/login'],
    component: LoginPage,
    exact: true,
    menu: false,
    unauthenticated: [true],
    requiredPermissions: [],
    title: 'login',
  },
  BROWSE: {
    path: 'BROWSE',
    acceptedPaths: ['/'],
    component: LandingPage,
    exact: true,
    menu: true,
    unauthenticated: [false],
    requiredPermissions: [],
    title: 'home',
  },
  UNRECOGNISED_PLATFORM: {
    path: 'UNRECOGNISED_PLATFORM',
    acceptedPaths: ['/platform-not-found'],
    component: UnrecognisedPartnerPage,
    exact: true,
    menu: true,
    unauthenticated: [true, false],
    requiredPermissions: [],
    title: 'unrecognised',
  },
  FILTER: {
    path: 'FILTER',
    acceptedPaths: ['/filter'],
    component: FilterPage,
    exact: true,
    menu: true,
    unauthenticated: [false],
    requiredPermissions: [EXERCISE_LIBRARY],
    title: 'library',
  },
  LESSONS: {
    path: 'LESSONS',
    acceptedPaths: ['/lessons'],
    component: LessonsPage,
    exact: true,
    menu: true,
    unauthenticated: [false],
    requiredPermissions: [EXERCISE_LIBRARY],
    title: 'library',
  },
  SCHEDULE: {
    path: 'SCHEDULE',
    acceptedPaths: ['/schedule'],
    component: SchedulePage,
    exact: true,
    menu: true,
    unauthenticated: [false],
    requiredPermissions: [SCHEDULER],
    title: 'timetable',
  },
  UPCOMING: {
    path: 'UPCOMING',
    acceptedPaths: ['/upcoming'],
    component: UpcomingPage,
    exact: true,
    menu: false,
    unauthenticated: [false],
    requiredPermissions: [LIVE_STUDIO_CLASS],
    title: 'studio',
  },
  LESSON_INSTANCE: {
    path: 'LESSON_INSTANCE',
    acceptedPaths: ['/lesson-instance/:id'],
    component: LessonInstancePage,
    exact: true,
    menu: false,
    unauthenticated: [false],
    requiredPermissions: [LIVE_STUDIO_CLASS],
    title: 'lesson instance',
  },
  PRE_LOBBY: {
    path: 'PRE_LOBBY',
    acceptedPaths: ['/pre-lobby/:id'],
    component: PreLobbyPage,
    exact: true,
    menu: false,
    unauthenticated: [false],
    requiredPermissions: [LIVE_STUDIO_CLASS],
    title: 'pre lobby',
  },
  LESSON_DETAILS: {
    path: 'LESSON_DETAILS',
    acceptedPaths: ['/classes/:id'],
    component: LessonPage,
    exact: true,
    menu: true,
    unauthenticated: [false],
    requiredPermissions: [EXERCISE_LIBRARY],
    title: 'lesson details',
  },
  // Profile
  PROFILE: {
    path: 'PROFILE',
    acceptedPaths: ['/profile'],
    component: ProfilePage,
    exact: true,
    unauthenticated: [false],
    menu: true,
    requiredPermissions: [],
    title: 'profile',
  },
  LEGAL: {
    path: 'LEGAL',
    acceptedPaths: ['/legal'],
    component: LegalPage,
    exact: true,
    unauthenticated: [false],
    menu: true,
    requiredPermissions: [],
    title: 'legal',
  },
  STATS: {
    path: 'STATS',
    acceptedPaths: ['/stats'],
    component: StatsPage,
    exact: true,
    unauthenticated: [false],
    menu: true,
    requiredPermissions: [],
    title: 'stats',
  },
};

export default routes;
