export enum StoredInstallationTypes {
  SET_INSTALLATION = 'INSTALLATION/SET_INSTALLATION',
}

export type StoredInstallationArgs = {
  installationId?: number,
  platform?: string,
  userAgent?: string,
};

export const setStoredInstallation = (payload: StoredInstallationArgs) => ({
  type: StoredInstallationTypes.SET_INSTALLATION,
  payload,
});

export type SetStoredInstallation = typeof setStoredInstallation;

export default {
  setStoredInstallation,
};
