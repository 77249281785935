import React from 'react';

import styled from 'styled-components';

import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';

export type Props = {
  tags: Array<(string | undefined)>;
};

const Tag = styled(Typography)`
  margin-right: ${rem(40)};
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 50%;
    border-left: 1px solid ${({ theme }) => theme.colors.beckersKnop};
    margin-left: ${rem(20)};
    margin-top: ${rem(6)};
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
`;

const ClassDetailsTags = ({ tags }: Props) => (
  <>{tags.filter((tag) => tag).map((tag) => (
    <Tag variant="pica" color="beckersKnop" key={tag} data-test="class-details-tag">{ tag }</Tag>
  ))}
  </>
);

export default ClassDetailsTags;
