import { addSeconds } from 'date-fns';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import memoize from 'lodash/memoize';
import storage from 'utils/local-storage-fallback';

import { Login, RefreshTokens, AuthActionTypes } from 'actions/auth';
import createReducer from 'utils/create-reducer';
import { ActionCreator, Handler } from 'actions';
import { analytics } from 'utils/analytics';

type AuthHandler<Action extends ActionCreator> = Handler<AuthState, Action>;

type BaseAuthState = {
  authorization: string | null;
  expires: Date | null;
  loggedIn: boolean;
  refreshToken: string | null;
  userId: number | null;
};

export type AuthState = BaseAuthState & {
  initialized: boolean;
};

const emptyState: BaseAuthState = {
  authorization: null,
  expires: null,
  loggedIn: false,
  refreshToken: null,
  userId: null,
};

const AUTH_COOKIE_NAME = 'fiit_authorization';
const AUTH_LS_EXPIRES = 'fiit_authorization_expiry';
const REFRESH_LS_NAME = 'fiit_authorization_refresh_token';

const setState: (state: AuthState) => void = ({
  authorization,
  expires,
  refreshToken,
}: AuthState) => {
  if (authorization) {
    cookies.set(AUTH_COOKIE_NAME, authorization, { domain: window.location.hostname });
  }
  if (expires) {
    storage.setItem(AUTH_LS_EXPIRES, String(expires));
  }
  if (refreshToken) {
    storage.setItem(REFRESH_LS_NAME, refreshToken);
  }
};

type DecodedAuthToken = {
  pay: {
    user: {
      id: string;
    };
  };
};

export const extractUserIdFromToken = memoize((accessToken: string) => (
  parseInt(jwtDecode<DecodedAuthToken>(accessToken).pay.user.id, 10)
));

const tokenTypeRegex = /^[^ ]* /;
export const extractUserIdFromAuth = memoize((authorization: string) => (
  extractUserIdFromToken(authorization.replace(tokenTypeRegex, ''))
));

const clearState: () => void = () => {
  cookies.remove(AUTH_COOKIE_NAME);
  storage.removeItem(AUTH_LS_EXPIRES);
  storage.removeItem(REFRESH_LS_NAME);
};

export const getPersistedState = (): BaseAuthState => {
  const authorization = cookies.get(AUTH_COOKIE_NAME) || null;
  const expires = storage.getItem(AUTH_LS_EXPIRES);
  const refreshToken = storage.getItem(REFRESH_LS_NAME);

  if (!refreshToken) {
    clearState();
    return emptyState;
  }

  return {
    authorization,
    expires: expires ? new Date(expires) : null,
    loggedIn: true,
    refreshToken,
    userId: authorization ? extractUserIdFromAuth(authorization) : null,
  };
};

const updateTokens: (identify?: boolean) => AuthHandler<Login | RefreshTokens> = (identify = false) => (
  (_state, payload) => {
    const { tokenType, accessToken, expiresIn, refreshToken } = payload;

    const userId = accessToken ? extractUserIdFromToken(accessToken) : null;

    const newState = {
      authorization: `${tokenType} ${accessToken}`,
      expires: addSeconds(new Date(), expiresIn),
      loggedIn: true,
      refreshToken,
      initialized: true,
      userId,
    };

    if (identify && userId) {
      analytics.identify(userId.toString());
    }

    setState(newState);
    return newState;
  }
);

const handlers = {
  [AuthActionTypes.LOGIN]: updateTokens(true),
  [AuthActionTypes.REFRESH_TOKENS]: updateTokens(),
  [AuthActionTypes.LOGOUT]: (): AuthState => {
    analytics.reset();
    clearState();
    return {
      ...emptyState,
      initialized: true,
    };
  },
};

const persistedState = getPersistedState();
export const initialAuthState = {
  ...persistedState,
  // If we're logged in we will check the token still works on load, while we perform this check "initialized = false"
  // If we're not logged in there is no token for us to check so "initialized = true" immediately
  initialized: !persistedState.loggedIn,
};
export const authReducer = createReducer<AuthState>(handlers);
