import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgFavouriteFill = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : 'currentColor';
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <path
        d="M12 22L1.7 12C.6 10.9 0 9.4 0 7.8s.6-3 1.7-4.1C2.8 2.6 4.4 2 5.9 2c1.6 0 3.1.6 4.2 1.7L12 5.5l1.9-1.8C15 2.6 16.5 2 18.1 2s3.1.6 4.2 1.7S24 6.3 24 7.8c0 1.5-.6 3-1.8 4.1L12 22z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
      />
    </Svg>
  );
};

export default SvgFavouriteFill;
