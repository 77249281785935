import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

function SvgDumbbell({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.14159 4.5C3.14159 4.22386 3.36544 4 3.64159 4H5.32929C5.60543 4 5.82929 4.22386 5.82929 4.5V6.62958L10.5052 6.62958V4.5C10.5052 4.22386 10.729 4 11.0052 4H12.6929C12.969 4 13.1929 4.22386 13.1929 4.5L13.1929 10.5509C13.1929 10.8271 12.969 11.0509 12.6929 11.0509H11.0052C10.729 11.0509 10.5052 10.8271 10.5052 10.5509V8.42139L5.82929 8.42139V10.5509C5.82929 10.8271 5.60543 11.0509 5.32929 11.0509H3.64159C3.36544 11.0509 3.14159 10.8271 3.14159 10.5509V4.5ZM1.5 5.73367C1.22386 5.73367 1 5.95753 1 6.23367V8.81728C1 9.09342 1.22386 9.31728 1.5 9.31728H2.7918V5.73367H1.5ZM15.3344 8.81729C15.3344 9.09343 15.1106 9.31729 14.8344 9.31729H13.5426V5.73368L14.8344 5.73368C15.1106 5.73368 15.3344 5.95754 15.3344 6.23368V8.81729Z" fill={fill} />
    </Svg>
  );
}

export default SvgDumbbell;
