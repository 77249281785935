/* eslint-disable max-len */
import React from 'react';
import styled, { useTheme } from 'styled-components';

import useConfig from 'config';

import { ThemeColors } from 'ui/theme';

export type Props = {
  color?: ThemeColors,
  className?: string,
  height?: string,
};

const Svg = styled.svg<{ height: string }>`
  height: ${({ height }) => height};
  width: auto;
`;

const Logo = ({ color, height = '1rem', className }: Props) => {
  const theme = useTheme();
  const { config } = useConfig();

  const fill = color ? theme.colors[color] : 'currentColor';

  if (config.APP_TYPE === 'web') {
    return (
      <Svg
        id="beta_svg__Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 119 89"
        height={height}
      >
        <defs>
          <style>{`.beta_svg__cls-1{fill:${fill}}`}</style>
        </defs>
        <path
          className="beta_svg__cls-1"
          d="M46.25 77.64C46.68 74.92 44.69 73 42 73h-6.09L33.1 89h6.6c3.56 0 5.59-2 5.95-4.52a3.63 3.63 0 0 0-1.43-3.67 4.64 4.64 0 0 0 2.03-3.17ZM41.59 76a1.4 1.4 0 0 1 1.51 1.76 2.12 2.12 0 0 1-2.1 1.72h-3.08l.61-3.48Zm1 8.19a2.3 2.3 0 0 1-2.3 1.9h-3.51l.66-3.77H41a1.5 1.5 0 0 1 1.57 1.84ZM59.33 76l.53-3h-9.77l-2.81 16h9.89l.52-3H51l.64-3.59h6l.55-2.94H52.1l.62-3.47ZM73.12 73H61.37l-.52 3h4.29l-2.29 13H66l2.29-13h4.31ZM81.57 89H85l-2.8-16h-3.94l-8.42 16h3.45l1.48-2.88h6.39Zm-5.27-5.82 3.47-6.66 1 6.66Z"
        />
        <path
          d="M47.32 0a4.93 4.93 0 1 0 4.94 4.93A4.94 4.94 0 0 0 47.32 0ZM.17 18.1h29a.18.18 0 0 1 .17.17v7.12a.17.17 0 0 1-.17.16H8.64a.17.17 0 0 0-.16.17v9.81a.16.16 0 0 0 .16.16h18.29a.17.17 0 0 1 .17.17V43a.17.17 0 0 1-.17.16H8.64a.17.17 0 0 0-.16.17v15.84a.17.17 0 0 1-.17.16H.17a.16.16 0 0 1-.17-.16v-40.9a.17.17 0 0 1 .17-.17Zm118.5.17a.18.18 0 0 0-.17-.17H86a.18.18 0 0 0-.18.17v7.11a.17.17 0 0 0 .18.17h11.8a.18.18 0 0 1 .17.18v33.43a.17.17 0 0 0 .17.17h8.14a.17.17 0 0 0 .17-.17V25.73a.18.18 0 0 1 .17-.18h11.88a.17.17 0 0 0 .17-.17ZM51.39 59.33h-8.14a.17.17 0 0 1-.17-.17V18.27a.18.18 0 0 1 .17-.17h8.14a.17.17 0 0 1 .17.17v40.89a.16.16 0 0 1-.17.17Zm23.33 0h-8.16a.16.16 0 0 1-.16-.16V18.26a.16.16 0 0 1 .16-.16h8.16a.16.16 0 0 1 .16.16v40.91a.16.16 0 0 1-.16.16Zm-9-54.4a4.94 4.94 0 1 1 4.94 4.93 4.94 4.94 0 0 1-4.96-4.93Z"
          style={{
            fillRule: 'evenodd',
            fill,
          }}
        />
      </Svg>
    );
  }
  return (
    <Svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 246.78 123.04"
      xmlSpace="preserve"
      height={height}
      className={className}
    >
      <title>FIIT LOGO</title>
      <path
        fill={fill}
        d="M935.69,479.25a10.79,10.79,0,1,0,7.79,7.79A10.78,10.78,0,0,0,935.69,479.25Z"
        transform="translate(-836.88 -478.9)"
      />
      <path
        fill={fill}
        d="M888.18,553.43h-33.1a.85.85,0,0,1-.85-.85V533a.85.85,0,0,1,.85-.86h42.57a.85.85,0,0,0,.85-.85V518a.85.85,0,0,
          0-.85-.85H837.73a.85.85,0,0,0-.85.85v83.14a.85.85,0,0,0,.85.85h15.64a.85.85,0,0,0,.86-.85V569.35a.85.85,0,0,
          1,.85-.85h33.1a.85.85,0,0,0,
          .85-.86V554.29A.85.85,0,0,0,888.18,553.43Z"
        transform="translate(-836.88 -478.9)"
      />
      <rect fill={fill} x="87.02" y="38.19" width="17.35" height="84.85" rx="0.85" />
      <rect fill={fill} x="136.51" y="38.19" width="17.35" height="84.85" rx="0.85" />
      <path
        fill={fill}
        d="M1082.8,517.1H1017a.86.86,0,0,0-.86.85v13.84a.86.86,0,0,0,.86.85h23.44a.85.85,0,0,1,.85.86v67.59a.85.85,0,0,
          0,.85.85h15.53a.85.85,0,0,0,.85-.85V533.5a.85.85,0,0,1,.85-.86h23.44a.85.85,0,0,0,.86-.85V518A.85.85,0,0,0,
          1082.8,517.1Z"
        transform="translate(-836.88 -478.9)"
      />
      <path
        fill={fill}
        d="M984.71,479.25a10.79,10.79,0,1,0,7.8,7.79A10.79,10.79,0,0,0,984.71,479.25Z"
        transform="translate(-836.88 -478.9)"
      />
    </Svg>
  );
};

export default Logo;
