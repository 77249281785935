import * as Sentry from '@sentry/browser';
import { getPersistedState } from 'state/auth';
import storage, { hasLocalStorage } from 'utils/local-storage-fallback';

type Options = {
  label?: string,
  streamName?: string,
  logUrl?: string,
  loggerLevel?: LoggingLevel,
};

type LogLevelArray = LoggingLevel[];

type LoggerLevelsMap = {
  [key in LoggingLevel]: LogLevelArray
};

// Debug level to use for the external logger
const loggerErrorLevels: LogLevelArray = ['error'];
const loggerWarnLevels: LogLevelArray = ['warn', ...loggerErrorLevels];
const loggerInfoLevels: LogLevelArray = ['info', ...loggerWarnLevels];
const loggerDebugLevels: LogLevelArray = ['debug', ...loggerInfoLevels];
const logLevels: LogLevelArray = ['log', ...loggerDebugLevels];
const loggerLevels: LoggerLevelsMap = {
  log: logLevels,
  debug: loggerDebugLevels,
  info: loggerInfoLevels,
  warn: loggerWarnLevels,
  error: loggerErrorLevels,
};

export type LoggingLevel = 'log' | 'debug' | 'info' | 'warn' | 'error';
export type Logging = Pick<typeof console, LoggingLevel>;

const logging = console as Logging;
export default logging;

const SENTRY_LEVELS = {
  warn: 'warning',
  error: 'error',
};

const getPersistedErrors = () => {
  const data = storage.getItem('persistedErrors');
  if (!data) {
    return null;
  }
  return JSON.parse(data);
};

const storeError = (error: any, extra?: Record<string, any>) => {
  if (!hasLocalStorage) {
    return null;
  }

  const objectToStore = {
    message: typeof error === 'string' ? error : error.message,
    ...(error.stack ? { stack: error.stack } : {}),
    ...extra,
  };

  const persistedErrors = getPersistedErrors();
  if (!persistedErrors) {
    return storage.setItem('persistedErrors', JSON.stringify([objectToStore]));
  }

  const stringifiedError = JSON.stringify([
    ...persistedErrors,
    objectToStore,
  ]);
  return storage.setItem('persistedErrors', stringifiedError);
};

const handleErrorWarning = (level: LoggingLevel, logObj: any, extra?: Record<string, any>) => {
  if (!navigator.onLine) {
    storeError(logObj, extra);
  }

  Sentry.configureScope((scope) => {
    // @ts-ignore yeah I'm checking if level is an actual key
    const sentryLevel = SENTRY_LEVELS[level];
    if (sentryLevel) {
      scope.setLevel(sentryLevel);
    }
    const { userId } = getPersistedState();
    if (userId) {
      scope.setUser({ id: userId.toString() });
    }

    if (extra) {
      scope.setExtras(extra);
    }
  });

  if (typeof logObj === 'string') {
    Sentry.captureMessage(logObj);
  } else {
    Sentry.captureException(logObj);
  }
};

export const createLogger = ({ label, streamName, logUrl, loggerLevel }: Options) => {
  if (!label) {
    return logging;
  }

  return (logLevels as LoggingLevel[]).reduce((acc: any, level: LoggingLevel) => {
    acc[level] = (logObj: any, extra?: Record<string, any>) => {
      if (loggerWarnLevels.includes(level)) {
        handleErrorWarning(level, logObj, extra);
      }

      const args = [
        `[${label}]`,
        logObj,
        ...(extra ? [extra] : []),
      ];

      // Log to console
      logging[level](...args);

      // Send to logger
      if (
        logUrl && streamName && loggerLevel &&
        // logger level set by env
        loggerLevels[loggerLevel] && loggerLevels[loggerLevel].includes(level)
      ) {
        // Only care about logger request issues
        fetch(logUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: JSON.stringify({
              level,
              label,
              message: logObj,
              extra,
            }),
            timestamp: new Date().getTime(),
            logStreamName: streamName,
          }),
        }).catch((e) => {
          handleErrorWarning(
            'warn',
            `Issue sending message to logger: ${e.message}`,
            { extra: args },
          );
        });
      }
    };
    return acc;
  }, {}) as Logging;
};

const logger = createLogger({ label: 'persisted-errors' });
export const resolvePersistedErrors = (fromAppStartUp: boolean = false) => {
  const persistedErrors = getPersistedErrors();
  if (!persistedErrors) {
    if (fromAppStartUp) {
      return;
    }
    logger.info('window.online event triggered, network connection restored, no persisted errors recorded');
    return;
  }
  if (fromAppStartUp) {
    // setTimeout to make sure sentry has been initialised before we try to send it errors
    setTimeout(() => {
      logger.error('Persisted errors from app start up', persistedErrors);
      storage.removeItem('persistedErrors');
    }, 1000);
  } else {
    logger.info('window.online event triggered, network connection restored', persistedErrors);
    storage.removeItem('persistedErrors');
  }
};
