import React from 'react';
import styled from 'styled-components';

export type Props = {
  className?: string,
};

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1.1rem;
  height: 1.1rem;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgSpinner = ({ className = '' }: Props) => (
  <Svg className={className} viewBox="0 0 24 24">
    <defs>
      {/* eslint-disable-next-line max-len */}
      <path id="spinner_svg__a" d="M13.001 18.929L13 23.96c-.33.03-.66.04-1 .04-.34 0-.67-.01-1-.04v-5.03c.327.046.66.07 1 .07.34 0 .674-.024 1.001-.071zm-3.6-.427L6.89 22.86c-.61-.28-1.19-.62-1.74-1l2.515-4.364c.524.414 1.108.754 1.737 1.006zm-2.897-2.166L2.14 18.85c-.38-.55-.72-1.13-1-1.74l4.358-2.51c.252.628.593 1.212 1.006 1.736zM5 12c0 .34.024.674.071 1.001L.04 13c-.03-.33-.04-.66-.04-1 0-.34.01-.67.04-1h5.03c-.046.327-.07.66-.07 1zm18.96-1c.03.33.04.66.04 1 0 .34-.01.67-.04 1l-5.031.001c.047-.327.071-.661.071-1.001 0-.34-.024-.673-.07-1zM2.14 5.15l4.364 2.515c-.414.524-.754 1.108-1.006 1.737L1.14 6.89c.28-.61.62-1.19 1-1.74zm19.72 0c.38.55.72 1.13 1 1.74l-4.358 2.512c-.252-.63-.592-1.213-1.006-1.737zM6.89 1.14l2.512 4.358c-.63.252-1.213.592-1.737 1.006L5.15 2.14c.55-.38 1.13-.72 1.74-1zm10.22 0c.61.28 1.19.62 1.74 1l-2.515 4.364c-.523-.414-1.107-.754-1.736-1.006zM12 0c.34 0 .67.01 1 .04l.001 5.031C12.674 5.024 12.34 5 12 5c-.34 0-.673.024-1 .07V.04c.33-.03.66-.04 1-.04z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="spinner_svg__b" fill="#fff">
        <use xlinkHref="#spinner_svg__a" />
      </mask>
      <use fill="#000" xlinkHref="#spinner_svg__a" />
      <g fill="#FFF" mask="url(#spinner_svg__b)">
        <path d="M0 0H24V24H0z" />
      </g>
    </g>
  </Svg>
);

export default SvgSpinner;
