import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';

const BadgeContainer = styled.div<{ marginRight: boolean, outline: boolean, filled: boolean }>`
  ${({ theme, outline }) => (outline ? `border: 1px solid ${theme.colors.white}` : '')};
  border-radius: ${rem(2)};
  margin-bottom: ${rem(5)};
  ${({ marginRight }) => (marginRight ? 'margin-right: 0.5rem' : '')};
  ${({ filled }) => (filled ? 'background-color: rgba(0,0,0,0.4)' : '')};
  width: ${rem(34)};
  height: ${rem(34)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  children: ReactNode,
  marginRight?: boolean,
  outline?: boolean,
  fill?: boolean,
};

const CardBadge = ({ children, marginRight = false, outline = true, fill = false } : Props) => (
  <BadgeContainer marginRight={marginRight} outline={outline} filled={fill}>
    {children}
  </BadgeContainer>
);

export default CardBadge;
