import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MappedCircuit } from 'ui/components/molecules/current-exercise/utils';
import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';

type ClassBreakdownProps = {
  circuits: MappedCircuit[],
};

type RowProps = {
  name: string,
  subText: string,
  isBreakSection: boolean,
};

const Wrapper = styled.div`
  border-radius: 20px;
  border: ${({ theme }) => `4px solid ${theme.colors.janosGrey}`};
  padding: ${rem(40)};
  width: ${rem(550)};
  margin-left: ${rem(55)};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  height: fit-content;
  max-height: 100%;

  @media (max-width: 80rem) {
    width: ${rem(450)};
  }
`;

const RowWrapper = styled.div<{isBreakSection: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${rem(16)} 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.janosGrey}`};
  color: ${({ theme, isBreakSection }) => (isBreakSection ? theme.colors.classicGrey : theme.colors.white)};

  &:last-child {
    border-bottom: none;
  }
`;

const NameWrapper = styled(Typography)`
  display: flex;
  flex-direction: column;
  width: 58%;
  height: 100%;

  @media (max-width: 80rem) {
    font-size: 0.8rem;
  }
`;

const DurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 27%;
  height: 100%;
  text-align: right;

  @media (max-width: 80rem) {
    font-size: 0.8rem;
  }
`;

const Header = styled(Typography)`
  margin-bottom: ${rem(36)};
`;

const Row = ({ name, subText, isBreakSection }: RowProps) => (
  <RowWrapper isBreakSection={isBreakSection}>
    <NameWrapper weight="bold">{name}</NameWrapper>
    <DurationWrapper>{subText}</DurationWrapper>
  </RowWrapper>
);

const getBreakdownSections = (mappedTimeline: MappedCircuit[] = []) => (
  mappedTimeline.map(({ name, duration, type }, index) => {
    const isBreakSection = ['INTRO_SECTION', 'TRANSITION_SECTION', 'OUTRO_SECTION'].includes(type || '');
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const subText = `${minutes ? `${minutes}m` : ''} ${seconds ? `${seconds}s` : ''}`;

    return (
      <Row
        key={`${name}-${index}`}
        name={name}
        subText={subText}
        isBreakSection={isBreakSection}
      />
    );
  })
);

const ClassBreakdown = ({ circuits }: ClassBreakdownProps) => {
  const sectionRows = useMemo(() => getBreakdownSections(circuits), [circuits]);

  return (
    <Wrapper>
      <Header weight="bold">Class Overview</Header>
      {sectionRows}
    </Wrapper>
  );
};

export default ClassBreakdown;
