import { ChromecastActionTypes, ChromecastAddCallbackPayload, ChromecastCallback } from 'actions/chromecast';
import createReducer from 'utils/create-reducer';

export type ChromecastState = {
  callbacks: {
    [key: string]: ChromecastCallback,
  },
};

const addCallback = (state: ChromecastState, payload: ChromecastAddCallbackPayload) => {
  // eslint-disable-next-line no-param-reassign
  state.callbacks[payload.messageType] = payload.callback;

  return state;
};

const handlers = {
  [ChromecastActionTypes.ADD_CALLBACK]: addCallback,
};

export const initialChromecastState = {
  callbacks: {},
};

export const chromecastReducer = createReducer(handlers);
