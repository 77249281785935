import { config } from 'config/index.tsx';

interface SegmentOpts {
  integrations?: any;
  anonymousId?: string;
  context?: object;
}

export interface Analytics {
  // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/segment-analytics/index.d.ts
  page(name?: string, properties?: Object,
    options?: SegmentOpts, callback?: () => void): void;
  page(name?: string, properties?: Object): void;

  /* The track method lets you record any actions your users perform. */
  track(event: EventNames, properties?: Object, options?: SegmentOpts,
    callback?: () => void): void;
  track(event: EventNames, callback?: () => void): void;

  /* The identify method is how you tie one of your users and their actions
     to a recognizable userId and traits. */
  identify(userId: string, traits?: Object, options?: SegmentOpts,
    callback?: () => void): void;
  identify(userId: string, callback?: () => void): void;
  identify(traits?: Object, callback?: () => void): void;

  /* The ready method allows you to pass in a callback that will be called as
   soon as all of your enabled integrations have loaded. It’s like jQuery’s
   ready method, except for integrations. */
  ready(callback?: () => void): void;

  /* If you need to clear the user and group id and traits we’ve added a
   reset function that is most commonly used when your identified users
   logout of your application. */
  reset(): void;
}

const eventNames = [
  'TrainingPlanStarted',
  'TrainingPlanCancelled',
  'ContentStateChanged',
  'FavouriteLesson',
  'WorkoutStarted',
  'WorkoutFinished',
] as const;

type EventNames = typeof eventNames[number];

const context = {
  app: {
    build: config.APP_TYPE,
    version: config.VERSION,
  },
};

export const analytics: Analytics = {
  track: (event: EventNames, properties?: Object, options?: SegmentOpts) => {
    window.analytics.track(event, properties, { context, ...options });

    if (window.$badger) {
      window.$badger.appActionMetricsHandler(event, properties as { [key: string]: string | number | boolean });
    }
  },

  page: (name?: string, properties?: Object, options?: SegmentOpts) => {
    window.analytics.page(name, properties, { context, ...options });
  },

  identify: (userId: string, traits?: Object, options?: SegmentOpts) => {
    window.analytics.identify(userId, traits, { context, ...options });
  },

  ready: (callback?: () => void) => {
    window.analytics.ready(callback);

    if (window.$badger) {
      window.$badger.dismissLoadingScreen();
    }
  },

  reset: () => {
    window.analytics.reset();
  },
};
