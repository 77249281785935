import React, { memo } from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';
import StatCounter from 'ui/components/molecules/stat-counter';
import PersonalBestIcon from 'ui/components/atoms/icons/personal-best';
import { IconProps } from 'ui/components/atoms/icons/types';

const Wrapper = styled.div<{ alignRight?: boolean }>`
  display: flex;
  justify-content: ${({ alignRight }) => (alignRight ? 'flex-end' : 'center')};
  align-items: center;
  height: ${rem(100)};
  border-radius: ${rem(3)};
`;

const StyledStatCounter = styled(StatCounter)`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
`;

const StyledPersonalBestIcon = styled(PersonalBestIcon)`
  width: 0.8rem;
  height: 0.8rem;
`;

const StyledIcon = styled.div<{ disabled: boolean }>`
  width: 0.8rem;
  height: 0.8rem;
  ${({ disabled }) => (disabled ? 'opacity: 0.5' : '')}
`;

type Props = {
  metricCount?: number | null,
  personalBest?: number,
  showPersonalBest?: boolean,
  metricLabel: string,
  metricIcon: ({ className, color }: IconProps) => JSX.Element,
  className?: string,
  disabled?: boolean,
  alignRight?: boolean,
};

const InClassMetric = ({
  className,
  metricCount,
  personalBest,
  metricLabel,
  metricIcon,
  showPersonalBest = false,
  disabled = false,
  alignRight,
}: Props) => (
  <Wrapper className={className} alignRight={alignRight}>
    <StyledStatCounter
      fontVariant="double-pica"
      label={metricLabel}
      icon={<StyledIcon disabled={disabled} as={metricIcon} />}
      value={metricCount}
      disabled={disabled}
    />
    {showPersonalBest && (
      <StyledStatCounter
        disabled={(metricCount || 0) <= (personalBest || 0)}
        fontVariant="double-pica"
        label="PB"
        icon={<StyledPersonalBestIcon color="skyBlue" />}
        value={metricCount ? Math.max(personalBest || 0, metricCount) : personalBest}
      />
    )}
  </Wrapper>
);

export default memo(InClassMetric);
