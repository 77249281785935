import React, { RefObject, useRef, SyntheticEvent, useEffect } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { useSpatialTarget } from 'utils/spatial-nav';
import Cross from 'ui/components/atoms/icons/cross';
import useCustomKeyboard from 'utils/use-custom-keyboard';

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing.l} 0;

  :first-child {
    margin-top: 0;
  }
`;

type InputProps = {
  theme: DefaultTheme,
  hasError: boolean,
};

const Field = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${({ theme, hasError }: InputProps) => (hasError
    ? theme.colors.red
    : theme.colors.janosGrey
  )};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;

  &:focus {
    outline: 0 !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.fiitBlue};
  }
`;

const TextLabel = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing.l};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.janosGrey};
`;

export type Props = {
  id: string,
  type?: HTMLInputElement['type'],
  inputRef?: RefObject<HTMLInputElement>,
  label: string,
  className?: string,
  autofocus?: boolean,
  onKeyDown?: (event: SyntheticEvent) => void,
  errorMessage?: string,
};

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-transform: uppercase;
  padding-top: 10px;
`;

const CrossIcon = styled(Cross)`
  margin-left: -0.8rem;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextField = ({
  id,
  type = 'text',
  inputRef,
  label,
  autofocus = false,
  className,
  onKeyDown,
  errorMessage,
}: Props) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const elRef = inputRef || ref;

  const { showKeyboard, hideKeyboard } = useCustomKeyboard({
    title: label,
    message: `Type your ${label}`,
    mask: type === 'password' ? type : null,
  });

  const { setFocus } = useSpatialTarget({
    id,
    elRef,
    autofocus,
    onFocus: () => showKeyboard(elRef.current),
  });

  useEffect(() => () => hideKeyboard());

  return (
    <FieldContainer className={className}>
      <TextLabel size="xs" variant="pica" as="label" htmlFor={id}>{label}</TextLabel>
      <InputContainer>
        <Field
          hasError={!!errorMessage}
          type={type}
          id={id}
          ref={elRef}
          onKeyDown={onKeyDown}
          onClick={setFocus}
          autoComplete="off"
        />
        { errorMessage && (<CrossIcon color="red" />) }
      </InputContainer>
      {errorMessage && (
      <StyledErrorMessage>
        <Typography size="s" spacing="l" weight="bold">{errorMessage}</Typography>
      </StyledErrorMessage>
      )}
    </FieldContainer>
  );
};

export default TextField;
