import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';
import useConfig from 'app/on-tv/config-provider';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgFiitPoints = ({ className = '', color }: IconProps) => {
  const { config } = useConfig();
  const isFireTV = config.APP_TYPE === 'amazon';
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#D400FF';

  // Amazon TV Fire OS 7.2.7.3 (PS7273/2625) caused SVG icons to render black video screen. PNG works
  if (isFireTV) {
    return (
      <Svg className={className} viewBox="0 0 16 16">
        <image href="https://images-bucket.prod.fiit-static.net/app/uploads/2022/04/14140652/POINTS.png" height="16" width="16" />
      </Svg>
    );
  }

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <defs>
        <path
          id="fiit-points_svg__a"
          d="M8 13.267L3.056 16 4 10.211l-4-4.1 5.528-.844L8 0l2.472 5.267L16 6.11l-4 4.1.944 5.789z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={theme.colors.white}
          fillOpacity={0.005}
          fillRule="nonzero"
          d="M0 0h16v16H0z"
          style={{
            mixBlendMode: 'screen',
          }}
        />
        <mask id="fiit-points_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#fiit-points_svg__a" />
        </mask>
        <use fill={theme.colors.black} xlinkHref="#fiit-points_svg__a" />
        <g fill={fill} mask="url(#fiit-points_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgFiitPoints;
