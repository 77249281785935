import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';

import ErrorIcon from 'ui/components/atoms/icons/error-circle';

export enum NotificationPopupType {
  ERROR,
}

export type Props = {
  message: string,
  type?: NotificationPopupType,
};

const Notification = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5rem;
  z-index: 9999;
  background: ${({ theme }) => theme.colors.fordDarkCharcoal};
  border: 2px solid ${({ theme }) => theme.colors.janosGrey};
  box-shadow: 2px 1px 4px rgba(255, 255, 255, 0.1);
  border-radius: ${rem(5)};
  padding: ${rem(25)};
  width: 20vw;
`;

const Message = styled.p`
  font-size: 0.65rem;
  margin-top: 0.85rem;
  margin-bottom: 0.15rem;
`;

const Title = styled.p`
  line-height: 20px;
  margin-left: 0.5rem;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const getPopupTitle = (type: NotificationPopupType | undefined): ReactElement => {
  switch (type) {
    case NotificationPopupType.ERROR:
      return (
        <>
          <ErrorIcon />
          <Title>Error</Title>
        </>
      );
    default:
      return (
        <>
          <ErrorIcon />
          <Title>Notification</Title>
        </>
      );
  }
};

const NotificationPopup = ({ message, type }: Props) => (
  <Notification>
    <TitleArea>{getPopupTitle(type)}</TitleArea>
    <Message>{message}</Message>
  </Notification>
);

export default NotificationPopup;
