import React from 'react';
import LottieAnimation from 'ui/components/atoms/animated-icons/lottie-animation';

import animationData from './user-animation.json';

const AnimatedSVGUser = () => (
  <LottieAnimation animationData={animationData} loop autoplay />
);

export default AnimatedSVGUser;
