import React, { ElementType, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';
import MenuIcon from 'ui/components/atoms/menu-icon';
import Typography from 'ui/components/atoms/typography';

export type MenuItem = {
  id: string,
  icon: ElementType,
  selected: boolean,
  handleAction?: (event: SyntheticEvent) => void,
};

export type Props = {
  items: MenuItem[],
  title?: string,
  logo?: ReactNode,
};

export const height = rem(84);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: ${height};
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MenuList = styled.div`
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Title = styled(Typography)`
  opacity: 90%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  width: 29%;
`;

const LogoContainer = styled.div`
  margin-left: 1.5em;
`;

const StyledMenuIcon = styled(MenuIcon)`
  width: 33.3%;
`;

const TopMenu = ({
  items = [],
  title,
  logo,
}: Props) => (
  <Wrapper>
    <Content>
      <MenuList>
        { items.map((item, i) => (
          <StyledMenuIcon
            key={i}
            icon={<item.icon selected={item.selected} />}
            onClick={item.handleAction}
          />
        )) }
        {logo && (
          <LogoContainer>{logo}</LogoContainer>
        )}
        <Title variant="pica">{title}</Title>
      </MenuList>
    </Content>
  </Wrapper>
);

export default TopMenu;
