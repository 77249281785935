import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';
import useConfig from 'app/on-tv/config-provider';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 0.6rem;
  height: 0.6rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgKcal = ({ className = '', color }: IconProps) => {
  const { config } = useConfig();
  const isFireTV = config.APP_TYPE === 'amazon';
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FF9F00';

  // Amazon TV Fire OS 7.2.7.3 (PS7273/2625) caused SVG icons to render black video screen. PNG works
  if (isFireTV) {
    return (
      <Svg className={className} viewBox="0 0 16 16">
        <image href="https://images-bucket.prod.fiit-static.net/app/uploads/2022/04/14140655/KCAL.png" height="16" width="16" />
      </Svg>
    );
  }

  return (
    <Svg className={className} viewBox="0 0 16 16">
      <defs>
        <path
          id="kcal_svg__a"
          d="M8.896 0c-.342.149-.727.428-.951.606a6.732 6.732 0 00-.896.852c-.693.8-1.226 1.764-1.34 2.812-.074.691.074 1.397.281 2.056.21.66.513 1.309.552 2.008a1.09 1.09 0 01-.052.45c-.112.294-.408.404-.707.292a1.21 1.21 0 01-.417-.291c-.437-.438-.7-1.014-.847-1.604a7.695 7.695 0 01-.18-1.26c-.014-.188-.03-.378-.047-.564a12.04 12.04 0 01-.01-.607C.5 7.764 1.566 14.452 6.4 15.786c2.29.633 4.951-.17 6.416-1.98 2.286-2.829 1.05-6.887-1.878-8.758C7.415 2.798 8.896.001 8.897 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={theme.colors.white}
          fillOpacity={0.005}
          fillRule="nonzero"
          d="M0 0h16v16H0z"
          style={{
            mixBlendMode: 'screen',
          }}
        />
        <mask id="kcal_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#kcal_svg__a" />
        </mask>
        <use fill={theme.colors.black} xlinkHref="#kcal_svg__a" />
        <g fill={fill} mask="url(#kcal_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgKcal;
